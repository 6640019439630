import moment from 'moment'

export function downloadFile(videoUrl, title, callback) {
  // eslint-disable-next-line
  const xhr = new XMLHttpRequest();
  xhr.open('GET', videoUrl, true);
  xhr.responseType = 'blob';
  xhr.onprogress = (event) => {
    if (event.lengthComputable) {
      if (callback) callback(event)
    }
  }
  xhr.onload = function () {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], {
        type: 'audio/wav'
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.wav`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  xhr.send();
}

export function formatTime(val) {
  let time = new Date(val)
  moment.locale(['en-AU'])
  if (moment(time).format('MMM') === 'Sep') {
    return 'Sept ' + moment(time).format('DD, YYYY')
  } else {
    return moment(time).format('MMM DD, YYYY HH:mm')
  }
}

// 获取audio的时长
export const dealSoundTime = item => {
  const time = Math.floor(item)
  const minute = time / 60
  let minutes = Math.floor(minute)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  let seconds = time % 60
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${minutes}:${seconds}`
}

export default {
  downloadFile,
  formatTime,
  dealSoundTime
}