import axiosRequest from '@/utils/axios'

// 查看用户弹窗：取消订阅折扣
export const recoverSubCoupon = ()=> 
  axiosRequest.get(`/gateway-apis/account/v1/background/recoverSubCoupon`)

// 取消订阅后有效期内恢复订阅，并使用优惠券
export const recoverSubscription = (data)=> 
  axiosRequest.get(`/gateway-apis/account/v1/stripePay/recoverSubscription`, data)

// 查看用户account展示信息
export const getAccountPayAmount = (data)=> 
  axiosRequest.get(`/gateway-apis/account/v1/background/getAccountPayAmount`, data)

export default {
  recoverSubCoupon,
  recoverSubscription,
  getAccountPayAmount
}