import axiosRequest from '@/utils/axios'

export const aiSoundEffect = (data) =>
  axiosRequest.post('/gateway-apis/tts/v1/aiSoundEffect', data)

export const aiSoundEffectList = (data) =>
axiosRequest.get('/gateway-apis/tts/v1/aiSoundEffect/list', data)

export const aiSoundEffectRandomPrompt = (data) =>
axiosRequest.get('/gateway-apis/tts/v1/aiSoundEffect/randomPrompt', data)

export default {
  aiSoundEffect,
  aiSoundEffectList,
  aiSoundEffectRandomPrompt
}