// 用户帐号信息
import api from '@/utils/http-request.js'
import { VIP_TYPE, VIP_INTERVAL, PRODUCT_TYPE } from "@/constants/vip.js"
import { getPriceObj } from "@/component/pricing-component.vue"
import { Loading, Message } from "element-ui"
import Vue from 'vue'
import router from "../../router/router"
import stat from "@/utils/stat"
import { windOpen } from '../../utils/utils'
const vm = new Vue()
const state = {
  user: {}, // 用户信息
  isUserLogin: false,
  userInfoGot: false, // 是否获取到用户信息
  companyList: [], // 专辑列表
  usageRestrictions: '', // 用户可用时长，字数提示
  transitionCharacterLeft: {}, //翻译可用字数提示
  userRights: {}, // 用户可用时长， 可用字数
  vipInfo: { // 会员订阅信息
    subscribe: false, // 是否订阅
    type: VIP_TYPE.FREE, // 0:个人版会员  1:专业版会员  2:非会员
    interval: '', // 订阅单位：month，year
  },
  showPriceModal: false, // 展示价格弹框
  priceSource: '', //价格弹窗点击的来源（埋点使用）
  creditsDetalisModal:false,//credits详情弹框
}

const actions = {
  setUserInfo(context, info) {
    context.commit('setUserInfo', info)
  },
  // isLogOrSign 登录注册标识
  async getUserInfo({ commit }, params = {}) {
    try {
      let res = await api.getUserInfo(params)
      let {data: {data, code, message}} = res
      if (code !== 200) {
        return Promise.reject({ errMsg: message || `${vm.$t('errTxt.serverError')}，code65` })
      }
      localStorage.setItem('userInfo', JSON.stringify(data))
      stat.setUserID()
      commit('setUserInfo', data)
      return Promise.resolve(data)
    }catch(err) {
      console.log('getuserdata-----', err)
      // throw new Error('Login exception')
      return Promise.reject(err)
    }finally{
      commit('setUserInfoGot')
    }
  },
  async setCompanyList(context) {
    try {
      const responseCompany = await api.getCompanyList()
      let companys = responseCompany.data.data
      const list = companys.map(item => {
        return {
          id: item.id,
          type: item.type,
          name: item.name,
          amount: item.amount
        }
      })
      context.commit('setCompanyList', list)
    } catch (e) {
      console.log(e, 'err')
    }
  },
  // 切换tab页 需要取消上次的请求
  async getUserRights(context) {
    context.commit('setUsageRestrictions', '')
    context.commit('setTransitionCharacterLeft', {})
    try {
      let res = await api.getUserRights()
      let {
        data: { code, data }
      } = res
      if (code === 200) {
        context.commit('setUserRights', data)
        return Promise.resolve(data)
      }
    } catch (err) {
      console.log(err, 'err-getUserRights')
      return Promise.reject(err)
    }
  },
  // 获取最新的会员订阅信息
  async getVipInfo({ commit }) {
    const { code, data, message } = await api.getVipInfo();
    if (code === 200) {
      commit('setVipInfo', data)
      return data
    } else {
      throw new Error(message)
    }
  },
  // 创建/更新/续订会员订阅
  /**
   * 如果没有传入_payload则会走续订流程
   * _payload.productType 产品类型
   * _payload.vipType 订阅会员等级
   * _payload.interval 订阅周期
   * @returns 是否跳转订阅页面
   */
  async subscribeVipAfterLogin({ dispatch }, _payload) {
    const showLoading = _payload?.showLoading ?? true
    let $load = null
    if (showLoading) {
      $load = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)',
        customClass: 'custom-subscribe-loading'
      })
    }
    try {
      let payload = null

      const [vipInfo, hasSubscribed = false, prices] = await Promise.all([
        // 不管是什么情况，订阅前都去重新获取下最新的订阅信息
        // 防止用户中途离开页面去修改订阅状态
        dispatch('getVipInfo'),
        dispatch('hasSubscribed'),
        api.getPriceInfo().then(res => {
          if (res.code === 200) {
            return res.data
          }
          console.log('获取价格列表出错', res)
          throw new Error(res)
        })]
      )
      if (_payload) {
        if(_payload.productType === PRODUCT_TYPE.CREDITS){
          //credits一次性付费
          const priceObj = prices.find(item => item.name === _payload.currentCreditsType)
          payload = {
            initPricingDiscount: _payload.initPricingDiscount || 1,
            promotionCode: _payload.promotionCode || '',
            productType: _payload.productType,
            priceId: priceObj.price_id || '',
            priceObj
          }
        } else if (_payload.productType === PRODUCT_TYPE.LIFETIME_PAYMENT) {
          // 39一次性付费
          const priceObj = prices.find(item => item.name === 'Lifetime Plan')
          payload = {
            initPricingDiscount: _payload.initPricingDiscount || 1,
            productType: _payload.productType || PRODUCT_TYPE.SUBSCRIPTION,
            priceId: priceObj.price_id || '',
            interval: null,
            vipType: null,
            priceObj
          }
        } else {
          // 获取指定计划的priceId
          const priceObj = getPriceObj(
            _payload.vipType,
            _payload.interval,
            prices
          )
          payload = {
            initPricingDiscount: _payload.initPricingDiscount || 1,
            promotionCode: _payload.promotionCode || '',
            productType: _payload.promotionCode ? 'COUPON' : (_payload.productType || PRODUCT_TYPE.SUBSCRIPTION),
            priceId: priceObj.priceId,
            interval: _payload.interval,
            vipType: _payload.vipType,
            priceObj
          }
        }
      } else {
        // 续订需要查询下当前的priceId
        const priceObj = getPriceObj(
          vipInfo.type,
          vipInfo.interval,
          prices
        )
        payload = {
          productType: _payload?._payload.productType || PRODUCT_TYPE.SUBSCRIPTION, 
          vipType: vipInfo.type,
          interval: vipInfo.interval,
          priceId: priceObj.priceId,
          priceObj
        }
      }

      const { priceId, interval, vipType, productType, priceObj, promotionCode = '', initPricingDiscount = 1} = payload
      if (vipInfo.subscribe && vipInfo.interval === interval && vipInfo.type === vipType) {
        // 防止重复订阅
        $load?.close()
        return false
      }
    
      const isPayment = [PRODUCT_TYPE.LIFETIME_PAYMENT, PRODUCT_TYPE.CREDITS].includes(productType)

      let successParams = isPayment ? `productType=${payload.productType}` : `vipType=${vipType}&vipInterval=${interval}`
      
      let unitAmount = `${Math.ceil((priceObj.unit_amount || 0) / 100 * initPricingDiscount)}`

      successParams += `&currency=${priceObj.currency}&unitAmount=${unitAmount}`
      
      if (_payload.discount) successParams += `&discount=${_payload.discount}`

      //处理productType的逻辑 
      let postProductType = _payload.productType ? _payload.productType : (promotionCode ? 'COUPON' : (isPayment ? 'PAYMENT' : 'SUBSCRIPTION'));
      const res = (isPayment // 一次性产品
        || (!vipInfo.subscribe && vipInfo.type === VIP_TYPE.FREE)// 不在订阅态
        || !hasSubscribed)// 未订阅过
        ? await api.subscribeVip({
          productType: postProductType,
          priceId,  
          successUrl: `${location.origin}/pricing-success?${successParams}`,
          cancelUrl: location.href,
          promotionCode: promotionCode || '',
          referral: window.rewardfulData || ''
        })
        : await api.updateSubscribeVip({
          priceId,
          returnUrl: location.href
        })
      if (res.code === 200) {
        $load?.close()
        if(_payload && _payload.currentPageOpen) { ///当前窗口打开
          location.href = res.data
        } else {
          // 默认新窗口打开
          windOpen(res.data)
        }
        return true
      }
      console.log('创建/更新订阅出错:', res)
    } catch (e) {
      console.log('创建/更新订阅出错:', e)
    }
    Message.error('Failed to submit')
    $load?.close()
    return false
  },
  // true 没有购买过会员 但是可能送了vip
  async hasSubscribed() {
    try {
      const result = await api.isNewFreeCus()
      const { code, data } = result.data
      if (code === 200) {
        return !data.isNewFreeCus
      }
    } catch {
      //
    }
    return false
  },
  /**
   * 处理了登录状态的会员订阅，未登录会先去跳转登录页面
   * @param {*} interval 
   * @param {*} vipType
   * @returns 
   */
  async subscribeVipWithLogin({ dispatch }, payload) {
    try {
      // 从后台检查登录状态，防止从其他tab登出系统
      await dispatch('getUserInfo')
    } catch (e) {
      // 登出系统了、非登录态跳转登录页
      router.push({
        path: '/login',
        query: {
          // 注意/third/vip/subscribe是不存在的路由，
          // 仅仅在login页面登录完成后用来判断跳转第三方支付
          loginTarget: 'stripe',
          to: payload.from || '/',
          payload: JSON.stringify(payload)
        }
      })
      return
    }

    // 登录态去订阅，调用后台接口获取支付链接后跳转
    await dispatch('subscribeVipAfterLogin', payload)
    return
  },

  /**
   * 订阅3天试用会员
   * @returns 
   */
  async subscribe3DayFreeTrialAfterLogin({dispatch}) {
    // 已购买直接跳pricing，而不是更新老用户的订阅
    if (await dispatch('hasSubscribed')) {
      router.push('/pricing')
      return true
    }

    // 新用户首次直接跳转到stripe
    // 首页直接订阅按月专业版会员
    return await dispatch('subscribeVipAfterLogin', {
      vipType: VIP_TYPE.PROFESSIONAL,
      interval: VIP_INTERVAL.MONTH
    })
  },

  /**
   * 订阅3天试用会员
   * @returns 
   */
  async subscribe3DayFreeTrialWithLogin({ dispatch }) {
    try {
      // 从后台检查登录状态，防止从其他tab登出系统
      await dispatch('getUserInfo')
    } catch (e) {
      // 登出系统了、非登录态跳转登录页
      router.push({
        path: '/login',
        query: {
          // 注意/third/vip/subscribe是不存在的路由，
          // 仅仅在login页面登录完成后用来判断跳转第三方支付
          loginTarget: '3day-free-trial-subscribe',
          to: '/'
        }
      })
      return
    }

    // 登录态去订阅，调用后台接口获取支付链接后跳转
    await dispatch('subscribe3DayFreeTrialAfterLogin')
    return
  },

  /**
   * 购买39一次性次数
   * @returns 
   */
  async purchaseLifetimeWithLogin({ dispatch }) {
    return dispatch('subscribeVipWithLogin', {
      productType: PRODUCT_TYPE.LIFETIME_PAYMENT,
      from: '/offer'
    })
  },

  async goBuy(dispatch, buyLoad){
    const $load = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.8)'
    })
    try {
      const res = await api.orderSubmit(buyLoad)
      if(res.code === 200){
        $load?.close()
        // 跳到portal订阅页面
        location.href = res.data.data
        return true
      }
    } catch (e) {
      console.log('创建/更新订阅出错:', e)
    }
    Message.error('Failed to submit')
    $load.close()
    return false
  }
}

const mutations = {
  setShowPriceModal(state, [status, source]) {
    console.log(status)
    state.showPriceModal = status
    state.priceSource = source || ''
    if(status){
      stat.event('general_vip_popup_exposure')
    }
  },
  seCreditsDetalisSatate(state,status) {
    state.creditsDetalisModal = status
  },
  setUserLoginState(state, value) {
    state.isUserLogin = value
  },
  setUserRights(state, info) {
    state.userRights = info
  },
  setUsageRestrictions(state, info) {
    state.usageRestrictions = info
  },
  setTransitionCharacterLeft(state, info){
    state.transitionCharacterLeft = info
  },
  setUserInfo(state, info) {
    state.user = info
  },
  setUserInfoGot(state) {
    state.userInfoGot = true
  },
  setCompanyList(state, list) {
    state.companyList = list
  },
  setVipInfo(state, data) {
    state.vipInfo = data
  }
}

const getters = {
  // showPriceModal: state => state.showPriceModal,
  user: state => state.user,
  isLogin: state => state.isUserLogin,
  usageRestrictions: state => state.usageRestrictions,
  transitionCharacterLeft: state => state.transitionCharacterLeft,
  userRights: state => state.userRights
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
