import axiosRequest from '@/utils/axios'

// 获取发音人详情
function getSpeakerDetail(data) {
  return axiosRequest.get(`/gateway-apis/tts/v1/storeSpeakerV2/speakerDetail?speakerId=${data.speakerId}`)
}

// 声音商店查询列表
let allUsableSpeakers = null
function getUsableSpeakers() {
  return new Promise((resolve, reject) => {
    const timeout = 3000 // 3s请求未返回则使用前端缓存
    const cacheKey = 'allUsableSpeakers'
    const cacheValue = localStorage.getItem(cacheKey)
    const _resolve = (speakers) => {
      if (!allUsableSpeakers) {
        allUsableSpeakers = speakers
      }
      resolve(allUsableSpeakers)
    }
    if (cacheValue) {
      setTimeout(() => {
        _resolve(JSON.parse(cacheValue))
      }, timeout)
    }

    axiosRequest.get('/gateway-apis/tts/v1/storeSpeakerV2/searchSpeakerList?pageSize=2000&pageNum=1',
      {
        useCache: true
      }
    ).then(res => {
      if (res?.status === 200 && res.data?.code === 200) {
        localStorage.setItem('allUsableSpeakers', JSON.stringify(res))
      }
      _resolve(res)
    }).catch(reject)
  })

}

export {
  getSpeakerDetail,
  getUsableSpeakers
}