/**
 * 配音员数据
 */
import axiosRequest from '@/utils/http-request'
import axios from '@/utils/axios'
const state = {
  speakerEmotionImgList: [], // 情绪列表
  domainMap: {}, // 领域列表
}

const actions = {
  // 获取情绪列表
  getSpeakerEmotionImgList({ commit }) {
    axiosRequest.getSpeakerEmotionImgList().then(res => {
      if (res.status == 200 && res.data.code == 200) {
        commit('setEmotionImgList', res.data.data || [])
      }
    })
  },
  // 获取领域列表
  getDomainMap({ commit }) {
    axiosRequest.getStoreSearchCriteria().then(res => {
      const data = res.data.data['Scenario:domainId'].reduce((map, { value, name }) => {
        map[value] = name
        return map
      }, {})
      commit('setDomainMap', data)
    })
  },
  // 按名字获取某个speaker
  async getSpeakerByName(context, voiceName) {
    // 根据 name 查询声音列表
    const { data: res } = await axiosRequest.searchSpeaker(
      axios.urlParamFormat({
        keyWord: voiceName
      })
    )
    const { code, data } = res
    if (code === 200) {
      // 过滤查找对应声音的内容详情
      return data.results.find(speaker => speaker.name === voiceName) || null
    }
    return null
  },
  speakerInit({ dispatch }) {
    dispatch('getSpeakerEmotionImgList')
    dispatch('getDomainMap')
  }
}

const mutations = {
  setEmotionImgList(state, data = []) {
    state.speakerEmotionImgList = data
  },
  setDomainMap(state, data = {}) {
    state.domainMap = data
  },
  setSpeakerTypeList(state, data = []) {
    state.speakerTypeList = data
  }
}

const getters = {
  // 获取情绪对应的图片
  getEmotionAvatar: state => {
    let MoodsMap = {}
    state.speakerEmotionImgList.forEach(item => {
      MoodsMap[item.emotion] = item.imageUrl
    })
    return mood => MoodsMap[mood]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
