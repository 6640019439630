/** 接口调用相关 state */

const state = {
  // make talking-photo's request params
  talkingPhotoParams: {
    // uploaded photo url 
    photoUrl: '',
    imageEsrgan:'',
    face:false,
    // generated audio url && info list
    audioList: [
      {
        type:'GenerateWithTTS',  
        audioUrl: '',
        GenerateWithTTS:{
          audioPreviewState: "PLAY",
          ttsText: "",
          audioSource: "GenerateWithTTS",
          speaker: "davidacameron_meet_24k",
          speakerSpeed: 1,
          speakerPitch: 0,
          emotionName: "Default",
          speakerName: "Davida Cameron",
          speakerHeaderImage: "https://cdn-static.dupdub.com/backend/headerImage/224_224/c712bff1926a4206a19bc8618f14d8f0-Davida_Came.png"
        },
        GenerateWithRecord: {
          audioPreviewState: 'PLAY',
          recorderStatus: 0,
          restDuration: 0,
          audioSource: 'GenerateWithRecord',
          audioDuration: 0
        },
        GenerateWithUpload: {
          audioPreviewState:'PLAY',
          restDuration: 0,
          audioSource: 'GenerateWithUpload',
          uploadProgress:0,
          uploadState:'WAITING', // 'WAITING' || 'UPLOADING' || 'FAILED'
          audioDuration:0,
          sourceName:''
        },
        box:[]
      }
    ],
    // watermark or not (default: 1; 1: with watermark; 0: without watermark)
    watermark: 1,
    // enhancer or not
    isEnhancer: false,
    // case id
    caseId: null,
    // avatar类型 0(talking photo) 1(2.5D avatar)
    avatarType: 0,
    avatarinfo: {}, //2.5D avatar info
    subtitleState:false,//字幕是否展示
    subtitlesTextStyle:{},//字幕内容样式
    subtitlesStyle:{},//字幕背景
    subtitleStyleData:{
      fontsize: 51,
      alignment: 2,
      bold: false,
      italic: false,
      underline: false,
      outlineColour: '#040D1A',
      outline: 1,
      marginV: 102,
      assTextEffect: 'NoEffect',
      fontname: 'Noto Sans',
      color1: '#FFFFFF', // 主色
      color2: '#C51919', // 卡拉ok次色拉用
    },//字幕传给后端的数据
    // talking photo  mask 遮罩图片 
    maskImageUrl:'',
    //图片是否有透明
    alphaState:false,
    //talking photo图片 形状 0-圆 1-矩形	
    maskShape:1,
  },
  // talking-photo's photo info
  talkingPhotoInfo: {
    width: 0,
    height: 0,
    boxes: [],
    // photo face gender (0: male; 1: female)
    gender: 1,
    /**
     * speaker info
     * default male: { speakerName: 'Albie Wood'; speaker: 'mercury_davis' }
     * default female: { speakerName: 'Lottie Miller'; speaker: 'mercury_nancy' }
     */
    speakerInfo: {
      speaker: 'mercury_davis',
      speakerSpeed: 1,
      speakerPitch: 0
    }
  },
  // talking-photo's common data
  talkingPhotoCommon: {
    // talking photo template
    talkingFacePhoto: {},
    // message dialog options
    messageDialog: {
      visible: false,
      title: '',
      content: '',
      button: ''
    },
    // global loading
    isLoadingVisible: false
  },
  // 显示类型 0 默认录音页面 1 详情页面
  showType: 0,
  // faceState:false,
  
}

const getters = {
  // make talking photo disabled state
  isMakeTalkingPhotoDisabled: () => {
    console.log('isMakeTalkingPhotoDisabled',state.talkingPhotoParams.audioList)
    return (
      !state.talkingPhotoParams.photoUrl ||
      !state.talkingPhotoParams.audioList.filter(item => item.audioUrl && item.box.length).length
    )
  },
  // is audio list visible
  isAudioOperationVisible: () => {
    return state.talkingPhotoParams.photoUrl
  },
  // is photo own multiple face
  isPhotoOwnMultipleFace: () => {
    return state.talkingPhotoInfo.boxes.length > 1
  }
}

const mutations = {
  /********** talkingPhotoParams **********/
  setTalkingPhotoParams(state, value) {
    // 复制除audioList外的所有属性  
    for (const key in value) {  
      if (key !== 'audioList' && value.hasOwnProperty(key)) {  
        state.talkingPhotoParams[key] = value[key];  
      }  
    } 
  },
  setTalkingPhotoInfo(state, value) {
    state.talkingPhotoInfo = value
  },
  setMaskShape(state, value) {
    state.talkingPhotoParams.maskShape = value
    console.log('setMaskShape',value)
  },
  setAlphaState(state, value) {
    state.talkingPhotoParams.alphaState = value
  },
  setPhotoSubtitleState(state, value) {
    state.talkingPhotoParams.subtitleState = value
  },
  setPhotoSubtitleStyleData(state, value) {
    state.talkingPhotoParams.subtitleStyleData = value
  },
  setPhotoSubtitlesStyle(state, value) {
    state.talkingPhotoParams.subtitlesStyle = value
  },
  setPhotoSubtitlesTextStyle(state, value) {
    state.talkingPhotoParams.subtitlesTextStyle = value
  },
  setPhotoAvatarType(state, value) {
    state.talkingPhotoParams.avatarType = value
  },
  setPhotoAvatarInfo(state, value) {
    state.talkingPhotoParams.avatarinfo = value
  },
  setMaskImageUrlState(state, value) {
    state.talkingPhotoParams.maskImageUrl = value
    console.log('setMaskImageUrlState',value)
  },
  setImageEsrganState(state, value) {
    state.talkingPhotoParams.imageEsrgan = value
    console.log('setImageEsrganState',value)
  },
  setPhotoUrlState(state, value) {
    state.talkingPhotoParams.photoUrl = value
    console.log('setPhotoUrlState',value)
  },
  setFaceState(state, value) {
    state.talkingPhotoParams.face= value
    console.log('setFaceState',value)
  },
  setAudioListState(state, value) {
    console.log('setAudioListState22', value)
    state.talkingPhotoParams.audioList = value
  },
  setWatermarkState(state, value) {
    state.talkingPhotoParams.watermark = value
  },
  setEnhancerState(state, value) {
    state.talkingPhotoParams.isEnhancer = value
  },
  setCaseIdState(state, value) {
    console.log('setCaseIdState',value)
    state.talkingPhotoParams.caseId = value
  },
  /********** talkingPhotoInfo **********/
  setPhotoWidthState(state, value) {
    state.talkingPhotoInfo.width = value
  },
  setPhotoHeightState(state, value) {
    state.talkingPhotoInfo.height = value
  },
  setPhotoBoxesState(state, value) {
    state.talkingPhotoInfo.boxes = value
  },
  setPhotoGenderState(state, value) {
    state.talkingPhotoInfo.gender = value
  },
  setSpeakerState(state, value) {
    state.talkingPhotoInfo.speakerInfo.speaker = value
  },
  setSpeakerSpeedState(state, value) {
    state.talkingPhotoInfo.speakerInfo.speakerSpeed = value
  },
  setSpeakerPitchState(state, value) {
    state.talkingPhotoInfo.speakerInfo.speakerPitch = value
  },
  /********** talkingPhotoCommon **********/
  setPhotoFaceTempState(state, value) {
    state.talkingPhotoCommon.talkingFacePhoto = value
  },
  setMessageDialogState(state, value) {
    state.talkingPhotoCommon.messageDialog = {
      visible: value.visible,
      title: value.title,
      message: value.message,
      button: value.button
    }
  },
  setLoadingVisibleState(state, value) {
    state.talkingPhotoCommon.isLoadingVisible = value
  },
  /************* showType ******************/
  setPhotoShowType(state, value) {
    state.showType = value
  },
  
    
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}