/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: '/overview/article-voice',
    name: 'articleVoice',
    component: () => import(/* webpackChunkName: "voice_editor" */ '@/pages/article-voice-v2-qiankun/index.vue'),
    meta: {
      title: 'DupDub | Synthesize Natural Sounding',
      content: {
        keywords: 'speech synthesis, natural voices',
        description: 'AI Voiceover & Text to Speech Platform with human-like voices.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/overview/article-voice',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/overview/article-voice/:id',
    name: 'articleVoiceDetail',
    component: () => import(/* webpackChunkName: "voice_editor" */ '@/pages/article-voice-v2-qiankun/index.vue'),
    meta: {
      title: 'DupDub | Generate most life-like Speech ',
      content: {
        keywords: 'life-like, speech generation',
        description: '400+ voices in different languages, styles and emotions can be chosen. Convert your texts into audio files in minutes.'
      },
      canonical: 'https://app.dupdub.com/overview/article-voice',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/talking-photo-editor',
    name: 'talkingPhotoEditor',
    component: () => import(/* webpackChunkName: "talking_photo_editor" */ '@/pages/talking-photo-base/index-all-new.vue'),
    meta: {
      title: '',
      content: {
        keywords: '',
        description: ''
      },
      canonical: 'https://www.dupdub.com/talking-photo-editor',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    },
    beforeEnter(to, from, next) {
      const previousPath = from.path
      to.meta.previousPath = previousPath
      next()
    }
  },
  // {
  //   path: '/talking-avatar',
  //   name: 'talkingAvatar',
  //   component: () => import(/* webpackChunkName: "talking_photo_editor" */ '@/pages/talking-photo-base/index-all-new.vue'),
  //   meta: {
  //     title: '',
  //     content: {
  //       keywords: '',
  //       description: ''
  //     },
  //     canonical: 'https://www.dupdub.com/talking-photo-editor',
  //     // 是/否需要校验登录
  //     auth: true,
  //     // 是/否有 header
  //     isOwnHeader: false,
  //     // 是/否有 footer
  //     isOwnFooter: false,
  //     noCache:true,//不需要加缓存
  //   },
  //   beforeEnter(to, from, next) {
  //     const previousPath = from.path
  //     to.meta.previousPath = previousPath
  //     next()
  //   }
  // },
  {
    path: '/video-translation-editor',
    name: 'videoTranslationEditor',
    component: () => import(/* webpackChunkName: "video_localization_editor" */ '@/pages/video-translation/editor.vue'),
    meta: {
      title: '',
      content: {
        keywords: '',
        description: ''
      },
      canonical: 'https://www.dupdub.com/video-translation-editor',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    },
  }
]