import Vue from 'vue'
// element ui
import ElementUI from 'element-ui'
// page components
import EditorPopover from '@/pages/article-voice-v2/component/common/editor-popover'
// custom components
import ToolTip from '@/component/tooltip/tooltip'
import locale from 'element-ui/lib/locale/lang/en'
/**
 * 按需加载组件
 * @param app vue 实例对象
 */
export const registerComponents = () => {
  Vue.use(ElementUI,{locale})

  Vue.component('editor-common-popover', EditorPopover)

  Vue.component('m-tooltip', ToolTip)
}