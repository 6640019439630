
/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: '/pricing',
    name: 'pricing', //原价name设置pricingOriginal 
    component: () => import(/* webpackChunkName: "home" */ '@/pages/pricing/index.vue'),
    meta: {
      title: 'Unlock the Power of DupDub AI Voiceover: Try Our Pro Plan FREE!',
      content: {
        keywords: 'voice over online,voice over generator,free voice over online,free voice over generator,free voice over generator online, DupDub AI voiceover, pricing plans, AI writing, GPT-3.5, voice cloning, face cloning, transcription, translation, subtitle alignment, YouTube downloader, free trial, professional plan, personal plan, enterprise plan, commercial rights, API, customer support, data review, priority support, flexible package, standard voices, premium voices, speech generations, characters per file, unlimited download, audio alignment, video alignment, online YouTube downloader, FAQ',
        description: 'Discover DupDub\'s AI voiceover pricing plans and find the perfect fit for your needs. Try our Professional plan for free with a 3-day trial and access AI-powered writing, translation, transcription, and more. Upgrade to Personal, Professional, or Enterprise plans for even more features and commercial rights. Unleash the potential of DupDub\'s voice and face cloning capabilities with flexible pricing options.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/pricing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/pricing-original',
    name: 'pricingOriginal', //原价name设置pricingOriginal
    component: () => import(/* webpackChunkName: "home" */ '@/pages/pricing/index.vue'),
    meta: {
      title: 'Unlock the Power of DupDub AI Voiceover: Try Our Pro Plan FREE!',
      content: {
        keywords: 'voice over online,voice over generator,free voice over online,free voice over generator,free voice over generator online, DupDub AI voiceover, pricing plans, AI writing, GPT-3.5, voice cloning, face cloning, transcription, translation, subtitle alignment, YouTube downloader, free trial, professional plan, personal plan, enterprise plan, commercial rights, API, customer support, data review, priority support, flexible package, standard voices, premium voices, speech generations, characters per file, unlimited download, audio alignment, video alignment, online YouTube downloader, FAQ',
        description: 'Discover DupDub\'s AI voiceover pricing plans and find the perfect fit for your needs. Try our Professional plan for free with a 3-day trial and access AI-powered writing, translation, transcription, and more. Upgrade to Personal, Professional, or Enterprise plans for even more features and commercial rights. Unleash the potential of DupDub\'s voice and face cloning capabilities with flexible pricing options.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/pricing-original',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/pricing/14dayfreetrial',
    alias: ['/pricing/14dayfreetrial'],
    name: 'pricing14',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/pricing/index.vue'),
    meta: {
      title: 'Pricing Page | To Get a 14-day Free Trail in DupDub',
      content: {
        keywords: 'Pricing Plans, Personal, Professional, free trial',
        description: '14-day Free Trial and 30% OFF on ALL Plans. 400+voices and 40+ languages and accents launched'
      },
      canonical: 'https://app.dupdub.com/pricing/14dayfreetrial',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
]