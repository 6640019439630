import {
  axios as Axios
} from '@/utils/axios'

const urlParamFormat = data => {
  let res = ''
  let keyArr = Object.keys(data)
  if (keyArr.length === 0) return ''
  keyArr.forEach((val, index) => {
    res = `${res}${index == 0 ? '?' : '&'}${val}=${data[val]}`
  })
  return res
}
export default {
  achieveVideoByLink: (data) =>
    Axios({
      url: '/gateway-apis/moyin-tool/v1/videoToAudio/achieveVideoByLink',
      method: 'POST',
      data: data
    }),
    //查询名称是否重复
    checkName(name) {
      return Axios({
        url: `/gateway-apis/tts/v1/avatar/case/checkName?name=${name}`,
        method: 'GET',
      })
    },
    //获取表单选择数据
    criteria() {
      return Axios({
        url: `/gateway-apis/tts/v1/avatar/case/getSearchCriteria`,
        method: 'GET',
      })
    },
    //设置模版可用
    caseUsable(data) {
      return Axios({
        url: `/gateway-apis/tts/v1/avatar/case/changeUseStatus`,
        method: 'PUT',
        data
      })
    },
    //创建模版
    createCase(data) {
      return Axios({
        url: `/gateway-apis/tts/v1/avatar/case/create`,
        method: 'POST',
        data
      })
    },
    //克隆模版列表
    cloneCheckList() {
      return Axios({
        url: `/gateway-apis/tts/v1/avatar/case/checkList`,
        method: 'GET',
      })
    },
    //webm转mp4
    webmToMp4(data) {
      return Axios({
        url: `/gateway-apis/moyin-tool/v1/video/transformToMp4`,
        method: 'POST',
        data
      })
    },
}