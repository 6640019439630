module.exports = {
  "voiceStoreSearch": {
    "IdentifySpeaker": "Identify speaker",
    "link": "Import from URL",
    "file": "Upload audio/video",
    "match": "Identifying speakers",
    "clear": "Clear",
    "paste": "Paste",
    "startSearch": "Search",
    "cancle": "Cancel",
    "linkNeed": "Please enter URL",
    "fileNeed": "Please upload an audio/video file",
    "searchRelative": "Found related speakers for you",
    "searchErr": "Failed to search, please try again later",
    "jiexiErr": "Failed to parse the video, please try again later",
    "10minFile": "Please upload a file within 10 minutes",
    "100mFile": "Please upload a file within 100M",
    "300mFile": "Please upload a file within 300M",
    "mpwavFile": "Please upload an MP3/WAV/MP4 file",
    "300m10min": "Please upload files within 300M and 10mins",
    "uploadAgain": "Try another",
    "uploading": "Parsing",
    "openRead": "Please allow access permission to read your clipboard",
    "more": "More",
    "less": "Less",
    "resetFilter": "Reset filters",
    "moreSpeaker": "More high-quality speakers are coming soon...",
    "loading": "Loading",
    "noFilterSpeaker": "No matched speakers. Please try other filters!",
    "recommendSpeaker": "No matched speakers, please try the following recommended",
    "recommend": "Recommend",
    "findNum": (ctx) => {
      let count = parseInt(`${ctx.named('num')}`)
      let str = count > 1 ? ' speakers' : ' speaker'
      return "Identify and match " + count + str + " for you"
    },
    "findResult": (ctx) => {
      let speaker = parseInt(`${ctx.named('totalElements')}`)
      let Style = parseInt(`${ctx.named('totalEmotionCounts')}`)
      return speaker + (speaker > 1 ? ' speakers, ': ' speaker, ') + Style + ( Style > 1 ? ' styles' : ' style')
    },
    "activityDisconut": "Discount",
    "styleNum": "Contain {num} style",
    "styleNums": "Contain {num} styles",
    "notAudio": "Your browser does not support audio element",
    "favorited": "Favorited",
    "addFavorited": "Add to favorite",
    "userCase": "User Case",
    "noSearch": "Failed to search"
  }
}