<template >
  <div class="play-container">
    <div class="play-progress">
      <el-slider
        v-model="currentTime"
        :min="0"
        :max="data.durationSeconds > 0 ? parseFloat(data.durationSeconds.toFixed(2)) : 1"
        class="ai-sound-effect-slider"
        :show-tooltip="false"
        @change="changeCurrentTime"
      ></el-slider>
    </div>
    <div class="play-duration">{{ dealSoundTime(currentTime) }} / {{ dealSoundTime(data.durationSeconds) }}</div>
    <!-- play / paused -->
    <div @click="play">
      <Icon :name="isPlaying ? 'icon-ai-sound-effect-paused' : 'icon-play-dp5579o8'" :isShow="false" :tooltipContent="isPlaying ? 'Pause' : 'Preview'"  :size="20" />
    </div>
    <audio
      :ref="`audioPlayer-${index}`"
      :src="data.resultUrl"
      @ended="audioEnd"
      @timeupdate="getDuration"
      @loadedmetadata="getTolalDuration"
    ></audio>
  </div>
</template>
<script>
import Icon from './icon.vue'
import { dealSoundTime } from '../utils'

export default {
  data() {
    return {
      isPlaying: false,
      audioPlayer: null,
      currentTime: 0
    }
  },
  props: ['data', 'index', 'pagePlaying'],
  inject: ['$aiSoundEffectPage'],
  components: {Icon},
  mounted () {
    this.audioPlayer =  this.$refs[`audioPlayer-${this.index}`]
  },
  watch: {
    '$aiSoundEffectPage.pagePlaying' (n) {
      if (!n && this.isPlaying) {
        this.play()
      }
    }
  },
  methods: {
    dealSoundTime,
    max () {
      if (data.durationSeconds < 0) {
        return 1
      } else {
        return parseFloat(data.durationSeconds.toFixed(2))
      }
    },
    play() {
      if (!this.isPlaying) {
        this.$aiSoundEffectPage.changePlaying(false)
        this.$nextTick(() => {
          this.audioPlayer.currenttime = 0
          this.audioPlayer.play()
          this.$aiSoundEffectPage.changePlaying(true)
          this.isPlaying = true
        })
      } else {
        this.isPlaying = false
        this.audioPlayer.pause()
        this.$aiSoundEffectPage.changePlaying(false)
      }
    },
    audioEnd () {
      this.$aiSoundEffectPage.changePlaying(false)
      this.isPlaying = false
      this.currentTime = 0
    },
    getDuration () {
      console.log('currentTime', this.audioPlayer.currentTime)
      this.currentTime = this.audioPlayer.currentTime
    },
    changeCurrentTime (val) {
      this.$aiSoundEffectPage.changePlaying(false)
      this.$nextTick(() => {
        this.audioPlayer.currentTime = val
        this.audioPlayer.play()
        this.$aiSoundEffectPage.changePlaying(true)
        this.isPlaying = true
      })
    },
    getTolalDuration () {
      this.data.durationSeconds = this.audioPlayer.duration
    }
  }
}
</script>
<style lang="less" scoped>
.play-container {
  flex: 1;
  display: flex;
  align-items: center;
  .play-progress {
    flex: 1;
    padding-right: 16px;
  }
  .play-duration {
    color: #424242;
    margin-right: 23px;
  }
  .play {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>