import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/assets/language/en/index.js'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: { en }, // set locale messages
  silentTranslationWarn: true
})
/*
  messages 少了一个括号 找了半小时 崩溃
*/

// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args)
}
