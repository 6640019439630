<template>
  <div class="keboard-container">
    <ul class="big-screen">
      <li
        :class="['ipa-item', { button: item.type === 'button' }]"
        v-for="item in ipaList"
        :key="item.key"
        @click="clickButton(item.key, item.type)"
      >
        <iconpark-icon v-if="item.type === 'ipa'" size="24" :icon-id="item.id"></iconpark-icon>
        <iconpark-icon v-else width="75" height="24" :icon-id="item.id"></iconpark-icon>
      </li>
    </ul>
    <!-- <div class="keboard-btn">
      <el-button>{{$t('button.Clear')}}</el-button>
      <el-button>{{$t('button.apply')}}</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name:'ipa-keyboard',
  data() {
    return {
      ipaList: [
        {
          name: 'ɪ',
          key: 'ɪ',
          type: 'ipa',
          id: '560007'
        },
        {
          name: 'e',
          key: 'e',
          type: 'ipa',
          id: '559988'
        },
        {
          name: 'æ',
          key: 'æ',
          type: 'ipa',
          id: '559977'
        },
        {
          name: 'ə',
          key: 'ə',
          type: 'ipa',
          id: '559993'
        },
        {
          name: 'ʌ',
          key: 'ʌ',
          type: 'ipa',
          id: '560022'
        },
        {
          name: 'ʊ',
          key: 'ʊ',
          type: 'ipa',
          id: '560021'
        },
        {
          name: 'ɒ',
          key: 'ɒ',
          type: 'ipa',
          id: '559981'
        },
        {
          name: 'i:',
          key: 'i:',
          type: 'ipa',
          id: '560006'
        },
        {
          name: 'ɜ:',
          key: 'ɜ:',
          type: 'ipa',
          id: '559989'
        },
        {
          name: 'u:',
          key: 'u:',
          type: 'ipa',
          id: '560017'
        },
        {
          name: 'ɔ:',
          key: 'ɔ:',
          type: 'ipa',
          id: '560010'
        },
        {
          name: 'ɑ:',
          key: 'ɑ:',
          type: 'ipa',
          id: '559980'
        },
        {
          name: 'eɪ',
          key: 'eɪ',
          type: 'ipa',
          id: '559990'
        },
        {
          name: 'aɪ',
          key: 'aɪ',
          type: 'ipa',
          id: '559978'
        },
        {
          name: 'ɔɪ',
          key: 'ɔɪ',
          type: 'ipa',
          id: '559998'
        },
        {
          name: 'aʊ',
          key: 'aʊ',
          type: 'ipa',
          id: '559979'
        },
        {
          name: 'əʊ',
          key: 'əʊ',
          type: 'ipa',
          id: '559992'
        },
        {
          name: 'ɪə',
          key: 'ɪə',
          type: 'ipa',
          id: '560002'
        },
        {
          name: 'eə',
          key: 'eə',
          type: 'ipa',
          id: '559987'
        },
        {
          name: 'ʊə',
          key: 'ʊə',
          type: 'ipa',
          id: '560018'
        },
        {
          name: 'p',
          key: 'p',
          type: 'ipa',
          id: '560003'
        },
        {
          name: 'b',
          key: 'b',
          type: 'ipa',
          id: '559982'
        },
        {
          name: 't',
          key: 't',
          type: 'ipa',
          id: '560023'
        },
        {
          name: 'd',
          key: 'd',
          type: 'ipa',
          id: '559983'
        },
        {
          name: 'k',
          key: 'k',
          type: 'ipa',
          id: '559999'
        },
        {
          name: 'g',
          key: 'g',
          type: 'ipa',
          id: '560004'
        },
        {
          name: 'f',
          key: 'f',
          type: 'ipa',
          id: '559991'
        },
        {
          name: 'v',
          key: 'v',
          type: 'ipa',
          id: '560020'
        },
        {
          name: 's',
          key: 's',
          type: 'ipa',
          id: '559996'
        },
        {
          name: 'z',
          key: 'z',
          type: 'ipa',
          id: '560015'
        },
        {
          name: 'θ',
          key: 'θ',
          type: 'ipa',
          id: '560016'
        },
        {
          name: 'ð',
          key: 'ð',
          type: 'ipa',
          id: '559984'
        },
        {
          name: 'ʃ',
          key: 'ʃ',
          type: 'ipa',
          id: '560013'
        },
        {
          name: 'ʒ',
          key: 'ʒ',
          type: 'ipa',
          id: '560019'
        },
        {
          name: 'tʃ',
          key: 'tʃ',
          type: 'ipa',
          id: '560012'
        },

        {
          name: 'dʒ',
          key: 'dʒ',
          type: 'ipa',
          id: '559986'
        },
        {
          name: 'r',
          key: 'r',
          type: 'ipa',
          id: '560011'
        },
        {
          name: 'h',
          key: 'h',
          type: 'ipa',
          id: '560000'
        },
        {
          name: 'm',
          key: 'm',
          type: 'ipa',
          id: '560005'
        },
        {
          name: 'n',
          key: 'n',
          type: 'ipa',
          id: '560001'
        },

        {
          name: 'ŋ',
          key: 'ŋ',
          type: 'ipa',
          id: '559997'
        },
        {
          name: 'l',
          key: 'l',
          type: 'ipa',
          id: '560008'
        },
        {
          name: 'j',
          key: 'j',
          type: 'ipa',
          id: '560009'
        },
        {
          name: 'w',
          key: 'w',
          type: 'ipa',
          id: '560014'
        },
        {
          name: "ˈ",
          key: "ˈ",
          type: 'ipa',
          id: '559976'
        },
        {
          name: '.',
          key: '.',
          type: 'ipa',
          id: '400882'
        },
        {
          name: '/',
          key: '/',
          type: 'ipa',
          id: '559994'
        },
        {
          name: 'delete',
          key: 'delete',
          type: 'button',
          id: '559985'
        },
        {
          name: 'enter',
          key: 'enter',
          type: 'button',
          id: '538271'
        }
      ]
    }
  },
  methods: {
    clickButton(v) {
      if (v === 'delete') {
        this.$emit('delete-symbol') // 删除每次删除一个字符
      } else if (v === 'enter') {
        this.$emit('close-board') // 关闭键盘
      } else {
        this.$emit('select-phonetic-symbol', v) // 输入音标
      }
    }
  }
}
</script>

<style lang="less" scoped>
.keboard-container {
  padding-top: 10px;
  .keboard-btn{
    margin: 12px 18px 12px 0;
    display: flex;
    float: right;
    .el-button{
      width: 72px;
      height: 28px;
      font-weight: normal;
      padding: 0;
      margin: 0;
      color: #333333;
      line-height: 28px;
      background: #FFFFFF;
      border-radius: 12px;
      font-size: 14px;
      border: 1px solid #DCDFE6;
      &:first-child{
        margin-right: 16px;
        &:hover{        
          background: rgba(0, 0, 0, 0.1);
        }
      }
      &:last-child{
        background: #3583FB;
        color: #fff;
        &:hover{
          opacity: 0.8;
        }
      }
    }

  }
}
.big-screen {
  display: flex;
  flex-wrap: wrap;
  .ipa-item {
    width: 28px;
    height: 28px;
    background: #ececec;
    border-radius: 4px;
    border: 2px solid #cccccc;
    margin-left: 6px;
    margin-top: 6px;
    color: #333333;
    box-sizing: border-box;
    cursor: pointer;
  }
  .button {
    display: flex;
    justify-content: center;
    width: 79px;
  }
}
</style>
