import Vue from "vue";
import * as Sentry from "@sentry/vue";
export default function sentry_init (){
    console.log('sentry init')
    Sentry.init({
        Vue,
        dsn: "https://22c074c1f2718d5d08432aa78f7de2ed@o4507422860247040.ingest.us.sentry.io/4507507699417088",
        // dsn: "https://822fbbb518a32dcd02d01e74367c9000@o4507422860247040.ingest.us.sentry.io/4507422865489920",
        integrations: [
          Sentry.browserTracingIntegration(),
          // Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://moyin-gateway.dupdub.com/.*"],
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: process.env.VUE_APP_ENV
    });
}
