import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/assets/language/en/index.js'

export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: { en }, // set locale messages
  silentTranslationWarn: true
})

export const registerI18n = () => {
  // 挂载 i18n
  Vue.use(VueI18n)
  Vue.prototype.$t = (key, value) => i18n.t(key, value)
}
