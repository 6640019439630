/*
 * @Date: 2020-05-12 10:33:02
 * @Author: yang.yang1809
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-07-12 23:41:14
 */
import * as types from './mutation-types'
import https from '../../utils/http-request'
import { formatDate } from '../../assets/js/format'
import { VIP_TYPE, APPSUMO_LEVEL } from '@/constants/vip.js'
import {
  STANDARD_MAX_INPUT,
  PREMIUM_MAX_INPUT
} from '@/pages/article-voice-v2/constant.js'

const app = {
  state: {
    playStatus: false,
    discountGot: false,
    articlePages: 1, // 记录文章页页码
    isShowVipDialog: false, // 0尊贵会员 1超级会员 2无会员
    vipInfo: {},
    discountInfo: {},
    isShowQuestionnaire: false, //问卷弹窗
    is5offBooleanFeature: false, //折扣弹框 默认不打折
  },
  getters: {
    playStatus: state => state.playStatus,
    articlePages: state => state.articlePages,
    vipInfo: state => state.vipInfo,
    redeemList: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level === 1),
    redeemListSingle: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level === 5),
    redeemCount: (state, getters) => getters.redeemList.length + getters.redeemListSingle.length,
    redeemComposeCount: (state, getters) => getters.redeemList.reduce((count, item) => count + item.composeCount, 0),
    redeemLeftComposeCount: (state, getters) => getters.redeemList.reduce((count, item) => count + item.leftComposeCount, 0),
    //改版新增
    appsumoLength: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level !== 6),
    dealMirrorLength: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level === 6),
    redemptionList: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level !== 99),
    //改版新增
    topRedeemLevel: (state, getters) => {
      const redeemList = getters.redeemList
      const advanced = redeemList.filter(item => item.leftComposeCount > 0 && item.level === APPSUMO_LEVEL.ADVANCED)
      const growth = redeemList.filter(item => item.leftComposeCount > 0 && item.level === APPSUMO_LEVEL.GROWTH)
      const basic = redeemList.filter(item => item.leftComposeCount > 0 && item.level === APPSUMO_LEVEL.BASIC)
      return advanced.length > 0 ? APPSUMO_LEVEL.ADVANCED : growth.length > 0 ? APPSUMO_LEVEL.GROWTH : basic.length > 0 ? APPSUMO_LEVEL.BASIC : ''
    },
    topRedeemLevelSingle: (state, getters) => {
      const redeemListSingle = getters.redeemListSingle
      const single = redeemListSingle.filter(item => item.level === APPSUMO_LEVEL.SINGLE)
      return single.length > 0 ? APPSUMO_LEVEL.SINGLE : ''
    },
    lifetimeList: state => (state.vipInfo?.appSumoVOS ?? []).filter(item => item.level === 99),
    lifetimeCount: (state, getters) => getters.lifetimeList.length,
    lifetimeLeftComposeCount: (state, getters) => getters.lifetimeList.reduce((count, item) => count + item.leftComposeCount, 0),
    
    canUseTxtLength: (state, getters) => {
      if (state.vipInfo.type === VIP_TYPE.FREE) {
        if (getters.redeemCount > 0) {
          if (getters.topRedeemLevel === APPSUMO_LEVEL.ADVANCED) {
            return PREMIUM_MAX_INPUT
          }
          return STANDARD_MAX_INPUT
        }
        if (getters.lifetimeCount > 0) {
          return STANDARD_MAX_INPUT
        }
      }
      if (state.vipInfo.type === VIP_TYPE.PROFESSIONAL) {
        return PREMIUM_MAX_INPUT
      }
      if (state.vipInfo.type === VIP_TYPE.PERSONAL) {
        return STANDARD_MAX_INPUT
      }
      return STANDARD_MAX_INPUT
    },
    isShowVipDialog: state => state.isShowVipDialog,
    discountInfo: state => state.discountInfo,
    isShowQuestionnaire: state => state.isShowQuestionnaire,
    is5offBooleanFeature: state => state.is5offBooleanFeature
  },
  mutations: {
    // 播放状态
    [types.CHANGE_PLAY_STATUS](state, status) {
      state.playStatus = status
    },
    // 记录用户页码
    [types.ARTICLE_PAGES](state, status) {
      state.articlePages = status
    },
    // VIPINFO
    [types.VIPINFO](state, status) {
      state.vipInfo = status
    },
    setVipdialog(state, status) {
      state.isShowVipDialog = status
    },
    setDiscountInfo(state, status) {
      state.discountInfo = status
    },
    setShowQuestionnaire(state, status) {
      state.isShowQuestionnaire = status
    },
    setDiscountGot(state) {
      state.discountGot = true
    },
    setCouponDiscount(state, status){
      state.is5offBooleanFeature = status
    }
  },
  // 改变状态
  actions: {
    changeRemStatus({ commit }, status) {
      commit(types.CHANGE_REMEMBER_STATUS, status)
    },
    changePlayStatus({ commit }, status) {
      commit(types.CHANGE_PLAY_STATUS, status)
    },
    async changeVipinfo({ commit }) {
      const {code, data} = await https.getVipInfo()
        if( code === 200){
          let _data = data
          if (_data.type === 0) {
            _data.endDate = formatDate(
              data.endTime,
              'YYYY.MM.DD HH:mm:ss'
            )
          }
          if (_data.type === 1) {
            _data.endDate = formatDate(
              data.superEndTime,
              'YYYY.MM.DD HH:mm:ss'
            )
          }
          commit(types.VIPINFO, _data)
        }else{
          commit(types.VIPINFO, {})
        }
    },
    saveArticlePages({ commit }, status) {
      commit(types.ARTICLE_PAGES, status)
    },
    getDiscountInfo({ commit }) {
      // 先按照接口返回的数据赋值
      commit('setDiscountInfo', {
        "exhibit": true,
        "content": null,
        "imageUrl": null,
        "linkUrl": null,
        "discount": null,
        "code": null
      })
      // https
      //   .getDiscountInfo({ cancel: 'getDiscountInfo' })
      //   .then(res => {
      //     let {
      //       data: { data, code }
      //     } = res
      //     if (code === 200) {
      //       commit('setDiscountInfo', data)
      //     } else {
      //       commit('setDiscountInfo', {})
      //     }
      //   })
      //   .catch((err) => {
      //     if (err.__CANCEL__ ) {
      //       return
      //     }
      //     commit('setDiscountInfo', {})
      //   }).finally(()=>{
      //     commit('setDiscountGot')
      //   })
    }
  }
}

export default app
