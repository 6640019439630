export function isCloneSpeaker(speakerObj) {
	return speakerObj?.speakerType === 'clone' || speakerObj?.speakerType === 'avatar'
}

export let getUsage = (num) => {
	let usage = 0
    if (num) {
      if (Math.floor(num / 1000000) > 0) {
        usage = (num / 1000000).toFixed(1) + 'M'
      } else if (Math.floor(num / 1000) > 0) {
        usage = (num / 1000).toFixed(1) + 'K'
      } else {
        usage = num
      }
    }
    return usage
}