<template>
  <div class="editor-popover-wrapper">
    <!-- <div class="arrow-icon" :style="`left:${arrowLeftValue}px`"></div> -->
    <div class="editor-popover-inner" :style="style">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    padding: {
      type: [Number, String],
      default: 12
    },
    arrowLeftValue: Number
  },
  computed: {
    style() {
      if (this.padding) {
        return {
          padding: this.padding + 'px'
        }
      }
      return null
    }
  }
}
</script>

<style lang="less">
.editor-popover-wrapper {
  border: 1px solid #ebeef5;
  font-size: 14px;
  border-radius: 4px;
  background: rgba(254, 254, 254, 1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  position: relative;
  z-index: 2;
  .arrow-icon {
    content: '';
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    // transform:s
    z-index: 0;
    top: -8px;
    margin-left: 0px;
    left: 50%;
    // border-top: 1px solid #ecebeb;
    // border-left: 1px solid #ecebeb;
    background-color: #fefefe;
  }
}
</style>
