<template>
  <div class="ai-create">
    <div class="ai-top">
      <span class="title">AI sound effect</span>
      <span class="history" @click="history">History</span>
    </div>
    <div class="ai-main">
      <div class="ai-type">Prompt<span class="red">*</span></div>
      <div class="ai-textarea" :class="{ focus: isFocus }">
        <el-input
          type="textarea"
          :rows="3"
          class="ai-sound-effect-textarea"
          placeholder="Describe your sound effect to generate it into authentic sounds..."
          v-model="aiParams.prompt"
          resize="none"
          :maxlength="200"
          autofocus
          @blur="isFocus = false"
          @focus="isFocus = true"
        >
        </el-input>
        <div class="ai-textarea-info">
          <div class="work-lenght">{{ aiParams.prompt.length }}/200</div>
          <el-tooltip
            popper-class="ai-sound-effect-tooltip"
            effect="dark"
            content="Inspiration"
            placement="top"
          >
            <div class="inspiration" @click="aiSoundEffectRandomPrompt">
              <img src="../images/inspiration.png" alt="" width="24" />
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="ai-type">
        Duration&nbsp;<span class="hui">(max 22s)</span>
      </div>
      <div class="ai-duration">
        <el-radio v-model="aiParams.autoDuration" :label="1"
          >Automatch</el-radio
        >
        <el-radio v-model="aiParams.autoDuration" :label="0">Custom </el-radio>
        <div class="ai-sound-input" v-if="aiParams.autoDuration === 0">
          <el-input
            type="text"
            v-model="aiParams.durationSeconds"
            :class="['ai-sound-numbers', numberInputForce ? 'numberforce' : '']"
            @blur="inputBlur"
            @input="changeInput"
            @focus="numberInputForce = true"
          >
          </el-input>
          <div class="changeinput">
            <div @click="handlerUp">
              <iconpark-icon
                name="ai-sound-effect-down"
                :size="12"
              ></iconpark-icon>
            </div>
            <div @click="handlerDown">
              <iconpark-icon
                name="ai-sound-effect-up"
                :size="12"
              ></iconpark-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="ai-type">Stability</div>
      <div class="ai-stability">
        <div class="stability">
          <span>More creative</span>
          <span>Follow prom</span>
        </div>
        <div class="create-slider-box">
          <el-slider
            v-model="aiParams.influence"
            :min="0"
            :max="1"
            :step="0.1"
            class="ai-sound-effect-slider"
            :show-tooltip="false"
          ></el-slider>
        </div>
      </div>
      <div class="ai-sound-btn-list">
        <div class="btn cancel" @click="cancel">Cancel</div>
        <div class="btn generate" @click="generate">
          <iconpark-icon
            name="loading"
            class="loading"
            v-if="isCheck"
          ></iconpark-icon>
          Generate
          <el-tooltip placement="top" popper-class="ai-sound-effect-tooltip middle-width" content="It will cost 2 credits to generate, and you will receive 4 sound effects.">
            <iconpark-icon name="common-i" size="14"></iconpark-icon>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AiSoundEffectApi } from '@/api/index.js'

export default {
  data() {
    return {
      dialogVisible: true,
      aiParams: {
        source: 'web',
        prompt: '',
        autoDuration: 1,
        durationSeconds: 4,
        influence: 0.5
      },
      isCheck: false,
      isFocus: false,
      numberInputForce: false
    }
  },
  methods: {
    // 随机获取
    aiSoundEffectRandomPrompt() {
      AiSoundEffectApi.aiSoundEffectRandomPrompt()
        .then(res => {
          if (res.data.code === 200) {
            this.aiParams.prompt = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    // 添加
    generate() {
      if (!this.aiParams.prompt.trim()) {
        this.$message.error('Please first enter a prompt.')
        return
      }
      if (this.isCheck) return
      this.isCheck = true
      this.aiParams.prompt = this.aiParams.prompt.trim()
      AiSoundEffectApi.aiSoundEffect(this.aiParams)
        .then(res => {
          if (res.data.code === 200) {
            this.$emit('result', res.data.data)
          } else {
            this.$message.error(res.data.message)
          }
          this.isCheck = false
        })
        .catch(err => {
          this.isCheck = false
          this.$message.error(err)
        })
    },
    history() {
      this.$emit('changeType', 2)
    },
    cancel() {
      this.$emit('cancel')
    },
    inputBlur() {
      this.numberInputForce = false
      if (!this.aiParams.durationSeconds) {
        this.aiParams.durationSeconds = 1
      } else {
        let str = this.aiParams.durationSeconds * 1
        if (str > 22) {
          this.aiParams.durationSeconds = 22
        } else if (str < 0.5) {
          this.aiParams.durationSeconds = 0.5
        }
      }
    },
    changeInput() {
      let str = this.aiParams.durationSeconds * 1
      if (isNaN(str)) {
        this.aiParams.durationSeconds = ''
      }
    },
    handlerUp () {
      if (this.aiParams.durationSeconds < 22) {
        this.aiParams.durationSeconds = (this.aiParams.durationSeconds * 10 + 1) /10
      }
    },
    handlerDown () {
      if (this.aiParams.durationSeconds > 0.5) {
        this.aiParams.durationSeconds = (this.aiParams.durationSeconds * 10 - 1) /10
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ai-create {
  .ai-top {
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    box-shadow: inset 0px -1px 0px 0px #f0f0f0;
    margin-bottom: 4px;
    .title {
      font-family: 'Poppins-SemiBold';
      color: #101010;
      font-size: 16px;
    }
    .history {
      color: #076bf7;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .ai-main {
    padding: 0 24px;
    user-select: none;
    .ai-type {
      height: 44px;
      font-family: Poppins-Medium;
      color: #040d1a;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 2px;
      .red {
        color: #f22a18;
      }
      .hui {
        color: #a8a8a8;
        font-size: 12px;
      }
    }
    .ai-textarea {
      height: 120px;
      background: #f7f8fa;
      border-radius: 12px;
      border: 1px solid #f5f5f5;
      margin-bottom: 12px;
      &.focus {
        border: 1px solid #076bf7;
      }
      .ai-textarea-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        .work-lenght {
          font-size: 12px;
          color: #a8a8a8;
          margin: 10px 16px 0;
        }
        .inspiration {
          width: 56px;
          height: 32px;
          background: #ffffff;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 0 12px 8px 0;
          &:hover {
            background: #076bf7;
          }
        }
      }
    }
    .ai-duration {
      height: 48px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #f5f5f5;
      display: flex;
      align-items: center;
      padding: 0 16px;
      margin-bottom: 12px;
    }
    .ai-stability {
      height: 64px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #f5f5f5;
      padding: 0 16px;
      box-sizing: border-box;
      .stability {
        display: flex;
        justify-content: space-between;
        color: #a8a8a8;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  .loading {
    margin: 0 10px;
    animation: rotateForever 2s linear infinite;
    color: #fff;
  }
  @keyframes rotateForever {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
<style lang="less">
.ai-sound-effect-textarea {
  textarea {
    border: 0;
    background: none;
    padding: 10px 16px;
    font-size: 14px;
    color: #424242;
    height: 80px;
    font-family: Poppins-Regular;
    color: #101010;
    &::placeholder {
      color: #a8a8a8;
    }
  }
}
.create-slider-box {
  .el-slider__runway {
    margin: 10px 0;
  }
}
</style>
<style lang="less">
.ai-sound-input {
  display: flex;
  align-items: center;
  position: relative;
  left: -18px;
  .ai-sound-numbers {
    width: 56px;
    .el-input__inner {
      width: 56px;
      height: 32px;
      background: #f7f8fa;
      border-radius: 8px;
      border: 1px solid #f2f3f5;
      text-align: center;
      color: #101010;
    }
    &.numberforce{
      .el-input__inner{
        border-color: #076bf7;
      }
    }
  }
  .changeinput {
    width: 24px;
    height: 32px;
    background: #f7f8fa;
    border-radius: 8px;
    border: 1px solid #f2f3f5;
    margin-left: 2px;
    box-sizing: border-box;
    > div {
      height: 50%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        iconpark-icon {
          position: relative;
          top: 3px;
          color: #757575;
        }
      }
      &:nth-child(2){
        iconpark-icon {
          position: relative;
          top: -2px;
          color: #757575;
        }
      }
      &:hover{
        iconpark-icon {
          font-weight: bold;
          color: #076bf7;

        }
      }
    }
  }
}
</style>