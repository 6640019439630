import Vue from 'vue'
import App from '@/App.vue'
import posthogPlugin from "./plugins/posthog"; // import the plugin
Vue.use(posthogPlugin); // install the plugin, before new Vue()

// 全局错误处理
import { mediaErrorHandler } from '@/utils/error-handler'
mediaErrorHandler()

import { mapActions } from 'vuex'
import store from '@/store/index'
import { setLocalPromoteInfo } from '@/utils/product-promotion.js'
import $ from 'jquery'
import "./microApp"
// register vue-router
import router, { registerRouter } from '@/router/router'
// register vue prototype
import { $bus, registerVuePrototype } from '@/plugins/registerVuePrototype'
// register vue Directive
import { registerVueDirective } from '@/plugins/registerVueDirective'
// register vue Filter
import { registerVueFilter } from '@/plugins/registerVueFilter'
// register global/custom components
import { registerComponents } from '@/plugins/registerComponents'
// config I18n
import { registerI18n, i18n } from '@/plugins/registerI18n'
// setup asssets
import setupAssets from '@/plugins/registerAssets'
// growthbook
// import growthbook from '@/utils/growthBook'
// import api from '@/utils/http-request'
// import { getClientId } from '@/utils/utils'

// a/b test
// let home_A_or_B = false
// if (!localStorage.getItem('home_A_or_B')) {
//   try {
//     let res = await api.featureGetClientId({
//       clientId: getClientId(),
//       featureKey: 'new_home'
//     })
//     if (res.data?.data?.status === 1){
//       home_A_or_B = res.data?.data?.value
//     } else {
//       await growthbook.init()
//       home_A_or_B = window.gb.isOn('new_home')
//     }
//   } catch (err) {
//     await growthbook.init()
//     home_A_or_B = window.gb.isOn('new_home')
//   }
//   localStorage.setItem('home_A_or_B', home_A_or_B)
// } else {
//   home_A_or_B = localStorage.getItem('home_A_or_B') === 'false' ? false : true
// }
// console.log('main----home_A_or_B-----', home_A_or_B)

Vue.config.productionTip = false

registerRouter()

// if you want to config prototype in Vue, please code in this function!
registerVuePrototype()

// if you want to config Directive in Vue, please code in this function!!!
registerVueDirective()

// if you want to config Filter in Vue, please code in this function!!!
registerVueFilter()

// if you want to config custom/global components, please code in this function!!!
registerComponents()

// if you want to config I18n options,  please code in this function!!!
registerI18n()

// if you want to config img/css/json/...assets, please code in this function!!!
setupAssets()

import stat from "@/utils/stat"
import sentry_init from "@/utils/sentry"
import './registerServiceWorker'
stat.init()
setLocalPromoteInfo()
// window.userpilot?.anonymous();
window.$baseVueRouter = router // 主应用路由
window.$baseVueStore = store // 主应用store
window.$baseVueBus = $bus // 主应用bus
// init sentry
sentry_init()
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data() {
    return {
      calcusStatus: true,
      compoundStatus: false,
      companyId: null,
      companyType: null,
      companyAmount: null,
      role: null,
      speakerId: 55,
      popoverShow: false,
      isMacOs: false
    }
  },
  created() {
    this.checkUserBrowser()
  },
  mounted() {
    // 将推广信息存到本地
    document.dispatchEvent(new Event('custom-render-trigger'))
    this.$nextTick(() => {
      $('.el-message').remove()
    })
    // 声音商店执行相关接口
    if (window.location.pathname === '/voiceover-store') {
      this.$store.dispatch('speaker/speakerInit')
    }

    if(window.location.search.includes('utm_source=edm')) {
      // 记录邮件进来的用户
      this.$http.get(`/overseas-apis/account/v1/background/clickEmail${window.location.search}&url=${window.location.pathname}`)
    }
  },
  methods: {
    ...mapActions(['changeVipinfo']),
    checkUserBrowser() {
      let agent = navigator.userAgent.toLowerCase()
      this.isMacOs = /macintosh|mac os x/i.test(agent)
    },
    __getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    }
  }
}).$mount('#app')