<template>
    <div class="nps" v-if="scoreStatus">
        <div class="nps-container">
            <div class="clone" @click="cloneBtn"><iconpark-icon name="white-close"></iconpark-icon></div>
            <div class="nps-top">
                <div class="title">We value your feedback on DupDub!</div>
                <div class="desc">{{ configData.content }}</div>
            </div>
            <div class="nps-bottom">
                <ul>
                    <li v-for="item in configData.itemList" :key="item.itemContent" @click="scoreBtn(item)">
                        <div class="prompt"><img :src="item.img" alt=""><span>{{ item.placeholder }}</span></div>
                        <div class="score-num" :class="item.itemContent==scoreActive.itemContent?'score-num-active':''">{{ item.itemContent }}</div>
                    </li>
                </ul>
                <div class="evaluate">
                    <p>0-Very unlikely</p>
                    <p>10-Very likely</p>
                </div>
                <template v-if="inputBox">
                    <textarea class="reason-text" v-model="content" @input="changeReason" :placeholder="configData.placeholder"></textarea>
                    <div class="bottom-button">
                        <el-button @click="cloneBtn">Cancel</el-button>
                        <el-button @click="submit">Submit</el-button>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>
<script>
import http from '@/utils/http-request.js'
import {mapActions } from 'vuex'
import to from 'await-to-js';

export default{
    data(){
        return{
            scoreStatus:false,
            scoreActive:{},//选中评价
            inputBox:false,//输入框
            configData:{},
            content:'',//输入内容
            postQueryConfigStatus:true,
        }
    },
    mounted(){
        this.$bus.$on('npsStatus',this.triggerNps)
    },
    beforeDestroy() {
        this.$bus.$off('npsStatus', this.triggerNps);
    },
    methods:{
        ...mapActions('userInfo', ['getUserRights']),
        //查询是否显示弹框
        triggerNps(){
            http.getHasTriggerNps({scene:5}).then((result) => {
                if(result.data.code===200){
                    if(result.data.data){
                        //查询内容
                        this.getConfig()
                        this.scoreStatus=true
                    }
                }
            }).catch((err) => {
                console.log(err)
            });
        },
        //查询问卷内容
        getConfig(){
            http.queryConfig({scene:5}).then(res=>{
                if(res.data.code===200){
                    this.configData=res.data.data[0]
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        // 选择分数
        scoreBtn(item){
            this.scoreActive=item
            this.inputBox=true
        },
        // 关闭弹框
        cloneBtn(){
            this.scoreStatus=false
        },
        // 监听输入框
        changeReason(){
            if(this.content.length>1000){
                this.$message.error('Please type within 1000 characters.')
                this.content=this.content.substr(0, 1000);
            }
        },
        //提交评价
        async submit(){
            //接口没有返回数据前点击无效
            if(!this.postQueryConfigStatus) return
            this.postQueryConfigStatus=false
            if(!this.scoreActive){
                this.postQueryConfigStatus=true
                this.$message.error('Choose a score to submit and earn 10 credits!')
                return
            }
            let questionConfigList=[
                {
                    configId:this.scoreActive.configId,
                    scene:5,
                    type: 1,
                    items:[
                        {
                            id:this.scoreActive.id,
                            userContent:this.content
                        }
                    ]
                }
            ]
            http.postQueryConfig({questionConfigList}).then(async res=>{
                if(res.data.code===200){
                    this.scoreStatus=false
                    this.$message.success('Thank you for your feedback! We have added 10 credits to your account.')
                    const [userErr, userMes] = await to(this.getUserRights())
                    if (!userMes) return
                    if (userErr) return
                }else{
                    this.postQueryConfigStatus=true
                }
            }).catch(err=>{
                console.log(err)
                this.postQueryConfigStatus=true
            })
        }
    }
}
</script>
<style lang="less" scoped>
.nps{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 17, 38,0.6);
}
.nps-container{
    width: 830px;
    min-height: 381px;
    position: relative;
    .clone{
        width: 39px;
        height: 39px;
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        border-radius: 50%;
        right: -49px;
        top: -12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
        &:hover{
            background: rgba(255, 255, 255, 0.4);
        }
    }
    .nps-top{
        width: 100%;
        height: 240px;
        background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub-1698831459/dupdub-1698831459/nps-bg.png');
        background-size: 100% 100%;
        padding: 0 40px;
        box-sizing: border-box;
        text-align: left;
        .title{
            font-size: 24px;
            font-family: Poppins-SemiBold, Poppins;
            font-weight: 600;
            color: #100026;
            line-height: 32px;
            padding-top: 43px;
        }
        .desc{
            font-size: 18px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #040D1A;
            line-height: 26px;
            margin-top: 55px;
        }
    }
    .nps-bottom{
        width: 100%;
        background-color: #FFFFFF;
        margin-top: -1px;
        border-radius: 0px 0px 16px 16px;
        padding: 0 40px 24px;
        box-sizing: border-box;
        ul{
            display: flex;
            justify-content: space-between;
            padding-top: 21px;
            li{
                position: relative;
                display: flex;
                justify-content: center;
                .score-num{
                    width: 50px;
                    height: 50px;
                    border: 1px solid #ECECF0;
                    border-radius: 50%;
                    font-size: 20px;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #100026;
                    text-align: center;
                    line-height: 50px;
                    cursor: pointer;
                }
                .prompt{
                    position: absolute;
                    top: -38px;
                    padding: 5px 8px;
                    background: linear-gradient(289deg, #ECE7FF 0%, #F1DFFF 100%);
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    &::before{
                        position: absolute;
                        bottom: -5px;
                        margin: auto;
                        left: 0;
                        right: 0;
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 5px solid rgba(238, 228, 255, 1);
                        border-right: 5px solid transparent;
                        border-left: 5px solid transparent;
                    }
                    img{
                        width: 18px;
                        height: 18px;
                        margin-right: 3px;
                    }
                    span{
                        font-size: 12px;
                        font-family: Poppins-SemiBold, Poppins;
                        font-weight: 600;
                        color: #100026;
                        line-height: 20px;
                        background: linear-gradient(90deg, #3C56FF 0%, #9658FF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        white-space: nowrap;
                    }
                }
                .score-num-active{
                    color: #FFFFFF;
                    background: linear-gradient(127deg, #3C56FF 0%, #9658FF 100%);
                }
                &:hover{
                    .score-num{
                        color: #FFFFFF;
                        background: linear-gradient(127deg, #3C56FF 0%, #9658FF 100%);
                    }
                    .prompt{
                        opacity: 1;
                    }
                }
            }
        }
        .evaluate{
            display: flex;
            justify-content: space-between;
            margin-top: 18px;
            p{
                font-size: 14px;
                font-weight: 400;
                color: #040D1A;
                line-height: 22px;
            }
        }
        .reason-text{
            width: 100%;
            height: 130px;
            border-radius: 8px;
            border: 2px solid #DBDFE6;
            padding: 10px 12px 0;
            box-sizing: border-box;
            margin-top: 32px;
            resize: none;
            font-size: 16px;
            font-family: Poppins-Regular, Poppins;
        }
        .reason-text:focus{
            border: 2px solid #006EFF;
        }
        .bottom-button{
            margin-top: 48px;
            display: flex;
            justify-content: center;
            button{
                width: 211px;
                height: 44px;
                background: rgba(4,13,26,0.04);
                border-radius: 8px;
                margin-right: 20px;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
                color: #040D1A;
                border: none;
            }
            button:hover{
                opacity: 0.8;
            }
            button:nth-child(2){
                color: #FFFFFF;
                background: #040D1A;
            }
        }
    }
}
</style>