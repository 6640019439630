/*
 * @Author: youshuai.wang youshuai.wang@mobvoi.com
 * @Date: 2023-12-14 14:34:11
 * @LastEditors: youshuai.wang youshuai.wang@mobvoi.com
 * @LastEditTime: 2023-12-14 14:58:55
 * @FilePath: /moyin-overseas/src/router/move-routes.js
 * @Description: 迁移后的路由统计
 */
export const routes = [
  {
    path: '/',
    name: 'index',
    // component: () => import('@/pages/index/index.vue'),
    meta: {
      title: 'Try AI Tools For Voiceovers & Content Creation | DupDub',
      content: {
        keywords: 'AI voiceover software, AI content creation, AI art generator, AI avatar maker, AI video editing software, AI writing assistant, AI design tools, AI animation creator, AI creative suite',
        description: 'Enhance your content with DupDub\'s suite of AI-powered tools for voiceover, writing, painting, avatar creation, and video editing. Transform your creative projects with AI in a snap and save time and money. Start your free trial today!'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/text-to-speech',
    name: 'featureTts',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/tts.vue'),
    meta: {
      title: 'Instant AI Text-to-Speech: 50+ Emotive Characters | DupDub',
      content: {
        keywords: 'ai text to speech characters',
        description: 'DupDub\'s Instant AI Text-to-Speech brings 50+ emotive characters to your fingertips. Turn text into engaging voiceovers effortlessly! '
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/text-to-speech',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/talking-photo',
    name: 'talkingPhoto',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/talking-photo.vue'),
    meta: {
      title: 'Create Talking Photos and Avatars From Portrait Photo',
      content: {
        keywords: 'talking photos, talking avatar',
        description: 'Unleash the power of your portrait photos with DupDub Lab. Turn them into talking wonders by uploading a photo and matching audio or video file.'
      },
      canonical: 'https://app.dupdub.com/talking-photo',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/chatgpt-writing',
    name: 'featureWrite',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/ai-write.vue'),
    meta: {
      title: 'Free AI Content Writing Generator by DupDub - Get Started Today!',
      content: {
        keywords: 'free ai writing generator, ai content generator',
        description: 'Don\'t settle for mediocre writing. Try DupDub\'s Free AI Content Writing Generator & unleash the best in content creation. Get started today and save time!'
      },
      canonical: 'https://app.dupdub.com/chatgpt-writing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/video-editing',
    name: 'videoEditing',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/video-editing.vue'),
    meta: {
      title: 'AI-Powered Video Editing | Automate Your Video Editing Process',
      content: {
        keywords: 'AI talking photo, AI avatar',
        description: 'Transform your video editing experience with our AI-powered video editing platform. Automate and streamline your video editing workflow and save time with our AI subtitles, AI avatars, AI voiceovers and video localization.'
      },
      canonical: 'https://app.dupdub.com/video-editing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/speech-to-text',
    name: 'speechToText',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/transcription.vue'),
    meta: {
      title: 'Transcribe Your Video & Audio Files to Text Made Simple',
      content: {
        keywords: 'transcribe video to text, transcribe audio to text',
        description: 'Turn audio and video into text effortlessly. Speak your thoughts, we\'ll handle the rest. Supports MP4, MP3, WAV, and links from top platforms.'
      },
      canonical: 'https://app.dupdub.com/speech-to-text',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
   // 声音克隆
   {
    path: '/voice-cloning',
    name: 'customizedVoice',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/customized-voice'),
    meta: {
      title: 'Revolutionize Your Workflow with AI Voice Cloning | Transform Your Content Creation Today',
      content: {
        keywords: 'AI voice cloning, AI voice cloning service, voice cloning technology, content creation, content creators, voiceover recording, text to speech editor, beloved one\'s voice, cherish memories, exclusive voices, customizable voices, API access, face cloning technology, brand-specific digital twin, intelligent digital twin, gaming and entertainment, real-time APIs, data security, ethical AI, MeetHifiVoice, deep-learning model',
        description: 'Discover the Power of AI Voice Cloning with DupDub! Streamline your content creation process and gain more traffic. Customize voices for enterprises and cherish your beloved one\'s voice forever. Explore our face cloning technology for immersive experiences. Get started now!'
      },
      canonical: 'https://app.dupdub.com/voice-cloning',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/ai-tools',
    name: 'toolsList',
    // component: () => import(/* webpackChunkName: "products" */ '@/pages/products/products-tools'),
    meta: {
      title: 'Simplify Content Creation with Transcription & Translate Tools',
      content: {
        keywords: 'transcription tools, translate tool',
        description: 'Enhance content creation with our transcription & translation tools. Convert audio/video to editable text, translate in 30+ languages, & easily align subtitles.'
      },
      canonical: 'https://app.dupdub.com/ai-tools',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },

  {
    path: '/solutions/marketing',
    name: 'solutionsMarketing',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/solutions-marketing.vue'),
    meta: {
      title: 'Marketing Video Generator | Marketing Copy | Best Text to speech| YouTube Talking Avatar Video | TikTok Talking Avatar | Marketing Avatar Videos',
      content: {
        keywords: 'Create marketing videos,voiceovers and marketing copy with AI',
        description: 'Create captivating marketing videos using only your ideas. DupDub AI writes copy, generates voiceovers, and creates AI avatars for you. Say goodbye to video production stress and unlock your brand\'s endless possibilities. '
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/solutions/marketing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/youtube-tiktok',
    name: 'featureYoutube',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/youtube-tiktok.vue'),
    meta: {
      title: 'YouTube & TikTok Voice Generator: 400+ Ultra-Realistic Voiceovers',
      content: {
        keywords: 'tiktok voice generator, voice changer for youtube',
        description: 'Transform your YouTube and TikTok videos with our AI-powered voice generator. Get 400+ ultra-realistic voiceovers & try our voice changer for YouTube.'
      },
      canonical: 'https://app.dupdub.com/youtube-tiktok',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/learning-training',
    name: 'learning-training',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/learning-training'),
    meta: {
      title: 'E-Learning Voiceover Narration: Video Voice Dubbing Solution',
      content: {
        keywords: 'voice dubbing, e-learning voice over',
        description: 'Save time and budget with AI voiceovers for your e-learning videos. Our dubbing solution offers substantial savings while delivering a professional result.'
      },
      canonical: 'https://app.dupdub.com/learning-training',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/marketing',
    name: 'marketing',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/marketing'),
    meta: {
      title: 'AI Marketing Tools: Voice Characters For Commercial Video',
      content: {
        keywords: 'voice ai characters, ai marketing tools',
        description: 'Save time and money on voiceovers for your marketing videos. Choose from a diverse range of commercial AI voiceovers at great prices, only at DupDub.'
      },
      canonical: 'https://app.dupdub.com/marketing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/audio-book',
    name: 'audio-book',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/audio-book'),
    meta: {
      title: 'AI Voice Changer & Cloning Free: Create Audiobooks Easily',
      content: {
        keywords: 'ai audiobooks, ai voice cloning free',
        description: 'Effortlessly create audiobooks with our AI voice changer & cloning-free tool. Enjoy online generation, API access, & voice-cloning services for enterprises.'
      },
      canonical: 'https://app.dupdub.com/audio-book',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/podcast',
    name: 'podcast',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/podcast.vue'),
    meta: {
      title: 'Best AI Voice Generator: Elevate Your Podcasts Instantly | DupDub',
      content: {
        keywords: 'ai podcasts, best ai voice generator',
        description: 'Best AI Voice Generator: Elevate Your Podcasts Instantly with DupDub. Revolutionize your audio content effortlessly and save time and money!'
      },
      canonical: 'https://app.dupdub.com/podcast',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/customer-service',
    name: 'customerService',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/customer-service.vue'),
    meta: {
      title: 'DupDub\'s Voiceovers: Your Key to Outstanding Service',
      content: {
        keywords: '',
        description: 'Exceptional customer service with DupDub\'s lifelike voiceovers. Experience 24/7 high-quality support that leaves a lasting impression.'
      },
      canonical: 'https://app.dupdub.com/customer-service',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/solutions/content-creators',
    name: 'solutionsCreators',
    // component: () => import(/* webpackChunkName: "solutions" */ '@/pages/use-cases/solutions-creators.vue'),
    meta: {
      title: 'Make YouTube Videos I TikTok Videos ITalking Avatars | Text to Speech I and VideoL ocalization I Al Subtitles I Video Editing',
      content: {
        keywords: 'Monetize YouTube videoFaceless Video,Al video, AIvoice, Al content',
        description: 'Make YouTube video and TikTok video with DupDub Al designed for content creators.Unleash the power of talking avatars, text tospeech technology, Al subtitles, video localization and video editing features. enablincyou to engage your audience in ways you neverthought possible.'
      },
      canonical: 'https://dupdub.com/solutions/content-creators',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/api',
    name: 'api',
    // component: () => import(/* webpackChunkName: "home" */ '@/pages/api/index.vue'),
    meta: {
      title: 'Unlock the Power of Real-Time APIs: Explore DupDub\'s One-Stop Developer Service!',
      content: {
        keywords: 'Real-time APIs, one-stop developer service, flexible APIs, low-latency APIs, exclusive face cloning models, authentic voice cloning models, voice assistant, AI-generated voices, text-to-speech API, natural-sounding voices, expressive voice styles, adjustable parameters, MeetHiFiVoice, API key generation, free trial, tiered pricing, billing service, API usage tracking, open API launch discounts.',
        description: 'Discover flexible and low-latency APIs with exclusive face and voice cloning models at DupDub. Power your product with 400+ natural-sounding AI-generated voices in 60+ languages. Try our free trial with 10,000 characters and tiered pricing starting at $20 per 1M characters.'
      },
      canonical: 'https://app.dupdub.com/api',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/blog',
    name: 'blog',
    // component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/blob-list.vue'),
    meta: {
      title: 'Skills, perspectives, and studies Al voiceover, avatar, video and content',
      content: {
        keywords: 'text to speech generator,realistic voice generator,text to voice generator,ai voice generator,voice generator text to speech,online voice generator,ai text to speech,free text to speech converter,best ai voice generator,realistic voice generator free,text to speech voice changer',
        description: 'Discover the #1 text-to-speech platform, DupDub, and unleash the potential of AI voiceovers. Create captivating voiceovers and scripts in a snap, serving 40+ languages and accents. Say goodbye to expensive voice actors and time-consuming recordings. Start your 3-day free trial now – no credit card required! DupDub is the best online AI voice generator to generate realistic text-to-speech voices. Instantly convert 400+ text-to-speech human-like voices using our voice changer/converter today!'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/blog',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/DupdubContest',
    name: 'DupdubContest',
    // component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/dupdub-contest/index.vue'),
    meta: {
      canonical: 'https://app.dupdub.com/DupdubContest',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/changelog',
    name: 'changelog',
    // component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/changelog'),
    meta: {
      title: 'Transform Your Content with Natural-Sounding Voices | Try Our Text-to-Speech Technology Today!',
      content: {
        keywords: 'Text-to-Speech API, Natural Voices, High-Quality Voices, Accessibility, Voice Technology, Voice Cloning, AI Voice Studio, Transcription, Subtitle alignment, YouTube downloader',
        description: 'Enhance Accessibility and Creativity | Experience the Ultimate Freedom in Voice Technology with Our Text-to-Speech API. Explore New Possibilities!'
      },
      canonical: 'https://app.dupdub.com/changelog',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/about',
    name: 'aboutUs',
    // component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/aboutus'),
    meta: {
      title: 'Experience the Future of AI Voice with DupDub\'s Cutting-Edge Text-to-Speech Editor',
      content: {
        keywords: 'AI voice, Text-to-Speech editor, MeetHifiVoice, Mobvoi, human-computer interaction, voice cloning, speech synthesis, AI technology',
        description: 'Discover DupDub, powered by Mobvoi\'s innovative AI technology, delivering authentic and human-like voice interactions. Create next-generation audio content with our user-oriented Text-to-Speech editor, MeetHifiVoice, the world-leading and game-changing solution.'
      },
      canonical: 'https://app.dupdub.com/about',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    // component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/contact'),
    meta: {
      title: 'Contact Us | DupDub',
      content: {
        keywords: 'Enterprise Cooperation, Customer Support, Follow us, Social Media, Join Our WhatsApp Group',
        description: 'If you have any questions, issues, suggestions, or requirements, please feel free to contact us. Our support staff will respond to you as soon as possible.'
      },
      canonical: 'https://app.dupdub.com/contact',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/help-detail',
    name: 'helpDetail',
    // component: () => import(/* webpackChunkName: "others" */ '@/pages/help/help-detail-v2'),
    meta: {
      title: 'Discover 400+ AI Text To Speech Voices | Try Free AI Dubbing Now!',
      content: {
        keywords: 'Text to Speech, AI Dubbing, 400+ Voices, Text to Speech Transcription, Audio/Video Export, Professional Editing Features, AI Speakers, Background Music, Sound Effects, Free Membership, Professional Membership, Personal Membership',
        description: 'Convert text to lifelike AI dubbing with 400+ voices in multiple languages and styles. Enjoy professional editing features, audio/video export, and more. Try it now for free!'
      },
      canonical: 'https://app.dupdub.com/help-detail',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    // component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/affiliate/index.vue'),
    meta: {
      title: 'Earn 20% Recurring Commission: Join DupDub\'s Affiliate Program',
      content: {
        keywords: '',
        description: 'Join DupDub\'s Affiliate Program & earn 20% recurring commission for 12 months. Offer cutting-edge AI technology to your audience & start earning today!'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/affiliate',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path:'/privacy-policy',
    name: 'privacy-policy',
    // component: () => import(/* webpackChunkName: "others" */ '@/pages/agreement/privacy-policy.vue'),
    meta:{
      title:'DupDub Privacy Policy | DupDub',
      content: {
        keywords: 'Privacy Policy, right to privacy, personal information',
        description: 'This Privacy Policy explains who we are, how we collect, share and use personal information about you, and how you can exercise your privacy rights.'
      },
      canonical: 'https://app.dupdub.com/privacy-policy',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    // component: () => import(/* webpackChunkName: "others" */ '@/pages/agreement/protocol'),
    meta: {
      title: 'Terms of Service | DupDub',
      content: {
        keywords: 'Service, legal agreement, License',
        description: 'These Terms of Service below are applicable to all Apps published by HK SmartMV Limited and its affiliates.'
      },
      canonical: 'https://app.dupdub.com/terms-of-service',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/youtube-downloader',
    name: 'youtubeDownloader',
    // component: () => import(/* webpackChunkName: "manage" */ '@/pages/tools/videoDownload/index.vue'),
    meta: {
      title: 'Productivity Tools | DupDub, Download videos and covers only with URL',
      content: {
        keywords: 'YouTube downloader, cover download, video URL',
        description: 'YouTube downloader can help to make content creation more convenient by just URL links from the websites/apps. No more worries for having no idea of materials.'
      },
      canonical: 'https://app.dupdub.com/youtube-downloader',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/tools/video-download',
    name: 'videoDownload',
    redirect: '/youtube-downloader'
  },
  {
    path: '/subtitle-alignment',
    name: 'subtitleAlignment',
    // component: () => import(/* webpackChunkName: "manage" */ '@/pages/tools/subtitleAlignment/srt.vue'),
    meta: {
      title: 'Productivity Tools | DupDub, Align Speech and Paired Scripts to Subtitle with Timelines',
      content: {
        keywords: 'subtitle, alignment, timelines, scripts',
        description: ' Import the files and you will get aligned subtitle immediately. Automatically align speech in video/audio files and paired scripts to subtitle with timelines.'
      },
      canonical: 'https://app.dupdub.com/subtitle-alignment',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/tools/alignment',
    name: 'toolsAlignment',
    redirect: '/subtitle-alignment'
  },
]