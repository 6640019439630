export default {
    "speakerSearchType": {
        "quality": "Quality",
        "language": "Language",
        "accent": "Accent",
        "style": "Style",
        "scenario": "Scenario",
        "gender": "Gender",
        "age": "Age"
    },
    "miniAudio": {
        "normal": "NORMAL",
        "min": "MIN",
        "max": "MAX",
        "open": "Expand",
        "stow": "Collapse",
        "minimize": "Minimize",
        "all": "All",
        "male": "Male",
        "female": "Female",
        "noSpeaker": "No matched speakers",
        "highDefinition24k": "24K High Quality",
        "24k": "24K",
        "highDefinition48k": "48K Super Quality",
        "unlockHighDefinition48k": "Click to unlock 48 SQ",
        "48K": "48K",
        "default": "Default",
        "speakerDetail": "Speaker profile",
        "speedOfSpeech": "Speed",
        "word": "Character",
        "second": "Second",
        "intonation": "Pitch",
        "bigShot": "Bigshot",
        "recommend": "Recommend",
        "purchased": "Purchased",
        "lately": "Recent",
        "purchasedNoData": "暂未购买声音~赶快前往声音商店选购吧",
        "goPay": "Upgrade",
        "fee": "Pay",
        "free": "Free",
        "Beta": "Beta",
        "All": "All",
        "VoiceLab": "VoiceLab",
        "Favorite": "Favorites",
        "favoriteNoData": "No favorite speakers added",
        "Recent": "Recents",
        "DupDubLab": "My voices",
        "recentNoData": "No recently used speakers",
        "myVoicesNoData": "No My voices",
        "addFavorited": "Add to favorite",
        "Unfavorite": "Unfavorite",
        "addFavoritedTip": "Add current settings to favorite",
        "UnfavoriteTip": "Remove current settings from favorites",
        "wenwenLaboratory": "Mobvoi Lab",
        "wenwenLaboratoryNoData": "No related content for Mobvoi Lab",
        "noContent": "No related content ",
        "empty": "Empty",
        "pay": "PAY",
        "Cloned": "Cloned",
        "cloneSpeakerLimitTip": "We currently support clone a maximum of 10 voices.",
        "deleteCloneSpeakerTip": "This file currently uses this particular voice. Please replace it with a different voice before removing this voice.",
        "addVoice": "Add voice",
        "deleteVoice": "Delete the voice",
        "deleteBtn": "Delete",
        "cancelBtn": "Cancel",
        "deleteTitle": "Are you sure to delete this voice?",
        "popTxt": {
            "tips": "Tips",
            "topay": "Upgrade",
            "vip": "VIP",
            "svip": "SVIP",
            "or": "or",
            "use": "use",
            "48kNeedCoordination": "48K尊享音质需要配合",
            "youSelectDubbingEngineer": "speaker you selected",
            "extraUnlock": "是需要额外付费解锁才能使用的，你可以前往声音商店进行试听和查看声音详情",
            "reminder": "Kind Tips",
            "viewDetails": "View Details",
            "know": "Got it",
            "speakerFloatingLayer": "Speaker floating widget",
            "payMemberMatching": "付费声音需要与会员搭配使用，不可单独使用",
            "contain": "contains",
            "styles": "styles",
            "buyMember": "To upgrade",
            "receiveUnlock": "是需要领取解锁才能使用的，你可以前往声音商店进行试听和查看声音详情",
            "goAudition": "Preview"
        },
        "mini": "mini",
        "supportEnglish": " only support English",
        "let_english": "english",
        "changeHere": "Change voice styles here",
        "confirm": "Confirm",
        "cancel": "Cancel"
    },
    "errTxt": {
        "errorOccured": "An error just occured",
        "failedList": "Failed to get article list",
        "serverError": "Server error",
        "serverOvertime": "Request timeout, please try again later",
        "serverFail": "Request failed, please try again later",
        "serverErrRefresh": "Server error, please try again later",
        "synthesisFail": "Failed to generate speech",
        "serviceException": "Server error, please try again later",
        "serviceError": "Server error, please try again later"
    },
    "toolMenus": {
        "preview": "Preview",
        "alias": "Alias",
        "emphasis": "Emphasis",
        "sayAs": "Say As",
        "phoneme": "Phoneme",
        "localSpeed": "Local Speed",
        "multiSpeaker": "Multi-Speaker",
        "findAndReplace": "Batch Mode",
        "rhythm": "Rhythm",
        "addPause": "Add Pause",
        "pauseSetting": "Pause Setting",
        "soundEffect": "Sound Effect",
        "backgroundMusic": "Background Music",
        "lexIcon": "Lexicon",
        "homograph": "Heteronym",
        "speed": "Speed",
        "pitch": "Pitch"
    },
    "audioDetail": {
        "upgrade": "Upgrade",
        "sampleVoices": "Sample voices",
        "voiceSetting": "Return to voice setting"
    },
    "collection": {
        "title": "Upgrade to continue",
        "desc": "The feature to save personalized voice settings is not available to your current plan. To enjoy this exclusive feature, please upgrade your plan to Professional.<p class='tip'>Don’t hesitate to enhance your enjoyment and optimize your work efficiency!</p>",
        "descTip": "Don’t hesitate to enhance your enjoyment and optimize your work efficiency!",
        "cancel": "Cancel",
        "upgrade": "Upgrade"
    },
    "previewOutOfWordsFree": {
        "title": "Upgrade to continue", 
        // "title": "Your free trail has run out, please upgrade your plan.",
        "desc": "You have reached the maximum character limit for the free preview. Upgrade your plan to continue previewing.",
        // "desc": "Free plan is limited to {let_textLen} preview characters.",
        "cancel": "Cancel",
        "upgrade": "Upgrade",
    },
    "subscriptionExpirationFree": {
        "desc": "It appears that your account has exceeded the expiration of the free trial or subscription period. Upgrade your plan to continue previewing."
    },
    "multiSpeaker": {
        "searchPlaceholder": "Find speaker",
        "speaker": "Speaker",
        "style": "Style",
        "default": "Default",
        "speed": "Speed",
        "tone": "Pitch",
        "apply": "Apply",
        "remove": "Remove",
        "commonlyUsed": "Recent",
        "free": "Free",
        "pleaseSlideTheText": "Please select text",
        "auditionFaild": "Failed to preview, please refresh and try again",
        "selectSpeakerStyle": "Please select speaker and style",
        "speakerNotSupportStress": "This speaker do not support emphasis",
        "popTxt": {
            "tips": "Kind Tips",
            "topay": "Go to pay",
            "vip": "VIP",
            "svip": "SVIP",
            "or": "or",
            "use": "use",
            "48kNeedCoordination": "48K尊享音质需要配合",
            "youSelectDubbingEngineer": "Speaker you selected",
            "extraUnlock": "是需要额外付费解锁才能使用的，你可以前往声音商店进行试听和查看声音详情",
            "reminder": "Kind Tips",
            "viewDetails": "View Details",
            "know": "Got it",
            "speakerFloatingLayer": "Speaker floating layer",
            "payMemberMatching": "付费声音需要与会员搭配使用，不可单独使用",
            "contain": "Contains",
            "styles": "styles",
            "buyMember": "Upgrade",
            "receiveUnlock": "是需要领取解锁才能使用的，你可以前往声音商店进行试听和查看声音详情",
            "goAudition": "To preview"
        }
    },
    "placeholder": {
        "atCharacters": "at most 100 characters",
        "search": "Search",
        "searchArticle": "Enter filenames or keywords",
        "searchProject": "Enter project names or keywords",
        "searchSpeaker": "Enter speaker names",
        "create": "Name your new folder",
        "selectLanguage": "Please select language"
    }
}