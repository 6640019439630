import { stringifyUrl } from "@/utils/url"
import { getCookie } from '@/utils/cookie'
import { dupdubGateway } from "@/utils/env"
import { oldGetCookie } from "./cookie"

const proxyConfig = [
    {
        test: /^\/gateway-apis/,
        exclude: [],
        target: dupdubGateway,
        pathRewrite: {
            '^/gateway-apis': ''
        }
    }
]
export function proxyUrl(url, withToken = true) {
    if (!url) return ''
    for (let proxy of proxyConfig) {
        if (proxy.exclude?.some(r => typeof r === 'string' ? url.startsWith(r) : r.test(url))) continue
        if (proxy.test.test(url)) {

            // pathRewrite
            if (proxy.pathRewrite) {
                Object.keys(proxy.pathRewrite).forEach(key => {
                    url = url.replace(new RegExp(key), proxy.pathRewrite[key])
                })
            }

            // concat target
            url = (typeof proxy.target === 'string' ? proxy.target : proxy.target()) + url

            // concat auth
            if (withToken !== false) {
                const ww_token = getCookie('ww_token') || oldGetCookie('ww_token')
                url = ww_token ? stringifyUrl({ url, query: { ww_token } }) : url
            }
            break
        }
    }
    return url;
}
export default function createProxiedAxios(axios) {
    axios.interceptors.request.use(function (config) {
        config.url = proxyUrl(config.url, config.withToken)
        config.headers.ww_token = getCookie('ww_token') || oldGetCookie('ww_token') // 兼容奇妙元等还在使用cookie的系统
        if (window.i18nLocale) {
            config.headers.ww_language = window.i18nLocale // 接口message国际化
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    return axios
}
