/*
 * @Date: 2020-05-14 10:23:42
 * @Author: yang.yang1809
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-07-22 14:53:54
 */
// 是否记录密码
export const CHANGE_REMEMBER_STATUS = 'CHANGE_REMEMBER_STATUS'
// 播放按钮状态
export const CHANGE_PLAY_STATUS = 'CHANGE_PLAY_STATUS'
// 提示可以导入状态
export const IMPORT_STATUS = 'IMPORT_STATUS'
// 用户页码
export const ARTICLE_PAGES = 'ARTICLE_PAGES'
// 别名内容
export const ALIAS_CONTENT = 'ALIAS_CONTENT'
// 英文发音内容
export const PRON_CONTENT = 'PRON_CONTENT'

// 播放速度
export const PLAY_AUDIO_SPEED = 'PLAY_AUDIO_SPEED'
// 发音人
export const PLAY_AUDIO_SPEAKER = 'PLAY_AUDIO_SPEAKER'
// 初始播放速度
export const AUDIO_ORIGIN_SPEED = 'AUDIO_ORIGIN_SPEED'
// 起始发音人
export const AUDIO_ORIGIN_SPEAKER = 'AUDIO_ORIGIN_SPEAKER'
// 语言
export const PLAY_AUDIO_SPEAKER_LANGUAGE = 'PLAY_AUDIO_SPEAKER_LANGUAGE'
// 文章输入类型
export const ARTICLE_TYPE = 'ARTICLE_TYPE'
// VIPINFO
export const VIPINFO = 'VIPINFO'
