<template>
    <el-dialog append-to-body :visible.sync="listenDialog" width="540px" custom-class="listen-dialog" :before-close="closePanel">
        <div class="header">
            <div class="title">Search for matching voices</div>
            <div class="desc">Find simllar voiceover options for your file</div>
        </div>
        <div class="body">
            <div class="tab-box">
                <div class="item" :class="videoTabInfo.id===item.id?'active':''" v-for="item in videoTabList" :key="item.id" @click="videoTab(item)">{{item.btn}}</div>
            </div>
            <!-- 第一步 上选文件/视频链接 -->
            <div class="video-upload">
                <div class="item-file" :class="{'file-error':fileTip}" v-show="videoType === 1">
                    <input  type="file" name="file" multiple title="" ref="upload" @change="onChange" :accept="videoAccept"/>
                    <template v-if="resultName">
                        <div class="file-type"><span>{{resultType}}</span></div>
                        <div class="name">{{ resultName }}</div>
                    </template>
                    <template v-else>
                        <img src="./img/Upload.svg" alt="" class="upload-img">
                        <p>Video or audio file, up to 20 mins</p>
                        <div class="error" v-if="fileTip">{{ fileTip }}</div>
                    </template>
                    <div class="loading-wrapper" v-if="isLoadingVisible">
                        <img class="dupdub-lading" src="https://cdn-static.dupdub.com/frontend/autoupload/npm/image/dupdub-logo1690869744.gif">
                    </div>
                </div>
                <div  class="video-link" :class="{'active':isFocus}" v-show="videoType === 0">
                  <textarea
                    @focus="keyWordsFocus"
                    @blur="keyWordsBlur"
                    ref="videoTextarea"
                    class="auto-resize"
                    spellcheck="false"
                    v-model.trim="videoUrl"
                    :placeholder="videoTabInfo.placeholderText"
                  >
                  </textarea>
                </div>
            </div>
            <div class="footer">
                <el-button class="cancel" @click="cancel()">{{searchLoading?'Stop searching':'Cancel'}}</el-button>
                <el-button class="start search-loading" v-if="searchLoading"><img src="./img/loading-icon.png" alt=""><span>Loading...</span></el-button>
                <el-button class="start" @click="startSearching" v-else>Start searching</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import stat from '@/utils/stat'
import http from '@/utils/http-request.js'
import uploadFile from "@dupdub/upload-file";
import { parseDuration,isAcceptFile } from '@/utils/file'
import axios from 'axios'
import {ttsUploadUrl} from "@/utils/env.js"
import { voiceSearchApi } from '@/api/index.js'
let CancelToken = axios.CancelToken
const MAX_DURATION = 10 * 60
export default{
    data(){
        return{
            isLoadingVisible:false,//上传文件loading
            searchUrl:'',
            resultType:'',
            videoUrl:"",//地址
            resultName:"",
            fileTip:"",//上传失败提示文案
            isFocus: false,//输入框是否获取焦点
            listenDialog:false,
            videoTabList:[
                {id:1,btn:'Upload file',type:'file',placeholderText:''},
                {id:2,btn:'YouTube',type:'link',placeholderText:'https://www.youtube.com/watch?v=9M0x5xDk36Y'},
                {id:3,btn:'TikTok',type:'link',placeholderText:'https://www.tiktok.com/@dupdubvoice/video/7323998758792940842'},
                {id:4,btn:'Facebook',type:'link',placeholderText:'https://www.facebook.com/DupdubDotCom/videos/675068811224201/'},
                {id:5,btn:'Instagram',type:'link',placeholderText:'https://www.instagram.com/reel/C236UgWSEYL'},
                {id:6,btn:'X(Twitter)',type:'link',placeholderText:'https://twitter.com/i/status/1746926152374165531'},
                {id:7,btn:'Other URL',type:'link',placeholderText:'Provide the URL and we will verify if it is supported'},
            ],
            videoType: 1, //0(url) 1(file)
            videoTabInfo:{id:1,btn:'Upload file',type:'file',placeholderText:''}, //默认显示上传文件
            videoAccept: [
                '.mp4',
                '.mov',
                '.webm',
                '.mkv',
                '.m2p',
                '.3gp',
                '.m4v',
                '.mp3',
                '.wav',
                '.aac',
                '.m4a',
                '.flac',
                '.opus',
                '.ac3',
                '.rm',
                '.mpg',
                '.vob',
                '.wmv',
                '.ts',
                '.mxf',
                '.mts',
                '.avi',
                '.rmvb',
                '.m2ts',
                '.flv',
            ],
            searchLoading:false, //是否在搜索中
            request: null,
            source:'',//弹框来源
        }
    },
    methods:{
        //取消请求
        cancel(){
            if(this.searchLoading){
                this.searchLoading=false
                this.request && this.request()
            }else{
                this.listenDialog=false
                this.$emit('listen-recognize',this.listenDialog)
            }
        },
        //打开弹框
        openListenDialog(ev){
            console.log(ev)
            this.source=ev.source
            let listenDialog=document.querySelector('.listen-dialog')
            listenDialog.style.top=ev.top+'px'
            let right=ev.right
            if(ev.right<=0){
                right=0
            }
            listenDialog.style.right=right+'px'
            this.searchUrl=''
            this.fileTip=''
            this.videoType=1
            this.resultName=''
            this.videoUrl=''
            this.videoTabInfo={id:1,btn:'Upload file',type:'file',placeholderText:''},
            this.listenDialog=true
            this.$emit('listen-recognize',this.listenDialog)
        },
        //关闭弹框
        closePanel(){
            this.listenDialog=false
            this.request && this.request()
            this.$emit('listen-recognize',this.listenDialog)
        },
        // 搜索声音
        startSearching(){
            //声音商店
            if(this.source=='voice-store'){
                stat.event('voicestore_matchingvoices_startsearching_click')
            }else{ //发音人面板
                stat.event('ttseditor_matchingvoices_startsearching_click')
            }
            if(this.searchLoading) return
            if(this.videoType === 0){
                if(!this.isExistAndLink()){
                    this.$message.error('We\'re sorry, but the URL you provided is currently not supported.')
                    return
                }
                this.searchUrl=this.videoUrl
            }
            if(!this.searchUrl) return
            this.searchLoading=true
            voiceSearchApi.voiceSearch({url: this.searchUrl},{cancelToken: new CancelToken(c => { this.request = c})}).then(res=>{
                const {data,code ,message} = res.data
                if(code===200){
                    console.log(data)
                    this.listenDialog=false
                    this.$emit('listen-recognize',this.listenDialog)
                    this.$emit('listen-complete',data)
                }else{
                    this.$message.error(message || 'Sorry, we failed to search for similar voices.')
                }
                this.searchLoading=false
            }).catch(err=>{
                this.searchLoading=false
                console.log(err)
            })
        },
        isExistAndLink() {
            return this.videoUrl && /^(http|https):\/\/[^ "]+$/.test(this.videoUrl);
        },
        videoTab(ev){
            this.transcriptionId = ''
            this.videoTabInfo=ev
            this.videoType=ev.type==='file'?1:0
            this.uploadError=false
        },
        async onChange(e) {
            this.fileTip=''
            this.resultName=''
            const file = e.target.files[0];
            e.target.value=''
            let duration = 0;
            if (!isAcceptFile(file, this.videoAccept,true)) {
                this.fileTip='This file format is currently not supported. Please try a different one.'
                return
            }
            // 限制文件大小 最大300M
            const FILE_LIMIT = 300 * 1024 * 1024
            if (file.size > FILE_LIMIT) {
                this.fileTip='Please upload a file that is under 300M.'
                return 
            }
            // 解析时长
            let durationJson=await parseDuration(file)
            duration = durationJson.track ? durationJson.track[0].Duration : 0
            // 校验时长
            if (duration>MAX_DURATION){
                this.fileTip='Please upload a file that is under 10 minutes in duration.'
                return
            } 
            this.isLoadingVisible=true
            this.videoAudioUpload(file)
        },
        videoAudioUpload(ev) {
            this.isCreating = true;
            let file = ev
            // 分片上传
            uploadFile(ttsUploadUrl, "frontend/dupdub-upload/").multipleUploads({file: file,
                success: (res) => {
                    this.searchUrl = res;
                    this.resultType=file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
                    this.resultName = file.name
                    this.isLoadingVisible=false
                },
                fail: (err) => {
                    this.fileTip=err
                    this.isCreating = false;
                },
                progress: (res) => {
                    let { progress } = res;
                    if (progress === 100) {
                        this.realUpload = true;
                    }
                },
            });
            },
        keyWordsFocus() {
            this.isFocus = true
        },
        keyWordsBlur() {
            this.isFocus = false
        }
    }
}
</script>
<style lang="less" scoped>
:deep(.listen-dialog){
    position: absolute;
    margin: 0 !important;
    height: fit-content;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0;
    background: #fff;
    min-height: auto;
    @keyframes loading {
        0% {
        transform: rotate(0deg);
        }

        100% {
        transform: rotate(360deg);
        }
    }
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        padding: 0;
    }
    .header{
        box-sizing: border-box;
        height: 64px;
        padding: 12px 16px;
        box-shadow: inset 0px -1px 0px 0px #F0F0F0;
        .title{
            font-weight: 600;
            font-size: 14px;
            color: #101010;
            line-height: 21px;
            font-family: "SemiBold";
        }
        .desc{
            font-size: 12px;
            color: #A8A8A8;
            line-height: 18px;
            margin-top: 2px;
        }
    }
    .body{
        padding: 0 16px;
        .tab-box{
            height: 44px;
            display: flex;
            align-items: center;
            .item{
                font-size: 12px;
                color: #000000;
                line-height: 18px;
                margin-right: 16px;
                cursor: pointer;
                &:hover{
                    color: #076BF7;
                }
                &.active{
                    font-weight: 600;
                    color: #076BF7;
                    position: relative;
                    font-family: "SemiBold";
                    &::after{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -4px;
                        content: '';
                        width: 16px;
                        height: 2px;
                        background: #076BF7;
                    }
                }
            }
        }
        .video-upload{
            height: 96px;
            width: 100%;
            margin-top: 4px;
            .item-file{
                height: 100%;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px dashed #CCCCCC;
                position: relative;
                text-align: center;
                padding-top: 14px;
                box-sizing: border-box;
                position: relative;
                .upload-img{
                    width: 40px;
                    height: 40px;
                }
                .loading-wrapper{
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(250, 250, 250, 1);
                    img{
                        width: 120px;
                    }
                }
                &.file-error{
                    padding-top: 8px;
                    p{
                        margin-top: -6px;
                    }
                    .error{
                        font-size: 12px;
                        color: #F22A18;
                        line-height: 18px;
                        margin-top: 4px;
                    }
                }
                &:hover{
                    background: #F0F6FF;
                    border-color: #076BF7;
                }
                .file-type{
                    width: 40px;
                    height: 40px;
                    margin: auto;
                    background-image: url('./img/file.png');
                    background-size: 100% 100%;
                    position: relative;
                    span{
                        position: absolute;
                        left: 6px;
                        top: 16px;
                        font-weight: 500;
                        font-size: 10px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 14px;
                        display: block;
                        width: 25px;
                        height: 14px;
                        background: #076BF7;
                        border-radius: 2px;
                    }
                }
                .name{
                    font-size: 12px;
                    color: #424242;
                    line-height: 18px;
                    text-align: center;
                    margin-top: 4px;
                }
                input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    left: 0;
                    top: 0;
                }
                p{
                    font-size: 12px;
                    color: #A8A8A8;
                    line-height: 18px;
                    margin-top: -2px;
                }
            }
            .video-link{
                height: 100%;
                width: 100%;
                border-radius: 8px;
                border: 1px solid #CCCCCC;
                padding: 12px 16px;
                box-sizing: border-box;
                &.active{
                    border-color: #076BF7;
                }
                textarea{
                    width: 100%;
                    min-height: 100%;
                    resize: none;
                    box-sizing: border-box;
                    border: none;
                    font-size: 12px;
                    color: #424242;
                    line-height: 18px;
                    outline: none;
                    &::-webkit-input-placeholder {
                        font-size: 12px;
                        font-weight: 400;
                        color: #A8A8A8;
                    }
                }
            }
        }
        .footer{
            padding: 16px 0;
            height: 40px;
            display: flex;
            justify-content: center;
            .el-button{
                width: 144px;
                border-radius: 12px;
                border: none;
                font-weight: 500;
                font-size: 14px;
                margin: 0;
            }
            .cancel{
                border: 1px solid #DCDCDC;
                margin-right: 16px;
                color: #101010;
                background: #FFFFFF;
                &:hover{
                    background: #F2F3F5;
                }
            }
            .start{
                background: #076BF7;
                color: #FFFFFF;
                &:hover{
                    background: #0052CC;
                }
            }
            .search-loading{
                background: #A6C9FF;
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    animation: loading 3s linear infinite;
                }
                &:hover{
                    background: #A6C9FF;
                }
            }
        }
    }
}

</style>