<template>
  <div class="lexicon-content"
    v-infinite-scroll="loadnext"
    :infinite-scroll-immediate="true"
    :infinite-scroll-delay="100"
    :infinite-scroll-distance="200">
    <div class="lexicon-header">
      <div class="search-left">
        <h2>{{$t('searchArticleLexicon')}}</h2>
        <p>{{$t('lexicon.lexiconDesc')}}</p>
      </div>
      <div class="search">
        <el-autocomplete
          ref="listSelectAutocomplete"
          popper-class="lexicon-list-select"
          :debounce="1"
          v-model.trim="keyWord"
          :fetch-suggestions="querySearchAsync"
          :placeholder="$t('lexicon.searchPlaceholder')"
          @blur="autocompleteBlur"
          @input="changeStyle"
          @select="clickHighItem"
          :trigger-on-focus="true"
          v-scrollLoad="loadSuggestions"
        >
        <template slot-scope="{ item }">
          <div class="alias-li">
            <img :src="item.type === 1 ? phonmeIcon : aliasIcon" alt="" class="before-icon">
            <span class="name" v-html="addHighColor(item.original)"></span>
            <span class="name-desc" v-html="addHighColor(item.alternative)"></span>
          </div>
        </template>
        </el-autocomplete>
        <div class="search-icon gtm-search-icon" @click="searchIconEvt">
          <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_search1695785609.png" class="gtm-search-icon" alt="">
        </div> 
      </div>
      <iconpark-icon
        name="common-close"
        class="lexicon-close"
        size="24"
        @click.stop="$emit('close')"
      ></iconpark-icon>
    </div>
    <section class="works">
      <div class="tab-header">
        <span @click="tabClick(0)" class="gtm-alias-lexicon" :class="{'high': addLexiconParam.type === 0}">{{$t('lexicon.tabAlias')}}</span>
        <span @click="tabClick(1)" class="gtm-phoneme-lexicon" :class="{'high': addLexiconParam.type === 1}">{{$t('lexicon.tabPhoneme')}}</span>
      </div>
      <div class="alias-phoneme-list">
        <h3>{{$t('lexicon.add')}} {{addLexiconParam.type === 0 ? aliasTxt:phonmeTxt}}</h3>
        <ul class="add">
          <li>
            <el-input 
            :class="{'err': originalErrorMsg}" 
            @input="inputTxtNum(addLexiconParam.original, 'original')" 
            v-model="addLexiconParam.original" 
            :placeholder="$t('lexicon.original')">
            </el-input>
            <div class="error-tips">{{originalErrorMsg}}</div>
          </li>
          <li v-clickoutside="outside" @click.stop="() => {}">
            <div class="keyboard-contain" v-if="isShowKeyboard">
              <ipa-keyboard
              class="keyboard"
              @select-phonetic-symbol="selectPhoneticSymbol"
              @delete-symbol="deleteSymbol"
              @close-board="closeBoard"
              ></ipa-keyboard>
            </div>
            <listen-audio ref="listenAudio" @playAlias="playAlias"></listen-audio>
            <!-- 音标  -->
            <el-input ref="addAlternative" 
            v-if="addLexiconParam.type === 1"
            :class="{'err': lexiconErrorMsg}"
            @blur="changeCursorPos($event)"
            @focus="showPhonemeKeyboard"
            @input="inputTxtNum(addLexiconParam.alternative, 'alternative')"
            v-model="addLexiconParam.alternative" 
            :placeholder="phonmeTxt"></el-input>
            <!-- 别名 -->
            <el-input v-else 
            @input="inputTxtNum(addLexiconParam.alternative, 'alternative')" 
            v-model="addLexiconParam.alternative" 
            :class="{'err': lexiconErrorMsg}"
            :placeholder="aliasTxt"></el-input>
            <div class="error-tips">{{lexiconErrorMsg}}</div>
          </li>
          <li class="no-user-select" :class="[addLexiconParam.type === 0 ? 'gtm-alias-add' : 'gtm-phoneme-add']" @click="addLexiconEvt">
            <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_add1695793721.png" :class="[addLexiconParam.type === 0 ? 'gtm-alias-add' : 'gtm-phoneme-add']" alt="">{{$t("lexicon.add")}}
          </li>
        </ul>
        <h3>{{$t('lexicon.all')}}{{addLexiconParam.type === 0 ? aliasTxt:phonmeTxt}}</h3>
        <ul class="table-header" @click="hideKeyword">
          <li class="lexicon-li">
            <div class="original" @click="sortEvt">
              {{$t('lexicon.original')}}
              <span class="sort-wrapper">
                <i :class="{'high' : sort === 1 }"></i>
                <i :class="{'high' : sort === 2 }"></i>
              </span>
            </div>
            <div class="alternative">
              {{addLexiconParam.type === 0 ? aliasTxt:phonmeTxt}}
            </div>
            <div class="no-user-select">
              {{$t('lexicon.option')}}
            </div>
          </li>
        </ul>
        <ul class="table-body" v-clickoutside="outTableside" @click.stop="() => {}">
          <li @click="hideKeyword" v-for="(list, index) in tableData" :key="index" class="lexicon-li">
            <div class="original">
              <el-input v-if="list.canEdit"
              @input="inputTxtNumEdit(list, 'original')" 
              v-model="list.original">
              </el-input>
              <span v-else class="txt-ellipsis">{{list.original}}</span>
            </div>
            <div class="alternative">
              <template v-if="list.canEdit">
                <el-input 
                v-if="addLexiconParam.type === 0"
                @input="inputTxtNumEdit(list, 'alternative')" 
                v-model="list.alternative"></el-input>
                <!-- 音标 键盘 -->
                <template v-else>              
                  <el-input ref="alternative"
                  @focus="showKeyTableboard(list)"
                  @blur.stop="phonmeBlur($event)"
                  @input="inputTxtNumEdit(list, 'alternative')" 
                  v-model="list.alternative"></el-input>
                  <div 
                  v-show="list.showListKeyboard" class="keyboard-contain" 
                  :style="{ top: 41 + 'px'}">
                    <ipa-keyboard
                    class="keyboard"
                    @select-phonetic-symbol="(v) => selectTablePhonetic(v, list)"
                    @delete-symbol="delSymbolPhonetic(list)"
                    @close-board="closeTableBoard(list)"
                    ></ipa-keyboard>
                  </div>
                </template>
              </template>
              <span v-else class="txt-ellipsis">{{list.alternative}}</span>
            </div>
            <div class="no-user-select">
              <div class="func-opr" v-show="list.canEdit">
                <el-tooltip effect="dark" popper-class="usertooltip" :open-delay="100" :tabindex="1" content="Save" placement="top">
                  <iconpark-icon name="lexiconsave" class="save" size="16" @click.stop="saveEvt(list)"></iconpark-icon>
                </el-tooltip>
                <el-tooltip effect="dark" popper-class="usertooltip" :open-delay="100" :tabindex="2" content="Cancel" placement="top">
                  <iconpark-icon name="iconcancle" class="del" size="16" @click.stop="cancleEvt(list)"></iconpark-icon>
                </el-tooltip>
                <el-tooltip effect="dark" popper-class="usertooltip" :open-delay="100" :tabindex="3" content="Delete" placement="top">
                  <img @click.stop="deleteEvt(list)" src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_del1695793721.png" alt="" class="del-img">
                </el-tooltip>
              </div>
              <div class="func-opr" v-show="!list.canEdit">
                <el-tooltip effect="dark" popper-class="usertooltip" :open-delay="100" :tabindex="4" content="Edit" placement="top">
                 <iconpark-icon name="lexiconedit" size="16" @click="confirmEvt(list, index)"></iconpark-icon>
                </el-tooltip>
              </div>
            </div>
          </li>
          <template v-if="!loadingArticle && tableData.length <= 0">
            <div class="no-content">
              <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/no_lexicon1695793721.png" alt="">
              {{ addLexiconParam.type === 1 ? $t('lexicon.noPhonme') : $t('lexicon.noAlias')}}
            </div>
          </template>
          <div class="loadtip" v-if="loadingArticle">
            <img class="dupdub-load-loading" src="https://cdn-static.dupdub.com/frontend/autoupload/npm/image/dupdub-logo1690869744.gif" alt="">
          </div>
          <div
            class="loadtip loaded"
            v-if="
              tableData.length && (params.page + 1) >= total_pages && !loadingArticle
            ">
            {{total_num}}
            {{ addLexiconParam.type === 1 ? $t('lexicon.phoneme') : $t('lexicon.alias')}}{{$t('lexicon.noDataMore')}}
          </div>
        </ul>
      </div>
    </section>
    <del-dialogue ref="delDialogue" @deleteList="deleteList" @cancleLexicon="cancleLexicon"></del-dialogue>
  </div>
</template>
<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import clickoutside from '@/directive/click-outside'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import apis from '@/utils/http-request.js'
import IpaKeyboard from './component/ipa-keyboard.vue'
import delDialogue from './component/del-dialogue.vue'
import listenAudio from './component/listen-audio.vue'
@Component({
  components: {
    delDialogue,
    IpaKeyboard,
    listenAudio
  },
	computed: {
    ...mapGetters(['vipInfo']),
    ...mapGetters('userInfo', ['user']),
    vipPermission() {
      return this.vipInfo.type
    }
  },
  directives: {
    clickoutside,
    scrollLoad: {
      bind(el, binding, vnode) {
        let wrapDom = el.querySelector('.el-autocomplete-suggestion__wrap')
        let listDom = el.querySelector(
          '.el-autocomplete-suggestion__wrap  .el-autocomplete-suggestion__list'
        )
        wrapDom.addEventListener(
          'scroll',
          () => {
            let condition =
              wrapDom.offsetHeight +
              wrapDom.scrollTop +
              10 -
              listDom.offsetHeight
            if (condition > 5 && !vnode.context.loading) {
              //滚动到底部则执行滚动方法load，binding.value就是v-scrollLoad绑定的值，加()表示执行绑定的方法
              binding.value()
            }
          },
          false
        )
      }
    }
  }
})
export default class lexiconList extends Vue {
	data() {
		return {
      blurTableIndex: 0,
      loading: false,
      isShowKeyboard: false,
      keyboardFrom: '',
      originalErrorMsg: '',
      lexiconErrorMsg: '',
      currentIdx: null,
      keyWord: '',
      tableData: [],
      callback: null,
      searchList: [],
      aliasIcon: 'https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_alias1695793721.png',
      phonmeIcon: 'https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_phoneme1695793721.png',
      aliasTxt: this.$t('lexicon.alias'),
      phonmeTxt: this.$t('lexicon.phoneme'),
      addLexiconParam: {
        "original": "",
        "alternative": "",
        "type": 0
      },
      sort: 0,
      params: {
        page: 0,
        size: 20,
        keyWord: '',
        type: '',
        sort: ''
      },
      total_pages: 0,
      total_num: 0,
      searchParams: {
        page: 0,
        size: 30,
        keyWord: '',
        type: 0
      },
      loadingArticle: false,
      currentLexicon: null,
      prePron: '', //add键盘超过字数限制无法输入
      preTablePron: '', // edit键盘超过字数限制无法输入
      blurIndex: 0,
      validateStatus: false,
      inputPron: '', // add字数超过限制无法输入
      inputEditPron: '', // edit字数超过限制无法输入
      currentInitLexicon: null, // 记录前一个数据
      currentLexiconIdx: -1 // 记录前一个位置
		}
	}
  activated() {
    let query = this.$route.query
    if(query.type === 'phoneme') {
      this.addLexiconParam.type = 1
    } else if(query.type === 'alias'){
      this.addLexiconParam.type = 0
    }
    this.getArticles(true)
  }
  mounted() {
    this.setHeight()
    this.getArticles()
  }
  changeStyle() {
    // if(!this.keyWord) {
    //   let dom = document.getElementsByClassName('lexicon-list-select')[0]
    //   dom.style.display = 'none'
    // }
  }
  outTableside() {
    this.tableData.forEach((item, index) => {
      this.$set(item, 'canEdit', false)
      this.$set(item, 'showListKeyboard', false)
      if(index === this.currentLexiconIdx) {
        this.$set(item, 'alternative', this.currentInitLexicon.lexicon)
        this.$set(item, 'original', this.currentInitLexicon.originalData)
      }  
    })
    // 清空上一次点击的值
    this.currentLexiconIdx = -1
    this.currentInitLexicon = {}
  }
  // 点击table区域隐藏
  hideKeyword() {
    this.isShowKeyboard = false
  }
  confirmEvt(item, index) {
    this.tableData.forEach(list => {
      this.$set(list, 'canEdit', false)
    })
    this.$set(item, 'canEdit', true)
    if(this.currentLexiconIdx !== -1) {
      this.$set(this.tableData[this.currentLexiconIdx], 'original', this.currentInitLexicon.originalData)
      this.$set(this.tableData[this.currentLexiconIdx], 'alternative', this.currentInitLexicon.lexicon)
    }
    this.currentLexiconIdx = index
    this.currentLexicon = item
    this.currentInitLexicon = {
      originalData: item.original,
      lexicon: item.alternative,
    }
  }
  saveEvt(item) {
    let result = item.original === this.currentInitLexicon.originalData && item.alternative === this.currentInitLexicon.lexicon
    if(!item.original || !item.alternative){
      this.$message.error('Please enter text first')
      return
    }
    // 未改变不调用接口
    if(result) {
      this.$set(item, 'canEdit', false)
      return
    }
    this.$set(item, 'canEdit', false)
    item.alternative = item.alternative.trim()
    item.original = item.original.trim()
    // 先检验是否合法
    apis.getAudioKey(item).then(res => {
      if(res.data.code === 200) {
        this.updateLexicon(item)
      } else {
        this.$message.error(res.data.message)
      }
    }) 
  }
  cancleEvt(item) {
    this.$set(item, 'canEdit', false)
    this.$set(item, 'alternative', this.currentInitLexicon.lexicon)
    this.$set(item, 'original', this.currentInitLexicon.originalData)
  }
  showKeyTableboard(item){
    this.$set(item, 'showListKeyboard', true)
  }
  phonmeBlur(e) {
    this.blurTableIndex = e.srcElement.selectionStart
  }
  // add 限制
  inputTxtNum(txt, type) {
    if(type === 'original' && this.addLexiconParam.type === 1) {
      this.addLexiconParam.original = this.addLexiconParam.original.replace(/\s+/g,'')
    }
    if (txt.length > 50) {
      this.addLexiconParam[type] = this.inputPron
      this.$message.error(this.$t('toolsEnglish.inputErr'))
      return
    }
    this.inputPron = this.addLexiconParam[type]
  }
  // 编辑限制
  inputTxtNumEdit(list, type) {
    if(type === 'original' && this.addLexiconParam.type === 1) {
      list.original = list.original.replace(/\s+/g,'')
    }
    if (list[type].length > 50) {
      list[type] = this.inputEditPron
      this.$message.error(this.$t('toolsEnglish.inputErr'))
      return
    }
    this.inputEditPron = list[type]
  }
  setHeight() {
    let dom = document.querySelector('.table-body')
    if(dom) {
      dom.style.minHeight = 'calc(100vh - 420px)'
    }
  }
  outside() {
    this.isShowKeyboard = false
  }
  searchIconEvt() {
    this.params.page = 0
    this.sort = 0
    this.getArticles()
  }
  selectTablePhonetic(v, list) {
    //键盘对应的input框高亮
    this.$refs.alternative[0].focus()
    let str = list.alternative
    if (str.length > 50 - 1 && v.length == 1) {
      list.alternative = this.preTablePron
      return this.$message.error(this.$t('lexicon.most50'))
    } else if (str.length > 50 - 2 && v.length == 2) {
      list.alternative = this.preTablePron
      return this.$message.error(this.$t('lexicon.most50'))
    }
    let _index = this.blurTableIndex
    let strPron = str.slice(0, _index) + v + str.slice(_index)
    this.blurTableIndex = this.blurTableIndex + v.length
    this.preTablePron = list.alternative = strPron
    this.currentLexicon = list
  }
  delSymbolPhonetic(list) {
    if (list.alternative) {
      let _index = this.blurTableIndex
      let str = list.alternative
      list.alternative = str.slice(0, _index).substr(0, _index - 1) + str.slice(_index)
      this.blurTableIndex = this.blurTableIndex - 1
    }
  }
  closeTableBoard(list) {
    this.$set(list, 'showListKeyboard', false)
  }
  changeCursorPos(e){
    this.blurIndex = e.srcElement.selectionStart
  }
  selectPhoneticSymbol(v) {
    let str = this.addLexiconParam.alternative
    this.$refs.addAlternative.focus()
    if (str.length >50 - 1 && v.length == 1) {
      this.addLexiconParam.alternative = this.prePron
      return this.$message.error(this.$t('lexicon.most50'))
    } else if (str.length > 50 - 2 && v.length == 2) {
      this.addLexiconParam.alternative = this.prePron
      return this.$message.error(this.$t('lexicon.most50'))
    }
    let _index = this.blurIndex
    let strPron = str.slice(0, _index) + v + str.slice(_index)
    this.blurIndex = this.blurIndex + v.length
    this.prePron = this.addLexiconParam.alternative = strPron
    this.addLexiconParam.alternative = this.prePron
  }
  deleteSymbol() {
    if (this.addLexiconParam.alternative) {
      let _index = this.blurIndex
      let str = this.addLexiconParam.alternative
      this.addLexiconParam.alternative = str.slice(0, _index).substr(0, _index - 1) + str.slice(_index)
      this.blurIndex = this.blurIndex - 1
    }
  }
  closeBoard() {
    this.isShowKeyboard = false
  }
  showPhonemeKeyboard() {
    if(this.addLexiconParam.type === 1) {
      this.isShowKeyboard = true
    } else {
      this.isShowKeyboard = false
    }
    this.outTableside()
  }
  loadnext() {
    debounce(() => {
      if (this.params.page + 1 >= this.total_pages) {
        return
      }
      this.params.page++
      this.getArticles()
    }, 200)()
  }
  getArticles() {
    let sort = this.sort - 1
    this.params.sort = sort === -1 ? '' : sort
    this.params.type = this.addLexiconParam.type
    this.params.keyWord = this.keyWord
    this.params = { ...this.params }
    if (this.params.page == 0) {
      this.tableData = []
    }
    this.loadingArticle = true
    apis
      .getAllLexicons(this.params)
      .then(res => {
        this.loadingArticle = false
        if (res.data.code == 200) {
          if (this.params.page == 0) {
            this.tableData = res.data.data.results
          } else {
            this.tableData = [...this.tableData, ...res.data.data.results]
          }
          this.total_pages = res.data.data.totalPages || 0
          this.total_num = res.data.data.totalElements || 0
        } else {
          if (this.params.page == 0) {
            this.tableData = []
          }
        }
      }).catch(err => {
        if (this.params.page == 0) {
          this.tableData = []
        }
        this.loadingArticle = false
        if(err.message!=='NotLogin'){
          this.$message.error(err.message || this.$t('ServerError'))
        }
      })
  }
  // 更新
  updateLexicon(item) {
    item.alternative = item.alternative.trim()
    item.original = item.original.trim()
    apis.editLexicon(item).then(res => {
      if(res.data.code === 200) {
        this.$message.success(this.$t('lexicon.editSuccess'))
        this.currentLexiconIdx = -1
        this.currentInitLexicon = null
        this.updateEditorLexIcon()
      } else {
        this.$message.error(res.data.message)
        this.keyWord = ''
        this.params.keyWord = this.keyWord
        this.params.page = 0
        this.sort = 0
        this.getArticles()
        // 恢复原来的值
        // this.$set(item, 'original', this.currentInitLexicon.originalData)
        // this.$set(item, 'alternative', this.currentInitLexicon.lexicon)
      }
    })
  }
  // 添加lexicon
  addLexiconEvt() {
    this.isShowKeyboard = false
    this.originalErrorMsg = this.lexiconErrorMsg = ''
    let status = this.validate()
    if(!status) return
    this.addLexiconParam.original = this.addLexiconParam.original.trim()
    this.addLexiconParam.alternative = this.addLexiconParam.alternative.trim()
    apis.getAudioKey(this.addLexiconParam).then(res => {
      if(res.data.code === 200) {
        this.postLexicon()
      } else {
        this.lexiconErrorMsg = res.data.message
      }
    })
  }
  postLexicon() {
    this.addLexiconParam.original = this.addLexiconParam.original.trim()
    this.addLexiconParam.alternative = this.addLexiconParam.alternative.trim()
    apis.postLexicon(this.addLexiconParam).then(res => {
      let {code, message} = res.data
      if(code === 200) {
        this.$message.success(this.$t('lexicon.addSuccess'))
        this.addLexiconParam = {
          "original": "",
          "alternative": "",
          "type": this.addLexiconParam.type
        }
        this.keyWord = ''
        this.params.keyWord = this.keyWord
        this.params.page = 0
        this.sort = 0
        this.getArticles()
        this.updateEditorLexIcon()
      } else {
        if(code === 4051) {
          this.originalErrorMsg = message
        } else {
          this.$message.error(this.$t('lexicon.addFailed'))
        }
      }
    }).catch(() => {
      this.$message.error(this.$t('ServerError'))
    })
  }
  // 通知编辑器更新词典数据
  updateEditorLexIcon(){
    localStorage.setItem('editor-lex-icon', Date.now())
  }
  tabClick(type) {
    if(this.loadingArticle) return
    this.addLexiconParam.type = type
    this.addLexiconParam.original = ''
    this.addLexiconParam.alternative = ''
    this.lexiconErrorMsg = this.originalErrorMsg = this.lexiconErrorMsg = ''
    this.sort = 0
    this.params.page = 0
    this.getArticles()
  }
  // 排序
  sortEvt() {
    // 点击排序 默认生序
    if(this.loadingArticle) return
    this.sort = (this.sort + 1) % 3
    this.params.page = 0
    this.getArticles(true)
  }
  // 校验输入框为空
  validate() {
    this.validateStatus = false
    if(!this.addLexiconParam.original) {
      this.originalErrorMsg = this.$t('lexicon.originalEmpty')
      return
    }
    if(!this.addLexiconParam.alternative) {
      this.lexiconErrorMsg = this.addLexiconParam.type === 0 ? this.$t('lexicon.aliasEmpty') : this.$t('lexicon.phonmeEmpty')
      return
    }
    this.validateStatus = true
    return this.validateStatus
  }
  playAlias() {
    this.isShowKeyboard = false
    this.lexiconErrorMsg = this.originalErrorMsg = ''
    let status = this.validate()
    if(!status) return
    this.addLexiconParam.original = this.addLexiconParam.original.trim()
    this.addLexiconParam.alternative = this.addLexiconParam.alternative.trim()
    apis.getAudioKey(this.addLexiconParam).then(res => {
      if(res.data.code === 200) {
        let url = res.data.data
        this.$refs.listenAudio.listenAudio(url)
      } else {
        this.lexiconErrorMsg = res.data.message
      }
    }).catch(() => {
      this.isShowKeyboard = false
      this.lexiconErrorMsg = this.originalErrorMsg = ''
      this.$refs.listenAudio.aliasAudioUrl = ''
    })
  }
  deleteEvt(item){
    this.currentLexicon = item
    this.$refs.delDialogue.$emit('dialog-visible', true)
  }
  cancleLexicon() {
    this.$refs.delDialogue.$emit('dialog-visible', false)
    this.currentLexicon = null
  }
  deleteList() {
    const id = this.currentLexicon.id
    apis.delLexicon(id).then(res => {
      if(res.data.code === 200) {
        this.updateEditorLexIcon()
        this.$message.success(this.$t('lexicon.delSuccess'))
      } else {
        this.$message.error(res.data.message)
      }
      this.$refs.delDialogue.$emit('dialog-visible', false)
      this.keyWord = ''
      this.params.page = 0
      this.params.keyWord = ''
      this.sort = 0
      this.getArticles()
    }).catch(() => {
      this.$refs.delDialogue.$emit('dialog-visible', false)
      this.$message.error(this.$t('ServerError'))
    })
  }
  // 点击搜索列表
  clickHighItem(item) {
    this.sort = 0
    this.params.page = 0
    this.addLexiconParam.type = item.type
    this.keyWord = item.original
    this.currentLexicon = item
    this.tableData = [item]
  }
  addHighColor(txt) {
    let highTxt = txt.replace(new RegExp(this.keyWord, "gi"),function($1){
      return $1 ?`<i style="font-style:normal;color: #3583FB">${$1}</i>`: ''
    })
    return highTxt
  }
  autocompleteBlur() {
    // this.$nextTick(()=> {
    //   setTimeout(() => {
    //     if (!this.$refs.listSelectAutocomplete.activated) {
    //       this.keyWord = ''
    //     }
    //   }, 700)
    // })
  }
  loadSuggestions() {
    if (this.callback && typeof this.callback === 'function') {
      this.querySearchAsync(this.keyWord, this.callback, false)
    }
  }
  async querySearchAsync(queryString, cb, isfirst = true) {
    if (isfirst) {
      this.callback = cb
    }
    debounce(this.search, 400)(queryString, cb, isfirst)
  }
  async search(queryString, cb, isfirst) {
    clearTimeout(this.timeout)
    let params = {}
    if (isfirst) {
      this.searchParams.page = 0
      this.searchList = []
    } else {
      this.searchParams.page++
    }
    this.searchParams.type = this.addLexiconParam.type
    this.searchParams.keyWord = this.keyWord
    Object.assign(params, this.searchParams, {
      keyWord: queryString
    })
    try {
      const response = await apis.searchPageOptions(params)
      let {
        data: {
          data: { results }
        }
      } = response
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.results
      ) {
        if (this.searchParams.page == 0) {
          this.searchList = results
        } else {
          this.searchList = this.searchList.concat(results)
        }
        this.timeout = setTimeout(() => {
          cb([...this.searchList])
        }, 100)
      } else {
        this.$message.error(this.$t('ServerError'))
        cb(this.searchList)
      }
    } catch (err) {
      console.log('serach in Lexicon', err)
      cb(this.searchList)
    }
  }
}
</script>
<style lang="less" src="./list.less"></style>


