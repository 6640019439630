<template>
  <div
    v-if="visible"
    class="tutorials-video"
    @click="hide()"
  >
    <div
      class="tutorials-content"
      @click.stop
    >
      <!-- title start -->
      <div class="video-title">
        <h3>{{ videoName }}</h3>
        <div
          class="close-button"
          @click="hide()"
        >
          <iconpark-icon
            name="iconcancle"
            class="iconpark"
          ></iconpark-icon>
        </div>
      </div>
      <!-- title end   -->
      <!-- content start -->
      <div class="video-content">
        <iframe
          width="100%"
          height="400px"
          :src="videoUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          @click.stop
        ></iframe>
      </div>
      <!-- content end   -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialsVideo',
  data() {
    return {
      visible: false,
      videoName: '',
      videoUrl: '',
      videoCover: '',
    }
  },
  methods: {
    show(options) {
      const { videoName, videoUrl, videoCover } = options
      this.visible = true
      this.videoName = videoName
      this.videoUrl = videoUrl
      this.videoCover = videoCover
    },
    hide() {
      this.visible = false
      this.videoName = ''
      this.videoUrl = ''
      this.videoCover = ''
    }
  }
}
</script>

<style lang="less" scoped>
.tutorials-video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: grid;
  place-content: center;
  background-color: fade(#040D1A, 60%);
}
.tutorials-content {
  padding: 0 40px 40px;
  width: 800px;
  border-radius: 16px;
  background-color: #FFF;
  .video-title {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    h3 {
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Poppins-SemiBold';
    }
    .close-button {
      display: grid;
      place-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: transparent;
      transform: scale(1);
      transition: all 300ms ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: fade(#2A85FF, 8%);
        transform: scale(1.2);
      }
      .iconpark {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .video-content {
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
