<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :lock-scroll="false"
    custom-class="lexicon-container"
    width="1050px"
  >
    <List @close="changeLexicon(false)" />
  </el-dialog>
</template>
<script>
import List from './list.vue'
export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  components: { List },
  mounted() {
    this.$bus.$on('lexicon', this.changeLexicon)
  },
  methods: {
    changeLexicon(type) {
      this.dialogVisible = type
    }
  }
}
</script>
<style lang="less">
.lexicon-container {
  border-radius: 16px !important;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    .lexicon-close {
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 10;
      color: #222222;
      cursor: pointer;
    }
  }
}
</style>