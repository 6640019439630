import axios from 'axios'
import store from '@/store'
import { getCookie } from './cookie'
import stat from "@/utils/stat"

class BasicError {
  timestamp = +new Date()
  url = location.href
  userAgent = navigator.userAgent
  data = {}
}

class RenderError extends BasicError {
  constructor({ name, message, type, trace, lifecycle, componentName }) {
    super()
    this.data = {
      name,
      message,
      type,
      trace,
      lifecycle,
      componentName
    }
  }
}

export class PromiseError extends BasicError {
  constructor(data) {
    super()
    this.data = data
  }
}

function saveErrorToServer(err) {
  if (err && process.env.NODE_ENV === 'production' ) {
    const user = store.getters['userInfo/user'] || {}
    const formData = new FormData()
    formData.append('browserInfo', err.userAgent)
    formData.append('targetUrl', err.url)
    formData.append('errorInfo', JSON.stringify(err.data))
    formData.append('userId', user.id)

    axios.post(`/overseas-apis/account/sys/toolkit/saveError`, formData, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'ww_token':getCookie('ww_token')
      },
      hideLoginModal: true
    })
  } else {
    console.log('errorHandler log: ', err)
  }
}

export function saveRenderError(err) {
  if (!err) return
  let errObj = new RenderError({
    name: RenderError.name,
    type: err.name,
    message: err.message,
    trace: err.stack,
    lifecycle: err.info,
    componentName: err.vm.name
  })

  saveErrorToServer(errObj)
}

export function savePromiseError(err) {
  let errObj = new PromiseError({
    name: PromiseError.name,
    type: err.name,
    method: err.method,
    message: err.message,
    trace: err.stack,
    formData: err.formData
  })
  saveErrorToServer(errObj)
}

export default function errorHandler(err, vm, info) {
  switch (err.constructor) {
    case ReferenceError:
    case SyntaxError:
    case TypeError:
    case RangeError:
      err.vm = vm
      err.info = info
      saveRenderError(err)
      break
  }
}

export function mediaErrorHandler() {
  const originAudioPlay = Audio.prototype.play
  Audio.prototype.play = function () {
    let res = originAudioPlay.apply(this, arguments)
    if (res && res.catch) {
      const url = this.src
      stat.event('audio_play_try', {
        url,
      })
      res = res.catch(e => {
        stat.event('audio_play_error', {
          url,
          error: e?.message || 'unknown'
        })
      })
    }
    return res
  }
  const originVideoPlay = HTMLVideoElement.prototype.play
  HTMLVideoElement.prototype.play = function () {
    let res = originVideoPlay.apply(this, arguments)
    if (res && res.catch) {
      const url = this.src
      stat.event('video_play_try', {
        url,
      })
      res = res.catch(e => {
        stat.event('video_play_error', {
          url,
          error: e?.message || 'unknown'
        })
      })
    }
    return res
  }
}
