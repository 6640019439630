<template>
  <div v-if="dialogVisible" class="questionnaire">
    <!-- 调查问卷弹窗 loading - start -->
    <template v-if="questionnaireLoading">
      <div class="loadtip">
        <i class="el-icon-loading"></i>
      </div>
    </template>
    <!-- 调查问卷弹窗 loading - end   -->
    <!-- 调差问卷填写选项 - start -->
    <template v-if="!questionnaireLoading && !isQuestionnaireCompleted">
      <div class="questionnaire-main">
        <div class="title">
          <h3>{{ titleTextContent.h3 }}</h3>
          <template v-if="!ua.mobile">
            <p>{{ titleTextContent.p1 }}</p>
            <p>{{ titleTextContent.p2 }}</p>
          </template>
          <p v-else>{{ titleTextContent.p1 }}{{ titleTextContent.p2 }}</p>
        </div>
        <div class="content">
          <Swiper
            class="swiper-container-que swiper-no-swiping"
            ref="swiper"
            @slide-change="onSlideChange"
            :options="socialOption"
          >
            <SwiperSlide v-for="(item, index) in socialList" :key="index">
              <div class="list">
                <h3>{{ item.content }}</h3>
                <ul>
                  <li
                    v-for="(item1, index1) of item.itemList"
                    :key="index1"
                    :class="[
                      item1.img ? 'card-list' : 'text-list',
                      item.selectId == item1.id ? 'active' : ''
                    ]"
                    @click="selectHandle(item, item1)"
                  >
                    <img
                      :src="
                        item.selectId == item1.id ? item1.imgSelected : item1.img
                      "
                      alt=""
                      v-if="item1.img"
                    />
                    <p>{{ item1.itemContent }}</p>
                  </li>
                </ul>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="content-footer" >
          <ul>
            <li
              v-for="(item, index) in socialList"
              :key="index"
              :class="[index <= swiperIndx ? 'active' : '']"
            ></li>
          </ul>
          <div class="content-main">
            <p>
              <span @click="prevHandle" v-show="swiperIndx > 0"></span>{{ swiperIndx + 1 }}/{{ socialList.length }}
            </p>
            <el-button
              :loading="questionnaireSubLoading"
              :disabled="questionnaireSubLoading"
              @click="nextHandle"
              >{{
                swiperIndx + 1 >= socialList.length
                  ? $t('button.submit')
                  : $t('button.continue')
              }}</el-button
            >
          </div>
        </div>
      </div>
    </template>
    <!-- 调差问卷填写选项 - end   -->
    <!-- 调查问卷填写完成后弹出 -->
    <template v-if="!questionnaireLoading && isQuestionnaireCompleted">
      <div class="questionnaire-completed">
        <iconpark-icon color="#fff" @click="closePopup" class="close-icon" name="dialoguedelect"></iconpark-icon>
        <img class="mobile-close" @click="closePopup" src="./img/close.png" width="28" />
        <div class="title-box">
          <h3>Welcome to DupDub</h3>
          <p>You've got a <b>3-day free trial of 10 credits</b> with no commitment!</p>
        </div>
        <div class="content-box">
          <!-- PC 端 - start -->
          <div class="recommend-box" > 
            <div class="recommend-left">
              <div class="player" @click="videoPlayer" ></div>
              <img :src="videoDetail.videoCover" alt="">
            </div>
            <div class="recommend-right">
              <h5>Any plans in mind for today?</h5>
              <div class="link-box">
                <div
                  v-for="(link, index) in linkList"
                  :key="link.key"
                  class="link-item"
                  :class="{current: index === currentLinkIndex}"
                  @mouseenter="currentLinkIndex = index"
                  @click="toOtherPage(link.key)" 
                >
                  <div class="link-step"><div><div></div></div></div>
                  <div class="link-title">
                    {{ link.title }}
                  </div>
                  <div class="link-subtitle">
                    {{ link.subtitle }}
                  </div>
                  <div class="link-btn" >Try now
                    <iconpark-icon name="tubiaojiantou" class="iconpark"></iconpark-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!-- PC 端 - end   -->
          <!-- 移动端 - start -->
          <template>
            <div class="mobile-link">
              <iframe
                width="100%"
                height="400px"
                :src="videoDetail.videoUrl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                @click.stop
              ></iframe>
              <h5>Any plans in mind for today?</h5>
              <div class="mobile-link-type">
                <span>Create content</span>
              </div>
              <div class="mobile-link-list">
                <div class="mobile-link-item" @click="toOtherPage(linkList[0].key)">
                  <img :src="linkList[0].icon">
                  <div class="link-title">{{ linkList[0].title }}</div>
                  <div class="link-subtitle">{{ linkList[0].subtitle }}</div>
                </div>
                <div class="mobile-link-item" @click="toOtherPage(linkList[1].key)">
                  <img :src="linkList[1].icon">
                  <div class="link-title">{{ linkList[1].title }}</div>
                  <div class="link-subtitle">{{ linkList[1].subtitle }}</div>
                </div>
              </div>
              <div class="mobile-link-type">
                <span>Generate audios</span>
              </div>
              <div class="mobile-link-list">
                <div class="mobile-link-item" @click="toOtherPage(linkList[2].key)">
                  <img :src="linkList[2].icon">
                  <div class="link-title">{{ linkList[2].title }}</div>
                  <div class="link-subtitle">{{ linkList[2].subtitle }}</div>
                </div>
                <div class="mobile-link-item" @click="toOtherPage(linkList[3].key)">
                  <img :src="linkList[3].icon">
                  <div class="link-title">{{ linkList[3].title }}</div>
                  <div class="link-subtitle">{{ linkList[3].subtitle }}</div>
                </div>
              </div>
              <div class="mobile-link-type">
                <span>Make final videos</span>
              </div>
              <div class="mobile-link-list">
                <div class="mobile-link-item" @click="toOtherPage(linkList[4].key)">
                  <img :src="linkList[4].icon">
                  <div class="link-title">{{ linkList[4].title }}</div>
                  <div class="link-subtitle">{{ linkList[4].subtitle }}</div>
                </div>
                <div class="mobile-link-item" @click="toOtherPage(linkList[5].key)">
                  <img :src="linkList[5].icon">
                  <div class="link-title">{{ linkList[5].title }}</div>
                  <div class="link-subtitle">{{ linkList[5].subtitle }}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 移动端 - end   -->
        </div>
      </div>
    </template>
    <!-- video popup - start -->
    <VideoPlayer ref="videoRef" />
    <!-- video popup - end   -->
  </div>
</template>

<script>
import apis from '@/utils/http-request.js'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { toVideoAudioEditor } from '@/utils/utils'
import stat from "@/utils/stat"
import VideoPlayer from '@/component/tutorials-video/index.vue'

export default {
  name: 'questionnaireSurvey',
  data() {
    return {
      dialogVisible: false,
      // 加载选项loading
      questionnaireLoading: true,
      // 标题信息
      titleTextContent: this.$t('questionnaireV2.title'),
      // 是否符合弹出问卷条件
      hasTriggerQuestionFlag: false,
      socialList: [],
      swiperIndx: 0,
      // 数据选项
      socialOption: {
        el: '.swiper-container-que',
        type: 'bullets',
        slidesPerView: 1,
        preventClicksPropagation: true,
        preventClicks: true,
        loop: false,
        noSwiping: true,
      },
      // 提交loading
      questionnaireSubLoading: false,
      // 调查问卷是否填写完成
      isQuestionnaireCompleted: false,
      // 链接跳转列表
      linkList: [
        {
          key: 'AI_Transcription',
          title: 'AI transcription',
          subtitle: 'Obtain video scripts',
          icon: require('@/assets/img/questionnaire/new_icon_1.png'),
          link: ''
        },
        {
          key: 'AI_Writing',
          title: 'AI writing',
          subtitle: 'Craft words',
          icon: require('@/assets/img/questionnaire/new_icon_2.png'),
          link: ''
        },
        {
          key: 'AI_Voiceover',
          title: 'AI voiceover',
          subtitle: 'Enhance storytelling',
          icon: require('@/assets/img/questionnaire/new_icon_3.png'),
          link: ''
        },
        {
          key: 'Instanto_voice_cloning',
          title: 'Instant voice cloning',
          subtitle: 'Clone in seconds',
          icon: require('@/assets/img/questionnaire/new_icon_4.png'),
          link: ''
        },
        {
          key: 'AI_Avatar',
          title: 'AI avatar',
          subtitle: 'Bring story to life',
          icon: require('@/assets/img/questionnaire/new_icon_5.png'),
          link: ''
        }, 
        {
          key: 'Video_translation',
          title: 'Video translation',
          subtitle: 'Spread worldwide',
          icon: require('@/assets/img/questionnaire/new_icon_2.png'),
          link: ''
        },
        // {
        //   key: 'Video_Editing',
        //   title: 'Video editing',
        //   subtitle: 'Fine-tune everything',
        //   icon: 'https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/questionnaire_dialog/questionnaire_dialog/icon_video_editing.png',
        //   link: ''
        // }
      ],
      currentLinkIndex: 0, // 当前选中的
      videoDetail: {
        videoName: "Introducing DupDub: The Best App for Content Creation",
        videoCover:
         require('./img/checklist-banner.png'),
        videoUrl:
          "https://www.youtube.com/embed/yKfVAUIvh4o?si=razKiPfn5ukZE6JR"
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    VideoPlayer
  },
  directives: {
    swiper: directive
  },
  props: {
    questionnaireFlag: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('request', ['isGetHasCompleteQuestionState']),
    ...mapGetters(['isShowQuestionnaire']),
    ...mapGetters('userInfo', ['isLogin'])
  },
  watch: {
    // 显示问卷
    isShowQuestionnaire: {
      handler(val) {
        this.dialogVisible = val
        if (this.dialogVisible) {
          this.getQueryConfig()
        }
      },
      immediate: true
    },
    swiperIndx: {
      handler(val) {
        if (this.socialList.length != 0 && val >= this.socialList.length - 1) {
          this.titleTextContent = this.$t('questionnaireV2.titleLast')
        } else {
          this.titleTextContent = this.$t('questionnaireV2.title')
        }
      },
      immediate: true
    },
    $route: {
      deep: true,
      immediate: true,
      handler() {
        // 登陆状态canva插件会进入到登陆页面
        if(location.pathname === '/login') return
        if (this.isLogin) {
          this.hasCompleteQuestion()
        }
      }
    }
  },

  methods: {
    ...mapMutations('request', ['setGetHasCompleteQuestionState']),
    closePopup() {
      stat.event('popup_questionnaire_close_click')
      this.dialogVisible = false
      this.postQueryConfig()
    },
    videoPlayer () {
      this.$refs.videoRef.show({
        videoName: this.videoDetail.videoName,
        videoUrl: this.videoDetail.videoUrl,
        videoCover: this.videoDetail.videoCover
      })
    },
    // 前一页
    prevHandle() {
      if (this.swiperIndx <= 0) return
      this.$refs.swiper.$swiper.slideTo(this.swiperIndx - 1, 500, true)
    },
    // 下一页
    nextHandle() {
      // 没有选择
      if (!this.socialList[this.swiperIndx]?.selectId) {
        this.$message.error(this.$t('questionnaireV2.errDesc'))
        return
      }
      // 提交数据
      if (this.swiperIndx + 1 >= this.socialList.length) {
        this.isQuestionnaireCompleted = true
        return
      }
      this.$refs.swiper.$swiper.slideTo(this.swiperIndx + 1, 500, true)
    },
    onSlideChange() {
      const $swiper = this.$refs.swiper.$swiper
      const { activeIndex } = $swiper
      this.swiperIndx = activeIndex
    },
    // 选中信息
    selectHandle(item, item1) {
      this.$set(item, 'selectId', item1.id)
    },

    // 是否展示问卷
    async hasCompleteQuestion() {
      if (this.isGetHasCompleteQuestionState) return
      this.setGetHasCompleteQuestionState(true)

      let params = {
        scene: 4
      }
      const response = await apis.hasCompleteQuestion(params)
      let {
        data: { data, code }
      } = response
      if (code == 200) {
        // data == false弹出问卷
        if(!data){
          this.$store.commit('setShowQuestionnaire', true)
        }else{
          // 通知新会员弹窗
          this.$bus.$emit('freePlanPopUp',true)
        }
      }
    },
    // 获取问卷信息
    async getQueryConfig() {
      this.questionnaireLoading = true
      let params = {
        scene: 4
      }
      const response = await apis.queryConfig(params)
      let {
        data: { data, code, message }
      } = response
      if (code == 200) {
        this.questionnaireLoading = false
        this.socialList = data
      } else {
        this.$message.error(
          message || `${this.$t('errTxt.serverError')}，code1`
        )
      }
    },
    // 保存问卷信息
    async postQueryConfig(callback) {
      this.questionnaireSubLoading = true
      let list = []
      for (let i = 0; i < this.socialList.length; i++) {
        let itemObj = this.socialList[i].itemList.find(item => {
          return item.id == this.socialList[i].selectId
        })
        list.push({
          configId: this.socialList[i].selectId,
          scene: this.socialList[i].scene,
          type: this.socialList[i].type,
          items: [
            {
              id: itemObj.id
            }
          ]
        })
      }
      const response = await apis.postQueryConfig({
        questionConfigList: list
      })
      let {
        data: { code, message }
      } = response
      if (code == 200) {
        this.questionnaireSubLoading = false
        this.$message.success(this.$t('questionnaireV2.successDesc'))
        this.$store.commit('setShowQuestionnaire', false)
        // callback
        callback && callback()
      } else {
        this.$message.error(
          message || `${this.$t('errTxt.serverError')}，code2`
        )
      }
    },
    // 重置数据
    resetData() {
      Object.assign(this.$data, this.$options.data())
    },
    toOtherPage(link) {
      this.postQueryConfig(() => {
        switch(link) {
          case 'AI_Transcription':
            const routeUrl = this.$router.resolve({
              path: '/transcription'
            })
            window.open(routeUrl.href, '_blank')
            stat.event('popup_questionnaire_aitranscription_click')
            break;
          case 'Video_translation':
            this.$router.push({
              path: '/tools/home?show=video_translation'
            })
            stat.event('popup_questionnaire_videotranslation_click')
            break;
          case 'Instanto_voice_cloning':
            this.$router.push({
              path: '/tools/home?cloning=true'
            })
            stat.event('popup_questionnaire_voicecloning_click')
            break;
          case 'AI_Writing':
            this.$router.push({
              path: '/overview/article-voice'
            })
            stat.event('popup_questionnaire_aiwriting_click')
            break;
          case 'AI_Voiceover':
            const routeUrl1 = this.$router.resolve({
              path: '/overview/article-voice'
            })
            window.open(routeUrl1.href, '_blank')
            stat.event('popup_questionnaire_aivoiceover_click')
            break;
          case 'AI_Avatar':
            const routeUrl2 = this.$router.resolve({
              name: 'talkingPhotoEditor',
            })
            window.open(routeUrl2.href, '_blank')
            stat.event('popup_questionnaire_aiavatar_click')
            break;
          case 'Video_Editing':
            toVideoAudioEditor()
            stat.event('popup_questionnaire_videoediting_click')
            break;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.questionnaire {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottoM: 0;
  display: grid;
  place-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000001;
  .questionnaire-main {
    padding: 54px 40px 0;
    width: 808px;
    height: 614px;
    background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/wenjuan-1698648009471/bg-pc.png) no-repeat;
    background-size: contain;
    box-shadow: none;
    box-sizing: border-box;
    .title {
      h3 {
        font-family: 'Poppins-Bold';
        font-size: 20px;
        font-weight: bold;
        color: #001126;
        line-height: 24px;
      }
      p {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #596472;
        line-height: 18px;
      }
    }

    .content {
      margin-top: 20px;
      width: 100%;
      height: 366px;
      overflow: hidden;
      border-radius: 20px;

      .list {
        height: 366px;
        h3 {
          margin: 20px 0 30px;
          font-weight: bold;
          color: #001126;
          line-height: 26px;
          font-size: 18px;
          text-align: center;
          font-family: 'Poppins-Bold';
        }
        ul {
          margin: 0 22px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            &.card-list {
              width: 155px;
              height: 112px;
              margin-right: 8px;
              margin-left: 8px;
              margin-bottom: 16px;
              border-radius: 8px;
              cursor: pointer;
              padding: 0 10px;
              box-sizing: border-box;
              background: #f8f9ff;
              position: relative;
              img {
                width: 30px;
                height: 30px;
                margin: 26px auto 8px;
                display: block;
              }
              p {
                text-align: center;
                color: #001126;
                font-size: 14px;
                line-height: 22px;
                font-weight: bold;
              }
              &::after{
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border-radius: 10px;
                background: #ffffff;
                z-index: -1;
              }
              &.active,&:hover {
                background: #e9e8ff;
                &::after{
                  content: '';
                  display: block;
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  bottom: -2px;
                  left: -2px;
                  border-radius: 10px;
                  background: linear-gradient(136deg, rgba(65, 189, 255, 1), rgba(49, 86, 255, 1),rgba(156, 88, 255, 1),rgba(255, 104, 236, 1));
                  z-index: -1;
                }
              }
              &.active,&.active:hover{
                p {
                  color: #3c56ff;
                }
              }
              &:hover {
                p{
                  color: #001126;
                }
              }
            }

            &.text-list {
              width: 212px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border-radius: 8px;
              margin:0 8px 16px;
              cursor: pointer;
              font-family: 'Poppins-Medium';
              font-weight: bold;
              font-size: 14px;
              background: #f8f9ff;
              position: relative;
              &::after{
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border-radius: 10px;
                background: #ffffff;
                z-index: -1;
              }
              &.active,&:hover {
                background: #e9e8ff;
                &::after{
                  content: '';
                  display: block;
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  bottom: -2px;
                  left: -2px;
                  border-radius: 10px;
                  background: linear-gradient(136deg, rgba(65, 189, 255, 1), rgba(49, 86, 255, 1),rgba(156, 88, 255, 1),rgba(255, 104, 236, 1));
                  z-index: -1;
                }
              }
              &.active,&.active:hover{
                p {
                  color: #3c56ff;
                }
              }
              &:hover {
                p{
                  color: #001126;
                }
              }
            }
          }
        }
      }
     
    }
    .content-footer {
        margin: 14px 0 0;
        ul {
          display: flex;
          margin-bottom: 14px;
          li {
            margin-right: 4px;
            height: 5px;
            width: 33%;
            background: #fff;
            &.active {
              background: linear-gradient(90deg, #3c56ff 0%, #9658ff 100%);
            }
            &:first-child {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }
            &:last-child {
              margin: 0;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }
          }
        }

        .content-main {
          display: flex;
          justify-content: space-between;
          p {
            font-weight: 400;
            color: #001126;
            line-height: 24px;
            font-size: 16px;
            opacity: 0.5;
            display: flex;
            align-items: center;
            span {
              width: 24px;
              height: 24px;
              background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/wenjuan-1698648009471/left-2.png) no-repeat;
              background-size: cover;
              margin-right: 10px;
              cursor: pointer;
              &:hover {
                background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/wenjuan-1698648009471/left-hover-2.png);
                background-size: cover;
              }
            }
          }

          button {
            width: 140px;
            height: 44px;
            background: #040d1a;
            border-radius: 8px;
            color: #ffffff;
            font-size: 14px;
            border: none;
            padding: 0;
            cursor: pointer;

            &:hover {
              background: #333b48;
            }
          }
        }
      }
  }
  .questionnaire-completed {
    position: relative;
    width: 983px;
    color: #040D1A;
    border-radius: 16px;
    background: linear-gradient( 132deg, #D0DCFF 0%, #F3D5FF 100%);
    .close-icon {
      position: absolute;
      right: -50px;
      top: -28px;
      width: 38px;
      height: 38px;
      font-size: 22px;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      cursor: pointer;
    }
    .mobile-close{
      position: absolute;
      top:10px;
      right: 10px;
      cursor: pointer;
      z-index: 10;
      display: none;
    }
    .title-box {
      position: relative;
      z-index: 1;
      padding: 30px 36px 24px;
      &::before {
        content: '';
        position: absolute;
        top: -33px;
        right: 0;
        z-index: 1;
        width: 257px;
        height: 155px;
        background: url('./img/flower.png') no-repeat;
        background-size: cover;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
      h3 {
        position: relative;
        z-index: 2;
        line-height: 24px;
        font-size: 24px;
        font-weight: 500;
        font-family: 'Poppins-SemiBold';
        color: #001126;
      }
      p {
        position: relative;
        z-index: 2;
        margin-top: 8px;
        line-height: 24px;
        font-size: 16px;
        b {
          font-weight: 500;
          font-family: 'Poppins-SemiBold';
          color: #6F3AFF;
        }
      }
    }
    .content-box {
      position: relative;
      z-index: 2;
      padding: 40px;
      background: url(./img/bj_1.png) #fff repeat-x top center; 
      border-radius:16px;
      .recommend-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .recommend-left{
          width: 624px;
          height: 352px;
          border-radius: 16px;
          flex: 1;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .player {
            position: absolute;
            width: 64px;
            height: 64px;
            background: url("@/assets/img/common/player.png")
              center no-repeat;
            background-size: cover;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            z-index: 10;
            cursor: pointer;
            transition: all 300ms ease-in-out;
            &:hover {
              transform: translate(-50%, -50%) scale(1.2);
            }
          }
          video {
            width: 100%;
            height: 100%;
          }
          img{
            width: 100%;
          }
        }
        .recommend-right{
          width: 240px;
          margin-left: 40px;
          h5 {
            line-height: 24px;
            font-size: 16px;
            font-weight: 500;
            font-family: 'Poppins-Medium';
          }
          .link-box {
            padding-top: 16px;
            .link-item {
              transition: all 300ms ease-in-out;
              border-left: solid 1px #E2D9FF;
              padding-left: 25px;
              padding-bottom: 15px;
              cursor: pointer;
              position: relative;
              &:last-child{
                border-left:none;
                padding-bottom:0;
              }
              &.current,
              &:hover{
                .link-step{
                  padding:8px;
                  left: -16px;
                  top: -3px;
                  >div{
                    width: 16px;
                    height: 16px;
                    border:solid 1px #893BFF;
                    background: #fff;
                    padding: 3px;
                    box-sizing: border-box;
                    >div{
                      width: 8px;
                      height: 8px;
                      background: #893BFF;
                      border-radius: 50%;
                    }
                  }
                }
                .link-title{
                  color: #893BFF;
                }
                .link-subtitle {
                  display: block;
                }
                .link-btn{
                  display: block;
                  &:hover{
                    background: #040D1A;
                    .iconpark {
                      animation: creatorsArrowTranslateFlash 1000ms linear infinite;
                    }
                    @keyframes creatorsArrowTranslateFlash {
                      0% {
                        transform: translateX(0);
                      }
                      50% {
                        transform: translateX(10px);
                      }
                      100% {
                        transform: translateX(0);
                      }
                    }
                  }
                }
              }
              .link-step{
                padding: 9px;
                background: #fff;
                position: absolute;
                left: -12px;
                top: 2px;
                border-radius: 50%;
                >div{
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background: #E2D9FF;
                }
              }
              .link-title {
                margin-bottom: 4px;
                line-height: 24px;
                font-size: 16px;
                font-weight: 500;
                font-family: 'Poppins-SemiBold';
              }
              .link-subtitle {
                line-height: 20px;
                font-size: 12px;
                display: none;
              }
              .link-btn{
                width: 102px;
                height: 32px;
                line-height: 32px;
                background: #040D1A;
                border-radius: 16px;
                color: #fff;
                display: none;
                text-align: center;
                margin-top: 12px;
                .iconpark{
                  position: relative;
                  top: 2px;
                }
              }
            }
          }
        }
      }
      .mobile-link {
        display: none;
        iframe{
          height: 193px;
          border-radius: 8px;
        }
        h5 {
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          font-family: 'Poppins-Medium';
          margin-top: 16px;
        }
        .mobile-link-type {
          display: flex;
          align-items: center;
          margin-top: 16px;
          font-size: 16px;
          font-family: 'Poppins-SemiBold';
          &::before {
            content: '';
            margin-right: 14px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #6F3AFF;
            box-shadow: 0px 4px 13px -1px rgba(143, 108, 255, 0.5);
          }
          span{
            font-family: 'Poppins-SemiBold';
          }
        }
        .mobile-link-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 12px;
          margin-top: 8px;
        }
        .mobile-link-item {
          padding: 12px 10px;
          height: 66px;
          border-radius: 10px;
          background-color: #F7F5FF;
          box-sizing: border-box;
          position: relative;
          img{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 29px;
          }
          .link-title {
            line-height: 22px;
            font-family: 'Poppins-SemiBold';
            font-size: 14px;
            white-space: nowrap;
          }
          .link-subtitle {
            line-height: 20px;
            color: rgba(4, 13, 26, 0.45);
          }
        }
      }
    }
  }
  .loadtip {
    text-align: center;
    margin-top: 160px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
    margin: 0;
  }
}
@import '@/assets/css/responsive.less';
@rules: {
  .questionnaire {
    align-content: end;
  }
  .questionnaire-main {
    width: 100vw;
    padding: 0 18px 0;
    background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/bg-h5-1698995231766.png) no-repeat;
    background-size: 100% 100%;
    transform: none;
    border-radius: 16px 16px 0 0;
    .title{
      padding-top: 25px;
      h3{
        font-size: 20px;
        line-height: 24px;
      }

      p{
        font-size: 12px;
        line-height: 18px;
        color: #596472;
        height: 90px;
      }
    }
    .content{
      height: calc(100% - 150px - 76px);
      padding-top: 16px;
      margin-top: 0;
      box-sizing: border-box;
      background: #f0f2fc;
      border-radius: 18px;
      border: 2px solid #FFFFFF;
      overflow-y: scroll;
      .list{
        height: 100%;
        
        h3{
          margin: 0 30px 12px;
          font-size: 16px;
        }

        ul{
          margin: 0 10px;
          width: calc(100% - 20px);
          li{
            &.card-list{
              width: 45%;
              height: 90px;
              margin: 0 7px 14px;
              background: #f8f9ff;
              position: relative;
              overflow: inherit;
              padding: 0 2px;
              &::after{
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border-radius: 10px;
                background: #fff;
                z-index: -1;
              }
              &.active {
                background: #e9e8ff;
                &::after{
                  content: '';
                  display: block;
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  bottom: -2px;
                  left: -2px;
                  border-radius: 10px;
                  background: linear-gradient(136deg, rgba(65, 189, 255, 1), rgba(49, 86, 255, 1),rgba(156, 88, 255, 1),rgba(255, 104, 236, 1));
                  z-index: -1;
                }
                p{
                  color: #3c56ff;
                }
              }
              img{
                width: 24px;
                height: 24px;
                margin: 16px auto 8px;
              }
              p{
                font-size: 12px;
                line-height: 18px
              }
            }

            &.text-list{
              width: auto;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              font-size: 14px;
              padding: 0 12px;
              box-sizing: border-box;
              margin: 0 9px 16px;
              background: #f8f9ff;
              position: relative;
              &::after{
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border-radius: 10px;
                background: #ffffff;
                z-index: -1;
              }
              &.active {
                background: #e9e8ff;
                p{
                  color: #3c56ff;
                }
                &::after{
                  content: '';
                  display: block;
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  bottom: -2px;
                  left: -2px;
                  border-radius: 10px;
                  background: linear-gradient(136deg, rgba(65, 189, 255, 1), rgba(49, 86, 255, 1),rgba(156, 88, 255, 1),rgba(255, 104, 236, 1));
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }

    .content-footer{
      margin: 0;
      padding-top: 18px;
      height: 76px;
      ul{
        display: none
      }

      .content-main{
        height: 44px;
        position: relative;
        button{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .questionnaire-completed {
    width: 100vw;
    border-radius: 16px 16px 0 0;
    max-height: 100%;
    overflow-y: auto;
    background: linear-gradient( 135deg, #E0FBFF 0%, #E4ECFF 8%, #E5EBFF 72%, #F6E4FF 100%);
    .close-icon {
      display: none;
    }
    .mobile-close{
      display: block;
    }
    .title-box {
      padding: 24px 16px;
      &::before {
        display: none;
      }
      h3 {
        font-size: 20px;
      }
      p {
        line-height: 18px;
        font-size: 12px;
      }
    }
    .content-box {
      margin-top: -12px;
      padding: 24px 16px;
      .recommend-box{
        display: none;
      }
      .mobile-link{
        display: block;
      }
    }
  }
}
.screen-md(@rules);
</style>
