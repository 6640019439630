import { getClientId } from './utils'

const gtag = window.gtag
const ttq = window.ttq
// const fingerprint = ''
// import FingerprintJS from '@fingerprintjs/fingerprintjs'

const stat = {
    init() {
        stat.setUserID()
    },
    event(eventName, eventParams = null) {
        const wwid = JSON.parse(localStorage.getItem('userInfo'))?.wwid || "no_login"
        const client_id = getClientId();
        // console.log('fingerprintjs', fingerprintjs)

        // if(!uuid) {
        //     // Initialize an agent at application startup.
        //     const fpPromise = FingerprintJS.load()

        //     ;(async () => {
        //     // Get the visitor identifier when you need it.
        //     const fp = await fpPromise
        //     const result = await fp.get()
        //     console.log('visitorId', result.visitorId)
        //     })()
        // }

        const mergedParams = {
            ...eventParams,
            flag: client_id,
            wenwen: wwid
        }
        console.log('新埋点:', eventName, mergedParams)
        gtag('event', eventName, mergedParams)
    },
    setUserID() {
        try {
            var wwid = JSON.parse(localStorage.getItem('userInfo')).wwid
            if (wwid) {
                gtag('set', 'user_id', wwid)
            }
        } catch (e) {
            // ignore
        }
    },
    tiktokEvent(eventName, eventParams = null) {
        console.log('tiktok埋点:', eventName, eventParams)
        ttq.track(eventName, eventParams)
    }
}

export default stat