import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapActions } from "./store/speaker-audio-store"
import http from '@/utils/http-request'
import axios from '@/utils/axios'
// import ToolTip from '@/component/tooltip/tooltip'
import previewOutOfWords from './components/preview-out-of-words'
import articleSpeakerDetail from './speaker-detail.vue'
import ThreeSecondCloneDialog from '@/component/three-second-clone/index.vue'
import stat from '@/utils/stat'
import { getHeaderImage } from '@/utils/img'
import { threeSecondClone } from '../../api'
import { MessageBox } from 'element-ui'
import listenRecognize from '../listen-recognize/listen-recognize.vue'

import i18nObj from "./i18n/index"
import to from 'await-to-js';

const speakerDetailCache = {}

function _get(obj, path, defaultValue){
  const keys = path.split('.')
  for(let key of keys){
    if(!(key in obj)) return defaultValue
    obj = obj[key]
  }
  return obj 
}
@Component({
  name: 'mini-audio',
  provide() {
    return {
      getI18n: this.getI18n,
      preventCloseOnClickModal: this.preventCloseOnClickModal,
      speakerDetailCache,
      addSpeakerDetailCache: this.addSpeakerDetailCache
    }
  },
  methods: {
    ...mapActions([
      'getAllSpeakers',
      'refreshDupDubLabSpeakers'
    ]),
    getI18n(path) {
      return _get(i18nObj[this.locale || 'en'], path, path)
    }
  },
  props: {
    // id: {
    //   type: [Number, String],
    //   default: '' // 文章id
    // },
    // hideUltraSpeaker: {
    //   type: Boolean,
    //   default: false // 是否隐藏ultra发音人(即11labs声音)
    // },
    vipInfo: {
      type: Object,
      default: () => ({})
    },
    userRights: {
      type: Object,
      default: () => ({})
    },
    getUserRights: {
      type: Function,
      default: null
    },
    changeVipinfo: {
      type: Function,
      default: null
    },
    onChangeSpeakerInfo: {
      type: Function,
      default: () => true
    },
    confirmBtnText: {
      type: String,
      default: ''
    },
    onPauseAudion: {
      type: Function,
      default: null
    },
    onPlayAudion: {
      type: Function,
      default: null
    },
    locale: {
      type: String,
      default: 'en'
    }
  },
  components: {
    // ToolTip,
    previewOutOfWords,
    articleSpeakerDetail,
    ThreeSecondCloneDialog,
    listenRecognize
  },
  filters: {
    splitName(name) {
      name = name || ''
      if (name.indexOf('|') > -1) return name.split('|')[1]
      return name
    },
  },
  computed: {
    ...mapState([
      'userSpeakerTypeMapList',
      'searchCriteriaList',
      'nameToEngNameMap',
      'playerInit',
      'searchTagList',
      'allSpeakersMap',
      'allSpeakerIdMap',
      'userHistory',
      'allSpeakersLineMap',
    ]),

    articleInfoSpeaker() {
      if (this.articleInfo.speaker) {
        return this.articleInfo.speaker.split('@')[0]
      }
      return ''
    },
    slideConfig() {
      return {
        min: 0,
        max: 2,
        step: 0.05,
        marks: { // 修改为对象映射
          0: '0',
          0.5: '0.5',
          1: '1.0',
          1.5: '1.5',
          2: '2.0'
        },
        ...this.speedConfig
      }
    },
    threeCloneSpeakerList() {
      return this.searchSpeakersList.filter(v => v.speakerType === 'clone')
    },
  },
  watch: {
    slideConfig: {
      immediate: true,
      deep: true,
      handler() {
        this.speedMarks = Object.entries(this.slideConfig.marks).reduce((marks, [key, value]) => {
          marks[key] = {
            style: {
              color: '#666'
            },
            label: this.$createElement('span', value)
          }
          return marks
        }, {})
      }
    },
    playerInit: {
      handler(val) {
        if (val) {
          const info = this.searchTagList[
            this.selectedSearchTagIndex
          ]
          this.subSearchTagList = info?.mediaSpeakerListVOList || []
          this.selectIntonation(0)
          this.querySpeakerListCache()
        }
      },
      immediate: true
    }
  }
})
export default class MiniAudio extends Vue {
  data() {
    return {
      matchingVoices:false, //是否是听声识人
      listenState:false,
      distanceToRight: 0,
      listenElementTop: 0,
      currentSpeakerListUsed: [],
      tabList: ['All', 'VoiceLab', 'Favorite', 'Recent', 'DupDubLab'],
      videoTranslationSpeakerList: [],
      source: 'ttsEditor', // 打开来源 ttsEditor|videoEditor|talkingPhoto|videoTranslation
      articleId: '', // 文章id
      hideUltraSpeaker: false, // 是否隐藏ultra发音人
      hideCloneBtn: false, // 是否隐藏克隆发音人按钮
      avatorIndex: null,//当前高亮头像下标
      spkPage: 1,
      allpages: 1,
      keyword: '', // 搜索
      // searchTagList: [], // 第一部分查询条件 放到state里边
      subSearchTagList: [], // 第二部分的查询条件
      searchSpeakersList: [], // 左侧筛选之后的发音人列表
      selectedSearchTagIndex: 0, // 一级查询条件index
      selectedSubSearchTagIndex: 0, // 二级查询条件index
      searchSpeakerParams: {
        pageNum: 1
      },
      speakerTypeIndex: 0,
      searchSpeakersListByType: [], // 右侧通过常用 已购等筛选
      sliderTime: 0,
      currentTime: 0,
      speedMarks: [],
      intonationMarks: renderIntonationMarks.apply(this),
      currentSpeaker: '',
      other: {},
      intonation: 0, // 语调
      // 当前情绪索引
      currentMoodSpeaker: null,
      language: '',
      accent: '',
      gender: '',
      age: '',
      style: '',
      vipAuth: '',
      lastPlayTime: 0,
      lastPlayIndex: 0,
      guidanceUrl: '',
      //埋点用到的数据
      firstFilter: '', // 热榜 svip 付费
      thirdFilter: '', // 影视解说/情感配音等
      tagFilter: '', // 浮层右下角筛选 常用/最近/已购等
      searchSubMainArr: [
        {
          type: 'searchSubWrapperLanguage',
          typeChild: 'searchSubLanguage',
          searchSubMore: false, //是否有展开按钮
          searchSubOpen: false // 是否是展开状态
        },
        {
          type: 'searchSubWrapperStyle',
          typeChild: 'searchSubStyle',
          searchSubMore: false, //是否有展开按钮
          searchSubOpen: false // 是否是展开状态
        },
        {
          type: 'searchSubWrapperField',
          typeChild: 'searchSubField',
          searchSubMore: false, //是否有展开按钮
          searchSubOpen: false // 是否是展开状态
        },
        {
          type: 'searchSubWrapperVipAuth',
          typeChild: 'searchSubVipAuth',
          searchSubMore: false, //是否有展开按钮
          searchSubOpen: false // 是否是展开状态
        },
        {
          type: 'searchSubWrapperAccent',
          typeChild: 'searchSubAccent',
          searchSubMore: false, //是否有展开按钮
          searchSubOpen: false // 是否是展开状态
        }
      ],
      showSpeakerAudio: false, // 是否显示发音人面板
      activeTabType: 'All', // 当前tab分类
      categoryList: [
        {
          name: this.getI18n('speakerSearchType.language') + '&' + this.getI18n('speakerSearchType.accent'),
          attribute: 'specificLanguage',
          value: 'language'
        },
        {
          name: this.getI18n('speakerSearchType.gender'),
          attribute: 'gender',
          value: 'gender'
        },
        {
          name: this.getI18n('speakerSearchType.age'),
          attribute: 'age',
          value: 'age'
        },
        {
          name: this.getI18n('speakerSearchType.scenario'),
          value: 'scenario'
        },
        {
          name: this.getI18n('speakerSearchType.style'),
          attribute: 'style',
          value: 'style'
        },
        {
          name: this.getI18n('speakerSearchType.quality'),
          attribute: 'vipAuth',
          value: 'vipAuth'
        }
      ],
      currentTabIndex: -1,
      categorySelectMap: {
        vipAuth: '',
        language: '',
        style: '',
        gender: '',
        age: ''
      },
      categoryItemSelectMap: {
        vipAuth: {},
        language: {},
        accent: {},
        scenario: {},
        style: {},
        gender: {},
        age: {}
      },
      qualityMap: {
        '2': 'Ultra',
        '1': 'Premium',
        '0': 'Standard'
      },
      categorySpeakerList: {
        'All': []
      },
      currentSelectSpeaker: {},
      isUnfold: true, // 是否收起
      emptyDescMap: {
        'All': this.getI18n('miniAudio.noContent'),
        'Favorite': this.getI18n('miniAudio.favoriteNoData'),
        'Recent': this.getI18n('miniAudio.recentNoData'),
        'DupDubLab': this.getI18n('miniAudio.myVoicesNoData'),
      },
      accentLanguageList: [], // 方言
      accentStyle: {
        top: 0
      },
      isFocus: false,
      isConfirm: false, // 点击确认取消都会调close方法
      closeOnClickModal: true, // 点击modal是否关闭弹窗，在弹窗里面拖拽到modal误引发的点击事件不关闭
      audioStyleOrLanguageDemoUrl: '' ,// style or language 头像播放的地址
    }
  }

  preventCloseOnClickModal() {
    this.closeOnClickModal = false
  }
  restoreCloseOnClickModal() {
    this.closeOnClickModal = true
  }

  beforeDestroy() {
    document.removeEventListener('click', this.restoreCloseOnClickModal)
  }
  async created() {
    document.addEventListener('click', this.restoreCloseOnClickModal)
    await this.getAllSpeakers()
    // 从本地缓存中获取播放器模式和位置信息
    this.$nextTick(() => {

      // 生命周期中初始化语速
      if (this.articleInfo && this.articleInfo.speed) {
        if (this.speedValue != Number(this.articleInfo.speed)) {
          this.selectSpeed(Number(this.articleInfo.speed))
        }
      }
      if (this.articleInfo && this.articleInfo.pitch) {
        if (this.intonation != Number(this.articleInfo.pitch)) {
          this.selectIntonation(Number(this.articleInfo.pitch).toFixed(1))
        }
      }
    })
  }
  listenComplete(data){
    this.$refs.minAudioHeaderRef.style.setProperty('--tab-translateX', `translateX(${0 * 100}%)`)
    this.activeTabType='All'
    this.matchingVoices=true
    this.searchSpeakersList=data.results
  }
  //清空声音识别数据
  matchingVoicesBtn(){
    this.matchingVoices=false
    const index = this.tabList.findIndex(v => v == 'All')
    this.switchTo(this.tabList[index], index)
  }
  listenRecognizeState(state){
    this.listenState=state
  }
  searchVoices(){
     // 听声识人获取位置
     let clientWidth = document.body.clientWidth
     this.listenElementTop =document.querySelector('.listen').getBoundingClientRect().top + window.scrollY
     let elementLeft = document.querySelector('.listen').getBoundingClientRect().left + window.scrollX;
     let elementWidth =document.querySelector('.listen').offsetWidth
     this.distanceToRight = clientWidth - (elementLeft + elementWidth);  
      stat.event('ttseditor_matchingvoices_click')
     this.$refs.listenRecognizeRef.openListenDialog({top:this.listenElementTop+44,right:this.distanceToRight-10,source:'specker-audio'})
  }
  changeVideoTranslationStatus(data) {
    this.$emit('change-video-translation-status', data)
  }

  auditionLimitTips(msg) {
      const source = !this.userRights.threeDayCus ? 'free_ends' : ''
      let content = !this.userRights.threeDayCus ? this.getI18n('subscriptionExpirationFree.desc') : this.getI18n('previewOutOfWordsFree.desc')
      this.$refs.previewOutOfWords.openDialog({
        title: this.getI18n('previewOutOfWordsFree.title'),
        desc: msg || content,
        onConfirm: () => {
          this.$emit('show-price-modal', { source })
        }
      })
      // 三天会员过期了
      if(!this.userRights.threeDayCus){
        stat.event('purchase_free_ends')
      }
  }
  async awaitGetUserRight(){
    const [userErr, userMes] = await to(this.getUserRights()) 
    if (!userMes) return 'getUserErr'
    if (userErr) {
        this.$message.error('getUserErr')
        return 'getUserErr'
    }
  }
  // 检查试听权限
  async checkAuditionUserRights() {
    if (this.vipInfo.type !== 1) {
      if(await this.awaitGetUserRight() == 'getUserErr') return
    }
    if (this.userRights.leftCredits <= 0 && this.vipInfo.type == 2 && this.userRights.freeCharNums <= 0 && this.userRights.appSumoCharNums < 0) {
      const source = !this.userRights.threeDayCus ? 'free_ends' : ''
      this.$refs.previewOutOfWords.openDialog({
        title: this.getI18n('previewOutOfWordsFree.title'),
        desc: !this.userRights.threeDayCus ? this.getI18n('subscriptionExpirationFree.desc') : this.getI18n('previewOutOfWordsFree.desc'),
        onConfirm: () => {
          this.$emit('show-price-modal', { source })
        }
      })
      // 三天会员过期了
      if (!this.userRights.threeDayCus) {
        stat.event('purchase_free_ends')
      }
      return false
    }
    return true
  }
  /**
   * 显示发音人弹窗
   * @param {*} speaker 
   */
  showAudioDialog({
    source,
    speakerInfo,
    params,
    articleId = '',
    hideUltraSpeaker = false,
    hideClonedTab = false,
    hideCloneBtn = false
  }) {
    this.source = source
    this.articleId = articleId
    this.hideUltraSpeaker = hideUltraSpeaker
    this.hideCloneBtn = hideCloneBtn
    this.showSpeakerAudio = true
    this.isConfirm = false
    if (hideClonedTab) {
      this.tabList = this.tabList.filter(tabKey => tabKey !== 'DupDubLab')
    } else if (!this.tabList.includes('DupDubLab')) {
      this.tabList.push('DupDubLab')
    }
    if(this.source === 'videoTranslation' && !this.tabList.includes('Cloned')) {
      this.tabList.push('Cloned')
    }

    if (this.source === 'videoTranslation' && params?.videoTranslationSpeakerList?.length > 0) {
      this.videoTranslationSpeakerList = params.videoTranslationSpeakerList
      this.concatVideoTranslationSpeakers()
    } else {
      this.videoTranslationSpeakerList = []
    }
    if(params?.speakerListUsed) {
      this.currentSpeakerListUsed = params.speakerListUsed
      console.log(params.speakerListUsed, 'speakerListUsed');
    }
    this.$nextTick(() => {
      if (this.$refs.minAudioHeaderRef) {
        let len = this.tabList.length
        this.$refs.minAudioHeaderRef.style.setProperty('--tab-before-width',  `calc((100% - 8px) / ${len})`)
      }
      
      // 未指定发音人打开弹窗 且 弹窗没有选中过发音人
      if (!speakerInfo && !this.currentSpeaker) {
        // 则使用历史发音人
        const speakerList = this.userHistory.speaker || []
        speakerInfo = speakerList[speakerList.length - 1]
        if (!speakerInfo) {
          // 没有历史发音人取左侧列表第一个发音人
          if (this.searchSpeakersList.length > 0) {
            speakerInfo = this.searchSpeakersList.length[0]
          }
        }
      }
      // 指定发音人打开弹窗
      if (speakerInfo && (speakerInfo.speaker || speakerInfo.speakerId)) {
        if(speakerInfo.speakerType === 'origin') {
          this.currentSpeaker = speakerInfo
          this.$refs.speakerDetailRef.openDetail(speakerInfo, params)
          return
        }
        
        if (speakerInfo.speakerId) {
          const speaker = this.allSpeakerIdMap[speakerInfo.speakerId]
          if (speaker) {
            speakerInfo.speaker = speaker
          }
        }
        this.$refs.speakerDetailRef.openDetail(speakerInfo, params)
        const _speakerInfo = this.allSpeakersMap[speakerInfo.speaker.split('@')[0]]?.default
        if (_speakerInfo) {
          this.currentSpeaker = this.currentSelectSpeaker = _speakerInfo
        }
        if(speakerInfo.audioTab) {
          this.switchTo(speakerInfo.audioTab, this.tabList.findIndex(v => v === speakerInfo.audioTab))
        }
      }
    })
  }
  /**
   * 切换tab
   * @param {*} value 
   * @param {*} index 
   */
  switchTo(value, index) {
    this.matchingVoices=false
    // 处理播放中发音人-停止掉
    this.parentStopAudio()

    this.activeTabType = value
    this.$refs.minAudioHeaderRef.style.setProperty('--tab-translateX', `translateX(${index * 100}%)`)
    this.spkPage = 1
    if(value === 'Cloned') {
      this.searchSpeakersList = this.videoTranslationSpeakerList
      return 
    }
    if (value !== 'All') {
      if (this.categorySpeakerList[value] && this.categorySpeakerList[value].length) {
        this.querySpeakerListCache()
      } else if (value === 'VoiceLab') {
        // 获取VoiceLab的数据
        this.getVoiceLabData(value)
      } else {
        this.searchSpeakersList = []
        const params = {
          type: value || 'Favorite'
        }
        http.speakerListByType(axios.urlParamFormat(params)).then((res) => {
          const data = res.data
          if (data.code === 200) {
            const result = data.data.result
            if (result.length) {
              this.$set(this.categorySpeakerList, value, result)
              this.querySpeakerListCache()
            }
          }
        })
      }
    } else {
      this.querySpeakerListCache()
    }
  }
  // 获取VoiceLab数据
  getVoiceLabData (value) {
    http.searchSpeakerList(axios.urlParamFormat({
      pageSize:2000,
      pageNum:1,
      sort: 6
    }))
    .then(res => {
      if (res.data.code === 200) { 
        const results = res.data.data.results
        if (results.length) {
          this.$set(this.categorySpeakerList, value, results)
          this.querySpeakerListCache()
        }
      } else {
        this.$message.error(res.data.message)
      }
    })
    .catch(err => {
      this.$message.error(err)
    })
  }
  // 弹窗全部分类
  categoryPopupList(value) {
    if (value.name === this.getI18n('speakerSearchType.scenario')) {
      return this.subSearchTagList
    }
    if (this.hideUltraSpeaker && value.name === this.getI18n('speakerSearchType.quality')) {
      // 隐藏ultra发音人
      return this.searchCriteriaList[value.attribute]?.filter(item => item.value !== '2')
    }
    return this.searchCriteriaList[value.attribute]
  }
  loadMore() {
    if (this.spkPage + 1 > this.allpages) {
      return
    }
    this.spkPage = this.spkPage + 1
    this.querySpeakerListCache()
  }
  keyWordsQuery() {
    this.parentStopAudio()
    this.spkPage = 1
    this.eventDebounce(this.querySpeakerListCache, 200)
  }
  keyWordsFocus() {
    this.isFocus = true
  }
  keyWordsBlur() {
    this.isFocus = false
  }
  eventDebounce(fn, wait) {
    if (this.timer1 !== null) clearTimeout(this.timer1)
    this.timer1 = setTimeout(fn, wait)
  }

  querySpeakerListCache() {
    // 关键词搜索
    let arr = Object.keys(this.nameToEngNameMap).filter(
      v => v.toLowerCase().includes(this.keyword.toLowerCase()) //输入的内容转化为小写
    )
    let speakersEnNameList = []
    arr.map(v => {
      if (this.nameToEngNameMap[v]) {
        speakersEnNameList.push(this.nameToEngNameMap[v])
      }
    })
    // tag 搜索
    let allSpeakersEnNameList = []
    let subTag = this.subSearchTagList[this.selectedSubSearchTagIndex]
    let _speakerList = []
    allSpeakersEnNameList = subTag?.speakerList || []
    let len = this.activeTabType === 'All' ? 50 : 2000
    // 做一个本地分页 50条一页
    if (this.spkPage == 1) {
      this.allpages = Math.ceil(
        allSpeakersEnNameList.filter(v => speakersEnNameList.includes(v))
          .length / len
      )
    }
    let result = speakersEnNameList.filter(v =>
      allSpeakersEnNameList.includes(v)
    )
    let speakersList = this.__genSortedSpeakers(result)
    speakersList = speakersList
      .filter(
        item => {
          return (item.gender === this.gender || this.gender == '') &&
          (item.accent === this.accent || this.accent == '') &&
          (item.age === this.age || this.age == '') &&
          ((item.language && item.specificLanguageSet && item.specificLanguageSet.join(',').includes(this.language)) || this.language == '') &&
          ((item.emotionSet && item.emotionSet.includes(this.style)) || this.style == '') &&
          (!this.hideUltraSpeaker || item.vipAuth !== 2) && // 隐藏ultra发音人
          (item.vipAuth == this.vipAuth || this.vipAuth == '')
        }
      )
      .slice((this.spkPage - 1) * len, this.spkPage * len)
    // VoiceLab/收藏tab使用接口返回的数据
    let searchTypeList= ['VoiceLab', 'Favorite']
    if (searchTypeList.includes(this.activeTabType)) {
      let list = []
      speakersList.filter(item => {
        (this.categorySpeakerList[this.activeTabType] || []).findIndex(_item => {
          if (_item.speakerId == item.speakerId) {
            list.push(_item)
          }
        })
      })
      speakersList = list
    } else {
      if (this.categorySpeakerList[this.activeTabType] && this.categorySpeakerList[this.activeTabType].length) {
        speakersList = speakersList.filter(item => {
          const index = this.categorySpeakerList[this.activeTabType].findIndex(_item => _item.speakerId === item.speakerId)
          if (index !== -1) {
            return item
          }
        })
      }
    }
    let searchSpeakersList = []
    if (this.activeTabType === 'DupDubLab') {
      searchSpeakersList = this.categorySpeakerList[this.activeTabType] || []
    } else {
      searchSpeakersList =
      this.spkPage == 1
        ? speakersList
        : [...this.searchSpeakersList, ...speakersList]
    }
    this.searchSpeakersList = searchSpeakersList
    this.concatVideoTranslationSpeakers()
  }
  concatVideoTranslationSpeakers() {
    if (this.isSameVideoTranslationSpeaker(this.videoTranslationSpeakerList[0], this.searchSpeakersList[0])) return
    this.searchSpeakersList = [...this.videoTranslationSpeakerList, ...this.searchSpeakersList]
  }
  isSameVideoTranslationSpeaker(s1, s2) {
    if (!s1 || !s2) return false
    return s1.speakerType === 'origin' && s1.speakerType === s2.speakerType && s1.speaker === s2.speaker
  }
  isItemActivity(item, idx, popupItem) {
    if (item.value === 'style' || item.value === 'gender') {
      return this.categorySelectMap[item.value] === popupItem.name || (this.categorySelectMap[item.value] === '' && popupItem.name === 'All')
    } else {
      return (item.name === this.getI18n('speakerSearchType.scenario') &&
        idx === this.selectedSubSearchTagIndex) || (item.name !== this.getI18n('speakerSearchType.scenario') &&
          this.categorySelectMap[item.value] === popupItem.value)
    }
  }
  async selectTab(popupItem, type, idx, index) {
    this[type] = popupItem.value
    this.accentLanguageList = popupItem.detail || []
    const currentAccent = this.accentLanguageList.find(item => item.value === this.accent)
    if (!currentAccent) {
      this.accent = ''
    }
    if (type === 'style' || type === 'gender') {
      this.$set(this.categorySelectMap, type, popupItem.name)
    } else {
      this.$set(this.categorySelectMap, type, popupItem.value)
    }
    this.categoryItemSelectMap[type] = popupItem

    this.spkPage = 1
    this.querySpeakerListCache()
    const elRef = this.$refs[`categoryRef_${index}`][0]
    if (elRef && (!popupItem.detail || !popupItem.detail.length)) {
      elRef.doClose()
    }
    this.$nextTick(() => {
      this.setAccentPosition(idx, index)
    })

  }
  hideCategoryPouper() {
    this.accentLanguageList = []
    this.currentTabIndex = -1
  }
  showCategoryPouper(index) {
    let showTimer = setTimeout(() => {
      this.currentTabIndex = index
      clearTimeout(showTimer)
      showTimer = null
    }, 300)
  }
  /**
   * 设置方言弹窗位置
   */
  setAccentPosition(idx, index) {
    const el = this.$refs[`categoryRef_${index}`][0].$refs.popper
    const accentRef = this.$refs[`accentPopuperRef_${index}`][0]
    if (this.accentLanguageList.length > 0 && this.accentLanguageList.length < 4) {
      accentRef.style.width = 90 * this.accentLanguageList.length + 'px'
    } else if (this.accentLanguageList.length >= 4) {
      accentRef.style.width = '400px'
    }
    let left = el.style.left.split('px')[0]
    left = el.clientWidth + 20 + 'px'
    let top = el.style.top.split('px')[0]
    const activeIitem = el.getElementsByClassName('category-popup-item')[idx]
    top = activeIitem.offsetTop + 'px'
    this.accentStyle = {
      top,
      left
    }
  }
  /**
   * 选择方言
   * @param {*} item 
   */
  selectAccentTab(item, index) {
    this.accent = item.value
    this.categoryItemSelectMap.accent = item
    this.spkPage = 1
    this.querySpeakerListCache()
    const refs = this.$refs[`categoryRef_${index}`]
    if (!refs) return
    const elRef = refs[0]
    if (elRef) {
      elRef.doClose()
    }
  }
  idArrTransformEnNameArr(arr) {
    let result = []
    arr.map(val => {
      if (
        this.allSpeakerIdMap[val] &&
        !this.allSpeakerIdMap[val].includes('@')
      ) {
        result.push(this.allSpeakerIdMap[val])
      }
    })
    return result
  }
  /**
   * 搜索scenario
   * @param {*} idx 
   * @param {*} itemInfo 
   * @param {*} item 
   * @param {*} index 
   */
  subTagSearch(idx, itemInfo, item, index) {
    if (itemInfo.name === 'All') {
      this.$set(this.categorySelectMap, item.value, '')
    } else {
      this.$set(this.categorySelectMap, item.value, itemInfo.name)
    }
    this.categoryItemSelectMap.scenario = itemInfo

    this.selectedSubSearchTagIndex = idx
    this.spkPage = 1
    const elRef = this.$refs[`categoryRef_${index}`][0]
    if (elRef) {
      elRef.doClose()
    }
    this.querySpeakerListCache()
    if (item && item.name) {
      this.thirdFilter = item.name
    }
  }
  // 切换tab
  triggerCondition(id) {
    this.searchSubMainArr[id].searchSubOpen = !this.searchSubMainArr[id]
      .searchSubOpen
    if (this.searchSubMainArr[id].searchSubOpen) {
      this.$refs[this.searchSubMainArr[id].type].style.height = 'auto'
    } else {
      this.$refs[this.searchSubMainArr[id].type].style.height = '32px'
    }
  }
  collection(item) {
    let isFavourite = item.isFavourite || false
    let data = {
      speakerId: item.speakerId
    }
    // this.$set(item, 'isFavourite', !isFavourite)
    // return
    http.setFavouriteSpeaker(axios.urlParamFormat(data)).then(res => {
      if (res.data.code == 200 && res.data.message == 'OK') {
        this.$nextTick(() => {
          this.$set(
            this.allSpeakersMap[item.speaker].default,
            'isFavourite',
            !isFavourite
          )
          if (isFavourite) {
            this.userSpeakerTypeMapList['Favorite'].splice(
              this.userSpeakerTypeMapList[
                'Favorite'
              ].indexOf(item.speaker),
              1
            )
            if (this.speakerTypeIndex == 0) {
              this.searchSpeakersListByType.splice(
                this.searchSpeakersListByType.findIndex(
                  v => v.speaker == item.speaker
                ),
                1
              )
            }
          } else {
            this.userSpeakerTypeMapList['Favorite'].push(
              item.speaker
            )
            if (this.speakerTypeIndex == 0) {
              this.searchSpeakersListByType.push(
                this.allSpeakersMap[item.speaker].default
              )
            }
          }
        })
      } else {
        this.$message.error(this.getI18n('errTxt.serverFail'))
      }
    })
  }
  setCollectionList(isFavourite, item) {
    if (isFavourite) {
      this.userSpeakerTypeMapList['Favorite'].splice(
        this.userSpeakerTypeMapList['Favorite'].indexOf(
          item.speaker
        ),
        1
      )
      if (this.speakerTypeIndex == 0) {
        this.searchSpeakersListByType.splice(
          this.searchSpeakersListByType.findIndex(v => v.speaker == item.speaker),
          1
        )
      }
    } else {
      this.userSpeakerTypeMapList['Favorite'].push(
        item.speaker
      )
      if (this.speakerTypeIndex == 0) {
        this.searchSpeakersListByType.push(
          this.allSpeakersMap[item.speaker].default
        )
      }
    }
  }

  // 根据发音人key list, 获取排序后的发音人列表,isRight(是否是收藏+常用)
  __genSortedSpeakers(speakersList = [], isRight = false) {
    let list = []
    speakersList.forEach(key => {
      if (typeof key === 'string' && key.includes('@')) {
        key = key.split('@')[0]
      }
      let speakerMap = this.allSpeakersMap[key]
      if (!speakerMap) {
        return
      }
      let speaker
      // 如果切换的是右侧则去默认发音人里查询
      if (isRight) {
        speaker = speakerMap.default || {}
      } else {
        if (this.style === '') {
          speaker = speakerMap.default || {}
        } else {
          speaker = speakerMap[this.style] || {}
        }
      }
      speaker.disabled = speaker.vipAuth === 1 && this.vipInfo.type === 0
      speaker.isLimitTimeFree =
        speaker.tags && speaker.tags.indexOf(this.getI18n('miniAudio.free')) > -1
      list.push(speaker)
    })
    return list
  }
  async selectMoodSpeakerByClick(item) {
    this.currentMoodSpeaker = item.speaker
    const speaker = {
      speakerId: item.speakerId,
      speaker: item.speaker,
      speakerName: item.name,
      headerImage: item.headerImage,
      isHave48k: item.isHave48k || false,
      isFavourite: item.isFavourite || item.favourite,
      speakerObj: item
    }
    this.pauseMap = item.pauseMap || 'close'
    this.isHave48kQuality = item.isHave48k || false
    if (this.isHave48kQuality && this.vipInfo.type !== 2) {
      this.isCurrent48k = true
    } else {
      this.isCurrent48k = false
    }
    this.currentSelectSpeaker = speaker
  }

  // 选择情感分类发音人
  selectMoodSpeaker(index, item, is48k, isNotInit) {
    this.currentMoodSpeaker = item.speaker
    const speaker = {
      speakerId: item.speakerId,
      speaker: item.speaker,
      speakerSource: item.source,
      speakerName: item.name,
      speakerSpeed: item.speakerSpeed,
      headerImage: item.headerImage,
      isHave48k: item.isHave48k || false,
      speakerObj: item
    }
    this.pauseMap = item.pauseMap || 'close'
    this.isHave48kQuality = item.isHave48k
    if (!this.isHave48kQuality) {
      this.isCurrent48k = false
    } else {
      if (isNotInit) {
        if (is48k) {
          this.isCurrent48k = true
        } else if (is48k === false) {
          this.isCurrent48k = false
        } else if (!this.$parent.isFirstSave) {
          this.isCurrent48k = true
        }
      } else {
        if (item.vipAuth != 3) {
          this.isCurrent48k = false
        } else if (this.vipInfo.type != 0 && this.vipInfo.type != 1) {
          this.isCurrent48k = false
        }
      }
      if (this.vipInfo.type == 2) {
        this.isCurrent48k = false
      }
    }
    this.currentSelectSpeaker = speaker
  }
  // 选择发音人 // clickRight 是否点击播放器右边发音人筛选

  // isInit 是否初始化
  async selectSpeaker(index, item, clickRight, isNotInit = true, is48K) {
    let defaultSpeaker
    if (item.speaker.indexOf('@') > -1) {
      defaultSpeaker = this.allSpeakersMap[item.speaker.split('@')[0]].default
    } else {
      defaultSpeaker = item
    }
    this.selectSpeakerConfirm({
      speakerItem: defaultSpeaker,
      isNotInit,
      is48K,
      clickRight
    })
  }

  // 用户点击speaker切换
  onSpeakerClick(index, item, type) {
    // 视频本地化传过去的克隆发音人列表
    this.avatorIndex=index
    if(item.speakerType === 'origin') {
      this.currentSelectSpeaker = item
      this.$refs.speakerDetailRef.openDetail(item)
      return
    }
    if(item.speaker.indexOf('_48k') > -1){
      this.selectSpeaker(index, item, false, true,true)
      this.isHave48kQuality = true
      this.isCurrent48k = true
    } else {
      this.selectSpeaker(index, item)
    }
    this.stopAudio()
    if (!type) this.parentStopAudio()
    this.$refs.speakerDetailRef.openDetail(item, null, (newDetail) => {
      if (!item.speakerType && type) {
        this.styleOrLanguageDemoUrlPlay(item, newDetail)
      }
    })
  }
  // style
  styleOrLanguageDemoUrlPlay (item, newDetail) {
    let audio = this.$refs.styleOrLanguageDemoUrlRef
    if (!item.play) {
      if (newDetail.langDemoList && newDetail.langDemoList?.length) {
        let language = this.language || 'English'
        newDetail.langDemoList.forEach(item => {
          if (item.lang === language) {
            this.audioStyleOrLanguageDemoUrl = item.demoUrl
          }
        })
      } else {
        this.audioStyleOrLanguageDemoUrl = newDetail.speakerEmotionCacheVOList[0].demoUrl
      }
    }
    if (!item.play) {
      this.searchSpeakersList.forEach(item => {
        if (item.play) item.play = false
      })
      setTimeout(() => {
        item.play = true
        audio.currentTime = 0
        audio.addEventListener('ended', this.styleOrLanguageDemoUrlPlayCallback.bind(this, item))
        audio.play()
        this.$forceUpdate()
      })
    } else {
      audio.pause()
      item.play = false
    }
    this.$forceUpdate()
  }
  // style or language 头像播放完的回调
  styleOrLanguageDemoUrlPlayCallback (item) {
    let audio = this.$refs.styleOrLanguageDemoUrlRef
    item.play = false
    this.audioStyleOrLanguageDemoUrl = null
    audio.removeEventListener('ended', this.styleOrLanguageDemoUrlPlayCallback)
    this.$forceUpdate()
  }
  /**
   * 确认发音人
   * @returns 
   */
  async confirmSpeaker() {
    if (!this.currentSelectSpeaker.speakerId) return
    this.changePlaySpeed(this.speedValue)
    this.changeIntonation(this.intonation)
    this.currentSelectSpeaker.speed = this.speedValue
    this.currentSelectSpeaker.pitch = this.intonation
    console.log('confirmSpeaker', this.currentSelectSpeaker)
    // 更新文章发音人
    if (this.onChangeSpeakerInfo) {
      if (await this.onChangeSpeakerInfo(this.currentSelectSpeaker)) {
        // 返回false阻止关闭弹窗
        this.showSpeakerAudio = false
        this.isConfirm = true
      } else {
        // 外面组件在点击确认后，没有关闭弹窗，则重置isConfirm
        this.isConfirm = false
      }
    }
    
    // 将选择的发音人记录在后端
    this.updateAccountHistory()

    // 通知baseApp
    window.__baseApis?.onSpeakerChange({
      speakerId: this.currentSelectSpeaker.speaker
    })
  }
  /**
   * 关闭弹窗
   */
  closeSpeakerDetail() {
    if(!this.isConfirm) {
      this.$emit('cancel-select-speaker')
    }
    this.showSpeakerAudio = false
    this.stopAudio()
    this.hideTooltip()
    this.parentStopAudio()
  }
  parentStopAudio () {
    this.searchSpeakersList.forEach(item => {
      if (item.play) this.styleOrLanguageDemoUrlPlay(item)
    })
  }
  stopAudio() {
    if (this.$refs.speakerDetailRef) {
      this.$refs.speakerDetailRef.stopAudio()
      this.$refs.speakerDetailRef.listeningCancel()
    }
  }
  hideTooltip() {
    if (this.$refs.speakerDetailRef) {
      this.hideSliderTooltip(this.$refs.speakerDetailRef.$refs.speedSlider)
      this.hideSliderTooltip(this.$refs.speakerDetailRef.$refs.pitchSlider)
    }
  }
  hideSliderTooltip(slider) {
    if (slider) {
      slider.$refs.button1?.hideTooltip()
      slider.$refs.button2?.hideTooltip()
    }
  }

  // 切换发音人
  selectSpeakerConfirm(obj) {
    let { speakerItem, isNotInit, is48K, clickRight } = obj
    if (speakerItem.speaker.indexOf('_48k') > -1) {
      this.isHave48kQuality = true
      this.isCurrent48k = true
    } else {
      this.isHave48kQuality = false
      this.isCurrent48k = false
    }
    let defaultSpeaker = speakerItem
    let moods = this.allSpeakersMap[defaultSpeaker.speaker] ? Object.keys(this.allSpeakersMap[defaultSpeaker.speaker]).filter(
      key => key
    ) : []

    // 当分类为情感配音时
    this.currentSelectSpeaker = speakerItem
    
    // 触发自动保存
    if (
      JSON.stringify(this.backArticleInfo) != JSON.stringify(this.articleInfo)
    ) {
      this.globalAutoSaveArticle()
    }
    this.guidanceUrl = defaultSpeaker.guidanceUrl || ''
    // 个人会员 && 选择的是精选声音 && 点击切换时的状态为：非暂停（播放中/加载中）todo
    // if (
    //   this.vipInfo.type == 0 &&
    //   defaultSpeaker.vipAuth == 1 &&
    //   this.playState != 0
    // ) {
    //   this.$emit('change-play-state', true)
    // }
    // 常用发音人 初始化风格 语调 语速
    if (!isNotInit) {
      // 获取文章详情  初始化
      this.initArticleSpeaker(defaultSpeaker, isNotInit, is48K)
    } else if (this.speakerTypeIndex == 1 && clickRight) {
      // 点击常用 或者新建初始化
      this.selectSpeakerCommonlyUsed(defaultSpeaker, isNotInit)
    } else if (moods.length > 0) {
      // 选择发音人设置发音（非-常用《left，right部分》）
      this.selectSpeakerSet(defaultSpeaker, isNotInit)
      return
    }
    const speaker = {
      speakerId: defaultSpeaker.speakerId,
      speaker: defaultSpeaker.speaker,
      speakerSource: defaultSpeaker.source,
      speakerName: defaultSpeaker.name,
      speakerSpeed: defaultSpeaker.speakerSpeed,
      headerImage: defaultSpeaker.headerImage,
      speakerObj: defaultSpeaker
    }

    if (defaultSpeaker.speedValue) {
      this.speedValue = Number(defaultSpeaker.speedValue)
    }
    if (defaultSpeaker.pitch) {
      this.intonation = Number(defaultSpeaker.pitch)
    }
    this.speedValue = Number(this.speedValue)
    this.intonation = Number(this.intonation)
    this.currentSelectSpeaker = speaker

    this.pauseMap = defaultSpeaker.pauseMap || 'close'

    this.pauseMap = defaultSpeaker.pauseMap || 'close'

  }

  // 获取文章详情  初始化
  initArticleSpeaker(defaultSpeaker, isNotInit, is48K) {
    let [defaultName, style = 'default'] = defaultSpeaker.speaker.split('@')
    let moodObj = this.allSpeakersMap[defaultName][style]
    let _is48K = is48K !== undefined ? is48K : defaultSpeaker.is48k
    this.selectMoodSpeaker(0, moodObj, _is48K, isNotInit)
    this.selectSpeed(this.articleInfo.speed || 1)
    this.selectIntonation(this.articleInfo.pitch || 0)
  }
  // 点击常用选择发音人（right部分-常用） 或者新建初始化
  selectSpeakerCommonlyUsed(defaultSpeaker, isNotInit) { 
    let [userStyleObj] = this.userSpeakerTypeMapList[
      'Recent'
    ].filter(v => {
      return v.originSpeaker == defaultSpeaker.speaker
    })
    if (userStyleObj) {
      let style = userStyleObj.speaker.split('@')[1] || 'default'
      let moodObj = this.allSpeakersMap[defaultSpeaker.speaker][style]
      this.selectSpeed(userStyleObj.speed)
      this.selectIntonation(userStyleObj.pitch || 0)
      this.selectMoodSpeaker(0, moodObj, userStyleObj.is48k)
      return
    }
    let [defaultName, style = 'default'] = defaultSpeaker.speaker.split('@')
    let moodObj = this.allSpeakersMap[defaultName][style]
    this.selectSpeed(1)
    this.selectIntonation(0)
    this.selectMoodSpeaker(0, moodObj, true, isNotInit)
  }
  // 选择发音人设置发音(非常用《left，right部分》)
  selectSpeakerSet(defaultSpeaker, isNotInit) {
    let [defaultName, style = 'default'] = defaultSpeaker.speaker.split('@')

    let moodObj = this.allSpeakersMap[defaultName][style]

    this.selectSpeed(1)
    this.selectIntonation(0)
    this.selectMoodSpeaker(0, moodObj, true, isNotInit)
  }

  selectSpeed(value) {
    value = value == 0 ? 0.05 : value
    this.speedValue = value
    for (let i in this.speedMarks) {
      const key = Number(i)
      if (key === Number(value)) {
        this.speedMarks[key].style.color = '#3583FB'
      } else {
        this.speedMarks[key].style.color = '#666'
      }
    }
  }

  selectIntonation(value) {
    this.intonation = Number(value)
  }
  // 上传用户记录
  updateAccountHistory() {
    if (this.updateAccountTimer !== null) clearTimeout(this.updateAccountTimer)
    this.updateAccountTimer = setTimeout(this.updateAccountFn, 3000)
  }
  updateAccountFn() {
    let formData = new FormData()
    const speakerInfo = this.currentSelectSpeaker
    if(!speakerInfo.speakerId) return
    formData.append('speakerId', speakerInfo.speakerId)
    formData.append('speed', speakerInfo.speed)
    formData.append('pitch', speakerInfo.pitch)
    formData.append('quality', speakerInfo.isHave48k)
    http.historySpeaker(formData).then(() => { })
  }
  changePlaySpeed(value) {
    this.$emit('change-play-speed', value)
  }
  changeIntonation(value) {
    this.$emit('change-intonation', value)
  }
  changeUnfold(value) {
    this.isUnfold = value
  }
  addSpeakerDetailCache(speakerInfo) {
    speakerDetailCache[speakerInfo.speakerId] = speakerInfo
  }
  /**
   * 收藏
   * @param {*} speakerInfo 
   */
  changeFavourite(speakerInfo) {
    this.collectSpeaker(speakerInfo)
    this.$emit('collect-speaker', speakerInfo)
  }
  /**
   * @public 
   * 更新弹窗内部收藏发音人
   */
  collectSpeaker(speakerInfo) {
    if (this.categorySpeakerList['Favorite'] && this.categorySpeakerList['Favorite'].length) {
      const list = this.categorySpeakerList['Favorite']
      const favourite = speakerInfo.favourite
      const index = list.findIndex(item => item.speakerId === speakerInfo.speakerId)
      if (index === -1 && favourite) {
        this.categorySpeakerList['Favorite'].push(speakerInfo)
      }
      if (index !== -1 && !favourite) {
        this.categorySpeakerList['Favorite'].splice(index, 1)
      }
    } else {
      this.categorySpeakerList['Favorite'] = [speakerInfo]
    }
    this.$nextTick(() => {
      if (this.activeTabType === 'Favorite' && this.categorySpeakerList['Favorite'].length) {
        this.querySpeakerListCache()
      }
    })

    // 设置默认发音人favourite字段
    const defaultSpeakerInfo = this.getDefaultSpeaker(speakerInfo.speaker)
    if (!defaultSpeakerInfo) return
    this.$set(defaultSpeakerInfo, 'favourite', speakerInfo.favourite)
  }

  // 默认发音人
  getDefaultSpeaker(speaker) {
    if (!speaker) return null
    const [key] = speaker.split('@')
    return this.allSpeakersMap[key]?.default || null
  }
  genRenderAvatar(img = '') {
    if (typeof img === 'string' && img.includes('//')) {
      return img.search('.svg') === -1
        ? `${img}?iopcmd=thumbnail&type=8&width=80&height=80`
        : img
    }
    return require(img)
  }
  // 添加克隆声音 3秒克隆
  addVoice(searchVoices=false) {
    if(searchVoices) {
      stat.event('ttseditor_matchingvoices_clonethevoice_click')
    }
    let filteredCloneSpeakers = this.threeCloneSpeakerList.filter(speaker => speaker.speakerType === 'clone');  
    if (filteredCloneSpeakers.length >= 10) {  
      // 如果过滤后的clone类型扬声器数量达到或超过10，给出错误消息  
      this.$message.error(this.getI18n('miniAudio.cloneSpeakerLimitTip'));  
      return
    } 
    this.$refs.ThreeSecondCloneDialog.openDialog()
    if(this.$route.path.indexOf('/talking-photo-editor') === 0 || this.$route.path.indexOf('/talking-avatar') === 0) {
      stat.event('studio-instantvoicecloning-exposure', {'popup-resources': "avatarpanel"})
    } else if (this.$route.path.indexOf('/overview/article-voice') === 0) {
      stat.event('studio-instantvoicecloning-exposure', {'popup-resources': "ttspanel"})
    } else if (this.$route.path.indexOf('/video-translation-editor') === 0) {
      stat.event('studio-instantvoicecloning-exposure', {'popup-resources': "videotranslationpanel"})
    } else {
      stat.event('studio-instantvoicecloning-exposure', {'popup-resources': "videoeditingpanel"})
    }
  }
  // 删除后重新获取我的声音
  async onGetDupdubLab(isDelete = false) {
    this.categorySpeakerList.DupDubLab = null
    const index = this.tabList.findIndex(v => v == 'DupDubLab')
    await this.refreshDupDubLabSpeakers()
    this.$emit('refresh-dupdublab-speakers')
    this.switchTo(this.tabList[index], index)
  }
  onDeleteVoice(speakerInfo){
    if(this.currentSpeakerListUsed.includes(speakerInfo.speaker)) {
      return this.$message.error(this.getI18n('miniAudio.deleteCloneSpeakerTip'))
    }
    MessageBox.confirm('', this.getI18n('miniAudio.deleteTitle'), {
      closeOnClickModal: false,
      distinguishCancelAndClose: true,
      confirmButtonText: this.getI18n('miniAudio.deleteBtn'),
      cancelButtonText: this.getI18n('miniAudio.cancelBtn'),
      customClass: 'speaker-audio-delete-messagebox',
      showClose: false
    })
      .then(async() => {
        try {
          const {data} = await threeSecondClone.deleteVoice(speakerInfo.speakerId)
          if(data.code == 200 && data.data) {
            this.$message.success('Delete voice success')
            this.onGetDupdubLab(true)
            return
          }
          throw new Error(data.message)
        }catch(err) {
          this.$message.error(err.message || 'Delete error, please try again')
        }
      })
      .catch(() => {
        console.log('cancel');
      })
  }

  genRenderAvatar(...args) {
    return getHeaderImage(...args)
  }
  // 收藏自定义参数
  collectFavourite() {
    const params = {
      type: 'Favorite'
    }
    http.speakerListByType(axios.urlParamFormat(params)).then((res) => {
      const data = res.data
      if (data.code === 200) {
        const result = data.data.result
        if (Array.isArray(result)) {
          this.$set(this.categorySpeakerList, 'Favorite', result)
          this.querySpeakerListCache()
        }
      }
    })
  }
  speakerSelected(speakerInfo, index) {
    if(this.activeTabType === 'Favorite' ) {
      return this.currentSelectSpeaker.speakerId == speakerInfo.speakerId && this.avatorIndex === index
    }
    return this.currentSelectSpeaker.speakerId == speakerInfo.speakerId
  }

}

const renderIntonationMarks = function () {
  let obj = {}
  const list = [-10, -5, 0, 5, 10]
  list.forEach(i => {
    obj[i] = {
      style: {
        color: '#666'
      },
      label: this.$createElement('span', String(i))
    }
  })
  return obj
}
