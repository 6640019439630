<template>
  <div
    class="speaker-detail"
    :class="{'modify-setting': isModifySetting, 'audio-speaker-detail': true}"
    v-if="isShow"
  >
    <div class="speaker-detail-container">
      <div class="speaker-detail-header">
        <!-- 头像区域 start -->
        <div class="avatar-wrapper p24">
          <div class="avatar-info">
            <img v-if="currentSpeaker.headerImage" :src="genRenderAvatar(currentSpeaker.headerImage)" >
            <img v-else src="./assets/images/default_avator@2x.png" >
            <div
              v-if="currentSpeaker.speakerType !== 'origin' && currentSpeaker.speakerType !== 'clone' && currentSpeaker.speakerType !== 'avatar'"
              class="favorite-button"
              :class="{ favorite: isDefaultCollect }"
              @click="collectDefaultSpeaker"
            ></div>
          </div>
          <div class="speaker-name-wrapper">
            <span :data-id="currentSpeaker.speaker" class="speaker-name one-text-overflow" :class="{'no-unfold-name': !isUnfold}">{{ currentSpeaker.name | splitName }}</span>
            <div class="speaker-usage-24">
              <span class="usage" v-if="currentSpeaker.playCount" >
                <img src="./assets/images/fire.png" width="12" />{{ getUsage(currentSpeaker.playCount) }}
              </span>
              <span v-if="currentSpeaker.type != 'origin' && !(functionList.includes(source) && !isModifySetting && !isUnfold && currentSpeaker.playCount)" class="k-item">{{ getI18n('miniAudio.24k') }}</span>
            </div>
          </div>
          <div
            class="audition-box"
            :class="{'unfold-audition-box': !isUnfold}"
            @click="changePlay('btn')"
            v-if="functionList.includes(source) && !isModifySetting"
          >
            <!-- 试听状态 - start -->
            <div :class="playStateIcon[playState]"></div>
            <!-- 试听状态 - end   -->
          </div>
        </div>
        <!-- 头像区域 end -->
        <template v-if="isModifySetting">
          <div class="behavior p24" :class="{'unfold-behavior': !isUnfold}">{{ currentSpeaker.behavior }}</div>
          <!-- 领域 start -->
          <div
            ref="domainWrapperRef"
            class="domain-wrapper p24" 
            v-show="isUnfold"
          >
            <div
              class="domain-item"
              v-for="(domain, index) in domainNameList"
              :key="index"
            >{{domain}}</div>
          </div>
          <!-- 领域 end -->
          <!-- 分割线 start -->
          <div class="p24" v-show="isUnfold">
            <div class="divider"></div>
          </div>
          <!-- 分割线 end -->
          <div class="emotion-text p24" v-show="isUnfold">{{ currentEmotionWord }}</div>
          <!-- 语言 start -->
          <div class="emotion-list p24 modify-list" v-if="currentSpeaker?.langDemoList?.length" >
            <div
              class="emotion-item"
              v-for="(item, index) in currentSpeaker?.langDemoList"
              :key="index"
              :class="{'emotion-item-active': currentLangDemoIndex === index}"
              @click="audioLangDemoUrlPaly(item, index)"
            >
              <span class="mod-item-text">{{ item.lang}}</span>
              <span class="listen-btn">
                <span class="play-btn"></span>
              </span>
            </div>
            <audio :src="audioLangDemoUrl" preload="auto" ref="audioDemoUrlRef"></audio>
          </div>
          <!-- 语言 end -->
          <!-- 情绪部分 start -->
          <template v-else>
            <div class="emotion-list p24 modify-list" :class="{'no-unfold-emotion-list': !isUnfold}">
              <div
                class="emotion-item"
                v-for="(item, index) in speakerEmotionCacheVOList"
                :key="index"
                :class="{'emotion-item-active': currentEmotionIndex === index}"
                @click="audioPaly(item, index)"
              >
                <span class="mod-item-text">{{!item.name.split('|')[1] ? 'Default' : item.name.split('|')[1].trim()}}</span>
                <span class="listen-btn">
                  <span class="play-btn"></span>
                </span>
              </div>
              <audio :src="audioDemoUrl" preload="auto" ref="audioRef"></audio>
            </div>
          </template>
          <!-- 情绪部分 end -->
        </template>
        <template v-else>
          <div class="emotion-list p24" :class="{'no-unfold-emotion-list': !isUnfold}">
            <div
              v-for="(item, index) in speakerEmotionCacheVOList"
              :key="index"
              class="emotion-item"
              :class="{'emotion-item-select': currentSelectEmotionIndex === index}"
              @click="selectMoodSpeakerByClick(item, index)"
            >
              {{!item.name.split('|')[1] ? 'Default' : item.name.split('|')[1].trim()}}
            </div>
          </div>
          <!-- 语速、语调 start -->
          <div class="speed-pitch-wrapper p24" :class="{'no-unfold-input-wrapper': !isUnfold}">
            <!-- 语速 start -->
            <div class="speed-pitch speed-wrapper">
              <el-slider
                ref="speedSlider"
                v-if="isUnfold"
                v-model="speedValue"
                class="speaker-slider"
                tooltip-class="speaker-slider-tooltip"
                :max="slideConfig.max"
                :min="slideConfig.min"
                label
                :marks="speedMarks"
                :step="slideConfig.step"
                @change="selectSpeed"
                @input="preventCloseOnClickModal"
                :disabled="currentSpeaker.speakerType === 'origin'"
              ></el-slider>
              <div class="input-wrapper">
                <span class="label">{{ getI18n('toolMenus.speed') }}</span>
                <el-input-number
                  class="speed-pitch-input"
                  v-model="speedValue"
                  :max="slideConfig.max"
                  :min="slideConfig.min"
                  @change="selectSpeed"
                  :disabled="currentSpeaker.speakerType === 'origin'"
                ></el-input-number>
              </div>
            </div>
            <!-- 语速 end -->
            <!-- 语调 start -->
            <div class="speed-pitch pitch-wrapper">
              <el-slider
                ref="pitchSlider"
                v-if="isUnfold"
                v-model="pitchValue"
                class="speaker-slider"
                tooltip-class="speaker-slider-tooltip"
                :max="10"
                :min="-10"
                label
                :marks="intonationMarks"
                :step="1"
                @change="changeIntonation"
                @input="preventCloseOnClickModal"
                :disabled="currentSpeaker.speakerType === 'origin'"
              ></el-slider>
              <div class="input-wrapper">
                <span class="label">{{ getI18n('toolMenus.pitch') }}</span>
                <el-input-number
                  class="speed-pitch-input"
                  v-model="pitchValue"
                  :max="10"
                  :min="-10"
                  @change="changeIntonation"
                  :disabled="currentSpeaker.speakerType === 'origin'"
                ></el-input-number>
              </div>
            </div>
            <!-- 语调 end -->
          </div>
          <!-- 语速、语调 end -->
          <!-- 收藏自定义参数 start -->
          <div v-show="currentSpeaker.speakerType!=='clone' && currentSpeaker.speakerType!=='avatar' && currentSpeaker.speakerType!=='origin'" class="collection-parameter" :class="{'no-unfold-collection-parameter': !isUnfold}">
            <div class="item" >
              <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/member-icon.png" alt="">
              <div class="parameter" v-if="favoriteState">
                <span class="emotion">{{paramsEmotion?paramsEmotion:'Default'}}</span> · <span>{{speedValue}}</span> · <span>{{pitchValue}}</span>
              </div>
              <el-tooltip popper-class="collection-tip" class="item" effect="dark" :content="favoriteState?getI18n('miniAudio.UnfavoriteTip'):getI18n('miniAudio.addFavoritedTip')" placement="bottom">
                <div class="collect-btn" @click="changeCollection">{{favoriteState?getI18n('miniAudio.Unfavorite'):getI18n('miniAudio.addFavorited')}}</div>
              </el-tooltip>
            </div>
          </div>
          <!-- 收藏自定义参数 end -->
        </template>
      </div>
    </div>
    <audio :src="speakerAudioUrl" @ended="audioEndEvt" ref="audio"></audio>
    <div class="mode-wrapper" @click="changeMode" v-if="isShowSimpleVoice">
      {{ isModifySetting ? getI18n('audioDetail.voiceSetting') : getI18n('audioDetail.sampleVoices') }}
      <img class="mode-icon" v-if="currentSpeaker.type !== 'origin'" src="./assets/images/change_mode_icon.svg" >
    </div>
    <div
      class="unfold-wrapper"
      @click="changeUnfold">

      <span class="fold-icon" :class="{ isUnfold }"></span>
    </div>
    <!-- 先不删除 -->
    <div class="delet-clone-speaker" v-if="!!!isShowSimpleVoice" @click="deleteVoice">
      {{ getI18n('miniAudio.deleteVoice') }}
    </div>
  </div>
</template>
<script>
import http from '@/utils/http-request'
import axios from '@/utils/axios'
let audioElement = null
import { threeSecondClone } from '../../api'
import { getSpeakerDetail } from "@/api/modules/speakerAudio"
import { isCloneSpeaker, getUsage } from "./utils/utils"
export default {
  name: 'MiniAudioDetail',
  props: {
    id: {
      type: [Number, String],
      default: '' // 文章id
    },
    source: {
      type: String,
      default: ''
    },
    slideConfig: {
      type: Object,
      default: () => {
        return {}
      }
    },
    speedMarks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    intonationMarks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isUnfold: {
      type: Boolean,
      default: () => true
    },
    allSpeakersLineMap: {
      type: Object,
      default: () => ({})
    },
    vipInfo: {
      type: Object,
      default: () => ({})
    },
    // userRights: {
    //   type: Object,
    //   default: () => ({})
    // },
    // getUserRights: {
    //   type: Function,
    //   default: null
    // },
    changeVipinfo: {
      type: Function,
      default: null
    },
    genRenderAvatar: {
      type: Function,
      default: a => a
    },
    getDefaultSpeaker: {
      type: Function,
      default: a => a
    },
    onPauseAudion: {
      type: Function,
      default: null
    },
    onPlayAudion: {
      type: Function,
      default: null
    },
    checkAuditionUserRights: {
      type: Function,
      default: null
    }
  },
  inject: [
    'getI18n',
    'preventCloseOnClickModal',
    'speakerDetailCache',
    'addSpeakerDetailCache'
  ],
  data() {
    return {
      favoriteState:false,//是否收藏
      currentSpeaker: {}, // 当前发音人
      currentEmotionWord: '', // 当前情绪对应描述
      currentEmotionIndex: -1, // 当前情绪索引
      currentLangDemoIndex: -1, // 当前demo语言播放样列的索引
      speakerEmotionCacheVOList: [], // 当前情绪列表
      modeType: 'Modify setting', // 当前模式 Preview sample | Modify setting
      isShow: false,
      domainNameList: [],
      audioDemoUrl: '', // 播放音频
      audioLangDemoUrl: '', // 语言播放音频地址
      currentSelectEmotionIndex: 0,
      speedValue: 1, // 语速
      pitchValue: 1, // 语调
      auditionArr: [],
      auditionIndex: 0,
      speakerAudioUrl: '',
      listenParams: [], // 文章试听参数集合
      listenParamsIndex: 0, // 文章试听参数索引
      playState: 0,
      playStateIcon: ['play-icon', 'loading-icon', 'pause-icon'],
      playEndMes: null, //发音人面板试听结束后，提示文案
      isRequestPending:false,//查询是否收藏发音人
      speakerInfo:{},//发音人参数
      paramsEmotion:'',//收藏情绪参数
      collectSpeakerInfo:{},
      functionList: ['ttsEditor', 'videoEditor'] // 功能list
    }
  },
  filters: {
    splitName(name) {
      if (!name) return ''
      return name.split('|')[0]
    }
  },
  computed: {
    isModifySetting() {
      // 判断如果是声音克隆type为Preview sample
      let cloneState=this.domainNameList && this.domainNameList.length > 0 && this.speakerEmotionCacheVOList && this.speakerEmotionCacheVOList.length > 0
      if(!cloneState){
        this.modeType='Preview sample'
      }
      return this.modeType === 'Modify setting'
    },
    isShowSimpleVoice() {
      // 克隆的声音没有领域情绪，不显示这个面板
      return this.domainNameList && this.domainNameList.length > 0 && this.speakerEmotionCacheVOList && this.speakerEmotionCacheVOList.length > 0
    },
    // 默认发音人
    defaultSpeaker() {
      return this.getDefaultSpeaker(this.currentSpeaker?.speaker)
    },
    // 是否默认发音人被收藏
    isDefaultCollect() {
      return !!this.defaultSpeaker?.favourite
    }
  },
  methods: {
    getUsage,
    // 收藏自定义参数
    async changeCollection() {
      http.collectSpeakerParams(this.collectParams()).then(res=>{
        if (res.data.code == 200 && res.data.message == 'OK'){
          this.$nextTick(() => {
            this.favoriteState = true
            this.$emit('collect-favourite')
            this.getFavorite()
          })
        }else{
          this.$message.error(res.data.message)
        }
      })
      console.log('changeCollection',this.currentSpeaker.speaker.split('@')[1],this.speedValue, this.pitchValue)
    },
    //查询发音人参数是否收藏
    getFavorite() {
      if(this.isRequestPending) return
      this.isRequestPending = true
      http.getIsCollect(this.collectParams()).then(res=> {
        if(res.data.code===200){
          this.favoriteState = res.data.data
          this.isRequestPending = false
        }else{
          this.isRequestPending = false
        }
      }).catch(()=>{
        this.isRequestPending = false
      })
    },
    collectParams(){
      let speakerEmotionCacheVOList = this.currentSpeaker.speakerEmotionCacheVOList || []
      let emotion = speakerEmotionCacheVOList[this.currentSelectEmotionIndex]?.speaker.split('@')[1] || ''
      // console.log(this.collectSpeakerInfo.speakerId,this.currentSpeaker.speakerId,emotion,'发音人详情')
      let params = {
        speakerId: this.collectSpeakerInfo.speakerId,
        emotion: emotion ? emotion : '',
        speed: this.speedValue,
        pitch: this.pitchValue,
      }
      return params
    },
    /**
     * 获取发音人详情
     * @param {*} item 
     */
    openDetail(speakerItem, params, cb) {
      this.stopAudio()
      // 停止播放音频
      if(audioElement) {
        audioElement.pause()
        audioElement.src = ''
        audioElement = null
      }
      // 处理视频本地化origin发音人
      if(speakerItem.speakerType === 'origin') {
        this.modeType = 'Preview sample'
        this.currentSpeaker = speakerItem
        this.speedValue = 1
        this.pitchValue = 1
        this.currentSelectEmotionIndex = 0
        this.speakerEmotionCacheVOList = [speakerItem]
        this.isShow = true
        return
      }
      this.speakerInfo=speakerItem
      if (Array.isArray(params)) {
        this.listenParams = params
      }
      const speaker = this.allSpeakersLineMap[speakerItem.speaker.split('@')[0]]
      const _speakerInfo =  speaker ? speaker : speakerItem
      if (!_speakerInfo) {
        console.log('openDetail---找不到发音人')
        return
      }
      if(speakerItem.speakerType === 'clone' || speakerItem.speakerType === 'avatar') {
        this.modeType = 'Preview sample'
      }
      if(speakerItem.params){
        this.speedValue =  Number(speakerItem.params.speed) 
        this.pitchValue =  Number(speakerItem.params.pitch)
        this.paramsEmotion=speakerItem.params.emotion
      }else{
        this.speedValue =  typeof speakerItem.speed === 'number' ? speakerItem.speed : 1
        this.pitchValue =  typeof speakerItem.pitch === 'number' ? speakerItem.pitch : 0
      }
      //更新语调语速
      this.$emit('change-speed', this.speedValue)
      this.$emit('change-intonation', this.pitchValue)
      ;(this.speakerDetailCache[_speakerInfo.speakerId]
        ? Promise.resolve({
            data: {
              code: 200,
              data: this.speakerDetailCache[_speakerInfo.speakerId]
            }
          })
        : getSpeakerDetail({
            speakerId: _speakerInfo.speakerId
          })
      ).then(res => {
        if (cb) cb(res.data.data)
        const result = res.data
        if (result.code === 200) {
          this.addSpeakerDetailCache(JSON.parse(JSON.stringify(result.data)))
          const speakerInfo = result.data
          this.collectSpeakerInfo=result.data
          this.currentSpeaker = speakerInfo
          const speakerEmotionCacheVOList = speakerInfo.speakerEmotionCacheVOList || []
          if (!this.currentSpeaker.langDemoList?.length) {
            this.currentEmotionWord = speakerEmotionCacheVOList[0]?.demo || ''
          } else {
            this.currentEmotionWord = ''
          }
          this.speakerEmotionCacheVOList = speakerEmotionCacheVOList
          let index=null
          if(speakerItem.params){
            index= this.speakerEmotionCacheVOList.findIndex(item => item.speaker.split('@')[1] === speakerItem.params.emotion)
            if(index===-1){
              index=0
            }
          }else{
            index = this.speakerEmotionCacheVOList.findIndex(item => item.speaker === speakerItem.speaker)
          }
          if (index !== -1) {
            this.currentSelectEmotionIndex = index
            this.$emit('select-mood-speaker', {...this.currentSpeaker, ... this.speakerEmotionCacheVOList[index]})
          } else if (isCloneSpeaker(this.currentSpeaker)) {
            this.currentSelectEmotionIndex = 0
            this.$emit('select-mood-speaker', { ...this.currentSpeaker })
          }
          this.domainNameList = speakerInfo.domainName
          if (this.domainNameList.length > 4) {
            this.domainNameList = this.domainNameList.splice(0, 4)
          }
          this.getFavorite()
          this.isShow = true
        }
      })
    },
    /**
     * 选择情绪试听demo
     * @param {*} item 
     * @param {*} index 
     */
    audioPaly(item, index) {
      this.$emit('parentStopAudio')
      // 处理语言试听demo播放中暂停
      const langDemoList = this.currentSpeaker.langDemoList
      if (this.currentLangDemoIndex !== -1 && langDemoList && langDemoList.length) {
        langDemoList.forEach(item => {
          if (item.play) {
            this.stopAudio()
          }
        })
      }
      let audio = this.$refs.audioRef
      if (!item.play) {
        this.audioDemoUrl = item.demoUrl
      }
      if (!item.play || item.play == 2) {
        if (
          this.currentEmotionIndex != -1 &&
          this.currentEmotionIndex != index
        ) {
          this.$set(
            this.speakerEmotionCacheVOList[this.currentEmotionIndex],
            'play',
            0
          )
        }
        setTimeout(() => {
          this.currentEmotionWord = item.demo
          this.$set(this.speakerEmotionCacheVOList[index], 'play', 1)
          this.currentEmotionIndex = index
          audio.addEventListener('ended', this.__playCallback.bind(this, item))
          audio.play()
        })
      } else if (item.play == 1) {
        this.$set(this.speakerEmotionCacheVOList[index], 'play', 2)
        this.currentEmotionIndex = -1
        audio.pause()
      }
    },
    /**
     * 选择语言试听demo
     * @param {*} item 
     * @param {*} index 
     */
     audioLangDemoUrlPaly(item, index) {
      this.$emit('parentStopAudio')
      // 处理情绪试听demo播放中暂停
      if (this.currentEmotionIndex !== -1) {
        this.speakerEmotionCacheVOList.forEach(item => {
          if (item.play === 1) {
            this.stopAudio()
          }
        })
      }
      let audio = this.$refs.audioDemoUrlRef
      if (!item.play) {
        this.audioLangDemoUrl = item.demoUrl
      }
      if (!item.play) {
        if (
          this.currentLangDemoIndex != -1 &&
          this.currentLangDemoIndex != index
        ) {
          this.currentSpeaker.langDemoList[this.currentLangDemoIndex].play = false
        }
        setTimeout(() => {
          this.currentEmotionWord = item.demoDesc
          item.play = true
          audio.currentTime = 0
          this.currentLangDemoIndex = index
          audio.addEventListener('ended', this.__playDemoUrlCallback.bind(this, item))
          audio.play()
        })
      } else {
        audio.pause()
        item.play = false
        this.currentLangDemoIndex = -1
      }
      this.$forceUpdate()
    },
    __playDemoUrlCallback (item) {
      let audio = this.$refs.audioDemoUrlRef
      item.play = false
      this.audioLangDemoUrl = null
      this.currentLangDemoIndex = -1
      audio && audio.removeEventListener('ended', this.__playDemoUrlCallback)
    },
    __playCallback(emotion) {
      let audio = this.$refs.audioRef
      this.$set(emotion, 'play', 0)
      this.audioDemoUrl = null
      this.currentEmotionIndex = -1
      audio && audio.removeEventListener('ended', this.__playCallback)
    },
    stopAudio() {
      if (this.currentEmotionIndex !== -1) {
        let audio = this.$refs.audioRef
        const emotion = this.speakerEmotionCacheVOList[this.currentEmotionIndex]
        this.__playCallback(emotion)
        audio.pause()
      }
      if (this.currentLangDemoIndex !== -1) {
        let audio = this.$refs.audioDemoUrlRef
        const langDemoList = this.currentSpeaker.langDemoList
        if (langDemoList && langDemoList.length > this.currentLangDemoIndex) {
          const emotion = this.currentSpeaker.langDemoList[this.currentLangDemoIndex]
          this.__playDemoUrlCallback(emotion)
        }
        audio.pause()
      }
    },
    /**
     * 选择情绪发音人
     * @param {*} item 
     */
    selectMoodSpeakerByClick(item, index) {
      this.currentSpeaker = {...this.currentSpeaker, ...item}
      if (this.playState !== 0 && index !== this.currentSelectEmotionIndex) {
        this.listeningCancel()
        this.changePlay()
      }
      this.currentSelectEmotionIndex = index
      this.getFavorite()
      this.$emit('select-mood-speaker', this.currentSpeaker)
    },
    /**
     * 更改模式
     */
    changeMode() {
      if(this.currentSpeaker.speakerType === 'origin') {
        // 视频本地化做样音试听
        this.listenDemo()
        return 
      }
      if (this.modeType === 'Preview sample') {
        this.modeType = 'Modify setting'
      } else {
        this.modeType = 'Preview sample'
      }
      this.stopAudio()
      this.listeningCancel()
    },
    // 公共试听逻辑
    async changePlayCommon() {
      // 试听加载中
      if (this.playState === 1) return
      // 停止试听
      if (this.playState === 2) {
        try {
          await this.onPauseAudion()
        } catch (e) {
          console.log('停止试听出错', e)
        } finally {
          this.playState = 0
        }
        return
      }
      // 启动试听
      this.playState = 1
      try {
        await this.onPlayAudion({
          speakerInfo: {
            speaker: this.currentSpeaker.speaker,
            speed: this.speedValue,
            pitch: this.pitchValue
          },
          onAuditionStart: () => {
            this.playState = 2
          },
          onAuditionEnd: () => {
            this.playState = 0
          },
          onAuditionError: (e) => {
            console.log('试听出错', e)
            this.playState = 0
          }
        })
      } catch (e) {
        console.log('试听出错', e)
        this.playState = 0
      }
    },

    listenDemo() {
      console.log(this.currentSpeaker, 'this.currentSpeaker');
      if(this.currentSpeaker.demoUrl) {
        if(audioElement) {
          audioElement.pause()
          audioElement.src = ''
          audioElement = null
        }
        audioElement = new Audio()
        audioElement.crossOrigin='anonymous'
        audioElement.src = this.currentSpeaker.demoUrl
        audioElement.play()
      }

    },
    async changePlay(type) {
      if (this.source !== 'ttsEditor') {
        // 非ttsEditor，试听逻辑由模块自行实现
        this.changePlayCommon()
        return
      }
      // 检查试听权限
      if(!await this.checkAuditionUserRights()){
        return 
      }
      // if (this.vipInfo.type !== 1) await this.getUserRights()
      // // 剩余的Credit == 0 && 非会员 && 非会员送的字符使用完 && appsumo（没有购买）
      // if (this.userRights.leftCredit <= 0 && this.vipInfo.type == 2 && this.userRights.freeCharNums <= 0 && this.userRights.appSumoCharNums < 0) {
      //   this.$refs.previewOutOfWords.$emit('dialog-open', {
      //     dialogVisible: true,
      //     title: this.getI18n('previewOutOfWordsFree.title'),
      //     desc: !this.userRights.threeDayCus ? this.getI18n('subscriptionExpirationFree.desc') : this.getI18n('previewOutOfWordsFree.desc', {
      //       let_textLen: '100,000'
      //     }),
      //     source: !this.userRights.threeDayCus ? 'free_ends' : ''
      //   })
      //   // 三天会员过期了
      //   if(!this.userRights.threeDayCus){
      //     stat.event('purchase_free_ends')
      //   }
      //   return
      // }
      if (this.playState === 1) return
      if (this.playState === 2 && type) {
        this.listeningCancel()
        return
      }
      const articleId = this.id || this.$route.params.id
      if (!articleId) return
      let params = {
        articleId,
        product: 'article_platform',
        speaker: this.currentSpeaker.speaker,
        speed: this.speedValue,
        pitch: this.pitchValue
      }
      this.playState = 1
      if (!this.listenParams[this.listenParamsIndex]) {
        this.listeningCancel()
        return
      }
      params = {...params, ...this.listenParams[this.listenParamsIndex]}
      delete params['speakerInfo']
      const auditionData = await http.playDemoChooseForSpeaker(params)
      if(auditionData.data.code == 200){
        let dataArr = auditionData.data.data.list
        // 播放完弹出文案
        this.playEndMes = auditionData.data.data.message
        let promiseList = dataArr.map(item => {
          return this.__getAudioBlob(item)
        })
        Promise.all(promiseList).then(res => {
          res = res.map(item => {
            return { src: item }
          })
          this.auditionArr = this.auditionArr.concat(res)
          this.auditionIndex = 0
          this.playAudio()
        })
      }else if(auditionData.data.code == 4125){
        this.listeningCancel()
        this.$emit('audition-limit-tips', auditionData.data.message)
      }else if(auditionData.data.code == 4043 || auditionData.data.code === 4044){
        // 没有字数了
        this.listeningCancel()
        this.$emit('audition-limit-tips')
      }else if(auditionData.data.code == 4079){
        // 4079 = (11labs 声音不能试听，提示对应文案)
        this.$message.error( auditionData.data.message || `${this.getI18n('errTxt.serverError')}，code59`)
        this.listeningCancel()
      } else {
        this.listeningCancel()
      }
    },
    // 获取音频的blob
    __getAudioBlob(url) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const body = { url }
          let res = await this.$http.post(http.audition, body, {
            responseType: 'blob'
          })
          //let res = await this.$http({ url, responseType: 'blob' })
          const blob = URL.createObjectURL(res.data)
          resolve(blob)
        } catch (err) {
          reject(err)
        }
      })
    },
    playAudio() {
      this.stopAudio()
      if (!this.auditionArr || this.auditionArr.length === 0) {
        this.$message.error(this.getI18n('multiSpeaker.auditionFaild'))
        this.listeningCancel()
        return
      }
      this.playState = 2
      const index = this.auditionIndex
      const data = this.auditionArr[index]
      this.speakerAudioUrl = data.blob || data.src
      this.$nextTick(() => {
        const $audio = this.$refs.audio
        $audio.play()
      })
    },
    listeningCancel() {
      this.speakerAudioUrl = ''
      // 清空试听数据
      this.auditionArr = []
      this.auditionIndex = 0
      this.playState = 0
      this.listenParamsIndex = 0
      const $audio = this.$refs.audio
      $audio && $audio.pause()
    },
    async audioEndEvt() {
      this.auditionIndex++
      // 播放结束
      if (this.auditionIndex > this.auditionArr.length - 1) {
        if (this.listenParams.length - 1 === this.listenParamsIndex) {
          // 整体播放结束
          this.listeningCancel()
          if(this.playEndMes == null) return
          this.$message.error(this.playEndMes)
          this.playEndMes = null;
        } else {
          // “本条”音频播放结束
          this.listenParamsIndex = this.listenParamsIndex + 1
          // 清空试听数据
          this.auditionArr = []
          this.auditionIndex = 0
          this.changePlay()
        }
        return
       
      }
      this.playAudio()
    },
    /**
     * 展开收起
     */
    changeUnfold() {
      this.$emit('change-unfold', !this.isUnfold)
    },
    /**
     * 改变语速
     * @param {*} speed 
     */
    selectSpeed(speed) {
      this.$emit('change-speed', speed)
      //查看发音人是否收藏
      this.getFavorite()
      if (this.playState !== 0) {
        this.listeningCancel()
        this.changePlay()
      }
    },
    /**
     * 改变语调
     * @param {*} value 
     */
    changeIntonation(value) {
      this.$emit('change-intonation', value)
      //查看发音人是否收藏
      this.getFavorite()
      if (this.playState !== 0) {
        this.listeningCancel()
        this.changePlay()
      }
    },
    /**
     * 收藏默认发音人
     */
    collectDefaultSpeaker() {
      const defaultSpeakerInfo = this.defaultSpeaker
      if (!defaultSpeakerInfo) return
      let data = {
        speakerId: defaultSpeakerInfo.speakerId
      }
      http.setFavouriteSpeaker(axios.urlParamFormat(data)).then(res => {
        if (res.data.code == 200 && res.data.message == 'OK') {
          this.$nextTick(() => {
            const isFavourite = !defaultSpeakerInfo.favourite
            this.$set(defaultSpeakerInfo, 'favourite', isFavourite)
            // this.$set(this.currentSpeaker, 'favourite', isFavourite)
            this.$emit('change-favourite', defaultSpeakerInfo)
            this.$emit('collect-favourite')
          })
        } else {
          this.$message.error(this.getI18n('errTxt.serverFail'))
        }
      })
    },
    // delete clone speaker
    async deleteVoice() {
      this.$emit('delete-voice', this.currentSpeaker)
    }
  }
}
</script>
<style lang="less" scoped>
.speaker-detail {
  position: relative;
  width: calc(100% - 10px);
  background: #1C1C1C;
  border-radius: 16px;
  box-shadow: inset 0px 0px 0px 0px rgba(255,255,255,0.2);
  .unfold-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    top: calc(50% - 40px);
    left: -20px;
    width: 27px;
    height: 134px;
    cursor: pointer;
    line-height: 134px;
    transform: translateY(-50%);
    background: url('./assets/images/unflod_bg.svg') no-repeat center;
    .pickup-icon {
      margin-left: 8px;
      width: 14px;
      height: 24px;
      border-radius: 4px;
      &:hover {
        background: rgba(255,255,255,0.1);
      }
    }
    .fold-icon{
      margin-left: 8px;
      width: 14px;
      height: 24px;
      border-radius: 4px;
      background: url(./assets/images/fold_icon.svg) center/contain;
      transform: rotate(180deg);
      &:hover {
        background-color: rgba(255,255,255,0.1);
      }
      &.isUnfold{
        transform: rotate(0);
      }
    }
  }
  &-container {
    padding-bottom: 18px;
    max-height: 510px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  :deep(&-container::-webkit-scrollbar) {
    width: 0;
    height: 0;
  }
  &-header {
    padding-top: 20px;
    box-sizing: border-box;
    .avatar-wrapper {
      display: flex;
      align-items: center;
      .avatar-info {
        position: relative;
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border-radius: 18px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
        .favorite-button {
          position: absolute;
          right: -8px;
          bottom: -4px;
          z-index: 2;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #FFFFFF;
          background-color: rgba(255,255,255,0.1);
          background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_not_favorite.png');
          background-size: 28px 28px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          backdrop-filter: blur(5px);
          cursor: pointer;
          &.favorite {
            background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_favorite.png');
          }
        }
      }
      .speaker-name-wrapper {
        margin-left: 16px;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        .speaker-name {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 22px;
          width: 118px;
        }
        .no-unfold-name {
          width: 42px;
        }
        .speaker-usage-24{
          display: flex;
        }
        .k-item {
          display: inline-block;
          height: 20px;
          font-size: 12px;
          font-weight: 400;
          color: #FFD88D;
          line-height: 20px;
          text-align: center;
          background: rgba(255,216,141,0.2);
          border-radius: 6px;
          padding:0 4px;
        }
        .usage{
          display: inline-block;
          height: 20px;
          font-size: 12px;
          color: #FFA299;
          line-height: 20px;
          background: rgba(255, 162, 153, 0.20);
          border-radius: 6px;
          padding:0 4px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          img{
            margin-right: 2px;
          }
        }
      }
      .audition-box {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background-image: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/toolbar_play_button_background.png);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover {
          background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/toolbar_play_button_hover_background.png');
        }
        .play-icon, .pause-icon, .loading-icon  {
          margin-left: 1px;
          width: 30px;
          height: 30px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_play.png');
        }
        .pause-icon {
          background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_pause.png');
        }
        .loading-icon {
          background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_loading.png');
          animation: auditionButtonLoadingFlash 800ms linear infinite;
        }
      }
      .unfold-audition-box {
        margin-right: -14px;
      }
    }
    .behavior {
      margin: 8px 0;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255,255,255,0.6);
      line-height: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .unfold-behavior {
      margin-top: 12px;
      margin-bottom: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      white-space: inherit;
    }
    .domain-wrapper {
      display: flex;
      flex-wrap: wrap;
      .domain-item {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 3px 8px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        background: rgba(255,255,255,0.1);
        border-radius: 6px;
        box-sizing: border-box;
      }
    }
    .divider {
      margin: 16px 0;
      margin-top: 8px;
      width: 100%;
      height: 1px;
      background: rgba(255,255,255,0.2);
    }
    .emotion-text {
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17px;
      user-select: text;
      word-break: keep-all;
    }
    .emotion-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-left: -16px;
      align-content: flex-start;
      .emotion-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 16px;
        margin-bottom: 16px;
        box-sizing: border-box;
        height: fit-content;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.7);
        line-height: 22px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        .listen-btn {
          margin-left: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          line-height: 16px;
          background-image: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/audio_emition_play_button_background.png);
          border-radius: 8px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          .play-btn {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-image: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_play.png);
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
          }
        }
      }
      .emotion-item-select {
        color: #3583FB;
        &:hover {
          color: #3583FB;
        }
      }
      .emotion-item-select::after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        width: 16px;
        height: 16px;
        background: url('./assets/images/select_icon.svg') no-repeat center;
        transform: translateY(-50%);
      }
      .emotion-item-active {
        background: #3583FB;
        border: 1px solid #FFFFFF;
        .listen-btn {
          .play-btn {
            background-image: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_pause.png);
          }
        }
      }
    }
    :deep(.speed-pitch-wrapper) {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .speed-pitch {
        flex-shrink: 0;
        width: calc(50% - 26px);
        .input-wrapper {
          display: flex;
          align-items: center;
          .label {
            margin-right: 16px;
            display: inline-block;
            color: #fff;
            opacity: 0.4;
            white-space: nowrap;
          }
          .speed-pitch-input {
            flex: 1;
            .el-input-number__decrease, .el-input-number__increase {
              display: none !important;
            }
          }
          .el-input-number {
            width: 109px;
            line-height: 38px;
          }
          .el-input__inner {
            outline: none;
            border: none;
            width: 109px;
            height: 30px;
            font-size: 12px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 17px;
            text-align: center;
            background: #2B2B2B;
            border-radius: 6px;
            padding: 0;
            border: 1px solid transparent;
            &::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }
            &:hover {
              border: 1px solid rgba(255,255,255,0.3);
            }
            &:focus {
              border-color: #3583FB;
            }
          }
        }
      }
    }
    :deep(.no-unfold-input-wrapper) {
      flex-wrap: nowrap;
      .speed-pitch {
        .input-wrapper {
          color: #fff;
          .el-input-number {
            width: 32px;
          }
          .el-input {
            width: 32px;
            .el-input__inner {
              width: 32px;
            }
          }
        }
      }
    }
    .collection-parameter{
      padding: 3px 0;
      padding-left: 3px;
      background: #2B2B2B;
      border-radius: 6px;
      border: 0.5px solid #4D4D4D;
      margin-left: 24px;
      margin-top: 20px;
      display: inline-block;
      .item{
        display: flex;
        img{
          width: 20px;
          height: 20px;
        }
        .parameter{
          display: inline-block;
          width: 105px;
          height: 20px;
          background: #1A1A1A;
          border-radius: 4px;
          margin-left: 3px;
          color: rgba(255,255,255,0.45);
          font-size: 12px;
          padding: 0 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Poppins-Regular";
          .emotion{
            max-width: 54px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .collect-btn{
          display: inline-block;
          font-size: 12px;
          font-family: "Poppins-Regular";
          color: #FFFFFF;
          margin: 0 12px;
          cursor: pointer;
          padding: 1px 0px;
          border-radius: 4px;
        }
      }
      &:hover{
        .item{
          .collect-btn{
            background: rgba(255,255,255,0.1);
          }
        }
      }
    }
    .no-unfold-collection-parameter{
      margin-left: 24px;
      .item{
        .parameter{
          width: 81px;
          .emotion{
            width: 30px;
          }
        }
        .collect-btn{
          margin: 0 2px;
          padding: 1px 4px;
        }
      }
    }
    .collection-tip{
      font-size: 12px;
    }
  }
  .no-unfold-emotion-list {
    max-height: 230px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      width: 3px;
      border-radius: 4px;
      background: fade(#ffffff, 30%);
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
  .upgrade-btn, .listen-article-btn {
    position: absolute;
    bottom: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    font-size: 16px;
    font-weight: 500;
    color: #040D1A;
    line-height: 22px;
    transform: translateY(-100%);
    background: linear-gradient(90deg, #F3F0FF 0%, #FFB8FC 100%);
    border-radius: 16px;
    border: 1px solid #1C1C1C;
    box-sizing: border-box;
  }
  .upgrade-btn {
    position: relative;
    cursor: pointer;
    &:hover {
      .upgrade-btn-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: rgba(53,70,191,0.2);
      }
      
    }
  }
  .listen-article-btn {
    position: relative;
    background-image: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/listen_article_background.png);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 1px solid #1C1C1C;
    box-sizing: border-box;
    cursor: pointer;
    .listen-article-btn-bg {
       position: absolute;
       left: 0;
       top: 0;
       width: 100%;
       height: 100%;
       &:hover {
        background: rgba(0, 0, 0, 0.1);
       }
    }
    .listen-play-bg, .play-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-image: url('./assets/images/listen_play_bg_icon.svg');
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
    .listen-play-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .play-icon, .pause-icon, .loading-icon  {
      width: 30px;
      height: 30px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_play.png');
    }
    .pause-icon {
      background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_pause.png');
    }
    .loading-icon {
      background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_editor_1699355936406/dupdub_editor_1699355936406/icon_toolbar_loading.png');
      animation: auditionButtonLoadingFlash 800ms linear infinite;
    }
  }
  .mode-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    color: #fff;
    border-radius: 16px;
    background: #3546BF;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      background: #4958c5;
    }
    .mode-icon {
      margin-left: 4px;
    }
  }
  :deep(.speaker-slider) {
    margin-bottom: 13px;
    .el-slider__bar {
      display: none;
    }
    .el-slider__runway {
      height: 2px;
      background: #404040;
      margin: 16px 0;
      .el-slider__marks-stop {
        margin-top: -2px;
        width: 1px;
        height: 6px;
        background: #404040;
      }
      .el-slider__marks-text {
        margin-top: 4px;
        font-size: 12px;
        color: rgb(102, 102, 102) !important;
        span {
          white-space: nowrap;
        }
      }
    }
    .el-slider__button-wrapper {
      top: -17px;
      .el-slider__button {
        width: 10px;
        height: 10px;
        border: none;
        background: #3583FB;
      }
    }
  }
  .speaker-slider-tooltip {
    &.el-tooltip__popper {
      &.is-dark {
        padding: 10px 0;
        width: 40px;
        text-align: center;
        background: #3583FB;
      }
    }
    &.el-tooltip__popper[x-placement^=top] {
      .popper__arrow {
        border-top-color: #3583FB;
        &::after {
          border-top-color: #3583FB;
        }
      }
    }
  }
  .min-mode {
    position: absolute;
    top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 12px;
    transform: translateY(-50%);
    background: #3546BF;
    border: 2px solid #fff;
    box-sizing: border-box;
    overflow: hidden;
  }
  .mode-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    &:hover {
      background: rgba(255,255,255,0.1);
    }
  }
  .delet-clone-speaker {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 122px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.2);
    font-size: 14px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: .9;
    }
  }
}

.audio-speaker-detail {
  margin-left: 20px;
  height: 510px;
  border-right: 2px solid #3546BF;
  box-sizing: border-box;
  .speaker-detail-container {
    height: 510px;
    padding-bottom: 80px
  }
}
.modify-setting {
  background: #3546BF;
  border-color: #1C1C1C;
  .min-mode  {
    background: #1C1C1C;
  }
  .mode-wrapper {
    background: #1C1C1C !important;
    font-family: 'Medium';
    &:hover {
      background: #333333 !important;
    }
  }
  .emotion-list {
    margin-top: 0;
    margin-left: -10px;
    .emotion-item {
      margin-left: 10px;
      margin-bottom: 8px;
      padding: 6px;
      color: #FFFFFF;
      font-weight: 400;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid rgba(255,255,255,0.2);
      &:hover {
        border-color: #fff;
      }
    }
  }
  .no-unfold-emotion-list {
    height: 272px;
    overflow-y: scroll;
  }
  .unfold-wrapper {
    background-image: url('./assets/images/unflod_blue_bg.svg');
  }
}
.p24 {
  padding: 0 24px;
  box-sizing: border-box;
}
@keyframes auditionButtonLoadingFlash {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>