import store from '@/store/index'
import { ua } from '@/utils/env'
import { getCookie, delCookie,setCookie } from '@/utils/cookie'
import api from '@/utils/http-request'
import { parseUrl, stringifyUrl } from "@/utils/url"
import { getClientId } from '@/utils/utils'
const LOGIN_PATH = '/login'
const INDEX_PATH = '/'

const getUserInfo = () => store.dispatch('userInfo/getUserInfo')
const changeVipinfo = () => store.dispatch('changeVipinfo')
const mainWebPagePathList = [
  '/',
  '/text-to-speech',
  '/talking-photo',
  '/chatgpt-writing',
  '/video-editing',
  '/speech-to-text',
  '/voice-cloning',
  '/ai-tools',
  '/solutions/marketing',
  '/solutions/content-creators',
  '/youtube-tiktok',
  '/learning-training',
  '/marketing',
  '/audio-book',
  '/podcast',
  '/customer-service',
  '/api',
  '/blog',
  '/DupdubContest',
  '/changelog',
  '/about',
  '/contact',
  '/help-detail',
  '/affiliate',
  '/privacy-policy',
  '/terms-of-service',
  '/canva',
  '/subtitle-alignment',
  '/tools/alignment',
  '/youtube-downloader',
  '/tools/video-download',
  '/ai-subtitles',
  '/record',
  '/dupdub-contest',
  '/solutions/content-creators',
  '/instant-voice-cloning',
  '/video-translation',
  '/ai-sound-effect'
]

export const createRouterFilter = (router) => {
  //处理地址栏参数
  const appUrl = location.href
  const appProps = parseUrl(appUrl).query // 页面初始参数
  const {cookiesPolicy, ...params } = appProps
  if(cookiesPolicy){
    setCookie('cookiesPolicy',true)
  }

  // 地址栏，或者三方跳入oversea域名，追加wn
  const newWn = getWn();
  if (newWn && newWn !== appProps.wn) {
    history.replaceState('', '', stringifyUrl({
      url: location.pathname,
      query: {
        ...appProps,
        wn: newWn
      }
    }))
  }
  // oversea内部跳转，追加wn
  const originPush = router.push.bind(router)
  const originReplace = router.replace.bind(router)
  const originResolve = router.resolve.bind(router)
  router.push = function (location, ...args) {
    return originPush(replaceLocation(location), ...args)
  }
  router.replace = function (location, ...args) {
    return originReplace(replaceLocation(location), ...args)
  }
  router.resolve = function (location, ...args) {
    return originResolve(replaceLocation(location), ...args)
  }
  router.beforeEach(async (to, from, next) => {
    // 从video-edit跳出或者跳转到video-edit页面
    // if (from.path.startWith('/video-translation-editor') || to.path.startWith('/video-translation-editor')) {
    // abTest
    // let home_A_or_B = localStorage.getItem('home_A_or_B') === 'false' ? false : true
    // if(home_A_or_B) {
    //   const redirectMap = {
    //     //manageHome是老的name名称，如果是B用户跳转到新的页面
    //     'manageHome':'/tools/studio-home',
    //     'talkingPhotoEditor':'/talking-avatar'
    //   }
    //   const redirectUrl = redirectMap[to.name];  
    //   if(redirectUrl){
    //     next ({path:redirectUrl,query:to.query})
    //     return
    //   }
    // }
    // api.featureAdd({
    //   featureKey: 'new_home',
    //   value: home_A_or_B,
    //   clientId: getClientId()
    // })

    if (from.path === '/video-translation-editor') {
      const toObj = router.resolve({ path: to.path, query: to.query })
      location.href = toObj.href
      return
    }
    const ww_token = getCookie('ww_token')
    if (mainWebPagePathList.includes(to.path)) {
      let wwid =JSON.parse(localStorage.getItem('userInfo') || '{}').wwid || ''
      let cookiesPolicy=getCookie('cookiesPolicy');
      const params = {
        ww_token,
        cookiesPolicy,
        wwid
      }
      let fullPath = stringifyUrl({ url: to.fullPath, query: params })
      window.location.href = process.env.VUE_APP_DUPDUB_URL + fullPath
      return
    }
    if (checkAndToMobile(to)) return
    if (to.path === '/logout') {
      sessionStorage.clear()
      await api.overseasLoginOut()
      console.log('2222', 'delCookie')
      delCookie('ww_token')
      localStorage.removeItem('userInfo')
      next({
        name: 'index'
      })
      return
    }

    // 修改keywords/description
    if (to.meta.content) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords || '')
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description || '')
    }

    // 修改canonical Url
    document.querySelector('link[rel="canonical"]').setAttribute('href', `${window.location.origin}${to.path}` || '')
    // document.querySelector('link[rel="canonical"]').setAttribute('href', to.meta.canonical || '')

    // 路由发生变化修改页面title
    document.title = to.meta.title || 'DupDub | Powerful Text to Speech Online, Generate human-like Audio In Seconds'

    next = compatResponsive(router, to, from, next)

    const isLogin = store.getters['userInfo/isLogin']


    // 目前由于海外调用接口过慢！如果存在 ww_token 即认为已登录，等进入需要登录鉴权的页面再通过 user 接口判断登录
    store.commit('userInfo/setUserLoginState', !!ww_token)

    // 第三方客服弹窗(只在home页面显示)
    // const circleRollButton = document.getElementsByClassName('circleRollButton')
    // if (circleRollButton && circleRollButton[0]) {
    //   circleRollButton[0].style.display = to.name === 'manageHome' ? 'inline-block' : 'none'
    // }
 

    // 登录页
    if (to.path === LOGIN_PATH) {
      if (ww_token) {
        try {
          // 同步获取用户信息
          await getUserInfo()
          let cookiesPolicy = getCookie('cookiesPolicy')
          let wwid=JSON.parse(localStorage.getItem('userInfo') || '{}').wwid || ''
          let mark = to.query.to.indexOf('?') > -1 ? '&' : '?'
          let urlParams = `${mark}ww_token=${ww_token}&wwid=${wwid}&cookiesPolicy=${cookiesPolicy}`
          // 音视频剪辑会使用当前跳转
          if (to.query.to.indexOf('http') > -1) {
            window.location.href = `${to.query.to}${urlParams}`
          } else {
            let pathUrl = to.query.to ? to.query.to + urlParams : INDEX_PATH + urlParams
            next({ path: pathUrl, replace: from.path == INDEX_PATH ? false : true })
          }
        } catch (error) {
          console.log('获取用户信息出错')
          next()
        }
      } else {
        next()
      }
      return
    }

    const needLogin = to.meta.auth

    try {
      if (ww_token) {
        if (needLogin || to.path === '/tools/home' || to.path === '/tools/studio-home') {
          await getUserInfo()
        }
        changeVipinfo()
        next()
      } else {
        localStorage.removeItem('userInfo')
        isLogin || !needLogin
          ? next()
          : window.location.href = `${LOGIN_PATH}?to=${to.fullPath}`
        // next({ path: LOGIN_PATH, query: { to: to.fullPath } })
      }
    } catch (error) {
      console.error('获取用户信息出错', error)
      console.log('error details', error?.message, error?.name)
      // 网络异常，允许用户进入需要登陆的页面
      // 1. 如果真的是网络异常，则下个页面也会异常
      // 2. 如果是用户点击跳转的过程中又点击了一下导致了这个用户请求被浏览器杀掉，则允许用户进入。
      //    2.1 假定用户登陆了，则不会出现问题
      //    2.2 用户未登陆，通过快速点击两下进入需要登陆的页面，那个页面必然有接口需要登陆，页面会自己异常。
      //        正常设计也不会让用户有直接从未登陆的页面进入登陆的页面入口。
      const isNetError = error?.message === 'Network Error'
      if (!isNetError) {
        store.commit('userInfo/setUserLoginState', false)
        localStorage.removeItem('userInfo')
        delCookie('ww_token')
      }

      if (needLogin && !isNetError) {
        next({ path: LOGIN_PATH, query: { to: to.fullPath } })
      } else {
        next()
      }
    }

  })
  // router.afterEach((route) => {
  //   console.log('router afterEach',route)
  //   window.userpilot.reload();
  //  });
}


function checkAndToMobile(to = {}) {
  // 判断移动端
  let isMobile = ua.mobile
  let jumpToH5 = false
  if (isMobile) {
    let mobilePath = [
      '/promote',
      '/promote-marketing'
    ]
    let pathname = to && to.path
    mobilePath.forEach(val => {
      if (pathname == val) {
        let originFullPath = (
          to.fullPath.includes('https://')
            ? to.fullPath
            : `https://${process.env.VUE_APP_MOBILE_HOST}${to.fullPath}`
        )
        originFullPath.includes('?')
          ? (originFullPath += '&isfrompc=1')
          : (originFullPath += '?isfrompc=1')
        jumpToH5 = true
        // return
        if (pathname == '/login') {
          return window.location.replace(originFullPath)
        }
        return window.location.href = originFullPath
      }
    })
  }
  return jumpToH5
}
// 在url中显式带上wwid（弱加密），方便在clarity看用户操作行为
function getWn() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  const wenwenStr = userInfo?.email || userInfo?.wwid;
  if (!wenwenStr) return '';
  const wn = btoa(wenwenStr); // atob('xxx')  
  return wn || '';
}
function replaceLocation(location) {
  const newWn = getWn();
  if (newWn) {
    if (typeof location === 'string') {
      location = stringifyUrl({ url: location, query: { wn: newWn } })
    } else if (location?.path) {
      location = {
        ...location,
        query: {
          ...location.query,
          wn: newWn
        }
      }
    }
  }
  return location
}

// 临时，在所有页面都响应式后可以去掉
// 兼容响应式页面和非响应式页面之间的跳转
// 已经适配的响应式页面
const isResponsivePath = window.isResponsivePath
let jumpTimes = 0 // 页面跳转次数
function compatResponsive(router, to, from, next) {
  jumpTimes++
  if (jumpTimes > 1) {
    // 仅后续跳转
    // 检查是否是响应式与非响应式之间的跳转
    if (isJumpBetweenResponsiveAndNonResponsive(from, to) && from.path != '/login') {
      return (loc) => {
        if (!loc) {
          // 刷新页面跳转
          location.href = to.fullPath
          return
        }
        // next重定向，再次检查
        const newTo = router.resolve(loc).route
        if (isJumpBetweenResponsiveAndNonResponsive(from, newTo)) {
          location.href = newTo.fullPath
          return
        }
        next(loc)
      }
    }
  }
  // spa页面跳转
  return next;
}

// 是否是响应式与非响应式之间的跳转
function isJumpBetweenResponsiveAndNonResponsive(from, to) {
  const fromResponsive = isResponsivePath(from.path)
  const toResponsive = isResponsivePath(to.path)
  return (fromResponsive && !toResponsive) ||// 从响应式跳转到非响应式
    (!fromResponsive && toResponsive)// 从响应式跳转到非响应式
}