// https://mobvoi.feishu.cn/docx/doxcnAlYD0y1G1pidhqKko9KVHH 文档

const DURATION = 1000 * 60 * 60 * 24 * 30
const promote_source = 'promote_source' // 自动标记的时候 标识广告平台
let platformid = ['gclid', 'fbclid'] // 谷歌自动标记
let campaignParamsConfigArr = [ // 人工标记
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'lmref'
]
/**
 * 从本地获取广告来源信息
 * @returns {string} 广告来源路径
 */
export const getLocalPromoteInfo = function() {
  let promoteObjString = localStorage.getItem('promoteObj') || null
  let oldPromoteObj = JSON.parse(promoteObjString)
  return oldPromoteObj ? oldPromoteObj.transformParams : ''
}
export const setLocalPromoteInfo = function() {
  let oldPromoteObjString = localStorage.getItem('promoteObj') || null
  let oldPromoteObj = JSON.parse(oldPromoteObjString)

  let url = window.location.href
  let allParams = getParamsFromUrl(url)
  

  if (oldPromoteObj && isOvertime(oldPromoteObj)) {
    localStorage.removeItem('promoteObj')
  }
  
  if (!allParams) return
  const urlTime = getUrlTime(url)
  if(oldPromoteObj && urlTime) {
    // 处理app网页里边传过来的 app的时间比较小（旧） return 否则覆盖之前的
    if(oldPromoteObj.time - urlTime > 0) {
      return
    }
  }
  let flag = false;
  [...platformid, ...campaignParamsConfigArr].forEach(v => {
    if(allParams.includes(v)) {
      flag = true
    }
  })
  if(!flag) return
  let campaignParamsArr = campaignParams(allParams)
  let transformParamsArr = replacePromoteid(campaignParamsArr)
  // 设置本地广告来源信息 格式：a=b&c=d&e=f
  let transformParams = transformParamsArr.join('&')
  let time = new Date().getTime()
  let promoteObj = {
    transformParams,
    time
  }
  localStorage.setItem('promoteObj', JSON.stringify(promoteObj))
}
function getParamsFromUrl(url) {
  return url.split('?')[1] || ''
}

function campaignParams(params) {
  return params
    .split('&')
    .filter(val =>
      [...platformid, ...campaignParamsConfigArr].includes(`${val.split('=')[0]}`)
    )
}

function isOvertime({ time }) {
  return new Date().getTime() - time > DURATION
}

function getUrlTime(originUrl) {
  const url = decodeURIComponent(originUrl);
  return url.includes('utm_time') ? url.split('utm_time=')[1].split('&')[0] : null
}
function replacePromoteid(campaignParamsArr) {
  let typeid = ''
  let arr = campaignParamsArr.map(val => {
    platformid.forEach(v => {
      if (val.includes(`${v}=`)) {
        typeid = v
        val = val.replace(v, 'promote_id')
      }
    })
    return val
  })
  return typeid ? [...arr, `${promote_source}=${typeid}`] : arr
}
