import { parseUrl,stringifyUrl } from '@/utils/url'
export function cookieLocalStorage(){
    // token保存30天
    // const tokenTime= 1000 * 60 * 60 * 24 * 30
    //本地存储，名称，值，过期时间
    const setCookie =(name,value,expiration)=>{
        console.log(expiration)
        // token保存天数
        localStorage.setItem(name,value)
        
    }
    //读取ww_token
    const getCookie=(name)=>{
        if(getUrlParams('ww_token') || getUrlParams('cookiesPolicy') || getUrlParams('wwid')){
            delUrlParams(['ww_token','wwid','cookiesPolicy'])
        }
        return localStorage.getItem(name)
    }
    //删除ww_token
    const delCookie=(name)=>{
        localStorage.removeItem(name)
    }
    const getUrlParams = (keyWord) => {
        const keyAfter = location.href.split(`${keyWord}=`)[1]
        let value = ''
        if (keyAfter) {
            value = keyAfter.split('&')[0]
        }
        return value
    }
    const delUrlParams = (keyWords) => {  
        let appUrl = window.location.href;   
        let appProps =parseUrl(appUrl).query // 页面初始参数
        for (let keyWord of keyWords) {  
            delete appProps[keyWord];  
        }  
        history.replaceState('', '', stringifyUrl({
            url: window.location.pathname,
            query: appProps
        }))   
    }
    return {
        getCookie,
        setCookie,
        delCookie,
        delUrlParams
      }
}
