<template>
  <div :class="[{styleClass},{'black-five':!pricingType}]">
    <ul class="rights">
      <template v-if="rightList.top">
        <li v-for="(item, i) in rightList.top" :key="i+'top'" :class="['price-right', !item.hasOwnProperty('type') || item.hasOwnProperty('type') && item.type === selectedInterval ? 'show' : 'hide']">
          <template v-if="item.desc">
            <iconpark-icon name="gou-price"  :color="!pricingType?`${rightList.color}`:''" size="14"></iconpark-icon>
            <div class="desc-tip">
              <p v-html="item.desc"  :class="[{'block-five':vipType!==1}]"></p>
              <span v-if="item.showTip"><el-popover
                placement="top"
                trigger="hover"
                popper-class="price-tooltip-item"
                effect="dark">
                <div v-html="item.tipText"></div>
                <iconpark-icon name="tip-price" size="20" slot="reference" :class="[{'block-five-icon':vipType!==1}]"></iconpark-icon>
              </el-popover></span>
            </div>
          </template>
        </li>
      </template>
    </ul>
    <template v-if="rightList.content">
      <div v-for="(item,index) of rightList.content" :key="index">
        <h3 v-if="item.title" class="price-h3" :class="[{'price-h3-black':vipType!==1}]">{{item.title}}</h3>
        <ul v-if="item.bot" class="rights">
          <template v-for="(item1,index1) of item.bot" >
          <li :key="index1" class="price-right" v-if="selectedInterval === item1.type || !item1.type">
            <iconpark-icon name="gou-price" :color="!pricingType?`${rightList.color}`:''"  size="14"></iconpark-icon>
            <div class="desc-tip">
              <p v-html="item1.desc" :class="[{'block-five':vipType!==1}]"></p>
              <span v-if="item1.showTip">
                <el-popover
                  placement="top"
                  trigger="hover"
                  popper-class="price-tooltip-item"
                  effect="dark">
                    <div v-html="item1.tipText"></div>
                    <iconpark-icon name="tip-price" size="20" slot="reference" :class="[{'block-five-icon':vipType!==1}]"></iconpark-icon>
                </el-popover>
              </span>
            </div>
          </li>
        </template>
        </ul>
      </div>
    </template>
    <template v-if="rightList.note">
      <template v-for="(item,index) of rightList.note">
        <div class="price-note" :key="index" v-if="selectedInterval === item.type || !item.type" >
          {{ item.desc }}
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { VIP_TYPE, PRODUCT_TYPE, VIP_INTERVAL } from '@/constants/vip.js'
export default {
  props: {
    pricingType:{
      type:Boolean
    },
    selectedInterval: {
      type: String
    },
    productType: {
      //type: Number, credits购买不是number
      default: PRODUCT_TYPE.SUBSCRIPTION
    },
    vipType: { 
      // 前端自定义的
      // 998是pay as you go的enterprise的type值 
      // 999是购买credits的type值 
      type: Number,
      default: VIP_TYPE.FREE
    },
    styleClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      VIP_TYPE,
      VIP_INTERVAL
    }
  },
  computed: {
    rightList() {
      switch (this.vipType) {
        case VIP_TYPE.PERSONAL:
          return this.$t(`pricing.personalRights`)
        case VIP_TYPE.PROFESSIONAL:
          return this.$t(`pricing.professionalRights`)
        case VIP_TYPE.ULTIMATE:
          return this.$t(`pricing.ultimateRights`)
        case 999:
          return this.$t(`pricing.creditRights`)
        case 998:
          return this.$t(`pricing.enterpriseRights`)
        default:
          if (this.productType === PRODUCT_TYPE.LIFETIME_PAYMENT) {
            return this.$t(`pricing.lifetimeRights`)
          } else {
            return this.$t(`pricing.freeRights`)
          }
      }
    }
  }
}
</script>
<style lang="less">
.popular-plan-item{
  li{
    &.line{
      background: #DBD7FE!important;
    }
  }
}
.el-popover.price-tooltip-item{
  width: 300px;;
  background: #263446!important;
  line-height: 20px!important;
  border-radius: 8px!important;
  color: #FFFFFF!important;
  font-size: 12px!important;
  border: none!important;
  box-shadow: none!important;
  padding: 8px 12px 8px!important;
  text-align: inherit;
  word-break: break-word;
  z-index: 6001!important;
  &[x-placement^=top] .popper__arrow{
    bottom: -5px!important;
    border-top-color: #263446!important;
    &::after{
      border-top-color: #263446!important;
    }
  }
  h3{
    font-size: 14px;
  }
  p{
    font-size: 12px;
    word-break: break-word;
    margin-bottom: 8px;
    em{
      color: #2A85FF;
    }
  }
}
</style>
<style lang="less" scoped>
.bgx(@name, @dir:'../../../assets/img/index/pricing-success/') {
  background-size: cover;
  background-image: url('@{dir}@{name}@2x.png');
  background-image: -webkit-image-set(
    url('@{dir}@{name}.png') 1x,
    url('@{dir}@{name}@2x.png') 2x
  );
}
ul,
li {
  box-sizing: border-box;
}
.price-h3{
  font-weight: bold;
  color: #001126;
  line-height: 22px;
  font-size: 14px;
  padding-bottom: 16px;
  padding-top: 12px;
  font-family: Poppins-SemiBold, Poppins;
}

.price-note{
  font-size: 12px;
  font-family: Poppins-Medium;
  font-weight: 500;
  color: #5C616A;
  line-height: 22px;
  word-break: break-word;
  font-style:oblique;
  margin-top: 30px;
}

.rights{
  li{
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  min-height: 22px;
  &.title{
    font-size: 14px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #001126;
    line-height: 22px;
  }
  &.line{
    width: 100%;
    height: 1px;
    background: #F0F1F3;
    margin-bottom: 24px;
    min-height: 1px;
    margin-top: 8px;
  }
  iconpark-icon{
    margin-right: 6px;
    margin-top: 3px;
    color: #5C616A;
  }
  p{
    font-size: 14px;
    font-family: Poppins-Medium;
    font-weight: 500;
    color: #5C616A;
    line-height: 22px;
    word-break: break-word;
  }
  &.price-right{
    &.hide{
      display: none;
    }
    .desc-tip{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      iconpark-icon{
        margin: 0;
        margin-left: 2px;
        color: #5C616A;
      }
    }
    span{
      height: 22px !important;
      width: 22px !important;  
    }
  }
  }
}
// 黑五样式
.black-five{
  
}
</style>
