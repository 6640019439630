
/**
 * 添加axios缓存功能
 * options.useCache 1. 缓存重复请求结果 2. 合并并发的重复请求
 * options.mergeRequest 仅合并并发的重复请求
 */
import axios from 'axios';
import { stringifyObj } from '@/utils/url.js';
import { joinUrl } from "@/utils/url";

const rawAdapter = axios.defaults.adapter

export default function createCacheableAxios() {

    const axiosCache = window.commonAxiosCache || new Map()
    window.commonAxiosCache = axiosCache
    const cache = axiosCache;

    function getRequestKey(config) {
        let key = config.id
        if (!key) {
            const url = /^http/.test(config.url) ? config.url : joinUrl(config.baseURL, config.url)
            key = `${url}?method=${config.method.toLowerCase()}&params=${stringifyObj(config.params)}&data=${stringifyObj(config.data)}`
        }
        return key
    }

    function axiosCacheAdapter() {
        return config => {
            const adapter = rawAdapter
            let { useCache = false, mergeRequest = false } = config;

            if (useCache || mergeRequest) {

                const key = getRequestKey(config)

                let responsePromise = cache.get(key);



                if (!responsePromise) {

                    responsePromise = (async () => {

                        try {
                            const res = await adapter(config);
                            // 复制一份，防止调用方副作用污染
                            responsePromise.resultCopy = JSON.stringify(res)
                            if (!useCache && mergeRequest) {
                                // 仅合并请求
                                cache.delete(key)
                            }
                            return res
                        } catch (reason) {
                            cache.delete(key)
                            throw reason;
                        }

                    })();

                    cache.set(key, responsePromise);

                    return responsePromise;
                }

                return responsePromise.then(() => {
                    return JSON.parse(responsePromise.resultCopy)
                });
            }

            return adapter(config);
        }
    }

    const cacheableAxios = axios.create({
        adapter: axiosCacheAdapter()
    })
    cacheableAxios.interceptors.response.use(function (response) {
        if (response.data && response.data.code !== 200) {
            // 业务code非200 删除缓存
            const key = getRequestKey(response.config)
            cache.delete(key)
        }
        return response;
    }, function (error) {
        return Promise.reject(error);
    });
    return cacheableAxios
}