export const routes = [
  {
    path: '/login',
    name: 'loginPage',
    component: () => import('@/pages/account/index'),
    meta: {
      title: 'Login Page | DupDub, Best Text To Speech Studio',
      content: {
        keywords: 'Login, Google, Facebook, Email',
        description: 'We support Google, Facebook and Email Login. DupDub is Trusted by 1 Million Users Worldwide'
      },
      canonical: 'https://app.dupdub.com/login',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/email-sign-up',
    name: 'emailSignUp',
    component: () => import('@/pages/account/email-sign-up.vue'),
    meta: {
      title: 'Sign Up Page | DupDub, Best Text To Speech Studio',
      content: {
        keywords: 'Signup, Email',
        description: 'We support sign up by email'
      },
      canonical: 'https://app.dupdub.com/email-sign-up',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
]
