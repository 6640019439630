/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: 'home',
    name: 'manageHome',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/manage/home/newIndex.vue'),
    meta: {
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/home',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  {
    path: 'article',
    name: 'articleList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/article-list/index.vue'),
    meta: {
      title: 'Powerful Voice Studio | DupDub, Convert Articles to Text to Speech Audio Narrations',
      content: {
        keywords: 'convert, text to speech, text to audio, narration',
        description: 'Over 300 AI voices trained in more than 40+ languages and accents. Text-to-Speech makes your content accessible.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/article',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  {
    path: 'project',
    name: 'projectList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/project-list/index.vue'),
    meta: {
      title: 'AI Powered Video Editing Platform - DupDub',
      content: {
        keywords: 'AI-powered video editing, automated video editing, AI subtitles, AI voiceover, video editing software, advanced video editing tools, intelligent video editing solution, automatic video enhancement, easy video editing, professional-quality videos, user-friendly interface, DupDub AI',
        description: 'Experience the next level of video editing with DupDub, an AI-powered platform that revolutionizes the way you produce and polish your videos. Our advanced AI capabilities bring automation and intelligence to every step of the editing process, making it faster, more efficient, and incredibly accurate. Transform ordinary videos into extraordinary masterpieces effortlessly, harnessing the unbeatable power of AI. Try DupDub today and witness the seamless blend of technology and creativity.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/project',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  {
    path: 'talkingphoto',
    name: 'taikingList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/talking-photo-list/index-web.vue'),
    meta: {
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/talkingphoto',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  {
    path: 'videotranslation',
    name: 'videotranslationList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/video-translation/list.vue'),
    meta: {
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/videotranslation',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  // {
  //   path: 'talkingphotoWeb',
  //   name: 'taikingListWeb',
  //   component: () => import(/* webpackChunkName: "manage" */ '@/pages/talking-photo-list/index-web.vue'),
  //   meta: {
  //     // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
  //     canonical: 'https://www.dupdub.com/tools/talkingphoto',
  //     // 是/否需要校验登录
  //     auth: true,
  //     // 是/否有 header
  //     isOwnHeader: true,
  //     // 是/否有 aside
  //     isOwnAside: true
  //   }
  // },
  {
    path: 'transcription-list',
    name: 'transcriptionList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/transcription-list/index.vue'),
    meta: {
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://www.dupdub.com/tools/transcription-list',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  {
    path: 'lexicon',
    name: 'LexiconList',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/lexicon/list.vue'),
    meta: {
      title: 'Powerful Voice Studio | DupDub, Customized Lexicon as YOU Like',
      content: {
        keywords: 'lexicon, new feature, customization',
        description: 'AI Voiceover & Text to Speech Platform with human-like voices. 400+ voices in different languages, styles and emotions can be chosen.'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/lexicon',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  },
  // 个人中心
  {
    path: 'personal-center',
    name: 'personalCenter',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/personal-center/index.vue'),
    meta: {
      title: 'DupDub Help | AI Powered Human-like Voices',
      content: {
        keywords: 'convert, text to speech, text to audio, narration',
        description: 'Personal Center for DupDub, includes basic account information'
      },
      // 此字段为 SEO 相关，值为当前路由的 fullpath（不带参数），将在路由守卫添加到 link[ref="canonical"] 标签（*必填）
      canonical: 'https://app.dupdub.com/tools/personal-center',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 aside
      isOwnAside: true
    }
  }
]
