// 会员类型
export const VIP_TYPE = {
  FREE: 2, // 免费版
  PERSONAL: 0, // 个人版
  PROFESSIONAL: 1, // 专业版
  ULTIMATE: 3 //企业
} 
// 获取audio的时长
export const dealSoundTime = item => {
  const time = Math.floor(item)
  const minute = time / 60
  let minutes = Math.floor(minute)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  let seconds = time % 60
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${minutes}:${seconds}`
}