import Vue from 'vue';
import store from '../store/index'
import { initGlobalState } from 'qiankun';
import { logout } from '../assets/js/util';
// 子应用中的新手引导标识
export const action = initGlobalState({});
export const apps = [
  {
    name: 'DupdubEditorApp',
    devEntry: '//test.dupdub.com:9000/',
    sitEntry: '//dupdubeditor-sit.dupdub.com/',
    preEntry: '//dupdubeditor-pre.dupdub.com/',
    prdEntry: '//dupdubeditor.dupdub.com/',
    activeRule: '/overview/article-voice',
    props: {
      baseApp: 'moyin',
      action,
      apis: {
        showQuestionnaire() {
          store.commit('setShowQuestionnaire', true)
        },
        onSubscribeVipAfterLogin(payload) {
          return store.dispatch('userInfo/subscribeVipAfterLogin', payload)
        },
        // 会员升级弹窗
        upgradePop(isDialogVisible, statisticsVal){
          store.commit('userInfo/setShowPriceModal', [isDialogVisible, statisticsVal])
        },
        // $5 ab测试 触发弹框的逻辑
        abTest5(data){
          Vue.prototype.$bus.$emit('couponStatus', data)
        },
        logoutMain() {
          logout()
        },
        // 打开lexicon弹框
        lexicon () {
          Vue.prototype.$bus.$emit('lexicon', true)
        },
        aiSoundEffectHandler() {
          Vue.prototype.$bus.$emit('aiSoundEffect', {
            source: 'ttseditor'
          })
        }
      }
    }
  }
]
