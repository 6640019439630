// index layout
import { routes as IndexRoutes } from './modules/index/_routes'
import { routes as HomeRoutes } from './modules/home/_routes'
import { routes as ProductsRoutes } from './modules/products/_routes'
import { routes as ResourcesRoutes } from './modules/resources/_routes'
import { routes as OthersRoutes } from './modules/others/_routes'
import { routes as EditorRoutes } from './modules/editor/_routes'
// manage layout
import { routes as ManageRoutes } from './modules/manage/_routes'

import { routes as MovedRoutes} from './move-routes'
import { routes as AiWritingEditorRoutes} from './modules/ai-writing-editor/_routes'
export const routes = [
  {
    path: '/',
    component: () => import('@/layout/index/layout.vue'),
    meta: {
      title: 'IndexLayout'
    },
    children: [
      ...IndexRoutes,
      ...HomeRoutes,
      ...ProductsRoutes,
      ...ResourcesRoutes,
      ...OthersRoutes,
      ...EditorRoutes,
      ...MovedRoutes,
      ...AiWritingEditorRoutes
    ]
  },
  {
    path: '/tools',
    component: () => import('@/layout/manage/layout.vue'),
    meta: {
      title: 'ManageLayout'
    },
    children: [
      ...ManageRoutes
    ]
  },
  {
    path: '/404',
    name: 'notFonudPage',
    component: () => import('@/pages/404'),
    meta: {
      canonical: 'https://app.dupdub.com/404',
      // 是/否需要校验登录
      auth: false
    }
  },
  {
    path: '*',
    name: 'default',
    component: () => import('@/pages/404')
  }
]
