<template>
    <!-- unlockConfirmTitle：解锁，unlockUpgradeTitle：升级 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="480px"
      :modal-append-to-body='true'
      :append-to-body='true'
      custom-class="desktop-pop"
    >
    <div class="content">
        <iconpark-icon @click="handleClose" size="24" name="iconclose" class="iconpark-close"></iconpark-icon>
        <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_logo.png" alt="" class="logo-img">
        <div class="title">Add DupDub to desktop</div>
        <div class="desc">Add DupDub to the desktop for quick access.</div>
        <el-button  class="confirm-btn add-desktop" @click="addDesktop">Add to desktop</el-button>
    </div>
    </el-dialog>
</template>
<script>
export default {
    data(){
        return{
            dialogVisible:false,

        }
    },
    inject:['$pwaApp'],
    mounted(){
        this.$bus.$on('pwa-desktop-dialog',this.handleDesktopPwa)
    },
    beforeDestroy(){
        this.$bus.$off('pwa-desktop-dialog', this.handleDesktopPwa)
    },
    methods:{
        handleDesktopPwa(statue){
            this.dialogVisible=statue
        },
        handleClose(){
            this.dialogVisible=false
        },
        addDesktop(){
            this.$pwaApp.deferredPrompt.prompt()
        }
    }
}
</script>
<style lang="less" scoped>
:deep(.desktop-pop){
    border-radius: 22px;
    margin: auto;
    margin-top: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        width: 480px;
        height: 420px;
        background: linear-gradient( 180deg, #D6E7FF 0%, #FFFFFF 100%);
        border-radius: 22px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .content{
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 48px;
        box-sizing: border-box;
        text-align: center;
        .iconpark-close{
            position: absolute;
            top: 20px;
            right: 0;
            cursor: pointer;
        }
        .logo-img{
            width: 120px;
            height: 120px;
            margin: auto;
            display: block;
        }
        .title{
            font-weight: 600;
            font-size: 22px;
            color: #101010;
            line-height: 33px;
            margin-top: 32px;
            font-family: "SemiBold";
        }
        .desc{
            font-size: 14px;
            color: #424242;
            line-height: 21px;
            margin-top: 12px;
        }
        .add-desktop{
            width: 268px;
            height: 56px;
            background: #076BF7;
            border-radius: 16px;
            position: absolute;
            bottom: 32px;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 17px;
            color: #FFFFFF;
            font-weight: 500;
            font-family: "Medium";
            &:hover{
                transform: translateY(-2px);
            }
        }
    }
}
</style>