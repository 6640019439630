/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  // 声音商店
  {
    path: '/voiceover-store',
    name: 'voiceStore',
    component: () => import(/* webpackChunkName: "products" */ '@/pages/voice-store-v2/index.vue'),
    meta: {
      title: 'Create Your Content: 400+ AI Voice Generator Characters Options',
      content: {
        keywords: 'ai voice generator characters',
        description: 'Explore 400+ enthralling text-to-speech voices and discover 569 unique styles. Create captivating content for your audiobook, podcast, and narrative.'
      },
      canonical: 'https://app.dupdub.com/voiceover-store',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  // 旧版 - 声音商店
  {
    // 与上面voiceover-store指向同一个页面, 保留此旧的路由只是为了保留旧的流量入口，业务上不要使用此路由
    path: '/voice-store',
    component: () => import(/* webpackChunkName: "products" */ '@/pages/voice-store-v2/index.vue'),
    name: 'voiceStoreOld',
    meta: {
      title: 'Create Your Content: 400+ AI Voice Generator Characters Options',
      content: {
        keywords: 'ai voice generator characters',
        description: 'Explore 400+ enthralling text-to-speech voices and discover 569 unique styles. Create captivating content for your audiobook, podcast, and narrative.'
      },
      canonical: 'https://app.dupdub.com/voice-store',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    // 与上面ai-tools指向同一个页面, 保留此旧的路由只是为了保留旧的流量入口，业务上不要使用此路由
    path: '/tools-list',
    name: 'toolsListOld',
    component: () => import(/* webpackChunkName: "products" */ '@/pages/products/products-tools'),
    meta: {
      title: 'Boost Productivity with Instant Video Transcription, Translation, Alignment, and Downloads',
      content: {
        keywords: 'video transcription, audio to text, transcribe video, translate text to speech, subtitle alignment, speech-to-text, YouTube downloader, TikTok Downloader, productivity tools, content creation, podcast transcription, foreign language voiceover, multilingual video, enterprise training tutorials, video clipping, marketing videos',
        description: 'Streamline your content creation process with our comprehensive video toolkit! Automatically transcribe video/audio files to editable text, translate into 30+ languages with speech conversion, align subtitles effortlessly, and download videos from popular platforms. Perfect for podcasters, video creators, students, and businesses seeking efficient solutions.'
      },
      canonical: 'https://app.dupdub.com/tools-list',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/technology-article',
    component: () => import(/* webpackChunkName: "products" */ '@/pages/products/technology-article.vue'),
    meta: {
      title: 'Discover the Power of MeetHiFiVoice: Human Parity Achieved with DupDub\'s Fifth-Generation TTS Engine by Mobvoi!',
      content: {
        keywords: 'DupDub, MeetHiFiVoice, TTS engine, AI dubbing, human parity, Mobvoi, voice cloning, premium audio content, end-to-end TTS solution, MeetVoice, TTS model, voice experience',
        description: 'Experience the revolutionary MeetHiFiVoice, Mobvoi\'s fifth-generation TTS engine, making AI dubbing indistinguishable from real humans. Unlock the potential of DupDub and delve into the world of premium audio content creation. Achieve voice cloning and perfect voice experiences effortlessly with MeetHiFiVoice technology.'
      },
      canonical: 'https://app.dupdub.com/technology-article',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/transcription',
    name: 'transcription',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/tools/textGet/text-get.vue'),
    meta: {
      title: 'Productivity Tools | DupDub, Automatically Transcribe Video/Audio to Editable Text',
      content: {
        keywords: 'transcribe, transcription, editable text',
        description: 'Speech to Text tool. By using Transcription, you don’t need to hand type anymore, and will save time, conserve human and financial resources.'
      },
      canonical: 'https://app.dupdub.com/transcription',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/transcription-result',
    name: 'transcription-result',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/tools/textGet/text-result.vue'),
    meta: {
      title: 'Productivity Tools | DupDub, Automatically Transcribe Video/Audio to Editable Text',
      content: {
        keywords: 'transcribe, transcription, editable text',
        description: 'Speech to Text tool. By using Transcription, you don’t need to hand type anymore, and will save time, conserve human and financial resources.'
      },
      canonical: 'https://www.dupdub.com/transcription-result',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/translation',
    name: 'translation',
    component: () => import(/* webpackChunkName: "manage" */ '@/pages/tools/text-translation/index.vue'),
    meta: {
      title: 'Online Translation Services | Professional Language Translation | Video transltion',
      content: {
        keywords: 'Professional translation',
        description: 'Get accurate and fast online translation services for any language. Professional translators available to help you with your translation needs. Use our advanced translation function to easily translate text in real-time. Experience reliable multilingual translation with our human-powered service.'
      },
      canonical: 'https://app.dupdub.com/translation',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/tools/transcription',
    redirect: '/transcription'
  }
]