
import api from '@/utils/http-request.js'

const state = {
  // 是/否显示活动标签
  isActivityLabelVisible: true,
  // 是/否显示活动弹窗
  isActivityPopupVisible: false,
  // 是/否显示用户访谈
  isUserInterview: true
}

const mutations = {
  setActivityLabelVisibleState(state, value) {
    state.isActivityLabelVisible = value
  },
  setActivityPopupVisibleState(state, value) {
    state.isActivityPopupVisible = value
  },
  setActivityUserInterviewState(state, value) {
    state.isUserInterview = value
  },
}

const actions = {
  async getActivityVisibleState() {
    try {
      let res = await api.getActivityVisibleStateApi()
      const { code, data, message } = res.data
      if (code === 200) {
        // 存储的 state 状态
        state.isActivityLabelVisible = !!data
        state.isActivityPopupVisible = !!data
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    } catch(err) {
      // throw new Error('Login exception')
      return Promise.reject(err)
    }
  },
  async getUserInterview() {
    try {
      let res = await api.getUserInterviewApi()
      const { code, data, message } = res.data
      if (code === 200) {
        // 存储的 state 状态
        state.isUserInterview = !!data
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    } catch(err) {
      // throw new Error('Login exception')
      return Promise.reject(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}