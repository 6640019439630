// 发音人初始化
export function speakerNormalize(targetArray = [], purchasedList = []) {
  let obj = {}
  // 发音人本体
  let defaultSpeakerIds = []
  // 非48k发音人id
  let not48KSpeakerIds = []
  // 全体发音人
  let allSpeakerIds = []
  // 全部商店发音人
  let allStoreSpeakers = []
  // 发音人idMap
  let allSpeakerIdMap = {}
  // 声音转换发音人
  let vcSpeakerList = []
  // 已购发音人
  let userSpeakerList = []
  // 发音人名字和英文名字的映射
  let nameToEngNameMap = {}
  // 分类对象
  let typeSpeakerMap = {}
  let userHasSpeakersMap = {}
  let speakerId24to48Map = {}
  let speakerId48to24Map = {}
  //声音转换下载
  let voiceTransfromNameMap = {}

  for (let i = 0; i < targetArray.length; i++) {
    let speaker = targetArray[i]
    speaker.age = ageCorrespondenceType(speaker.age)
    // 如果list中已经存在，即跳过
    if (allSpeakerIds.indexOf(speaker.speaker) > -1) continue
    allSpeakerIds.push(speaker.speaker)

    allSpeakerIdMap[speaker.speakerId] = speaker.speaker
    // 初始化 默认没有48k 音质
    if (!speaker.speaker.includes('48k')) {
      speaker.isHave48k = false
    }else{
      speaker.isHave48k = true
    }
    let speakerMoods = speaker.speaker.split('@')
    let defaultSpeaker = speakerMoods[0]
    let speakerMood = speakerMoods[1] || 'default'

    // 只考虑本体
    if (speakerMoods.length == 1) {
      // 只做24k的映射
        nameToEngNameMap[`${speaker.name}~${speaker.speakerId}`] = speaker.speaker
      // 搜集本体
      if (defaultSpeakerIds.indexOf(defaultSpeaker) == -1) {
        defaultSpeakerIds.push(defaultSpeaker)
      }
      // 已购发音人
      if (speaker.vipAuth == 3 && purchasedList.includes(speaker.speaker)) {
        userSpeakerList.push(defaultSpeaker)
      }

      //商店发音人
      if (speaker.vipAuth == 3) {
        allStoreSpeakers.push(defaultSpeaker)
      }

    }
    userHasSpeakersMap[speaker.speaker] = speaker
    // map构造
    obj[`${defaultSpeaker}`] = obj[`${defaultSpeaker}`] || {}
    let moodsObj = obj[`${defaultSpeaker}`] || {}
    moodsObj[speakerMood] = speaker
    obj[`${defaultSpeaker}`] = moodsObj
  }
  // console.log(defaultSpeakerIds, 'defaultSpeakerIds');

  not48KSpeakerIds = defaultSpeakerIds.filter(item => item.indexOf('48k') == -1)
  return {
    allSpeakersMap: obj, // 对象包含speaker speaker包含情绪
    allSpeakerIdMap,
    nameToEngNameMap,
    allStoreSpeakers,
    defaultSpeakerIds,
    not48KSpeakerIds,
    allSpeakerIds,
    vcSpeakerList,
    userSpeakerList,
    typeSpeakerMap,
    userHasSpeakersMap,
    voiceTransfromNameMap,
    speakerId24to48Map,
    speakerId48to24Map
  }
}

// 根据speaker 获取speakerItem
export function getSpeakerItemBySpeaker(speaker, allSpeakersMap) {
  if (!speaker) return null
  let speakerArray = speaker.split('@')
  let speakerMap = allSpeakersMap[speakerArray[0]]
  if (!speakerMap) return null
  if (speakerArray[1]) return speakerMap[speakerArray[1]]
  return speakerMap.default
}

/**
 * 处理发音人数据
 * @param {*} list 
 */
export function handleSpeakerData(list, totalPages) {
  const map = list.reduce((map, cur, i) => {
    const [key, mood = 'default'] = cur.speaker.split('@')
    if (!map[key]) {
      map[key] = {}
    }
    map[key][mood] = i
    return map
  }, {})
  Object.entries(map).forEach(([key, moods]) => {
    // 删除没有default的发音人
    if (!('default' in moods)) {
      Object.values(moods).forEach((i) => {
        list[i] = undefined
      })
      console.log(`${key}丢失了默认发音人!!!`)
    }
  })
  list = list.filter(Boolean)
  const allSpeakersLineMap = {}
  list.forEach(ele => {
    ele.style = ele.speaker.split('@')[1] || ''
    allSpeakersLineMap[ele.speaker] = ele
  })
  let ret = {
    list,
    allSpeakersLineMap,
  }
  if (totalPages) {
    ret.totalPages = totalPages
  }
  return ret
}

/**
 * 处理发音人结构
 * @param {*} targetArray 
 */
export function speakerStructure(targetArray = []) {
  const _targetArray = targetArray.filter(item => item.speakerEmotionCacheVOList && item.speakerEmotionCacheVOList.length > 1)
  for(let i = 0; i < _targetArray.length; i++) {
    let speaker = _targetArray[i]
    const speakerEmotionCacheVOList = speaker.speakerEmotionCacheVOList
    speakerEmotionCacheVOList.forEach(item => {
        speaker = {...speaker, ...item}
        targetArray.push(speaker)
    })
  }
  return targetArray
}

/**
 *
 * @param {Array} arr 传入需要转换的id数组
 * @param {Object} allSpeakerIdMap speakerId对英文名的映射
 * @returns {Array} id 数组对应的英文名称数组
 */
export function idArrTransformEnNameArr(arr, allSpeakerIdMap) {
  let result = []
  arr.map(val => {
    if (allSpeakerIdMap[val] && !allSpeakerIdMap[val].includes('@')) {
      result.push(allSpeakerIdMap[val])
    }
  })
  return result
}

/**
 * 年龄对应的分类
 * @param {*} age 
 */
export function ageCorrespondenceType(age = 0) {
  if (age < 12) {
    return 'Children'
  } else if (age >= 12 && age <= 25) {
    return 'Youth'
  } else if (age >= 26 && age <= 40) {
    return 'Adults'
  } else {
    return 'Seniors'
  }
}
