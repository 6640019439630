import { render, staticRenderFns } from "./speaker-detail.vue?vue&type=template&id=34dbb673&scoped=true"
import script from "./speaker-detail.vue?vue&type=script&lang=js"
export * from "./speaker-detail.vue?vue&type=script&lang=js"
import style0 from "./speaker-detail.vue?vue&type=style&index=0&id=34dbb673&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34dbb673",
  null
  
)

export default component.exports