<template>
    <div class="cookies" v-if="cookiesStatus">
        <div class="title">Cookies</div>
        <div class="desc">This webiste uses cookies to ensure you get the best experience on our website. View our <a class="nuxt-link" target="_blank" to="/privacy-policy">Privacy Policy</a> for more information.</div>
        <div class="footer">
            <el-button @click="deny" class="deny">Deny</el-button>
            <el-button @click="accept" class="accept">Accept</el-button>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            cookiesStatus:false
        }
    },
    mounted(){
        console.log(localStorage.getItem('cookiesPolicy'),'cookie政策同意')
        if(!localStorage.getItem('cookiesPolicy')){
            this.cookiesStatus=true
        }
    },
    methods:{
        deny(){
            localStorage.setItem('cookiesPolicy',true)
            this.cookiesStatus=false
        },
        accept(){
            localStorage.setItem('cookiesPolicy',true)
            this.cookiesStatus=false
        }
    }
}
</script>
<style lang="less" scoped>
.cookies{
    width: 573px;
    height: 194px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    border-radius: 16px;
    position: fixed;
    bottom: 15px;
    right: 96px;
    padding: 24px 32px;
    box-sizing: border-box;
    z-index: 9999;
    .title{
        font-size: 18px;
        font-weight: 600;
        color: #040D1A;
        line-height: 27px;
    }
    .desc{
        font-size: 16px;
        font-weight: 400;
        color: rgba(4,13,26,0.85);
        line-height: 26px;
        margin-top: 9px;
        .nuxt-link{
            color: rgba(53, 131, 251, 1);
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .footer{
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 24px;
        button{
            width: 98px;
            height: 36px;
            font-size: 16px;
            color: #3583FB;
            font-weight: 600;
            cursor: pointer;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .deny{
            border: none;
            line-height: 22px;
            &:hover{
                background-color: rgba(247, 247, 248, 1);
            }
        }
        .accept{
            border: 1px solid #3583FB;
            &:hover{
                background: rgba(53,131,251,0.1);
            }
        }
    }
}
// 小屏
@import '@/assets/css/responsive.less';
@rules: {
.cookies{
    width: 100%;
    height: 237px;
    background: #FFFFFF;
    border-radius: 16px 16px 0px 0px;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 20px;
    z-index: 999999;
    .desc{
        font-size: 14px;
        margin-top: 16px;
    }
    .footer{
        margin-top: 32px;
    }
}
}
.screen-md(@rules);
</style>