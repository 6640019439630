<template >
  <el-tooltip
    :disabled="isShow"
    effect="dark"
    :content="tooltipContent"
    placement="top"
    popper-class="ai-sound-effect-tooltip"
  >
    <div class="icon-container">
      <iconpark-icon
        :class="{current: current}"
        :name="name"
        :size="size"
      ></iconpark-icon>
    </div>
  </el-tooltip>

</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    },
    tooltipContent: {
      type: String,
      default: ''
    },
    current: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.icon-container {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
  cursor: pointer;
  .current{
    color: #076bf7;
  }
  &:hover {
    background: #ecedef;
    iconpark-icon {
      color: #076bf7;
    }
  }
}
</style>