import Vue from 'vue'
import http from '@/utils/http-request'
import { getUsableSpeakers } from "@/api/modules/speakerAudio"
import { cloneDeep } from 'lodash'
import { speakerNormalize, speakerStructure, handleSpeakerData } from '../assets/js/editor-util'
import axios from '../../../utils/axios'
let cacheMap = new Map()
let vm = new Vue()
const state = {
  speakers: [],
  totalPages: 0,
  allSpeakersLineMap: {},
  searchTagList: [],
  userSpeakerTypeMapList: [],
  historySpeakers: [],
  userHistory: [],
  searchCriteriaList: [],
  allSpeakerIds: [],
  allSpeakersMap: {},
  allSpeakerIdMap: {},
  userHasSpeakersMap: {},
  nameToEngNameMap: {},
  typeSpeakerMap: {},
  userSpeakerList: [],
  playerInit: false
}
const actions = {
  getAllSpeakers({ state, dispatch }) {
    return Promise.all([
      dispatch('getCachedSpeakerInfo'),
      dispatch('getUseHistorypeakerList'),
      dispatch('getUserHistory'),
    ]).then(res1 => {
      const [res, historyList, userHistory] = res1
      let [
        { list: usableList, allSpeakersLineMap, totalPages },
        { list: typeList, allSpeakersLineMap: typeAllSpeakersLineMap },
        userSpeakerTypeMapList,
        searchTagList,
        searchCriteriaList
      ] = res
      state.speakers = usableList
      state.totalPages = totalPages
      allSpeakersLineMap = {...allSpeakersLineMap, ...typeAllSpeakersLineMap}
      state.allSpeakersLineMap = allSpeakersLineMap
      let data = speakerNormalize([...usableList], [
      ])
      userSpeakerTypeMapList['Favorite'].map(v => {
        if (data.allSpeakersMap[v] && data.allSpeakersMap[v].default) {
          data.allSpeakersMap[v].default.isFavourite = true
        }
      })
      // 将48k 转 24k 并且去重
      let newArr = []
      let obj = {}
      // 48k和24k同时存在 去重
      userSpeakerTypeMapList['Recent'] = (userSpeakerTypeMapList['Recent'] || []).map(v => {
        let originSpeaker24k = ''
        if(v.originSpeaker) {
          if(v.originSpeaker.includes('_48k')) {
            originSpeaker24k = v.originSpeaker = v.originSpeaker.replace('_48k', '_24k')
            v.speaker = v.speaker.replace('_48k', '_24k')
            v.is48k = true
          } else {
            originSpeaker24k = v.originSpeaker
            v.is48k = false
          }
        }

        // 去重
        if (!obj[originSpeaker24k]) {
          obj[originSpeaker24k] = 1
          newArr.push(v)
        }
        return v
      })
      userSpeakerTypeMapList['Recent'] = newArr

      searchTagList = searchTagList.map((val) => {
        val.mediaSpeakerListVOList = val.mediaSpeakerListVOList.map((v1) => {
          // v1.speakerList = v1.speakerList.filter(v2 => !v2.includes('_48k'))
          v1.speakerList = v1.speakerList.filter(v2 => v2)
          return v1
        })
        return val
      })

      state.searchTagList = searchTagList
      state.userSpeakerTypeMapList = userSpeakerTypeMapList
      setStateData(state, data)

      state.historySpeakers = historyList
      state.userHistory = userHistory

      state.playerInit = true

      state.searchCriteriaList = searchCriteriaList
      state.searchCriteriaList.accent = []
    }).catch(err => {
      console.log(err, 'err');
    })
  },
  getCachedSpeakerInfo({ dispatch }) {
    const CACHE_KEY = 'cachedSpeakerInfo'
    if (cacheMap.has(CACHE_KEY)) {
      return Promise.resolve(cloneDeep(cacheMap.get(CACHE_KEY)))
    }
    return Promise.all([
      dispatch('getSearchSpeakerList'),
      dispatch('getSpeakerListByType'),
      dispatch('getUserSpeakerByType'),
      dispatch('getSpeakerByType'),
      dispatch('getSearchCriteria')
    ]).then((res) => {
      cacheMap.set(CACHE_KEY, res)
      return res
    })
  },
  /**
   * 获取发音人
   * @param {*} context 
   * @param {*} param 
   * @returns 
   */
  getSearchSpeakerList() {
    return getUsableSpeakers().then((res) => {
      if (res?.status === 200 && res.data?.code === 200) {
                let list = res.data.data.results
        const totalPages = res.data.data.totalPages
        return handleSpeakerData(list, totalPages)
      }
      throw new Error(res?.data?.message || res)
    })
  },
  /**
   * 获取不同类型发音人（收藏、最近使用、DupdubLib）
   */
  getSpeakerListByType() {
    const params = {
      type: 'DupDubLab'
    }
    return http.speakerListByType(axios.urlParamFormat(params)).then((res) => {
      const data = res.data
      if (data.code === 200) {
        let list = data.data.result
        return handleSpeakerData(list)
      }
      throw new Error(res?.data?.message || res)
    })
  },
  /**
   * 新增克隆发音人使用
   * @param {*} 
   */
  async refreshDupDubLabSpeakers({ state, dispatch }) {
    const { list = [], allSpeakersLineMap } = await dispatch('getSpeakerListByType')
    state.allSpeakersLineMap = {...state.allSpeakersLineMap, ...allSpeakersLineMap}
    const usableList = [...state.speakers, ...list]
    let data = speakerNormalize([...usableList], [
    ])
    setStateData(state, data)
  },

  getUserSpeakerByType() {
    return new Promise((resolve) => {
      http
        .getUserSpeakerByType()
        .then(res => {
          let {
            data: { data, code }
          } = res
          if (code == 200) {
            let arr = []
            data['Recent'] &&
              data['Recent'].map(v => {
                if (
                  arr.findIndex(v1 => v.originSpeaker == v1.originSpeaker) < 0
                ) {
                  arr.push(v)
                }
              })
            data['Recent'] = arr
            resolve(data || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          resolve([])
        })
    })
  },
  getSpeakerByType() {
    return new Promise((resolve) => {
      http
        .getSpeakerByType()
        .then(res => {
          let data = res.data
          if (data.code == 200) {
            resolve(data.data || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          resolve([])
        })
    })
  },
  // 获取最近x篇文章的发音人列表
  getUseHistorypeakerList() {
    return new Promise((resolve) => {
      http.useHistory2().then(res => {
        if (res.status == 200 && res.data.code == 200) {
          let keys = []
          let list = [] //  取本体去重
            ;(res.data.data || []).forEach(item => {
              let speaker = item.speaker != null ? item.speaker.split('@')[0] : ''
              if (!keys.includes(speaker)) {
                keys.push(speaker)
                item.speaker = speaker
                list.push(item)
              }
            })
          resolve(list)
        }
      })
    })
  },
  // 获取用户操作历史
  getUserHistory() {
    return new Promise((resolve) => {
      http.getUserHistory().then(res => {
        const { data, code } = res.data
        if (code === 200) {
          const parse = (str) => {
            try {
              return JSON.parse(str)
            } catch {
              return null
            }
          }
          data.speaker = parse(data.speaker)
          resolve(data)
        } else {
          resolve(null)
        }
      }).catch(() => {
        resolve(null)
      })
    })
  },
  getSearchCriteria(){
    return new Promise((resolve) => {
      http
        .getSearchCriteria()
        .then(res => {
          let data = res.data
          if (data.code == 200) {
            resolve(data.data || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          resolve([])
        })
    })
  },
}
const setStateData = (state, data, type) => {
  state.allSpeakerIds = data.allSpeakerIds
  state.allSpeakersMap = data.allSpeakersMap
  state.allSpeakerIdMap = data.allSpeakerIdMap
  state.userHasSpeakersMap = data.userHasSpeakersMap
  state.nameToEngNameMap = data.nameToEngNameMap
  state.typeSpeakerMap = data.typeSpeakerMap
  state.userSpeakerList = data.userSpeakerList
}

export default {
  namespaced: true,
  state,
  actions
}

import Vuex from 'vuex'

Vue.use(Vuex)

export const speakerAudioStore = new Vuex.Store({
  state,
  actions
})

function _mapActions(store, keys) {
  return keys.reduce((methods, key) => {
    methods[key] = (...args) => {
      return store.dispatch(key, ...args)
    }
    return methods
  }, {})
}
function _mapState(store, keys) {
  return keys.reduce((methods, key) => {
    methods[key] = () => {
      return store.state[key]
    }
    return methods
  }, {})
}
export const mapActions = _mapActions.bind(null, speakerAudioStore)
export const mapState = _mapState.bind(null, speakerAudioStore)
