/** 接口调用相关 state */

const state = {
  // 是/否成功调用了接口
  isGetActivityVisibleState: false,
  isGetCompensationCreditState: false,
  isGetNewbiesState: false,
  isGetHasCompleteQuestionState: false,
  isGetUserInterviewState: false,
}

const mutations = {
  initAllRequestState(state) {
    state.isGetActivityVisibleState = false
    state.isGetCompensationCreditState = false
    state.isGetNewbiesState = false
    state.isGetHasCompleteQuestionState = false
    state.isGetUserInterviewState = false
  },
  setGetActivityVisibleState(state, value) {
    state.isGetActivityVisibleState = value
  },
  setGetCompensationCreditState(state, value) {
    state.isGetCompensationCreditState = value
  },
  setGetNewbiesState(state, value) {
    state.isGetNewbiesState = value
  },
  setGetHasCompleteQuestionState(state, value){
    state.isGetHasCompleteQuestionState = value
  },
  setGetUserInterviewState(state, value) {
    state.isGetUserInterviewState = value
  },
}

export default {
  namespaced: true,
  state,
  mutations
}