import rawAxios from 'axios'
import { MessageBox } from 'element-ui'
import donMessage from './el-message'
import { dealSoundTime } from '@/utils/format'
import { logout } from '@/assets/js/util'
import { getCookie, delCookie } from '@/utils/cookie'
import createCacheableAxios from "./axios-cache"
import createProxiedAxios from "./axios-proxy"
// import router from '@/router/router'
// import store from '@/store/index'

const CancelToken = rawAxios.CancelToken

export const axios = createProxiedAxios(createCacheableAxios())

axios.defaults.headers.common['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

let loginModalVisible = false
// axios.interceptors.request.use(config => {
//   console.log(config, config.timeout, 'config.timeout')
//   return config
// })
axios.interceptors.response.use(
  (response) => {
    const res = response.data;

    const router = window.$baseVueRouter // 防止打包子组件，引用axios把所有页面打包进去
    const store = window.$baseVueStore // store依赖了router
    if (res.code === 2001 && !response.config.hideLoginModal) {
      console.log('4444', 'delCookie', response)
      // 未登录错误
      delCookie('ww_token')
      store && store.commit('userInfo/setUserLoginState', false)
      if (window.__baseApp === 'qimiaoyuan') { // 奇妙元中 交给奇妙元处理登出
        window.__baseApis?.onLogout()
      } else if (!loginModalVisible && location.pathname !== '/login' && (!router || router.currentRoute.meta.auth)) {
        loginModalVisible = true
        MessageBox.confirm('', {
          title: 'Log out confirmation',
          message: 'You have logged out DupDub. Click 「Cancel」to stay in this page, or log in again.',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Relogin',
          customClass: 'axios-login-modal'
        })
          .then(() => {
            location.href= `/login?to=${location.pathname}&status=login`
            // $router.push({
            //   path: '/login',
            //   query: { to: $router.currentRoute.fullPath }
            // })
          })
          .catch(() => {
            // cancel
          }).finally(() => {
            loginModalVisible = false
          })
      }
      const error = new Error('Not logged in')
      error.code = 'ELOGOUT'
      return Promise.reject(error);
     
    }
    return response;
  },
  (error) => {
    console.log('err' + error); // for debug
    return Promise.reject(error || 'Something error');
  }
);
let cancelGetRequest = {}

function get(url, config = null) {
  config?.cancel && typeof cancelGetRequest[config.cancel] == 'function' && cancelGetRequest[config.cancel]()
  return new Promise((resolve, reject) => {
    if (config && config.cancel) {
      config.cancelToken = new CancelToken(function executor(c) {
        cancelGetRequest[config.cancel] = c
      })
    }
    axios
      .get(url, config)
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

function getFullData(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

let cancelRequest = null

function post(
  url,
  body,
  config = {
    headers: { 'Content-Type': 'application/json' },
    timeout: 600000 // 1000 * 60 * 10  10分钟超时
  }
) {
  typeof cancelRequest == 'function' && cancelRequest()

  return new Promise((resolve, reject) => {
    if (config.cancel) {
      config.cancelToken = new CancelToken(function executor(c) {
        cancelRequest = c
      })
    }
    axios
      .post(url, body, config)
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

// Submit FormData
function postFile(url, formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 1000 * 60 * 30 // 30分钟超时
      })
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

// Submit FormData
function putFile(url, formData) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 1000 * 60 * 10 // 10分钟超时
      })
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

// Submit FormData ProcessEvent
function postFileWithProcess(url, formData, progressCallback) {
  return uploadFileWithProcess(url, formData, ({ speed, time, complete }) => {
    const uploadMessage = 'Uploading files...'
    let uploadInfo = `Upload speed ${speed}M/s,completed ${complete}%`
    if (time > 0) {
      uploadInfo += `, still need about ${dealSoundTime(time)}`
    }
    if (complete === 100) {
      uploadInfo = `Completed ${complete}%,processing...`
    }
    progressCallback(uploadMessage, uploadInfo)
  })
}

// UploadFile With Process
function uploadFileWithProcess(url, formData, progressCallback, timeout = 1000 * 60 * 30) {

  let startedAt = new Date()
  let startedLoaded = 0
  let token = getCookie('ww_token')

  return new Promise((resolve, reject) => {
    axios
      .post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data', 'ww_token': token },
        onUploadProgress: progressEvent => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          // 计算上传速度和预计时间
          var secondsElapsed =
            (new Date().getTime() - startedAt.getTime()) / 1000
          var loadedElapsedMb = (loaded - startedLoaded) / 1024 / 1024
          startedAt = new Date()
          startedLoaded = loaded
          const speed = (loadedElapsedMb / secondsElapsed).toFixed(2)
          progressCallback({
            speed,
            time: ((total - loaded) / 1024 / speed).toFixed(2),
            complete: ((loaded / total) * 100) | 0
          })
        },
        timeout // 默认设置30分钟超时
      })
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

function del(url) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

function delNew(url) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then(res => {
        __responseProcess(resolve, reject, res)
      })
      .catch(err => {
        __errHandler(err)
        reject(err)
      })
  })
}

// 返回结果后统一处理
function __responseProcess(resolve, reject, res) {
  const resData = res
  resolve(resData)
}

function __errHandler(err) {
  let errTxt
  const response = err.response
  if (response && response.status === 404) {
    errTxt = `Server Error: ${response.statusText}`
  } else {
    if (response && response.data) {
      const { status, message } = response.data
      if (status === 150004) {
        errTxt = message
        setTimeout(() => logout(), 500)
      }
      if (errTxt) {
        donMessage.error(errTxt || 'Server error, please try again later')
      }
    } else {
      errTxt = 'Server error, please try again later'
    }
  }
  // alert(errTxt)
  console.log(errTxt)
}

function nativeXhrRequest({ url, method = 'GET', success, error }) {
  let xhr = new XMLHttpRequest()
  xhr.open(method, url)
  xhr.onreadystatechange = () => {
    if (xhr.readyState == 4) {
      if (xhr.status == 200) {
        success(xhr.response)
      }
      if (xhr.status >= 400) {
        error(xhr.status)
      }
    }
  }
  xhr.onerror = () => {
    error('request, error')
  }
  xhr.send()
}
const urlParamFormat = data => {
  let res = ''
  let keyArr = Object.keys(data)
  if (keyArr.length === 0) return ''
  keyArr.forEach((val, index) => {
    res = `${res}${index == 0 ? '?' : '&'}${val}=${data[val]}`
  })
  return res
}
export default {
  get,
  getFullData,
  post,
  postFile,
  postFileWithProcess,
  uploadFileWithProcess,
  put,
  putFile,
  del,
  nativeXhrRequest,
  urlParamFormat,
  delNew
}
