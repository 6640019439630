/**
 * @param uaText
 * @constructor
 */
export const UATest = (uaText) => {
    // 操作系统
    const android = /Android/.test(uaText);
    const iOS = /(iPad|iPhone|iPod)\s+OS\s([\d_.]+)/.test(uaText);
    const mac = /Macintosh/.test(uaText)
    const win = /Windows/.test(uaText)
    const pc = !android && !iOS;
    const system =(()=>{
        if(/Macintosh/.test(uaText)){
            return 'mac'
        }else if(/Windows/.test(uaText)){
            return 'windows'
        }else if(iOS){
            return 'ios'
        }else if(android){
            return 'android'
        }else{
            return 'none'
        }
    })
    // App
    const weixin = /MicroMessenger/i.test(uaText);
    const uc = /UCBrowser/.test(uaText);
    const QQ = /QQ\//.test(uaText);
    const QQBrowserCore = /MQQBrowser\//.test(uaText);
    const QQBrowser = QQBrowserCore && !weixin;
    const miuiBrowser = /XiaoMi\/MiuiBrowser\/([\d.]+)(-Beta)?$/i.test(uaText);
    const chrome = (() => {
        if (QQ || uc || miuiBrowser) {
            return false;
        }
        return /(Chrome\/([\d.]+))|(CriOS\/([\d.]+))/i.test(uaText);
    })();
    const safari = /version\/([\w.]+).+?mobile\/\w+\s(safari)/i.test(uaText) ||
        /Macintosh.+Apple.+Safari/i.test(uaText);

    // 小程序
    const ttminiprogram = /(?:toutiaomicroapp)|(?:ToutiaoMicroApp)|(?:toutiaodevtools)|(?:tt_micro_devtools)/.test(
        uaText,
    );
    // 直接判断 是否在支付宝内
    const alipayminiprogram = uaText.indexOf('AlipayClient') > -1;
    // const alipayminiprogram = typeof my !== undefined && my !== null && typeof my.alert !== undefined

    const ret = {
        // 操作系统
        android,
        iOS,
        win,
        mac,
        pc,
        mobile: !pc,
        system,
        // 应用
        weixin, // 微信
        QQ, // QQ
        QQBrowser, // QQ浏览器
        QQBrowserCore, // QQ浏览器内核
        uc, // uc浏览器
        miuiBrowser, // miui浏览器
        chrome, // chrome浏览器
        safari, // safari

        // 小程序
        ttminiprogram,
        alipayminiprogram,
        get wxminiprogram() {
            // 微信小程序
            const wxUAText = navigator.userAgent;
            return (
                window.__wxjs_environment === 'miniprogram' ||
                /^wechatdevtools/.test(wxUAText) ||
                (wxUAText.match(/MicroMessenger/i) &&
                    wxUAText.match(/miniProgram/i))
            );
        },
        get baiduminiprogram() {
            // TODO
            return false;
        },
        get miniprogram() {
            return (
                ret.wxminiprogram ||
                ret.ttminiprogram ||
                ret.alipayminiprogram ||
                ret.baiduminiprogram
            );
        },
    };

    return ret;
};

/**
 * 根据user-Agent parse出的一个object，具体内容可以直接打开看看
 * @kind 常量
 */
export const ua = UATest(window.navigator.userAgent);

export const isSit = ['sit-dubbing.dupdub.com','asiasit.weta365.com','app-sit.dupdub.com', 'video-editing-sit.dupdub.com'].includes(location.host)
export const isPre = ['pre-dubbing.dupdub.com','app-pre.dupdub.com', 'video-editing-pre.dupdub.com'].includes(location.host)
export const isPrd = ['dubbing.dupdub.com','intl.weta365.com', 'moj.weta365.com', 'asia.weta365.com', 'app.pinpon.ai', 'app.dupdub.com', 'video-editing.dupdub.com'].includes(location.host)
export const isDev = !isSit && !isPre && !isPrd
export const appEnv = isSit ? 'sit' : isPrd ? 'prd' : isPre ? 'pre' : 'development'

export const videoEditorUrl = {
    sit: "https://video-editing-sit.dupdub.com/",
    prd: "https://video-editing.dupdub.com/",
    pre: "https://video-editing-pre.dupdub.com/",
    development: "http://localhost:8085/"
}[appEnv]

export const dupdubGateway = {
    sit: "https://moyin-gateway-sit.dupdub.com",
    prd: "https://moyin-gateway.dupdub.com",
    pre: "https://moyin-gateway-pre.dupdub.com",
    development: "https://moyin-gateway-sit.dupdub.com"
}[appEnv]

export const ttsUploadUrl = {
    sit: "https://tts-files-sit.dupdub.com/oss/auth",
    prd: "https://tts-files.dupdub.com/oss/auth",
    pre: "https://tts-files-sit.dupdub.com/oss/auth",
    development: "https://tts-files-sit.dupdub.com/oss/auth"
}[appEnv]