<template>
  <div class="ai-item">
    <div class="ai-title-box" @click="changeHide()">
      <div class="title-box-left">
        <div class="number" v-if="type !== 1">{{ index }}</div>
        <el-tooltip
          effect="dark"
          :content="detail.prompt"
          :disabled="detail.prompt.length < 60"
          placement="top-start"
          popper-class="ai-sound-effect-tooltip big-width"
        >
          <div class="title">{{ detail.prompt }}</div>
        </el-tooltip>
      </div>
      <div class="title-box-right">
        <div class="time">{{ formatTime(detail.createdAt) }}</div>
        <div class="isopen" v-if="type !== 1">
          <iconpark-icon
            :class="{ up: !detail.hide }"
            name="icon-jiantou1"
            size="20"
          ></iconpark-icon>
        </div>
      </div>
    </div>
    <div
      class="ai-item-list"
      :class="{ autoheight: type !== 1 ? detail.hide : true }"
    >
      <div
        class="childern-item"
        v-for="(v, j) in detail.aiSoundEffectList"
        :key="j"
      >
        <div class="fir">{{ v.name }}</div>
        <PlayBox :data="v" :index="index" />
        <!-- download -->
        <div @click="download(v)">
          <Icon
            name="icon-downloads"
            :isShow="false"
            tooltipContent="Download"
            :size="20"
          />
        </div>
        <!-- add -->
        <div
          @click="addAudio(v, j)"
          v-if="$aiSoundEffectPage.config.source !== 'home'"
        >
          <Icon
            name="icon-ai-sound-add"
            :size="20"
            :isShow="false"
            :tooltipContent="tipStatus[$aiSoundEffectPage.config.source]?.tip"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlayBox from './play-box.vue'
import { downloadFile, formatTime } from '../utils'
import Icon from './icon.vue'

export default {
  data() {
    return {
      tipStatus: {
        ttseditor: {
          tip: 'Add to editor'
        },
        videoeditor: {
          tip: 'Add to my audios'
        }
      }
    }
  },
  props: {
    detail: {
      type: Object,
      default: {}
    },
    type: {
      type: Number,
      default: 2 // 1 单个结果 2 多个结果
    },
    index: {
      type: Number,
      default: null
    }
  },
  components: { PlayBox, Icon },
  inject: ['$aiSoundEffectPage'],
  methods: {
    formatTime,
    changeHide() {
      if (this.type === 1) return
      this.detail.hide = this.detail.hide ? false : true
      this.$forceUpdate()
    },
    download(item) {
      downloadFile(item.resultUrl, item.recordName, event => {
        if (event.loaded === event.total) {
          // this.$message.success('Downloaded success!')
        }
      })
    },
    addAudio(item) {
      item.detail = this.detail
      this.$aiSoundEffectPage.getAudio(item)
    }
  }
}
</script>
<style lang="less" scoped>
.ai-item {
  border: solid 1px #f5f5f5;
  overflow: hidden;
  border-radius: 12px;

  margin-bottom: 12px;
  .ai-title-box {
    background: #f7f8fa;
    display: flex;
    justify-content: space-between;
    height: 56px;

    cursor: pointer;
    .title-box-left {
      display: flex;
      align-items: center;
      .number {
        width: 56px;
        height: 32px;
        line-height: 32px;
        box-shadow: inset -1px 0px 0px 0px #ebedf0;
        text-align: center;
      }
      .title {
        margin-left: 16px;
        color: #101010;
        width: 424px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    .title-box-right {
      display: flex;
      align-items: center;
      .time {
        color: #757575;
        margin-right: 16px;
      }
      .isopen {
        width: 56px;
        height: 32px;
        box-shadow: inset 1px 0px 0px 0px #ebedf0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center center;
        iconpark-icon {
          transition: all 1s;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          &:hover {
            color: #076bf7;
          }
          &.up {
            transform: rotate(180deg);
          }
        }
      }
    }
    &:hover {
      .title-box-right {
        .isopen {
          iconpark-icon {
            background: #c5cddb;
          }
        }
      }
    }
  }
  .ai-item-list {
    transition: all 1s;
    height: 0;
    &.autoheight {
      height: auto;
    }
    .childern-item {
      box-shadow: inset 0px -1px 0px 0px #f4f4f4;
      display: flex;
      align-items: center;
      height: 56px;
      &:last-child {
        box-shadow: none;
      }
      .fir {
        width: 136px;
        padding-left: 16px;
        box-sizing: border-box;
        color: #101010;
        text-align: left;
      }
      .sec {
        width: 32px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
</style>