/* eslint-disable */
import uploadFile from '@dupdub/upload-file'
import { ttsUploadUrl } from '@/utils/env'
class RecordSliceUpload {
  config
  maxFile
  isFirstUpload
  sliceLength
  blob
  getData
  isComplete
  uuid
  single

  constructor(config) {
    this.config = config
    this.maxFile = 5 * 1024 * 1024
    this.isFirstUpload = true
    this.sliceLength = 0
    this.isComplete = false
    this.getData = {
      title: config.title,
      failed: false,
      uuid: this.getUuid(),
      progress: 0,
      fileList: [],
      resourceUrl: '',
      transcodingProgress: 0,
      transcodingFailed: false
    }
    this.single = uploadFile(ttsUploadUrl, 'frontend/dupdub-upload/')
    return this
  }
  addSliceUpload(blob, isStop) {
    if (blob) {
      let newblob = this.blob ? [this.blob, blob] : [blob]
      this.blob = new Blob(newblob, {
        type: blob.type
      })
    }
    console.log('pre-size', this.blob.size)
    if (!isStop) {
      if (this.blob.size < this.maxFile) {
        return
      } else {
        this.sliceLength = this.maxFile
      }
    } else {
      if (this.blob.size <= this.maxFile) {
        this.sliceLength = this.blob.size
        this.isComplete = true
      } else {
        this.sliceLength = this.maxFile
      }
    }
    let sliceblob = this.blob.slice(0, this.sliceLength, this.blob.type)
    const file = new File([sliceblob], this.getData.title, {
      type: this.config.type
    })
    this.blob = this.blob.slice(
      this.sliceLength,
      this.blob.size,
      this.blob.type
    )
    let ele = {
      progress: 0
    }
    this.getData.fileList.push(ele)
    this.single.singleUpload({
      file,
      success: (res) => {
        console.log('%cupload_success', 'background: green;color: #fff;', res)
        this.getData.resourceUrl = res
        
        this.config.callback && this.config.callback(this.getData)
        // MittTool.emit('triggerUpload', res)
        // MittTool.emit(MittTool.EVENTS.triggerUpload, res)
      },
      fail: (err) => {
        console.log('%cupload_err', 'color: red;', err)
        this.getData.failed = true
      },
      progress: (res) => {
        console.log('%cupload_progress', 'background: green;color: #fff;', res)
        this.getData.fileList = this.getData.fileList.map((item, index) => {
          return index === res.partNumber
            ? {
              ...item,
              progress: res.progress
            }
            : item
        })
        console.log('this.getData.fileList', this.getData.fileList, res)
      }
    })
    if (this.isFirstUpload) {
      this.isFirstUpload = false
      console.log(1)
    } else {
      console.log(2)
    }

    if (isStop && this.blob.size) {
      let ele = {
        progress: 0
      }
      this.getData.fileList.push(ele)
      this.single.singleUpload({
        file: this.blob
      })
    }
    if (isStop) this.single.singleCompleteUpload()
    console.log('next-size', this.blob.size)
  }
  getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }
  containueUpload(obj) {
    this.single.singleContainueUpload()
  }
}

export default RecordSliceUpload
