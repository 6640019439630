export default {
    "threeSecondClone": {
        "recordLimitTip": "You have reached the maximum duration of 1 minutes. Do you want to upload the recordings?",
        "recordConfirm": "If you cancel the upload, the recordings will not be saved.",
        "cancel": "Cancel",
        "upload": "Upload",
        "cancelUploadTip": "Do you want to upload the recordings?",
        "delete": "Delete",
        "deleteTitile": "Are you sure to delete this file?",
        "upgradeTitle": "Upgrade to continue",
        "upgrade": "Upgrade",
        "upgradeTip": "Instant voice cloning is not available for your current plan. Please upgrade to Professional plan to access this feature.",
        "serviceContent": "I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose. I reaffirm my obligation to abide by DupDub's ",
        "serviceName": "Terms of Service",
        "and": " and ",
        "policyName": "Privacy Policy",
        "point": ".",
        "note": "* This technology is powered by ElevenLabs.",
        "text10M": "Your file exceeds the maximum limit of 10M. Please choose a different file or we can automatically trim it for you.",
        "text1minutes": "Your file exceeds the maximum limit of 1 minutes. Please choose a different file or we can automatically trim it for you.",
        "autoTrim":"Auto-trim",
        "tryAnother":"Try another"
    }
}