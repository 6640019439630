<template>
  <div
    class="mini-10-dialogue"
    :class="{mini_small: showMiniStauts}"
    :style="{left: `${left}%`, top: `${top}%`, transform: `translate(-100%, -100%)`}"
    v-if="visible && (checkListShow !== 2 || discountShow !== 2)"
    v-draggable
  >
    <!-- 关闭按钮 -->
    <div
      class="big-close"
      @click.stop="becomeSmaller"
      v-if="!showMiniStauts"
    ></div>
    <div
      class="small-close"
      @click.stop="becomeSmaller"
      v-else
    ></div>
    <!-- checklist -->
    <template v-if="checkListShow !== 2">
      <!-- checklist-big -->
      <div
        class="checklist-box-big"
        v-if="!showMiniStauts"
      >
        <img
          src="./img/top-img.png"
          width="260"
        >
        <div class="checklist-box-fir"></div>
        <div class="checklist-box-sec">
          <div class="title">
            <div >20% OFF Awaiting</div>
            <p>
              Unlock two features to get a discount
            </p>
          </div>
          <div class="checked-list">
            <div
              class="checked-item"
              v-for="(item, index) in list"
              :key="index"
              @click="openPage(item)"
            >
              <!-- 0-未完成，1-已完成 -->
              <img
                v-if="!item.checked"
                src="./img/checked.png"
                alt=""
                width="14"
              >
              <img
                v-else
                src="./img/checked-check.png"
                alt=""
                width="14"
              >
              <span>
                {{ item.title }}
                <iconpark-icon
                  name="icon-arrow-right"
                  class="jt"
                ></iconpark-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- checklist-small -->
      <div
        class="checklist-box-small"
        v-else
      >
        <img
          src="./img/gift.png"
          width="32"
        />
        <p>Bonus<br>Awaiting</p>
      </div>
    </template>
    <!-- discount -->
    <template v-if="discountShow !== 2">
      <div
        class="discount-box-big"
        v-if="!showMiniStauts"
      >
        <img
          src="./img/flower.png"
          width="168"
          alt=""
        >

        <div class="discount-box-big-top">
          <div class="title">20% OFF</div>
          <div class="subtitle">Offer ends before free trial ends</div>
          <div class="time-show">
            <VueCountTo />
          </div>
        </div>
        <div class="discount-box-big-bottom">
          <div
            class="btn-buy"
            @click="copyCodeToStripe"
          >GRAB DISCOUNT<i></i></div>
        </div>
      </div>
      <div
        class="discount-box-small"
        v-else
      >
        <img src="./img/small-flower.png" alt="" width="96">
        <p>Grab Discount</p>
        <div class="time-show">
          <VueCountTo />
        </div>
      </div>
    </template>

  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import http from '@/utils/http-request.js'
import VueCountTo from '@/pages/article-list/component/vue-count-to'
import { VIP_INTERVAL } from '@/constants/vip.js'
import stat from '@/utils/stat'
export default {
  name: 'check-list',
  data() {
    return {
      list: [
        { title: 'Generate an AI avatar', checked: 0, type: 0 },
        { title: 'Export an AI voiceover', checked: 0, type: 1 },
        { title: 'Add Instant voice cloning', checked: 0, type: 2 },
        { title: 'Finalize a Video translation', checked: 0, type: 3 },
        { title: 'Try AI transcription', checked: 0, type: 4 },
      ],
      visible: false, // 是否显示当前组件
      checkListShow: 2, // 2不显示，1显示
      discountShow: 2, // 2不显示，1显示
      showMiniStauts: false, // 缩小展示
      isDrag: false,
      // 20%优惠券
      SUBSCRIBE: {
        "isPrd": "promo_1OHkAME4zFfOeQHcFfsLLi0m",
        "notIsPrd": "promo_1OHkV0E4zFfOeQHcH18O02rz",
      },
      left: 100,
      top: 100,
    }
  },
  components: {
    VueCountTo
  },
  computed: {
    ...mapGetters(['vipInfo']),
    ...mapState({
      is5offBooleanFeature: state => state.status.is5offBooleanFeature
    }),
    interval() {
      return this.vipInfo.interval
    },
    ...mapGetters('userInfo', ['user', 'isLogin'])
  },
  directives: {
    draggable: {
      bind(el, binding, vnode) {
        let dragData = { offsetX: 0, offsetY: 0 };
        // 监听鼠标按下事件
        el.addEventListener("mousedown", onMouseDown);
        function onMouseDown(e) {
          vnode.context.isDrag = false
          // 计算初始偏移值
          dragData.offsetX = e.clientX - el.offsetLeft;
          dragData.offsetY = e.clientY - el.offsetTop;
          // 监听鼠标移动和松开事件
          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("mouseup", onMouseUp);
        }
        function onMouseMove(e) {
          vnode.context.isDrag = true
          // 计算新的位置
          let left = e.clientX - dragData.offsetX;
          let top = e.clientY - dragData.offsetY;
          // 获取可视区域的宽度和高度
          let viewWidth = document.documentElement.clientWidth;
          let viewHeight = document.documentElement.clientHeight;
          // 计算可移动的最大位置
          let maxLeft = viewWidth - el.offsetWidth;
          let maxTop = viewHeight - el.offsetHeight;
          // 限制位置不超出可视区域
          left = Math.max(0, Math.min(left, maxLeft));
          top = Math.max(0, Math.min(top, maxTop));
          // 更新位置
          el.style.transform = '';
          el.style.left = left + "px";
          el.style.top = top + "px";
        }
        function onMouseUp() {
          // 清除鼠标移动和松开事件的监听
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
        }
        //移动端拖拽
        function onTouchStart(e) {
          e.preventDefault();
          dragData.offsetX = e.touches[0].clientX - el.offsetLeft;
          dragData.offsetY = e.touches[0].clientY - el.offsetTop;
          // 监听触摸移动和结束事件
          document.addEventListener("touchmove", onTouchMove, true);
        }
        function onTouchMove(e) {
          e.preventDefault();
          // 计算新的位置
          let left = e.touches[0].clientX - dragData.offsetX;
          let top = e.touches[0].clientY - dragData.offsetY;
          // 获取可视区域的宽度和高度
          let viewWidth = document.documentElement.clientWidth;
          let viewHeight = document.documentElement.clientHeight;
          // 计算可移动的最大位置
          let maxLeft = viewWidth - el.offsetWidth;
          let maxTop = viewHeight - el.offsetHeight;
          // 限制位置不超出可视区域
          left = Math.max(0, Math.min(left, maxLeft));
          top = Math.max(0, Math.min(top, maxTop));
          // 更新位置
          el.style.transform = '';
          el.style.left = left + "px";
          el.style.top = top + "px";
        }
        el.addEventListener("touchstart", onTouchStart, false);
      }
    }
},

  watch: {
    isLogin(n) {
      if (n) this.getDialogueStatus()
    },
    $route: {
      immediate: true,
      handler() {
        const path = this.$route.path || ''
        const mini = localStorage.getItem('checklist_is_mini')
        if (mini) {
          this.showMiniStauts = mini === 'true'
        }
        this.visible = [
          '/tools/home',
          '/tools/studio-home',
          '/tools/article',
          '/tools/transcription-list',
          '/tools/talkingphoto',
          '/tools/videotranslation',
          '/tools/project',
          '/tools/personal-center'
        ].includes(path)
      }
    }
  },
  mounted() {
    if (this.isLogin) {
      //兼容没有触发时调用弹框
      this.getDialogueStatus()
    }
    // 监听更新状态的消息
    this.$bus.$on('couponStatus', this.updateCounpon)
  },
  methods: {
    ...mapActions(['changeVipinfo']),
    ...mapActions('userInfo', ['subscribeVipWithLogin']),
    getDialogueStatus() {
      http.abCounponShow().then(res => {
        if (res.data.code === 200) {
          console.log(res.data.data, 'res------')
          this.checkListShow = res.data.data.checkListShow
          this.discountShow = res.data.data.firstShow
          if (res.data.data.checkList) {
            this.list[0].checked = res.data.data.checkList.avatarStatus
            this.list[1].checked = res.data.data.checkList.voiceoverStatus
            this.list[2].checked = res.data.data.checkList.voiceCloneStatus
            this.list[3].checked = res.data.data.checkList.videoTranslationStatus
            this.list[4].checked = res.data.data.checkList.transcriptionStatus
          }
          if (this.checkListShow === 1) {
            stat.event('onboarding_checklist_exposure')
          }
        }
      })
    },
    // 放大/缩小
    becomeSmaller() {
      this.showMiniStauts = !this.showMiniStauts
      if (this.showMiniStauts) stat.event('onboarding_checklist_minimize_click')
      localStorage.setItem('checklist_is_mini', this.showMiniStauts)
    },
    // 关闭
    closeModal() {
      this.checkListShow = 2
      this.discountShow = 2
    },
    // 打开响应的功能
    openPage(item) {
      stat.event('onboarding_checklist_tool_click', {
        'button_name': item.title
      })
      switch (item.type) {
        case 0:
          const routeUrl2 = this.$router.resolve({
            name: 'talkingPhotoEditor',
          })
          window.open(routeUrl2.href, '_blank')
          break;
        case 1:
          const routeUrl1 = this.$router.resolve({
            path: '/overview/article-voice'
          })
          window.open(routeUrl1.href, '_blank')
          break;
        case 2:
          this.$router.push('/tools/home?cloning=true')
          break;
        case 3:
          this.$router.push({ path: '/tools/home?show=video_translation' })
          break;
        case 4:
          const routeUrl = this.$router.resolve({
            path: '/transcription'
          })
          window.open(routeUrl.href, '_blank')
          break;
      }
    },
    // 更新接口
    updateCounpon(data) {
      // 1-avatar、2-voiceover、3-voiceClone、4-videoTranslation、5-transcription
      http.UpdateABCounpon({
        params: data
      }).then((res) => {
        if (res.data.data?.result) {
          if (res.data.data?.message) this.$message.success(res.data.data.message)
          this.getDialogueStatus()
        }
      })
    },
    copyCodeToStripe() {
      if (this.isDrag) {
        return
      }
      this.getDialogueStatus()
      // 跳转stripe页面
      this.onSubscribe()
    },
    onSubscribe() {
      let { isPrd, notIsPrd } = this.SUBSCRIBE
      // 默认走Personal-monthly
      this.subscribeVipWithLogin({
        vipType: 1, // pro版
        interval: VIP_INTERVAL.MONTH, // 月会员
        freeTrialType: '',
        promotionCode: process.env.VUE_APP_ENV == 'production' ? isPrd : notIsPrd,
        from: '/tools/article',
        discount: true // ABTest 20% $off
      })
      return
    }
  }
}
</script>
<style lang="less" scoped>
.mini-10-dialogue {
  position: fixed;
  z-index: 100;
  z-index: 1000;
  cursor: move;
  padding-top: 50px;
  &.mini_small {
    padding-top: 15px;
  }
  .big-close {
    width: 24px;
    height: 24px;
    background: url(./img/big_close.png) no-repeat center;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    &:hover {
      background: url(./img/big_close_hover.png) no-repeat center;
      background-size: cover;
    }
  }
  .small-close {
    width: 16px;
    height: 16px;
    background: url(./img/small_close.png) no-repeat center;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    &:hover {
      background: url(./img/small_close_hover.png) no-repeat center;
      background-size: cover;
    }
  }
  .checklist-box-big {
    width: 318px;
    background: linear-gradient(135deg, #ff7ce5 0%, #ab89ff 100%);
    border-radius: 40px;
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    user-select: none;
    > img {
      position: absolute;
      top: -46px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      -webkit-user-drag: none;
    }
    .checklist-box-fir {
      position: absolute;
      top: 10px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      background: linear-gradient(144deg, #ffffff 0%, #ffffff 100%);
      box-shadow: inset 8px 8px 12px 0px rgba(255, 255, 255, 0.65);
      border-radius: 32px;
      backdrop-filter: blur(10px);
      opacity: 0.5;
      z-index: 2;
    }
    .checklist-box-sec {
      background: #ffffff;
      box-shadow: inset 8px 8px 12px 0px rgba(255, 255, 255, 0.65);
      border-radius: 29px;
      backdrop-filter: blur(10px);
      position: relative;
      z-index: 3;
      .title {
        text-align: center;
        >div{
          font-size: 18px;
          font-family: Poppins-SemiBold;
          text-align: center;
          padding: 15px 0 5px;
        }
        img {
          margin: 17px 0 8px;
        }
        p {
          color: rgba(4, 13, 26, 0.65);
          font-family: Poppins, Poppins;
          font-size: 13px;
          text-align: left;
          text-align: center;
          span {
            color: #893bff;
            font-family: Poppins-SemiBold;
            font-size: 13px;
          }
        }
      }
      .checked-list {
        padding-bottom: 20px;
        .checked-item {
          margin-top: 12px;
          line-height: 22px;
          padding: 0 18px;
          cursor: pointer;
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            font-family: Poppins-SemiBold;
            margin-left: 10px;
            color: #040d1a;
            display: flex;
            .jt {
              margin-left: 8px;
            }
          }
          &:hover {
            span {
              text-decoration: underline;
            }
            .jt {
              margin-left: 8px;
              animation: move 1s ease infinite;
            }
            @keyframes move {
              0% {
                transform: translateX(0);
              }

              50% {
                transform: translateX(5px);
              }

              100% {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  }
  .checklist-box-small {
    width: 130px;
    height: 56px;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 100px 0px 0px 100px;
    border: 2px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(132deg, #ff7ce5 0%, #9d76ff 100%),
      linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    position: relative;
    user-select: none;
    > p {
      font-family: Poppins-SemiBold;
    }
  }

  .discount-box-big {
    width: 324px;
    background: linear-gradient(180deg, #f8cbff 0%, #cbbbff 100%);
    border-radius: 32px;
    padding-top: 12px;
    position: relative;
    user-select: none;
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -35px;
    }

    .discount-box-big-top {
      margin: 0 12px;
      height: 266px;
      background: linear-gradient(132deg, #ea67ff 0%, #7070ff 100%);
      border-radius: 24px;
      font-family: Poppins, Poppins;
      color: hsl(0, 0%, 100%);
      text-align: center;
      .title {
        font-family: Poppins-SemiBold;
        font-weight: bold;
        font-size: 40px;
        line-height: 56px;
        padding-top: 10px;
      }
      .subtitle {
        font-size: 14px;
        line-height: 20px;
      }
      .time-show {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        :deep(.count) {
          span {
            width: 24px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            color: #040d1a;
            font-size: 24px;
          }
          em {
            color: #ffffff;
          }
        }
      }
    }

    .discount-box-big-bottom {
      background: url(./img/bj.png) no-repeat center;
      background-size: cover;
      height: 112px;
      position: absolute;
      bottom: 0;
      width: 100%;
      .btn-buy {
        width: 248px;
        line-height: 54px;
        cursor: pointer;
        background: linear-gradient(123deg, #a869ff 0%, #ff60dd 100%);
        border-radius: 32px;
        border: 5px solid #ffffff;
        font-size: 22px;
        font-family: Poppins-SemiBold;
        text-align: center;
        color: #ffffff;
        margin: 28px auto 0;
        box-sizing: border-box;
        position: relative;
        i {
          height: 41px;
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          border-radius: 32px;
          opacity: 0.4;
          position: absolute;
          left: 11px;
          top: 6px;
          right: 11px;
        }
        &:hover {
          background: linear-gradient(
            123deg,
            rgba(168, 105, 255, 0.8) 0%,
            rgba(255, 96, 221, 0.8) 100%
          );
        }
      }
    }
  }
  .discount-box-small {
    width: 150px;
    height: 58px;
    box-sizing: border-box;
    border-radius: 100px 0px 0px 100px;
    border: 2px solid;
    font-size: 14px;
    color: #fff;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(132deg, #ff7ce5 0%, #9d76ff 100%),
      linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    position: relative;
    user-select: none;
    img {
      position: absolute;
      top: -20px;
      right: 20px;
      -webkit-user-drag: none;
    }
    p {
      font-family: Poppins-SemiBold;
      font-weight: bold;
      font-size: 15px;
      color: #ffffff;
      line-height: 24px;
      text-align: right;
      padding-right: 13px;
      margin-top: 3px;
    }

    .time-show {
      display: flex;
      justify-content: right;
      align-items: center;
      padding-right: 6px;
      :deep(.count) {
        span {
          width: 15px;
          height: 17px;
          background: #ffffff;
          border-radius: 4px;
          color: #040d1a;
          font-size: 12px;
        }
        em {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
