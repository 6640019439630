// 用户帐号信息
const state = {
  eyeShow: false,
  calcusStatus: true,
  toolsValue: null, // 当前工具选项value
  polyphoneWordInfo: '', // 已选多音字
  playState: 0, // 全局播放状态：0停止 | 1播放 | 2加载中
  sensitiveModalState: false,
  isWordBreaking: false,
  company: null
}

const actions = {
  setCalucsShow(context, status) {
    context.commit('setCalucs', status)
  },
  setEyeShow(context, status) {
    console.log(status)
    context.commit('setEye', status)
  },
  setToolsValue(context, value) {
    context.commit('setToolsValue', value)
  },
  setPolyphoneWord(context, wordInfo) {
    context.commit('setPolyphoneWord', wordInfo)
  },
  changePlayState(context, status) {
    context.commit('changePlayState', status)
  },
  setSensitiveModalState(context, status) {
    context.commit('setSensitiveModalState', status)
  }
}

const mutations = {
  setCalucs(state, status) {
    state.calcusStatus = status
  },
  setEye(state, status) {
    state.eyeShow = status
  },
  setToolsValue(state, value) {
    state.toolsValue = value
  },
  setPolyphoneWord(state, wordInfo) {
    state.polyphoneWordInfo = wordInfo
  },
  changePlayState(state, status) {
    state.playState = status
  },
  setSensitiveModalState(state, status) {
    state.sensitiveModalState = status
  },
  setWordBreaking(state, status) {
    state.isWordBreaking = status
  },
  setCompany(state, status) {
    state.company = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
