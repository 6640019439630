<template>
  <div class="notification-main" v-if="popShow">
    <div class="notification-main-content" :style="{'height': dialogueHeight}">
      <div class="notification-top">
        <h3 class="" v-html="$t('notification.title')"></h3>
        <div class="close-btn" @click="goIt">
          <iconpark-icon name="iconcanclenew" size="34"></iconpark-icon>
        </div>
      </div>
      <div class="notification-text">
        <p>{{ $t('notification.desc0') }}</p>
        <p>{{ $t('notification.desc1') }}</p>
        <ul>
          <li>
            <div v-html="$t('notification.notificationCopy.list1')"></div>
          </li>
          <li v-for="(desc, index) in renderTxt" :key="index">
            <div v-html="desc"></div>
          </li>
        </ul>
        <p v-html="$t('notification.desc2')"></p>
        <p v-html="$t('notification.desc3')"></p>
        <p v-html="$t('notification.desc4')" class="last-name"></p>
      </div>
      <div class="notification-bottom">
        <button class="got-it" @click="goIt">
          {{ $t('notification.gotIt') }}
        </button>
        <button class="see-new-pricing" @click="newPricing">
          {{ $t('notification.newPricing') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/utils/http-request.js'
export default {
  name: 'nitificationPop',
  data() {
    return {
      popShow: false,
      xMultiple: null,
      renderTxt: [],
      dialogueHeight: '',
      innerHeight: ''
    }
  },
  computed: {
    ...mapState('request', ['isGetCompensationCreditState']),
    ...mapGetters('userInfo', ['user', 'isLogin'])
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        if(this.isLogin) {
          this.init()
        }
      }
    }
  },
  mounted() {
    //获取屏幕的高度
    window.addEventListener('resize', this.resizeScreen)
    this.dialogueWidth()
  },
  methods: {
    ...mapMutations('request', ['setGetCompensationCreditState']),
    resizeScreen() {
      debounce(() => {
        this.dialogueWidth()
      }, 50)()
    },
    dialogueWidth() {
      this.innerHeight = window.innerHeight
      // 上下留白60 最大是747（设计稿高度）
      const initdialogueH = this.innerHeight - 120
      this.dialogueHeight = initdialogueH < 747 ? initdialogueH + 'px' : '747px'
    },
    async init() {
      if (this.isGetCompensationCreditState) return
      this.setGetCompensationCreditState(true)
      const response = await http.compensationCredit()
			const {data: { data, code }} = response
      if(code === 200) {
        // 设置请求状态
        if(data === null) return
        const {type, interval, xMultiple} = data
        let personalYearArr = [this.$t('notification.notificationCopy.personalYearFisrt', {xMultiple: xMultiple}), this.$t('notification.notificationCopy.personalYearLast')]
        let proYearArr = [this.$t('notification.notificationCopy.proYearFisrt', {xMultiple: xMultiple}), this.$t('notification.notificationCopy.proYearLast')]
        if(type === 0){
          this.renderTxt = interval === 'month' ? this.$t('notification.notificationCopy.personalMonth') : personalYearArr
        } else if(type === 1) {
          this.renderTxt = interval === 'month' ? this.$t('notification.notificationCopy.proMonth') : proYearArr
        }
        this.popShow = true
      } else {
        this.popShow = false
      }
    },
    goIt() {
      this.popShow = false
    },
    newPricing() {
      this.$router.push('/pricing')
    }
  }
}
</script>

<style lang="less" scoped>
.notification-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 17, 38, 0.6);
  z-index: 100;
  .notification-main-content {
    width: 880px;
    max-height: 747px;
    background: #fff;
    top: 50%;
    left: 50%;
    position: fixed;
    border-radius: 16px;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .notification-top {
      height: 152px;
      width: 100%;
      background: url('https://cdn-static.dupdub.com/frontend/autoupload/js/image/notification-1695108504959/notification-1695108504959/bg.png');
      background-size: contain;
      background-repeat: no-repeat;
      h3 {
        font-size: 30px;
        font-weight: 500;
        font-family: 'Poppins-SemiBold';
        color: #000f1a;
        line-height: 38px;
        padding: 40px 40px 0;
        box-sizing: border-box;
        position: relative;
        height: 100%;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 100px;
          width: 247px;
          height: 184px;
          background: url('https://cdn-static.dupdub.com/frontend/autoupload/js/image/notification-1695108504959/notification-1695108504959/pop-n.png');
          background-size: contain;
        }
      }
      .close-btn {
        width: 34px;
        position: absolute;
        height: 34px;
        text-align: center;
        top: 30px;
        right: 30px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #fff;
        }
      }
    }
    .notification-text {
      margin: 0 6px;
      padding: 0 34px;
      height: calc(100% - 152px - 104px);
      overflow-y: scroll;
      p {
        font-size: 16px;
        color: #596472;
        padding-bottom: 16px;
        :deep(a) {
          color: #3583fb;
          text-decoration: revert;
        }
        &.last-name{
          padding-bottom: 10px;
        }
      }
      ul {
        li {
          position: relative;
          margin-bottom: 10px;
          padding-left: 24px;
          line-height: 24px;
          font-size: 16px;
          color: #596472;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 22px;
            background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/icon_star_yellow1696768059.svg');
            background-size: 16px 16px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
          }
          :deep(b) {
            font-weight: bold;
            font-family: 'Poppins-Bold';
            color: #001126;
          }
        }
      }
    }
    .notification-bottom {
      height: 104px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 222px;
        height: 44px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        &.got-it {
          background: #f0f1f3;
          margin-right: 16px;
          color: #001126;

          &:hover {
            background: #e8eaef;
          }
        }

        &.see-new-pricing {
          background: #001126;
          color: #fff;
          &:hover {
            background: #272f3a;
          }
        }
      }
    }
  }
}
</style>