// 会员类型
export const VIP_TYPE = {
    FREE: 2, // 免费版
    PERSONAL: 0, // 个人版
    PROFESSIONAL: 1, // 专业版
    ULTIMATE: 3 //企业
} 
// 会员订阅周期
export const VIP_INTERVAL = {
    YEAR: 'year',
    MONTH: 'month',
    CREDITS: 'credits'
}

// appsumo等级
export const APPSUMO_LEVEL = {
    BASIC: 1,
    GROWTH: 2,
    ADVANCED: 3,
    SINGLE: 5,
    DealMirror: 6
}

// 产品类型
export const PRODUCT_TYPE = {
    SUBSCRIPTION: 1, // 订阅
    LIFETIME_PAYMENT: 11, // 39一次性付费
    CREDITS: 'PAYMENT' // 购买credits
}