// const navigator = window.navigator;
/* eslint-disable */
import RecordSliceUpload from './recordSliceUpload'
class CameraRecorderReal {
  config
  canRecording
  stream
  mediaRecorder
  blob
  isStop
  sliceUpload
  that
  getRecorderData
  callbackRecorder
  constructor(config) {
    this.isStop = false
    this.config = config
    this.callbackRecorder = config.callbackRecorder
    return this
  }

  // 检测是否可以录制
  async checking(callback) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(this.config.params)
        .then((stream) => {
          this.stream = stream
          this.canRecording = true
          callback(this.canRecording)
        })
        .catch((error) => {
          console.log('error', error, error.code, error.name);
          this.canRecording = false
          callback(this.canRecording, error.name)
        })
    } else {
      this.canRecording = false
      callback(this.canRecording)
      this.throwError("当前浏览器不支录像功能。");
    }
  }
  // 开始录制
  start(callback) {
    this.mediaRecorder = new MediaRecorder(this.stream, {
      audioBitsPerSecond: 128000, //音频码率
      videoBitsPerSecond: 2500000, //视频码率 (数值越大视频越清晰)
      mimeType: this.config.mimeType //视频编码格式
    })
    console.log('this.mediaRecorder', this.mediaRecorder)
    this.mediaRecorder.start(10000)
    const curTime = new Date()
    const y = curTime.getFullYear()
    const m = curTime.getMonth() + 1
    const d = curTime.getDate()
    const h = curTime.getHours()
    const min = curTime.getMinutes()
    const sec = curTime.getSeconds()
    const timeName = '' + y + m + d + h + min + sec
    this.sliceUpload = new RecordSliceUpload({
      title: `Record-${timeName}.webm`,
      type: this.config.type,
      $TTSASR: this.config.$TTSASR,
      callback: this.callbackRecorder
    })
    this.mediaRecorder.ondataavailable = (e) => {
      console.log('ondataavailable----------------------', e.data, this.isStop)
      // 开始分片
      this.sliceUpload.addSliceUpload(e.data, this.isStop)
    }
    this.mediaRecorder.onerror = (e) => {
      console.error(e)
    }
    this.mediaRecorder.onstart = (e) => {
      console.log('开始', e)
      if (callback) callback(this.stream)
    }
    this.mediaRecorder.onstop = (e) => {
      console.log('recorder----结束', e, this.sliceUpload.getData)
      console.log('recorder----1111', this.sliceUpload.getData)
      this.getRecorderData(this.sliceUpload.getData)
    }
  }
  containueUpload() {
    this.sliceUpload.containueUpload()
  }
  // 暂停录制
  pause() {
    this.mediaRecorder && this.mediaRecorder.pause()
  }
  // 恢复录制
  resume() {
    this.mediaRecorder && this.mediaRecorder.resume()
  }
  // 结束录制
  stop(cb) {
    this.clear()
    this.isStop = true
    this.getRecorderData = cb
    this.mediaRecorder && this.mediaRecorder.stop()
  }
  // 清除录制状态
  clear() {
    if (this.stream) this.stream.getTracks().forEach((track) => track.stop())
  }
  throwError(message) {
    alert(message);
    throw Error(message);
  }
}

export default CameraRecorderReal;