// 用户帐号信息
const state = {
  language: 'en'
}

const actions = {
  setLanguage(context, lang) {
    context.commit('setLanguage', lang)
  }
}

const mutations = {
  setLanguage(state, lang) {
    state.language = lang
  }
}

const getters = {
  getLanguage: state => state.language
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
