<template>
  <speaker-audio
    v-if="needInit"
    ref="speakerAudioRef"
    :vipInfo="vipInfo"
    :userRights="userRights"
    :getUserRights="getUserRights"
    :changeVipinfo="changeVipinfo"
    :onChangeSpeakerInfo="onChangeSpeakerInfo"
    @cancel-select-speaker="onCancelSpeakerSelect"
    @refresh-dupdublab-speakers="refreshDupdublabSpeakers"
    @show-price-modal="onShowPriceModal"
    @collect-speaker="onCollectSpeaker"
  ></speaker-audio>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SpeakerAudio from './speaker-audio.vue'
export default {
  components: {
    SpeakerAudio
  },
  computed: {
    ...mapGetters({
      vipInfo: ['vipInfo'],
      userRights: ['userInfo/userRights']
    })
  },
  data() {
    return {
      needInit: false // 需要初始化发音人组件，一旦为true始终为true
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.needInit) return
        const { path } = this.$route
        this.needInit =
          ['/talking-photo-editor', '/video-translation-editor','/talking-avatar'].includes(
            path
          ) || path.indexOf('/overview/article-voice') === 0
      }
    }
  },
  created() {
    this.$bus.$on('show-global-speaker-dialog', this.showDialog)
    this.$bus.$on('global-speaker-dialog-collect', this.collectSpeaker)
  },
  beforeDestroy() {
    this.$bus.$off('show-global-speaker-dialog', this.showDialog)
    this.$bus.$off('global-speaker-dialog-collect', this.collectSpeaker)
  },
  methods: {
    ...mapActions('userInfo', ['getUserRights']),
    ...mapActions(['changeVipinfo']),
    // 方法
    showDialog(...args) {
      this.$refs.speakerAudioRef.showAudioDialog(...args)
    },
    collectSpeaker(...args) {
      this.$refs.speakerAudioRef.collectSpeaker(...args)
    },

    // 事件
    onChangeSpeakerInfo(payload) {
      this.$bus.$emit('global-speaker-confirm', payload)
      return true
    },
    onCancelSpeakerSelect() {
      this.$bus.$emit('global-speaker-cancel')
    },
    refreshDupdublabSpeakers() {
      this.$bus.$emit('global-refresh-dupdublab-speakers')
    },
    onShowPriceModal({ source }) {
      this.$store.commit('userInfo/setShowPriceModal', [true, source])
    },
    onCollectSpeaker(speakerInfo) {
      this.$bus.$emit('global-speaker-collect', speakerInfo)
    }
  }
}
</script>