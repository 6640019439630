import { Message } from 'element-ui'

// message私有化
const showMessage = Symbol('showMessage')
let messageInstance = null
class DonMessage {
  success(options, single = true) {
    let obj = {
      ...(typeof options === 'object' ? options: {}),
      message: options.message || options,
      duration: options.duration || 3000,
      customClass: 'custom-class-style custom-class-style-success'
    }
    this[showMessage]('success', obj, single)
  }
  warning(options, single = true) {
    let obj = {
      message: options.message || options,
      duration: options.duration || 3000,
      customClass: 'custom-class-style'
    }
    this[showMessage]('warning', obj, single)
  }
  info(options, single = true) {
    let obj = {
      message: options.message || options,
      duration: options.duration || 3000,
      customClass: 'custom-class-style custom-class-style-info'
    }
    this[showMessage]('info', obj, single)
  }
  error(options, single = true) {
    let obj = {
      message: options.message || options,
      duration: options.duration || 3000,
      customClass: 'custom-class-style custom-class-style-error'
    }
    this[showMessage]('error', obj, single)
  }

  [showMessage](type, options, single) {
    let msg = options
    if (messageInstance && single) {
      messageInstance.close()
    }
    messageInstance = Message[type](msg)
  }
}
export default new DonMessage()