<template>
  <div class="ai-list-container">
    <div class="ai-list-top">
      <div class="ai-list-top-left">
        <div @click="back">
          <Icon
            name="icon-back-dp4752hn"
            :isShow="true"
            :size="20"
          />
        </div>
      </div>
      <div class="title">History</div>
      <div class="ai-list-top-right">
        <div @click="changeSort(1)">
          <Icon
            name="icon-inverted"
            :current="listParams.sort === 1"
            :isShow="false"
            tooltipContent="From newest to oldest"
            :size="24"
          />
        </div>
        <div @click="changeSort(0)">
          <Icon
            name="icon-just"
            :current="listParams.sort === 0"
            :isShow="false"
            tooltipContent="From oldest to newest"
            :size="24"
          />
        </div>
      </div>
    </div>
    <div
      class="ai-box-wrapper"
      v-infinite-scroll="loadMore"
      :infinite-scroll-immediate="true"
      :infinite-scroll-disabled="busy"
      :infinite-scroll-delay="100"
      :infinite-scroll-distance="200"
    >
      <div
        class="no-data"
        v-if="!list.length"
      >
        No generated sound effect yet.
      </div>
      <div
        v-else
        class="ai-list-box"
      >
        <template v-for="(item, index) in list">
          <AiDetail
            :key="index"
            :detail="item"
            :index="!listParams.sort ? totalElements-index : index + 1"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { AiSoundEffectApi } from '@/api/index.js'
import AiDetail from './ai-detail.vue'
import Icon from './icon.vue'

export default {
  data() {
    return {
      list: [],
      listParams: {
        page: 0,
        size: 30,
        sort: 1
      },
      totalElements: 0,
      totalPages: 0,
      busy: true
    }
  },
  components: { AiDetail, Icon },
  mounted() {
    this.initList()
  },
  methods: {
    // 改变排序
    changeSort(sort) {
      this.listParams.sort = sort
      this.initList()
    },
    initList() {
      this.listParams.page = 0
      this.getList()
    },
    // 加载更多
    loadMore() {
      this.busy = true
      if (this.listParams.page < this.totalPages - 1) {
        this.listParams.page++
        this.getList()
      } else {
        this.busy = false
      }
    },
    getList() {
      AiSoundEffectApi.aiSoundEffectList({
        params: this.listParams
      })
        .then(res => {
          console.log(res.data)
          this.busy = false
          if (res.data.code === 200) {
            this.totalPages = res.data.data.totalPages
            this.totalElements = res.data.data.totalElements
            if (!this.listParams.page) {
              this.list = []
            }
            this.list = this.list.concat(res.data.data.results)
          }
        })
        .catch(err => {
          this.busy = false
          this.$message.error(err)
        })
    },
    back() {
      this.$emit('changeType', 1)
    }
  }
}
</script>

<style lang="less" scoped>
.ai-list-container {
  color: #101010;
  font-size: 14px;
  padding-bottom: 16px;
  .ai-list-top {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    box-shadow: inset 0px -1px 0px 0px #f0f0f0;
    .title {
      font-family: 'Poppins-SemiBold';
      color: #101010;
      font-size: 16px;
    }
    .ai-list-top-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100px;
      padding-left: 12px;
      box-sizing: border-box;
    }
    .ai-list-top-right {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 100px;
      iconpark-icon {
        margin-left: 10px;
        cursor: pointer;
      }
      .current {
        color: #076bf7;
      }
    }
  }
  .ai-box-wrapper {
    height: 470px;
    overflow: auto;
    padding: 0 24px;
    padding-top: 16px;
    .no-data {
      height: 100%;
      display: flex;
      justify-content: center;
      color: #757575;
      font-size: 14px;
      padding-top: 210px;
    }
  }
}
</style>
