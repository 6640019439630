<template>
  <div :class="['select-container',]" v-clickoutside="outSide">
    <div :class="['input-panel', { active: isActive, 'require-box': required && !selected, placeholder: !selected }]"
      @click="show">
      <span :class="{ 'require': required }">{{ content }}</span>
      <img src="./img/arrow.svg" alt="">
    </div>
    <div class="list-box" v-show="isActive">
      <ul class="list">
        <li v-for="item in options" :key="item.value" @click="selectItem(item)"
          :class="{ active: selected === item.value }">
          {{ item.name }}</li>
      </ul>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Please input value'
    },
    selected: {
      type: String,
      default: ''
    },
    isSelctLanguage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    content() {
      if (!this.selected) return this.placeholder
      return this.options.filter(v => v.value == this.selected)[0]?.name
    },
  },
  data() {
    return {
      isActive: false
    }
  },
  mounted() {

  },
  methods: {
    show() {
      console.log(this.isSelctLanguage, this.options.length, 'check');
      if (this.type === 'accent' && !this.isSelctLanguage) {
        return this.$message.error('Please select language first')
      }
      this.isActive = true
    },
    selectItem(item) {
      this.$emit('select-item', { item, type: this.type });
      this.isActive = false
    },
    outSide() {
      this.isActive = false
    }

  }
}
</script>

<style lang='less' scoped>
.select-container {
  position: relative;

  .input-panel {
    cursor: pointer;
    box-sizing: border-box;
    height: 40px;
    background: #F5F8FD;
    font-size: 14px;
    color: #040D1A;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 12px;
    border-radius: 8px;
    border: 1px solid #F0F1F3;
    &:hover {
      background-color: #F0F2F7;
    }

    .require {
      position: relative;
      &::after {
        content: '*';
        color: #EB0000;
        position: absolute;
        right: -11px;
        top: 0;
      }
    }
    img {
      transition: 0.3s ease-out;
    }
  }

  .placeholder {
    color: rgba(4, 13, 26, 0.45);
  }

  .active {
    background-color: #F0F2F7;
    img {
      transform: rotate(180deg);
    }
  }

  .list-box {
    overflow: hidden;
    border-radius: 8px;
    width: 260px;
    height: fit-content;
    position: absolute;
    left: 0;
    bottom: 60px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid rgba(4, 13, 26, 0.04);
    z-index: 2003;
  }

  .list {
    overflow-y: auto;
    max-height: 350px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(4, 13, 26, 0.04);
    padding: 8px 0;
    box-sizing: border-box;

    li {
      cursor: pointer;
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      padding-right: 60px;
      padding-left: 28px;
      font-size: 16px;
      color: rgba(4, 13, 26, 0.65);
      line-height: 44px;
      box-sizing: border-box;

      &:hover {
        background: rgba(4, 13, 26, 0.04);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
      }

      &.active {
        color: #2A85FF;
      }
    }
  }

}
</style>
<style lang="less">
.three-second-clone-dialog {
  .is-error {
    .require-box {
      border-color: #FE5050 !important;
    }
  }
}
</style>
