export default {
    "speakerSearchType": {
        "quality": "品質",
        "language": "言語",
        "accent": "アクセント",
        "style": "スタイル",
        "scenario": "シナリオ",
        "gender": "性別",
        "age": "年齢"
    },
    "miniAudio": {
        "normal": "標準",
        "min": "最小",
        "max": "最大",
        "open": "展開",
        "stow": "折りたたむ",
        "minimize": "最小化",
        "all": "すべて",
        "male": "男性",
        "female": "女性",
        "noSpeaker": "一致するスピーカーなし",
        "highDefinition24k": "24K 高品質",
        "24k": "24K",
        "highDefinition48k": "48K 超高品質",
        "unlockHighDefinition48k": "クリックして48 SQを解除",
        "48K": "48K",
        "default": "デフォルト",
        "speakerDetail": "スピーカープロフィール",
        "speedOfSpeech": "速さ",
        "word": "文字",
        "second": "秒",
        "intonation": "音調",
        "bigShot": "ビッグショット",
        "recommend": "おすすめ",
        "purchased": "購入済み",
        "lately": "最近使用",
        "purchasedNoData": "まだ声を購入していません。声のショップでお気に入りの声を購入しましょう。",
        "goPay": "アップグレード",
        "fee": "有料",
        "free": "無料",
        "favorite": "お気に入り",
        "favoriteNoData": "お気に入りのスピーカーは追加されていません",
        "recent": "最近使用",
        "All": "すべて",
        "Favorite": "お気に入り",
        "Recent": "最近使用した",
        "DupDubLab": "マイボイス",
        "recentNoData": "最近使用したスピーカーはありません",
        "myVoicesNoData": "ノー、私の声",
        "addFavorited": "收藏に追加",
        "Unfavorite": "お気に入りから削除する",
        "addFavoritedTip": "現在の設定をお気に入りに追加する",
        "UnfavoriteTip": "現在の設定をお気に入りから削除する",
        "wenwenLaboratory": "Mobvoi Lab",
        "wenwenLaboratoryNoData": "Mobvoi Labに関連するコンテンツはありません",
        "noContent": "関連するコンテンツはありません",
        "empty": "空",
        "pay": "支払う",
        "popTxt": {
            "tips": "ヒント",
            "topay": "アップグレード",
            "vip": "VIP",
            "svip": "SVIP",
            "or": "または",
            "use": "使用",
            "48kNeedCoordination": "48Kの高音質は連携が必要です",
            "youSelectDubbingEngineer": "選択したスピーカー",
            "extraUnlock": "は追加料金が必要です。詳細は声のショップでプレビューおよび確認できます。",
            "reminder": "注意",
            "viewDetails": "詳細を見る",
            "know": "了解",
            "speakerFloatingLayer": "スピーカーのフローティングウィジェット",
            "payMemberMatching": "有料の声は会員との組み合わせが必要で、単独では使用できません",
            "contain": "含まれています",
            "styles": "スタイル",
            "buyMember": "アップグレード",
            "receiveUnlock": "は解除が必要です。詳細は声のショップでプレビューおよび確認できます。",
            "goAudition": "プレビュー"
        },
        "mini": "ミニ",
        "supportEnglish": "英語のみサポート",
        "let_english": "英語",
        "changeHere": "ここで音声スタイルを変更",
        "confirm": "確認",
        "cancel": "キャンセル"
    },
    "errTxt": {
        "errorOccured": "エラーが発生しました",
        "failedList": "記事リストの取得に失敗しました",
        "serverError": "サーバーエラー",
        "serverOvertime": "リクエストがタイムアウトしました。後でもう一度お試しください",
        "serverFail": "リクエストが失敗しました。後でもう一度お試しください",
        "serverErrRefresh": "サーバーエラー、後でもう一度お試しください",
        "synthesisFail": "音声の生成に失敗しました",
        "serviceException": "サーバーエラー、後でもう一度お試しください",
        "serviceError": "サーバーエラー、後でもう一度お試しください"
    },
    "toolMenus": {
        "preview": "プレビュー",
        "alias": "別読み方",
        "emphasis": "発音強弱",
        "sayAs": "読み方",
        "phoneme": "発音",
        "localSpeed": "語速",
        "multiSpeaker": "マルチスピーカー",
        "findAndReplace": "まとめ置換",
        "rhythm": "リズム",
        "addPause": "間合い",
        "pauseSetting": "間合設定",
        "soundEffect": "効果音",
        "backgroundMusic": "BGM",
        "lexIcon": "用語集",
        "homograph": "同音異義語",
        "speed": "語速",
        "pitch": "ピッチ",
        "more": "もっと見る",
        "previewSample": "プレビュー",
        "removeFromFavorites": "お気に入りから削除",
        "addToFavorites": "お気に入りに追加"
    },
    "audioDetail": {
        "upgrade": "アップグレード",
        "sampleVoices": "サンプルボイス",
        "voiceSetting": "音声設定に戻る"
    },
    "collection": {
        "title": "続けるためにアップグレード",
        "desc": "現在のプランでは、パーソナライズされたボイス設定を保存する機能はご利用いただけません。この独占的な機能をお楽しみいただくには、プランをプロフェッショナルにアップグレードしてください。<p class='tip'>お楽しみを高め、作業効率を最適化することを躊躇しないでください！</p>",
        "descTip": "お楽しみを高め、作業効率を最適化することを躊躇しないでください！",
        "cancel": "キャンセル",
        "upgrade": "アップグレード"
    },
    "previewOutOfWordsFree": {
        "title": "継続するにはアップグレードしてください",
        "desc": "無料プレビューの最大文字数に達しました。継続してプレビューするにはプランをアップグレードしてください。",
        "subtitles": "字幕とグリーンスクリーンのビデオは、プロフェッショナルおよびアルティメットプランでのみ提供されます。これらの機能にアクセスするにはプランをアップグレードしてください！"
    },
    "subscriptionExpirationFree": {
        "desc": "アカウントが無料トライアルまたはサブスクリプション期間の終了を超えたようです。プランをアップグレードしてプレビューを続けてください。"
    },
    "multiSpeaker": {
        "searchPlaceholder": "スピーカーを検索",
        "speaker": "スピーカー",
        "style": "スタイル",
        "default": "デフォルト",
        "speed": "語速",
        "tone": "ピッチ",
        "apply": "適用",
        "remove": "削除",
        "commonlyUsed": "最近使用した",
        "free": "無料",
        "pleaseSlideTheText": "テキストを選択してください",
        "auditionFaild": "プレビューに失敗しました。リフレッシュして再試行してください",
        "selectSpeakerStyle": "スピーカーとスタイルを選択してください",
        "speakerNotSupportStress": "このスピーカーは発音強弱機能を対応していません",
        "popTxt": {
            "tips": "注意事項",
            "topay": "支払いに進む",
            "vip": "VIP",
            "svip": "SVIP",
            "or": "または",
            "use": "使用する",
            "48kNeedCoordination": "48K高音質は協力が必要です",
            "youSelectDubbingEngineer": "選択したスピーカー",
            "extraUnlock": "は追加料金でロックを解除する必要があります。サウンドストアで試聴および詳細を確認できます",
            "reminder": "注意事項",
            "viewDetails": "詳細を見る",
            "know": "了解しました",
            "speakerFloatingLayer": "スピーカーフローティングレイヤー",
            "payMemberMatching": "有料の音声は会員と組み合わせて使用する必要があり、単独で使用することはできません",
            "contain": "含まれています",
            "styles": "スタイル",
            "buyMember": "アップグレード",
            "receiveUnlock": "はロック解除を受け取る必要があります。サウンドストアで試聴および詳細を確認できます",
            "goAudition": "プレビューに移動"
        }
    },
    "placeholder": {
        "atCharacters": "最大100文字まで",
        "search": "検索",
        "searchArticle": "ファイル名またはキーワードを入力",
        "searchSpeaker": "スピーカー名を入力",
        "create": "新しいフォルダの名前を入力"
    },
}