import account from './account.json'
import common from './common.json'
import article from './article.json'
import project from './project.json'
import miniAudio from './miniAudio.json'
import voiceStore from './voice-store.js'
import tool from './tool.json'
import helpCenter from './help-center.json'
import pricing from './pricing.json'
import pricingOffer from './pricingOffer.json'
import pricingSuccess from './pricingSuccess.json'
import customizedVoice from './customizedVoice.json'
import contact from './contact.json'
import personal from './personal-center.json'
import home from './home.json'
import privacyPolicy from './privacy_policy.json'
import lexicon from './lexicon.json'
import feature from './feature.json'
import activity from './activity.json'
import openapi from './openapi.json'
import talking from './talking.json'
import transcriptionIntroduce from './transcription-introduce.json'
import notification from './notification.json'
import creators from './creators.json'
import referral from './referral.json'
import translation from './translation.json'
import threeSecondClone from './three-second-clone.json'
export default {
  ...account,
  ...common,
  ...article,
  ...miniAudio,
  ...voiceStore,
  ...tool,
  ...helpCenter,
  ...privacyPolicy,
  project,
  openapi,
  feature,
  personal,
  pricing,
  pricingOffer,
  pricingSuccess,
  customizedVoice,
  contact,
  home,
  lexicon,
  activity,
  transcriptionIntroduce,
  talking,
  notification,
  creators,
  referral,
  translation,
  threeSecondClone
}