<template lang="">
    <el-dialog
        :visible.sync="dialogVisible"
        :lock-scroll="false"
        custom-class="subscribe-discount-container"
        width="600px">
        <div class="subscribe-discount-box">
            <div class="closebtn" @click="close"></div>
            <div class="title" >Welcome back! </div>
            <p >We're thrilled to have you back! A 30% discount has been applied to your next subscription:</p>
            <div class="discount" >Billing {{ subscribeData.interval === 'month' ? 'monthly': 'annually'}} · Next invoice on {{ formatTime(subscribeData.payDate) }} for ${{ subscribeData.payAmount }}</div>
            <p>You can also view the detailed billing information on your account page. Thank you for choosing us again!</p>
            <div class="btnlist">
                <div class="btn cancel" @click="goPage('/tools/personal-center')" >View billing details in account</div>
                <div class="btn sure" @click="goPage('/tools/home')" >Head to the studio</div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import moment from 'moment'

export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  props: ['subscribeData'],
  methods: {
    open() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    goPage(path) {
      this.$router.push(path)
      this.close()
    },
    formatTime(val) {
      let time = new Date(val)
      moment.locale(['en-AU'])
      if(moment(time).format('MMM') === 'Sep') {
        return 'Sept ' + moment(time).format('DD, YYYY')
      } else {
        return moment(time).format('MMM DD, YYYY')
      }
    }
  }
}
</script>
<style lang="less" >
.subscribe-discount-container {
  border-radius: 16px !important;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 24px 40px 0;
  }
  .subscribe-discount-box {
    position: relative;
    .closebtn {
      position: absolute;
      right: 0;
      top: 0;
      width: 28px;
      height: 28px;
      background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/unsubscribeDiscount_133256900111/unsubscribeDiscount_133256900111/close.png)
        center;
      background-size: cover;
      cursor: pointer;
      &:hover{
        background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/unsubscribeDiscount_133256900111/unsubscribeDiscount_133256900111/close-hover.png)
        center;
        background-size: cover;
      }
    }
    .title {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #000f1a;
      line-height: 30px;
      margin-bottom: 20px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #666f76;
      line-height: 24px;
      word-break: keep-all;
    }
    .discount {
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      line-height: 24px;
      margin: 12px 0;
    }
    .btnlist {
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 254px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }
      .cancel {
        background: #f0f1f3;
        color: #001126;
        &:hover{
          background: #E5E7EA;
        }
      }
      .sure {
        background: #001126;
        color: #fff;
        &:hover{
          background: #263446;
        }
      }
    }
  }
}
</style>