<template>
  <div class="operationPanel-container">
    <div class="operationPanel-box">
      <div>{{ showTime || '00:00' }}</div>
      <div @click="close('end')">
        <img crossorigin="anonymous" src="../img/end.svg" />
      </div>
      <div v-if="!isPlaying" @click="start">
        <img crossorigin="anonymous" src="../img/start.svg" />
      </div>
      <div v-else @click="pause">
        <img crossorigin="anonymous" src="../img/pause.svg" />
      </div>
      <div @click="close('close')">
        <img crossorigin="anonymous" src="../img/close.svg" />
      </div>
    </div>
  </div>
</template>

<script>
// 录制文件时间限制
const RECORD_TOTAL_TIME = 1 * 60 * 1000
/**
 * 时间刻度上显示的格式化
 * @param duration 时长 单位: ms
 * @returns "hh:mm:ss"
 */
 export const formatTime = (duration, format = '', totalTime) => {
  // 播放时间和总时长有误差 做一个修正
  if(totalTime && duration && Math.abs(totalTime - duration) < 100) {
    duration = totalTime
  }
  // 计算秒数
  let second = Math.floor(duration / 1000)
  let hour = Math.floor(second / 3600)
  let min = Math.floor((second - hour * 3600) / 60)
  let sec = second % 60
  if (format) {
    format = format.replace('hh', hour < 10 ? `0${hour}` : `${hour}`)
    format = format.replace('mm', min < 10 ? `0${min}` : `${min}`)
    format = format.replace('ss', sec < 10 ? `0${sec}` : `${sec}`)
    return format
  }
  return `${hour > 0 ? hour + 'h' : ''}${hour > 0 ? min + 'm' : min > 0 ? min + 'm' : ''
    }${sec}s`
}
export default {
  name: 'operationPanel',
  data() {
    return {
      ising: false,
      time: 0,
      timer: null
    }
  },
  props: ['isPlaying'],
  computed: {
    showTime() {
      return formatTime(this.time, 'mm:ss')
    }
  },
  mounted() { },
  methods: {
    start() {
      this.timer = setInterval(() => {
        this.time += 1000
        if (this.time >= RECORD_TOTAL_TIME) {
          clearInterval(this.timer)
          this.close('limit')
        }
      }, 1000)
      this.$emit('started', this.ising)
    },
    pause() {
      clearInterval(this.timer)
      this.ising = true
      this.$emit('paused')
    },
    /**
     * 
     * @param {*} status limit到时间限制自动结束 close主动点击叉号 end点击结束
     */
    close(status) {
      clearInterval(this.timer)
      this.ising = false
      this.$emit('closed', {
        status,
        time: this.time,
      })
      this.time = 0
    }
  }
}
</script>

<style lang="less" scoped>
.operationPanel-container {
  position: fixed;
  z-index: 2100;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.12);

  .operationPanel-box {
    height: 70px;
    border-radius: 35px;
    background: #000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 63px;
    z-index: 1100;
    display: flex;
    align-items: center;
    color: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.12);

    >div {
      padding: 0 24px;
      font-size: 20px;
      height: 70px;
      display: flex;
      align-items: center;

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }

      &:last-child {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }

      img {
        width: 26px;
        height: 26px;
      }
    }
  }
}
</style>