<template>
  <el-dialog
    title=""
    :visible.sync="centerDialogVisible"
    width="492px"
    custom-class="del-alias-dialog"
    :modal="false"
		:show-close='false'
  >
    <p class="del-dialoge">
      <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/del-dialogue1695793721.png" alt="" @click="centerDialogVisible = false">
    </p>
    <div class="content">
      <p class="title">Are you sure to delete this Lexicon?</p>
      <div class="bottom">
        <el-button class="del-button" @click="cancleLexicon">{{$t('button.cancel')}}</el-button>
        <el-button class="confirm-btn" @click="deleteLexicon">{{$t('button.delete')}}</el-button>
      </div>
    </div>
  </el-dialog>  
</template>
<script>
export default {
  props: {},
  data() {
    return {
      centerDialogVisible: false
    }
  },
  created() {
    this.$on('dialog-visible', state => {
      this.centerDialogVisible = state
    })
  },
  methods: {
    cancleLexicon() {
      this.$emit('cancleLexicon')
    },
    deleteLexicon() {
      this.$emit('deleteList')
    }
  }
}
</script>
<style lang="less">
.del-alias-dialog{
  padding: 16px 0 28px;
  background: #FFFFFF;
  box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.08);
  border-radius: 20px;
  height: 162px;
  left: 50%;
  top: 50%;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  margin:0 !important;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    margin: 0;
    padding: 0;
    .del-dialoge{
      margin-bottom: 6px;
      margin-right: 16px;
      text-align: right;
      img{
        width: 16px;
        height: 16px;
        cursor: pointer;
      } 
    }
    .content{
      padding: 0 28px;
      font-size: 16px;
      .title{
        color: #666666;
        line-height: 23px;
      }
      .bottom{
        margin-top: 32px;
        text-align: right;
        .el-button{
          width: 86px;
          height: 32px;
          border-radius: 12px;
          border: 1px solid #999999;
          line-height: 32px;
          padding: 0;
          margin: 0;
          font-family: 'Poppins-Medium';
          &.del-button{
            margin-right: 14px;
            color: #333333;
            border-color: #999999;
            background: #fff;
            &:hover{
              background: rgba(0, 0, 0, 0.1);
            }
          }
          &.confirm-btn{
            background: #DF4747;
            border-color: #DF4747;
            color: #fff;
            &:hover{
              background: rgba(223, 71, 71, 0.8);
            }
            &.unsubscribe{
              width: 120px;
            }
          }
        }
      }
    }
  }
}
// .del-alias-dialog{
//   width: 492px;
//   height: 167px;
//   background: #FFFFFF;
//   box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.08);
//   border-radius: 20px;
//   .del-img{

//   }
//   .btns-options{
//     .desc{

//     }

//   }
// }
</style>