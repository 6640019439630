/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: '/blog/:title',
    name: 'blogDetail',
    component: () => import(/* webpackChunkName: "resources" */ '@/pages/resources/blob-detail.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      },
      canonical: 'https://app.dupdub.com/blog',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
]