import qs from "qs"

const SLASHES_REGEX = /^\/|\/$/g;
export const normalUrl = (url) => url ? url.replace(SLASHES_REGEX, '') : ''
export const joinUrl = (...urls) => urls.map(normalUrl).join('/')
export const stringifyObj = qs.stringify
export const stringifyUrl = ({ url, query }) => {
    const { url: _url, query: _query } = parseUrl(url)
    query = stringifyObj({
        ..._query,
        ...query
    })
    return `${_url}${query ? `?${query}` : ''}`
}
export const parseUrl = (str) => {
    const [url, search = ''] = str.split('?')
    return {
        url,
        query: qs.parse(search)
    }
}
