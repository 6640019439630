<template>
  <el-dialog append-to-body :visible.sync="showDialog" width="800px" custom-class="three-second-clone-dialog"
    :before-close="closePanel">
    <div class="header">
      <span class="title">Add voice</span>
      <div class="close-box"><iconpark-icon name="iconclose" @click="closePanel" class="close-icon"></iconpark-icon></div>
    </div>
    <div class="body">
      <el-form ref="form" label-position="top" :model="form" :rules="rules">
        <el-form-item label="Name" class="item-require" prop="name">
          <el-input v-model="form.name" placeholder="At most 50 characters"></el-input>
        </el-form-item>
        <el-form-item label="Voice sample" class="item-require" prop="url">
          <div class="uploaded" v-if="form.url">
            <div class="play-panel">
              <audio ref="audio" preload="auto" :src="audiourl" @loadedmetadata="loadedmetadata" @timeupdate="timeupdate"
                @ended="ended"></audio>
              <div class="play" @click="playDemo">
                <div v-if="isplayAudio" class="play-back pause-back">
                  <div class="mask"></div>
                </div>
                <div v-else class="play-back">
                  <div class="mask"></div>
                </div>
                <!-- <img src="./img/play.png" alt=""> -->
              </div>
              <div class="middle">
                <div class="audio-name">
                  {{ fileName }}
                </div>
                <div class="audio-play">
                  <div class="cur-time">{{ currentTime | timeFormat }}</div>
                  <el-slider v-model="currentTime" :max="duration" :min="0" @change="changeAudioCurrentTime"></el-slider>
                  <div class="duration">{{ duration | timeFormat }}</div>
                </div>
              </div>
              <div class="delete" @click="deleteFile">
                <div class="mask"></div>
              </div>
            </div>
          </div>
          <el-row v-else :gutter="12" v-loading="uploadLoading">
            <el-col :span="12" class="upload">
              <el-upload name="file" :on-change="onChange" class="record-card upload-card" :accept="supportTypeList.join()" action="/"
                :http-request="() => { }" :drag="true">
                <div class="icon upload"><img src="./img/upload.svg" alt="upload icon"></div>
                <p class="name">Click to upload a file or drag and drop</p>
                <p class="desc">Audio or video, up to 1 minute, 10MB</p>
                <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload></el-col>
            <el-col :span="12" class="record">
              <div class="record-card" @click="onRecordClick">
                <div class="icon micro">
                  <img src="./img/micro.svg" alt="microphone-icon">
                </div>
                <p class="name">Record microphone</p>
                <p class="desc">Up to 1 minutes</p>
              </div>
            </el-col>
            <el-col :span="12" class="link" :class="{'active':linkUrl}">
              <div class="record-card">
                <div class="textareabox">
                  <el-input type="textarea"
                    placeholder="Please enter or paste a link of YouTube or TikTok"
                    :clearable="true"
                    v-model="linkUrl"
                    @blur="linkAnalysisUrl"
                    @focus="linkTextareaFocus"
                    ></el-input>
                </div>
                <div class="link-card">
                  <div class="icon micro">
                    <img src="./img/link.svg" alt="microphone-icon">
                  </div>
                  <p class="name">Paste a video link</p>
                  <p class="desc">YouTube, TikTok, etc</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="description" label="Reduce background noise" prop="denoise">
          <div class="denoise">
            <el-switch v-model="form.denoise" active-color="#006EFF" :inactive-value="0" :active-value="1" ></el-switch>
          </div>
          <div class="denoise-txt">Clean and clear audio are recommended</div>
        </el-form-item>
        <el-form-item class="description" label="Voice description" prop="language">
          <el-row>
            <el-col :span="8" v-for="(item, index) in list" :key="index">
              <SelectOptions :required="item.required" :isSelctLanguage="!!form.language" @select-item="selectItem"
                :options="item.options" :placeholder="item.placeholder" :selected="form[item.key]" :key="index"
                :type="item.key" />
            </el-col>
          </el-row>

        </el-form-item>
      </el-form>
      <div class="note">
        <div class="agree-content">
          <el-checkbox v-model="isAgree" label="" size="large" />
        {{ getI18n('threeSecondClone.serviceContent') }}<span @click="gotoServiceAndPolicy('s')">{{ getI18n('threeSecondClone.serviceName') }}</span>{{
          getI18n('threeSecondClone.and') }}<span @click="gotoServiceAndPolicy('p')">{{ getI18n('threeSecondClone.policyName') }}</span>{{ getI18n('threeSecondClone.point') }}
        </div>
        <p class="note-item">{{ getI18n('threeSecondClone.note') }}</p>
        <div class="error-tip" :class="{ show: !isAgree && showAgreeTip }">Please acknowledge that you have read and understood the statement.</div>
      </div>

      <div class="footer">
        <div class="btn cancel" @click="closePanel">Cancel</div>
        <div class="btn confirm loading" v-if="isSubmiting"><img src="./img/loading-icon.png" alt=""><span>Just wait a
            bit...</span></div>
        <div class="btn confirm" v-else @click="addMyVoice"><span>Add to my voice</span></div>
      </div>
    </div>
    <OperationPanel ref="operationbox" v-show="isRecording" :isPlaying="isPlaying" @started="onStartRecording"
      @paused="onPauseRecording" @closed="onEndRecording" />

    <!-- 录制没有权限提示弹框 -->
    <el-dialog title="Kind tips" append-to-body :show-close="false" :visible.sync="noAuth" custom-class="noAuth-dialog"
      :close="closeNoAuth">
      <div class="noauth_container">
        <div class="noauth_close" @click="closeNoAuth"></div>
        <p>Please turn on your microphone permission.</p>
        <!-- <p>
          请点击浏览器网页前的
          <img src="./img/lock.svg" />，打开摄像头和麦克风权限以开始录制。
        </p> -->
        <!-- <div class="noauth_box">
          <img src="./img/auth.gif" />
        </div> -->
        <div class="noauthbtn" @click="closeNoAuth">Got it</div>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { videoTranslationApi } from '@/api/index.js'
import { threeSecondClone } from '../../api'
import CameraRecorderReal from './util/CameraRecorderReal'
// import { mapActions } from 'vuex'

import SelectOptions from './selectOptions.vue'
import { cloneDeep } from 'lodash'
import uploadFile from '@dupdub/upload-file'
import OperationPanel from './components/operationPanel.vue'
import { MessageBox } from 'element-ui'
import { dealSoundTime } from "./util/util"
import i18nObj from "./i18n/index"
import stat from "@/utils/stat"
import {ttsUploadUrl} from "@/utils/env.js"
import {isAcceptFile } from '@/utils/file'
import { parseDuration } from '@/utils/common-function'
// 10M
const FILE_LIMIT = 10 * 1024 * 1024
// 1分钟
const MAX_DURATION = 1 * 60
function _get(obj, path, defaultValue){
  const keys = path.split('.')
  for(let key of keys){
    if(!(key in obj)) return defaultValue
    obj = obj[key]
  }
  return obj 
}
let CameraReal = null
export default {
  name: 'threeSecondClone',
  components: { SelectOptions, OperationPanel },
  props: {
    isFromHome: { // Home的需要跳转编辑器，声音面板刷新列表
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: 'en'
    }
  },
  data() {
    var validateName = async (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('Please provide a name for the cloned voice.'));
      }
      if (value.length > 50) {
        callback(new Error('At most 50 characters'));
      }
      // 做免登录，不用调验证名字接口，创建的时候也有名字判断
      // const { data } = await threeSecondClone.nameExist({ name: value })
      // if (data.code == 200 && data.data) { // 掉接口校验一下
      //   callback(new Error('The name is already in use.'));
      // }
      callback()
    };
    var validateUrl = (rule, value, callback) => {
      if (!value && !this.linkUrl) {
        callback(new Error('Please upload a voice sample'));
      }
      callback()
    };
    var validateLanguage = (rule, value, callback) => {
      if (!value || !this.form.accent || !this.form.gender || !this.form.age) {
        callback(new Error('Don\'t forget to add the voice descriptions!'));
      }
      callback()
    };
    return {
      fileMinDuration: 1, // 文件最小时长
      linkUrl:'',//音视频地址
      isAgree: false,
      showAgreeTip: false,
      validLanguage: false, // 需要校验prop language
      isSubmiting: false,
      uploadStatus: 0, // 0 未点击确认取消 1 确认 2 取消
      recordUrl: '',
      uploadLoading: false,
      currentTime: 0,
      duration: 0,
      fileLength: 0,
      audiourl: '',
      isplayAudio: false,
      fileName: '',
      isRecording: false,
      isPlaying: false,
      showDialog: false,
      noAuth: false,
      form: {
        name: '',
        url: '', // 音频链接地址
        language: '',
        accent: '', // 方言
        gender: '',
        age: '',
        style: '',
        denoise: 0
      },
      list: [
        {
          key: 'language',
          placeholder: 'Language',
          required: true,
          options: [
          ]
        },
        {
          key: 'accent',
          placeholder: 'Accent',
          required: true,
          options: []
        },
        {
          key: 'gender',
          placeholder: 'Gender',
          required: true,
          options: []
        },
        {
          key: 'age',
          placeholder: 'Age',
          required: true,
          options: []
        },
        {
          key: 'style',
          placeholder: 'Style',
          required: false,
          options: []
        },
      ],
      supportTypeList: [
        '.mp4',
        '.mov',
        '.webm',
        '.mkv',
        '.m2p',
        '.3gp',
        '.m4v',
        '.mp3',
        '.wav',
        '.aac',
        '.m4a',
        '.flac',
        '.opus',
        '.ac3',
        '.rm',
        '.mpg',
        '.vob',
        '.wmv',
        '.ts',
        '.mxf',
        '.mts',
        '.avi',
        '.rmvb',
        '.m2ts',
        '.flv',
      ],
      cannotPlay:[
        'flv',
        'm2ts',
        'rm',
        'vob',
        'ts',
        'wmv',
        'mxf',
        'mts',
        'avi',
        'rmvb',
        'ac3'
      ],
      rules: {
        name: [
          { validator: validateName, trigger: 'blur' }
        ],
        url: [
          { validator: validateUrl }
        ],
        language: [
          { validator: validateLanguage }
        ],
      }
    }
  },
  filters: {
    timeFormat(v) {
      return dealSoundTime(v)
    }
  },
  mounted() {
    this.getspeakerInfo()
  },
  watch: {
    async recordUrl(val) {
      if (val && this.uploadStatus != 2) {
        try {
          const { data } = await threeSecondClone.extractAudioToWav(val)
          if (data.code == 200 && data.data) {
            this.uploadSuccess(data.data)
            this.audiourl = this.form.url = data.data.wavUrl
            this.currentTime = 0
            this.$refs.form.validateField('url')
            return
          }
          throw new Error('transform wav fail')
        } catch (err) {
          if (err.message == 'transform wav fail') {
            this.$message.error('Transform wav fail, please try again')
          } else {
            this.$message.error(err.message)
          }
          this.$refs.form.validateField('url')

          console.log(err, 'transform wav fail');
        }
        this.uploadLoading = false
      } else if (val && this.uploadStatus == 2) {
        this.uploadLoading = false
      }
    }
  },
  methods: {
    // ...mapActions('userInfo', ['getVipInfo']),
    //链接提取
    linkAnalysisUrl(){
      this.duration=0
      this.fileLength = 0
      if (!this.linkUrl) return
      // 校验是否为http/https
      const domainRegex = /^(http|https):\/\/[^ "]+$/;
      if (!domainRegex.test(this.linkUrl)){
        this.$message.error(`We're sorry, but the URL you provided is currently not supported.`)
        return
      }
      this.uploadLoading = true
      videoTranslationApi.achieveVideoByLink({
        url: this.linkUrl
      }).then(res => {
        if (res.data.code === 200) {
          this.transformToWavUrl(res.data.data.ossUrl, {name: res.data.data.title})
        } else {
          this.uploadLoading = false
          this.$message.error(res.data.message)
        }
      }).catch((err)=>{
        this.$message.error(err)
      })
    },
    linkTextareaFocus(){
      this.linkUrl=''
    },
    // 显示弹窗
    getI18n(path) {
      return _get(i18nObj[this.locale || 'en'], path, path)
    },
    openDialog() {
      stat.event('studio_instantvoicecloning_exposure')
      this.validLanguage = false
      this.showDialog = true
      this.uploadLoading = false
      this.recordUrl = ''
      // 重置表单
      Object.keys(this.form).forEach(v => {
        this.form[v] = ''
      })
      this.audiourl = '';
      this.fileName = '';
      this.isAgree = false;

      this.$nextTick(() => {
        this.showAgreeTip = false;
        // 对该表单项进行重置，将其值重置为初始值并移除校验结果
        this.$refs.form.resetFields()
        // 移到$nextTick下，否则赋值后不能重置表单项
        const formData = JSON.parse(localStorage.getItem('three_second_clone'))
        if(formData && this.isFromHome) {
          this.isAgree = true;
          Object.keys(this.form).forEach(v => {
            this.form[v] = formData[v];
            if(v === "language" && this.list?.[0].options?.length) {
              const item = this.list[0].options.find(v => v?.value === formData[v]);
              this.selectItem({ item, type: v })
              this.form.accent = formData.accent || 'No option';
            }
            if(v === 'url') this.audiourl = formData[v];
          })
          this.fileName = formData.fileName;
          localStorage.removeItem('three_second_clone');
        }
      })
    },
    // 关闭弹窗
    closePanel() {
      if (this.$refs.audio) {
        this.isplayAudio = false
        this.$refs.audio.pause()
      }
      this.linkUrl=''
      this.showDialog = false
    },
    gotoServiceAndPolicy(type) {
      if(type === 's') {
        window.open(process.env.VUE_APP_DUPDUB_URL + '/terms-of-service?'+this.$ssrUrlSearch(), '_blank')
        return
      }
      window.open(process.env.VUE_APP_DUPDUB_URL + '/privacy-policy?'+this.$ssrUrlSearch(), '_blank')
    },

    // 试听上传后的文件音频
    playDemo() {
      if (this.isplayAudio) {
        this.isplayAudio = false
        this.$refs.audio.pause()
        return
      }
      this.audiourl = this.form.url
      this.isplayAudio = true
      this.$nextTick(() => {
        this.$refs.audio.play()
      })
    },
    loadedmetadata() {
      this.duration = this.$refs.audio.duration
    },

    timeupdate() {
      this.currentTime = Math.ceil(this.$refs.audio.currentTime)
    },
    changeAudioCurrentTime(time) {
      this.$refs.audio.currentTime = time
    },
    ended() {
      this.isplayAudio = false
    },

    deleteFile() {
      MessageBox.confirm('', this.getI18n('threeSecondClone.deleteTitile'), {
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        confirmButtonText: this.getI18n('threeSecondClone.delete'),
        cancelButtonText: this.getI18n('threeSecondClone.cancel'),
        customClass: 'three-second-clone-message-box mini-three-second-clone-message-box',
        showClose: false
      })
        .then(() => {
          this.form.url = ''
          this.recordUrl = ''
          this.uploadLoading = false
          this.currentTime = 0
          this.isplayAudio = false
          this.duration = 0
          this.fileLength = 0
          this.$refs.form.validateField('url')

        })
        .catch(() => {
          console.log('cancel');
        })
    },
    // 开始录制 false:开始录制，true：继续录制
    onStartRecording(type) {
      this.isPlaying = true
      if (!type) {
        CameraReal.start()
      } else {
        CameraReal.resume()
      }
    },
    // 暂停录制
    onPauseRecording() {
      this.isPlaying = false
      CameraReal.pause()
    },

    // 点击开始录制按钮功能
    onRecordClick() {
      this.uploadStatus = 0
      this.recordUrl = ''
      CameraReal = new CameraRecorderReal({
        params: { audio: true },
        mimeType: 'audio/webm;codecs=opus',
        type: 'audio/webm',
        // 传入成功回调，data成功数据 有url，title等
        callbackRecorder: (data) => {
          if (!data.resourceUrl) {
            this.uploadStatus = 0
            this.uploadLoading = false
            return
          }
          // 上传过后调用接口webm转为wav
          this.fileName = data.title.replace('.webm', '.wav')
          this.recordUrl = data.resourceUrl
        }
      })
      CameraReal.checking(this.onCheckIsRecord)
    },
    //结束/关闭录制
    onEndRecording(obj) {
      CameraReal.stop(file => {
        this.checkForce(file, obj)
      })
      this.closeAllPanel()
    },
    checkForce(file, { status }) {
      if (status === 'limit') {
        // 时间到达限制自动弹窗
        this.sureUploadMedia(this.getI18n('threeSecondClone.recordConfirm'), this.getI18n('threeSecondClone.recordLimitTip'))
        return
      } else if (status === 'close') {
        // 点击叉号
        this.sureUploadMedia(this.getI18n('threeSecondClone.recordConfirm'), this.getI18n('threeSecondClone.cancelUploadTip'))
      }
      if (!this.recordUrl) {
        this.uploadLoading = true
        this.uploadStatus = 1
      }
    },
    // 确认上传弹框（实际可能已经上传完成，需要判断是否需要将上传完成后的地址保存
    sureUploadMedia(title, content) {
      MessageBox.confirm(title, content, {
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        confirmButtonText: this.getI18n('threeSecondClone.upload'),
        cancelButtonText: this.getI18n('threeSecondClone.cancel'),
        customClass: 'three-second-clone-message-box',
        showClose: false
      })
        .then(() => {
          if (!this.recordUrl) {
            this.uploadLoading = true
            this.uploadStatus = 1
          }
        })
        .catch(() => {
          if (!this.recordUrl) {
            this.audiourl = this.form.url = ''
            this.uploadLoading = true
            this.uploadStatus = 2
          } else {
            this.uploadLoading = false
            this.uploadStatus = 0
            this.audiourl = this.form.url = ''
            this.$refs.form.validateField('url')
          }
        })
    },
    // 关闭录制面
    closeAllPanel() {
      this.isPlaying = false
      this.isVideotape = false
      this.isRecording = false
    },
    onCheckIsRecord(type, err) {
      if (type) {
        this.isRecording = true
        this.$nextTick(() => {
          this.$refs.operationbox.start()
        })
      } else {
        this.cheeckNoAuth(err)
        this.closeAllPanel()
      }
    },
    cheeckNoAuth(error) {
      console.log('err', error)
      if (error === 'NotAllowedError') {
        this.noAuth = true
      }
    },
    closeNoAuth() {
      this.noAuth = false
    },
    // 上传本地文件校验
    async onChange(file) {
      file = file.raw
      if (!file || file.size == 0) {
        return this.$message.error('The file doesn\'t exit')
      }

      let durationJson = await parseDuration(file)
      const duration = durationJson.track ? durationJson.track[0].Duration : 0
      let MIN_DURATION = 1
      // 小于1s，提示
      if (duration < MIN_DURATION) {
        this.$message.error( `Please upload a file that is at least ${MIN_DURATION} second long.`)
        return
      }

      let lastIndex = file.name.lastIndexOf('.')
      let suffix = file.name.slice(lastIndex)
      let lowerSuffix = suffix.toLowerCase()

      if (!isAcceptFile(file, this.supportTypeList,true)) {
        this.$message.error('This file format is currently not supported. Please try a different one.')
        return
      }

      if (!this.supportTypeList.includes(lowerSuffix)) {
        this.$message.error('This file format is currently not supported. Please try a different one.')
        return 
      }
      this.onChangeUpload(file)
    },

    
    async onChangeUpload(file){
      this.uploadLoading = true
      this.uploadOss(file)
    },
    // 上传完成
    async transformToWavUrl(url, file){
      await threeSecondClone.extractAudioToWav(url).then(res=>{
        if(res.data.code === 200){
          let _data = res.data.data
          this.uploadSuccess(_data, file)
        }else{
          this.uploadLoading = false
          this.$refs.form.validateField('url')
        }
      }).catch((err) => {
         this.$message.error(err)
      })
    },
    // 上传资源的裁剪的提示弹窗
    cropPop( text ){
      stat.event('instantvoice-autotrimpopup_exposure')
      MessageBox.confirm(text, 'Limit Exceeded', {
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        confirmButtonText:  this.getI18n('threeSecondClone.autoTrim'),
        cancelButtonText: this.getI18n('threeSecondClone.tryAnother'),
        customClass: 'three-second-clone-message-box',
        showClose: true,
        beforeClose:((action,instance,done) => {
          if(action == 'confirm'){
            instance.confirmButtonLoading = true;
            this.subMyVoice(done, instance)
            stat.event('instantvoice-autotrimpopup_autotrim_click')
          }else{
            done()
          }
        })
      })
        .catch(() => {
          this.uploadLoading = false
          this.$refs.form.validateField('url')
          console.log('cancel');
        })
    },
    // 裁剪完成，进行页面渲染
    async uploadSuccess(sourceInfo, file = {}){
      this.audiourl = this.form.url = sourceInfo.wavUrl
      this.$refs.form.validateField('url')
      if(Object.keys(file).length > 0) 

      this.fileName = file.name
      this.currentTime = 0
      this.uploadLoading = false   
      this.duration = sourceInfo.duration / 1000

      this.fileLength = file.length
    },
    // 上传cdn
    async uploadOss(file) {
      uploadFile(ttsUploadUrl, 'frontend/dupdub-upload/').multipleUploads({
        file,
        progress: (res) => {
          console.log('file-progress----', res)
        },
        success: async (fileUrl) => {
          this.transformToWavUrl(fileUrl, file)
        },
        fail: (error) => {
          console.error('fail', error)
          this.uploadLoading = false
          this.$refs.form.validateField('url')
        }
      })
    },
    

    // 提交数据
    async addMyVoice() {
      // 防重提交
      if (this.isSubmiting == true) {
        return
      }
      this.isSubmiting = true

      this.$refs.form.validate(async (valid) => {
        if (!this.isAgree) {
          this.showAgreeTip = true;
          this.isSubmiting = false;
          if(!valid) this.validLanguage = true;
          return;
        }
        if (valid) {

          // 时长超过了
          if (this.duration > MAX_DURATION) {
            this.isSubmiting = false
            this.cropPop(this.getI18n('threeSecondClone.text1minutes'))
            return
          }
          // 文件大小超过了
          if (this.fileLength > FILE_LIMIT){
            this.isSubmiting = false
            this.cropPop(this.getI18n('threeSecondClone.text10M'))
            return
          }
          // 正常提交
          this.subMyVoice()
        } else {
          this.isSubmiting = false
          this.validLanguage = true
        }
      })
    },
    async subMyVoice(done = '',instance = ''){
      let params = cloneDeep(this.form)
      params.accent === 'No option' && (params.accent = '')
      try {
        const { data: { code, message } } = await threeSecondClone.speakerClone(params)
        if (code == 200) {
          this.$bus.$emit('couponStatus', {itemType: 3})
          if(done !== '') {
            done()
            instance.confirmButtonLoading = false;
          }
          // this.$message.success('Just moments away from cloning your voice.')
          if (this.isFromHome) {
            const query = {
              openMiniAudio: true,
              audioTab: 'DupDubLab'
            }
            let routeData = this.$router.resolve({
              path: '/overview/article-voice',
              query
            });
            //当前窗口打开
            window.open(routeData.href, '_self');
          } else {
            this.$emit('get-dupdub-lab')
          }
          this.showDialog = false
          this.isSubmiting = false
        } else {
          throw new Error(message)
        }
      } catch (err) {
        this.isSubmiting = false
        console.log('addMyVoice', err);
        if(err.code === 'ELOGOUT') {
          params.fileName = this.fileName;
          localStorage.setItem('three_second_clone', JSON.stringify(params));
          this.$router.push({
            path: "/login",
            query: {
              to: 'tools/home?show=three_second_clone',
            },
          });
          return;
        }
        if(done !== '') {
          done()
          instance.confirmButtonLoading = false;
        }
        this.$message.error(err.message || 'Clone fail, try again')
      }
    },
    // 获取表单选择数据
    async getspeakerInfo() {
      const { data: { data, code } } = await threeSecondClone.getSpeakerInfo()
      if (code == 200) {

        const list = cloneDeep(this.list)
        list.forEach(v => {
          switch (v.key) {
            case 'language':
              v.options = data.specificLanguage
              break;
            case 'gender':
              v.options = data.gender
              break;
            case 'age':
              v.options = data.age
              break;
            case 'style':
              v.options = data.style
              break;

          }
        })
        this.list = list
        if(this.form.language && this.list?.[0].options?.length) {
          const accent = this.form.accent;
          const item = this.list[0].options.find(v => v?.value === this.form.language);
          if(item?.value) this.selectItem({ type: 'language', item })
          this.form.accent = accent || 'No option';
        }
      }
    },
    // 表单选择
    selectItem({ type, item }) {
      if(!item?.value) return;
      this.form[type] = item.value
      if (type === 'language') {
        this.$set(this.list, 1, { ...this.list[1], options: [...item.detail, { name: 'No option', value: 'No option' }] })
        this.form.accent = ''
      }
      if (this.validLanguage) {
        this.$refs.form.validateField('language')
      }
    },

  }
}
</script>

<style lang='less'>
.el-dialog.three-second-clone-dialog {
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 20px auto !important;
  height: fit-content;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 13px 40px 28px;
  background: #fff;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      width: 584px;
      height: 24px;
      font-size: 16px;
      font-family: Poppins-SemiBold;
      color: #040D1A;
      line-height: 24px;
    }

    .close-box {
      width: 32px;
      height: 32px;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #F2F4F7;
      }

      .close-icon {
        width: 28px;
        height: 28px;
        font-size: 28px;
      }
    }



  }

  .body {
    margin-top: 14px;
    .denoise{
      position: absolute;
      left: 220px;
      top: -40px;
      .el-switch{
        .el-switch__input{
          border-radius: 15px;
        }
        .el-switch__core{
          width: 38px !important;
          height: 22px;
          &:after{
            top: 2px;
            left: 2px;
          }
        }
        &.is-checked{
          .el-switch__core{
            &:after{
              top: 2px;
              left: 35px;
            }
          }
        }
      }
    }
    .denoise-txt{
      color: rgba(4, 13, 26, 0.45);
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 4px;
    }
    .el-input__inner {
      background: #F5F8FD;
      border-radius: 8px;
      border: 1px solid #F0F1F3;
      font-size: 14px;
      color: #040D1A;
      line-height: 22px;

      &:hover {
        border-color: #CACBCC;
      }

      &:focus {
        border-color: #3583FB;
      }

      &:placeholder {
        color: rgba(4, 13, 26, 0.45);
      }
    }

    .el-form-item {
      margin-bottom: 18px;
      display: block;
      .record,.link {
        width: 28%;
      }
      .upload{
        width: 44%;
      }
      .link{
        .textareabox{
          display: none;
          width: 100%;
          .el-textarea__inner{
            font-size: 12px;
            resize: none;
            height: 106px;
            background: #FFFFFF;
            border-radius: 8px;
            color: #222222;
            padding: 8px;
            line-height: 20px;
            border: 1px solid transparent;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(90deg, #fff 0%, #fff 100%),
            linear-gradient(158deg, rgba(221, 236, 255, 1), rgba(235, 230, 255, 1));
            &::-webkit-input-placeholder {
              font-size: 12px;
              color: rgba(4,13,26,0.35);
              line-height: 20px;
            }
          }
        }
        .link-card{
          display: block;
        }
        &:hover,&.active{
          .record-card{
            padding: 6px;
            box-sizing: border-box;
          }
          .textareabox{
            display: block;
          }
          .link-card{
            display: none;
          }
        }
      }
      .el-form-item__content {
        display: block;
      }

      .el-form-item__label {
        width: fit-content;
        height: 24px;
        font-size: 16px;
        color: #040D1A;
        line-height: 24px;
        position: relative;
        margin-bottom: 8px;
        padding: 0;
      }

      /* el-upload-list */
      .el-upload-list {
        display: none;
      }

      .upload-card {
        padding: 0 !important;
        border: 0 !important;

        .el-upload {
          display: block;
          width: 100%;
          height: 100%;

          &:focus {
            .el-upload-dragger {
              border-color: rgba(4, 13, 26, 0.15);
            }
          }

          .el-upload-dragger {
            width: 100%;
            height: 100%;
            padding: 17px 0;
            box-sizing: border-box;

            &:hover {
              border-color: #90BFFE;
              background: #F7FAFE;
            }
          }
        }

      }

      .record-card {
        width: 100%;
        border-radius: 8px;
        border: 1px dashed rgba(4, 13, 26, 0.15);
        padding: 17px 0;
        cursor: pointer;

        &:hover {
          border-color: #90BFFE;
          background: #F7FAFE;
        }

        .icon {
          margin: 0 auto;
          width: 32px;
          height: 32px;
          padding: 6px;
          border-radius: 8px;
          line-height: 0;
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .icon.upload {
          background-color: #9166FF;
        }

        .icon.micro {
          background-color: #006EFF;
        }

        .name {
          text-align: center;
          font-size: 14px;
          color: #040D1A;
          line-height: 22px;
          margin: 8px auto 4px;
        }

        .desc {
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: rgba(4, 13, 26, 0.45);
          line-height: 18px;
        }
      }

      .uploaded {
        width: 720px;
        height: 120px;
        border-radius: 8px;
        border: 1px dashed rgba(4, 13, 26, 0.15);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .play-panel {
          width: 440px;
          height: 64px;
          background: linear-gradient(90deg, #E5F6FF 0%, #EEF4FF 40%, #FFE5F7 63%, #FFE5F3 100%);
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          padding-left: 32px;
          border: 2px solid;
          border-color: #EEF4FF;

          .play {
            flex: none;
            width: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .play-back,
            .pause-back {
              width: 24px;
              height: 24px;
              background-image: url(./img/play.png);
              background-size: 24px 24px;
              background-repeat: no-repeat;
              background-position: 0 0;
              position: relative;

              .mask {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                border-radius: 50%;
                background: rgba(0, 0, 0, .1);
              }

              &:hover {
                .mask {
                  display: block;
                }
              }

            }

            .pause-back {
              background-image: url(./img/pause-audio.png);
            }

            img {
              width: 24px;
              height: 24px;
            }
          }

          .middle {
            flex: 1;
            padding: 12px 32px 12px 16px;
            min-width: 0;
            border-radius: 0px 8px 0px 8px;

            .audio-name {
              width: 100%;
              height: 22px;
              font-size: 12px;
              color: #222222;
              line-height: 22px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .audio-play {
              display: flex;
              align-items: center;

              .cur-time,
              .duration {
                width: 36px;
                height: 18px;
                font-size: 12px;
                color: #222222;
                line-height: 18px;
              }

              .el-slider {
                flex: 1;

                .el-slider__button-wrapper {
                  top: -16px;
                }

                .el-slider__runway {
                  height: 4px;
                  background: #9AAAC5;
                  margin: 0;

                  .el-slider__bar {
                    height: 4px;
                    background: #3583FB;
                  }
                }

                .el-slider__button {
                  width: 10px;
                  height: 10px;
                  border: none;
                  background: #3583FB;
                }
              }

              .cur-time {
                padding-right: 10px;
              }

              .duration {
                padding-left: 10px;
              }

            }
          }

          .delete {
            cursor: pointer;
            flex: none;
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 0px 8px 0px 8px;
            padding: 3px;
            box-sizing: border-box;
            line-height: 0;
            background-image: url(./img/Delete@2x.png);
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: center center;
            position: relative;

            .mask {
              display: none;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              border-radius: 50%;
              background: rgba(0, 0, 0, .1);
              border-radius: 0px 8px 0px 8px;

            }

            &:hover {
              .mask {
                display: block;
              }
            }
          }
        }
      }

      .upload-tip {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(4, 13, 26, 0.65);
        line-height: 18px;

        &:first-of-type {
          margin-top: 8px;
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .rec {
        margin-top: 6px;
        color: rgba(4, 13, 26, 0.45);
      }
    }

    .is-error {

      .el-input__inner,
      .el-upload-dragger,
      .record-card {
        border-color: #FE5050;
      }

      .el-form-item__error {
        font-size: 12px;
        color: #FE5050;
        line-height: 15px;
      }

    }

    .description {
      .el-row {
        display: block;
        margin-top: -5px;
        width: calc(100% + 12px);
        margin-left: -6px;

        .el-col {
          margin-top: 12px;
          padding: 0 6px;
        }
      }
    }

    .item-require {
      .el-form-item__label:after {
        content: '*';
        position: absolute;
        right: -11px;
        top: 0;
        color: #EB0000;

      }
    }
  }

  .note {
    word-break: keep-all;
    font-size: 12px;
    color: rgba(4, 13, 26, 0.45);
    line-height: 18px;
    .agree-content {
      position: relative;
      padding-left: 20px;
      .el-checkbox {
        position: absolute;
        left: 0;
      }
    }
    .agree-content span {
      color: rgba(53, 131, 251, 1);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .error-tip {
      font-size: 12px;
      color: #FE5050;
      line-height: 15px;
      visibility: hidden;
      &.show {
        visibility: visible;
      }
    }
    .note-item {
      padding-left: 20px;
      margin-top: 6px;
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;

    .btn {
      cursor: pointer;
      width: 192px;
      height: 44px;
      background: #F0F1F3;
      border-radius: 8px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
      color: #001126;
      line-height: 44px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--default-hover-bg-color);
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        animation: loading 3s linear infinite;
      }
    }

    .confirm {
      margin-left: 16px;
      background: #040D1A;
      color: #FFFFFF;

      &:hover {
        background: #1a293c;
      }
    }

    .loading {
      background: rgba(4, 13, 26, 0.45);

      &:hover {
        background: rgba(4, 13, 26, 0.45);
      }
    }

  }



}

.noAuth-dialog {
  width: 492px;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;

  .el-dialog__header {
    text-align: left;
    padding: 28px 30px 10px;
    font-family: HarmonyOS_Sans_SC_Medium;
  }

  .el-dialog__body {
    padding: 15px 30px 36px;
    text-align: left;
    position: relative;

    .noauth_close {
      width: 20px;
      height: 20px;
      position: absolute;
      background: url('./img/bt_close_gray.svg') no-repeat;
      right: 28px;
      top: -32px;
    }

    p {
      color: #333333;
      height: 50px;
      text-align: center;

      img {
        position: relative;
        top: -2px;
      }
    }

    .noauth_box {
      background: #ebf3ff;
      border-radius: 4px;
      border: 1px solid #dddddd;
      margin: 18px auto 23px;

      img {
        width: 100%;
      }
    }

    .noauthbtn {
      width: 292px;
      line-height: 44px;
      background: #3583fb;
      border-radius: 5px;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.three-second-clone-message-box {
  width: 600px;
  box-sizing: border-box;
  position: relative;
  border-radius: 16px;
  padding-bottom: 40px;

  .el-message-box__header {
    padding: 24px 40px 20px;

    .el-message-box__title span {
      font-size: 20px;
      font-family: 'Poppins-SemiBold';
      font-weight: 600;
      color: #000F1A;
      line-height: 30px;
      height: fit-content;
    }
  }

  .el-message-box__content {
    padding: 0 40px;

    .el-message-box__message {
      font-size: 16px;
      color: #666F76;
      line-height: 24px;
    }
  }

  .el-message-box__btns {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .el-button {
      width: 174px;
      height: 44px;
      background: #F0F1F3;
      border-radius: 8px;
      font-size: 16px;
      font-family: 'Poppins-Medium';
      color: #001126;
      line-height: 25px;
      border: none !important;

      &:hover {
        background-color: var(--default-hover-bg-color);
      }
    }

    .el-button--primary {
      background: #040D1A !important;
      color: #FFFFFF !important;

      &:hover {
        background: #1a293c !important;
      }
    }
  }
}

.mini-three-second-clone-message-box {
  width: 400px;

  .el-message-box__header {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.upgrade-three-second-clone-message-box {
  overflow: visible;
  background: linear-gradient(180deg, #F0E1FF 0%, #FFFFFF 40%, #FFFFFF 100%);
  padding-top: 16px;

  img {
    position: absolute;
    right: -40px;
    top: -117px;
    width: 190px;
    height: 136px;
  }
}</style>
