/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: '/ai-writing-editor/:id',
    name: 'ai-writing-editor',
    component: () => import(/* webpackChunkName: "ai-writing-editor" */ '@/pages/ai-writing-editor/index.vue'),
    meta: {
      content: {
        keywords: '',
        description: ''
      },
      canonical: 'https://app.dupdub.com/ai-writing-editor',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
]