/*
 * @Author: pxbai
 * @Date:   2019-10-13 17:02:32
 * @Last Modified by: mongo.qiu
 * @Last Modified time: 2021-06-30 13:52:47
 */
import $ from 'jquery'
import {$t} from '@/assets/language/util.js'
import { TypeToExt } from './mime-types'
import { changeOptionsToParams } from '@/utils/utils'
import { getCookie } from '@/utils/cookie'
import { cloneDeepWith } from 'lodash'

export function downloadMp4(videoUrl, title, callback){
  // eslint-disable-next-line
  const xhr = new XMLHttpRequest();
  xhr.open('GET', videoUrl, true);
  xhr.responseType = 'blob';
  xhr.onprogress = (event) => {
    if (event.lengthComputable) {
      if (callback) callback(event)
    }
  }
  xhr.onload = function() {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: 'video/mp4' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.mp4`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  xhr.send();
}

export function downloadPhoto(photoUrl, title) {
  const fileExtension = photoUrl.slice(photoUrl.lastIndexOf('.') + 1)
  const xhr = new XMLHttpRequest();
  xhr.open('GET', photoUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = function() {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: `img/${fileExtension}` });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.jpg`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  xhr.send();
}

export function createSrtFile(data, title = $t('transcription.txt')) {
  let _srt = ''
  let _index = 1
  data.forEach((captionClip)=>{
    if (captionClip.text) {
      _srt += `${_index++} \n`
      _srt += `${durationToSrtTime(captionClip.inPoint)} --> ${durationToSrtTime(captionClip.outPoint)}\n`
      _srt += `${replaceAllPunctuation(captionClip.text)}\n`
      _srt += '\n'
    }
  })
  let ele = document.createElement('a')
  ele.style.display = 'none'
  ele.download = title + '.srt'
  ele.href = URL.createObjectURL(new Blob([_srt]))
  document.body.appendChild(ele)
  ele.click()
  document.body.removeChild(ele)
}
export function durationToSrtTime(point) {
  let milliseconds = point;
  // 计算时、分、秒和毫秒数
  let hours = Math.floor(milliseconds / 3600000);
  let minutes = Math.floor((milliseconds - (hours * 3600000)) / 60000);
  let seconds = Math.floor((milliseconds - (hours * 3600000) - (minutes * 60000)) / 1000);
  let ms = Math.floor(milliseconds - (hours * 3600000) - (minutes * 60000) - (seconds * 1000));
  // 格式化输出
  return (hours < 10 ? "0" : "") + hours + ":" +
    (minutes < 10 ? "0" : "") + minutes + ":" +
    (seconds < 10 ? "0" : "") + seconds + "," +
    (ms < 10 ? "00" : ms < 100 ? "0" : "") + ms;
}


function copy(text) {
  return new Promise((resolve,reject) => {
    var textareaEl = document.createElement('textarea');
    textareaEl.setAttribute('readonly','readonly');
    textareaEl.value = text;
    document.body.appendChild(textareaEl);
    textareaEl.select();
    var res = document.execCommand('copy');
    document.body.removeChild(textareaEl);

    if (res) {
      resolve()
    } else {
      reject('copy error')
    }
  })
}
/* eslint-disable */
const dragmyDom = (drag) => {
  // //点击某物体时，用drag对象即可，move和up是全局区域，
  // 也就是整个文档通用，应该使用document对象而不是drag对象(否则，采用drag对象时物体只能往右方或下方移动)
  var diffX,diffY
  drag.ondragstart  = function (event) {
    var event = event || window.event;  //兼容IE浏览器
    //    鼠标点击物体那一刻相对于物体左侧边框的距离=点击时的位置相对于浏览器最左边的距离-物体左边框相对于浏览器最左边的距离
    diffX = event.clientX - drag.offsetLeft;
    diffY = event.clientY - drag.offsetTop;
    let arrow=$(drag).find('.arrow-icon')[0]
    if(arrow){
      arrow.style.display="none"
    }
    if (typeof drag.setCapture !== 'undefined') {
      drag.setCapture();
    }
  }
  drag.ondrag  = function (event) {
    var event = event || window.event;
    event.preventDefault();
    var moveX = event.clientX - diffX;
    var moveY = event.clientY - diffY;
    if (moveX < 0) {
      moveX = 0
    } else if (moveX > window.innerWidth - drag.offsetWidth) {
      moveX = window.innerWidth - drag.offsetWidth
    }
    if (moveY < 0) {
      moveY = 0
    } else if (moveY > window.innerHeight - drag.offsetHeight) {
      moveY = window.innerHeight - drag.offsetHeight
    }
    if(moveX==0&&moveY==0){
      return
    }
    drag.style.left = moveX + 'px';
    drag.style.top = moveY + 'px';
    drag.style.right = '';
    drag.style.buttom = '';
  }
  drag.ondragend  = function (event) {
    //修复低版本ie bug
    var event = event || window.event;
    var moveX = event.clientX - diffX;
    var moveY = event.clientY - diffY;
    if (moveX < 0) {
      moveX = 0
    } else if (moveX > window.innerWidth - drag.offsetWidth) {
      moveX = window.innerWidth - drag.offsetWidth
    }
    if (moveY < 0) {
      moveY = 0
    } else if (moveY > window.innerHeight - drag.offsetHeight) {
      moveY = window.innerHeight - drag.offsetHeight
    }
    drag.style.left = moveX + 'px';
    drag.style.top = moveY + 'px'
    drag.style.right = "";
    drag.style.buttom = "";
  }
}
/* eslint-disable */
//计算字符串长度包含中文
const getByteLen = (val) => {
  var len = 0
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
  }
  return len
}

// 限制输入50个字符 length 输入字符的长度
const get50Byte = (val, length = 50) => {
  var len = 0
  for (let i = 0; i < val.length; i++) {
    var a = val.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
    if (len > length) {
      return val.substring(0, i)
    }
  }
}

// 获取固定长度的字符
const getStringByByteLength = (val, length = 100) => {
  var len = 0
  for (let i = 0; i < val.length; i++) {
    var a = val.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
    if (len > length) {
      return val.substring(0, i)
    }
  }
}

export function timeFormatForTip (time) {
  var m = Math.floor(time / 60)
  var s = Math.floor(time % 60)
  if (time === 0) {
    return '0 min ' + $t('time.left')
  } else {
    return (
      ((m && m + (m > 1 ? $t('time.mins') : $t('time.min'))) ||
        '') +
      ((s && s + $t('time.sec')) || '') +
      $t('time.left')
    )
  }
}
/* eslint-disable */
const PUNC_PATTERN = /[`:_.~!@#$%^&*()\+=<>?"{}|,\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘’，。、]/g
const END_PUNC_PATTERN = /[`:_.~!@#$%^&*()\+=<>?"{}|,\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘’，。、]+$/
/* eslint-disable */
/**
 * 去除标点符号
 * @param txt 
 * @returns 
 */
export function replaceAllPunctuation(txt) {
  return txt && txt.replace(PUNC_PATTERN, '')
}

/**
 * 去除结尾标点符号
 * @param txt 
 * @returns 
 */
const removeEndPunctuation = (txt) => {
  if (!txt) return ''
  return txt.replace(END_PUNC_PATTERN, '')
}
export function parseFileName(file) {
  return file.name.replace(/\.\w+$/, e =>
    e.toLocaleLowerCase()
  )
}

export function videoEditor(item) {
  // 需要拼到 url 上传递的参数
  const params = {
    resolution: "16:9",
    name: 'Untitled',
    talkingPhotoId: item.id,
    videoName: encodeURIComponent(item.title),
  }
  if(item.mediaId) {
    params.mediaId = item.mediaId
  } else {
    params.videoUrl = encodeURIComponent(item.videoUrl)
  }
    params['ww_token'] = getCookie('ww_token')
   // 音视频剪辑项目 url
  const url = new URL(`${
    process.env.VUE_APP_AUDIO_VIDEO_EDITOR_URL
  }${
    'App'
  }${
    changeOptionsToParams(params)
  }`)
  window.location.href = url.href
}
/* eslint-enable */

// 文件处理函数
/**
 * 是否是可接受的文件类型
 * @param {File} file 
 * @param {string} accept 'video/mp4,video/x-flv'
 * @returns {boolean}
 */
export function isAcceptFile(file, accept,more=false) {
  const match = /\.(\w+)$/.exec(file.name)
  const extension = match ? match[1] : ''
  const extensionMore = match ? match[0].toLowerCase() : ''; // 转换为小写  
  if(more){
   return(
    accept.indexOf(extensionMore) !== -1
   )
  }else{
    const mimeTypes = accept.split(',')
    const extensions = mimeTypes.map(mimeType => {
      if(TypeToExt[mimeType]) return TypeToExt[mimeType]
      let ext = mimeType.split('/')[1]
      ext = ext.replace(/^x-/, '')
      return ext
    }).flat()
    return (
      mimeTypes.indexOf(file.type) !== -1 ||
      extensions.indexOf(extension) !== -1
    )
  }
}
export function deepCopy (obj) {
  return cloneDeepWith(obj, (value, key) => {
    if (key === 'raw') return value
  })
}

// 解析video文件信息
export function parseVideoDuration(file) {
  return new Promise(resolve => {
    const url = URL.createObjectURL(file)
    const $video = document.createElement('video')
    $video.src = url
    $video.addEventListener('durationchange', () => {
      URL.revokeObjectURL(url)
      resolve($video.duration)
    })
    $video.onerror = e => {
      console.log('解析视频时长出错', e)
      URL.revokeObjectURL(url)
      resolve(0)
    }
  })
}
 // 解析audio文件信息
export function parseAudioDuration(file) {
  return new Promise(resolve => {
    const url = URL.createObjectURL(file)
    const $audio = document.createElement('audio')
    $audio.src = url
    $audio.addEventListener('durationchange', () => {
      URL.revokeObjectURL(url)
      resolve($audio.duration)
    })
    $audio.onerror = e => {
      console.log('解析音频时长出错', e)
      URL.revokeObjectURL(url)
      resolve(0)
    }
  })
}
export function secondToDate(initTime) {
  let time = Math.max(1, initTime)
  var m = Math.floor(time / 60)
  var s = Math.floor(time % 60)
  if (time === 0) {
    return '0 min '
  } else {
    return (
      ((m && m + (m > 1 ? $t('time.mins') : $t('time.min'))) ||
        '') + ((s && s + $t('time.sec')) || '')
    )
  }
}
function customLocateFile(url, scriptDirectory) {  
  // 假设您已经知道WASM文件的完整URL  
  // 注意：这里应该是一个完整的URL，而不是一个目录或前缀  
  const wasmUrl = 'https://cdn-static.dupdub.com/frontend/autoupload/dupdub/other/MediaInfoModule.wasm';  
  // 如果库要求的是相对于某个目录的URL，您可能需要结合scriptDirectory来构建URL  
  // 但在这个例子中，我们假设我们已经有了完整的URL  
  return wasmUrl;  
}  
//解析文件时长
let mediaInfoPromise = null; // 用于缓存初始化后的Promise   
function initMediaInfo() {  
  // 如果Promise已经存在，直接返回  
  if (mediaInfoPromise) {  
    return mediaInfoPromise;  
  }  
  // 创建一个新的Promise  
  mediaInfoPromise = new Promise((resolve, reject) => { 
    // format：结果值的格式（选项：object、、或）JSON XML HTML text 
    // locateFile  传递指定wasm地址路径的函数
    MediaInfo({ format: 'JSON',locateFile:customLocateFile}, (mediainfo) => {  
      // 初始化成功后，解析Promise  
      resolve(mediainfo);  
    }, (err) => {  
      console.log(err,'初始化报错')
      // 初始化失败时，拒绝Promise  
      reject(err);  
    });  
  });  
  // 返回Promise  
  return mediaInfoPromise;  
}  
export async function parseDuration(file) {   
  let output = null;  
  try {  
    // 等待mediainfo初始化完成  
    const mediainfo = await initMediaInfo();  
    // 封装获取文件大小和读取文件块的方法  
    const getSize = () => file.size;  
    const readChunk = (chunkSize, offset) =>  
      new Promise((resolve, reject) => {    
        const reader = new FileReader();    
        reader.onload = (event) => {    
          if (event.target.error) {    
            reject(event.target.error);    
          }    
          resolve(new Uint8Array(event.target.result));    
        };    
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));    
      });
    // 使用mediainfo实例分析文件  
    const result = await mediainfo.analyzeData(getSize, readChunk);  
    output = JSON.parse(result).media;  
  } catch (error) {  
    console.log( `An error occurred:\n${error.stack || error}`)
    output =0
  }  
  // 如果需要，可以返回output  
  return output;  
}
// 纯文字根据空格换行 getCharCount(str, '\\n') 原文案提取用
export function  getCharCount(str, char) {
  var regex = new RegExp(char, 'g') // 使用g表示整个字符串都要匹配
  var result = str.match(regex) //match方法可在字符串内检索指定的值，或找到一个或多个正则表达式的匹配。
  var count = !result ? 0 : result.length
  return count
}

export function getBrowserInfo() {
  let supporter,
      supporterVs,
      shell,
      shellVs
  const ua = navigator.userAgent.toLowerCase()
  const testUa = regexp => regexp.test(ua)
  const testVs = regexp =>
    ua
      .match(regexp)
      .toString()
      .replace(/[^0-9|_.]/g, '')
      .replace(/_/g, '.')
  if (testUa(/applewebkit/g)) {
    if (testUa(/edge/g)) {
      supporter = 'edge' // edge浏览器
      supporterVs = testVs(/edge\/[\d._]+/g)
    } else if (testUa(/opr/g)) {
      supporter = 'opera' // opera浏览器
      supporterVs = testVs(/opr\/[\d._]+/g)
    } else if (testUa(/chrome/g)) {
      supporter = 'chrome' // chrome浏览器
      supporterVs = testVs(/chrome\/[\d._]+/g)
    } else if (testUa(/safari/g)) {
      supporter = 'safari' // safari浏览器
      supporterVs = testVs(/version\/[\d._]+/g)
    }
  } else if (testUa(/gecko/g) && testUa(/firefox/g)) {
    supporter = 'firefox' // firefox浏览器
    supporterVs = testVs(/firefox\/[\d._]+/g)
  } else if (testUa(/presto/g)) {
    supporter = 'opera' // opera浏览器
  } else if (testUa(/trident|compatible|msie/g)) {
    supporter = 'ie' // iexplore浏览器
    supporterVs = testVs(/(msie [\d._]+)|(rv:[\d._]+)/g)
  }
  if (testUa(/micromessenger/g)) {
    shell = 'wechat' // 微信浏览器
    shellVs = testVs(/micromessenger\/[\d._]+/g)
  } else if (testUa(/qqbrowser/g)) {
    shell = 'qq' // QQ浏览器
    shellVs = testVs(/qqbrowser\/[\d._]+/g)
  } else if (testUa(/ubrowser/g)) {
    shell = 'uc' // UC浏览器
    shellVs = testVs(/ucbrowser\/[\d._]+/g)
  } else if (testUa(/qihu 360se/g)) {
    shell = '360' // 360浏览器(无版本)
    shellVs = 'unknow'
  } else if (testUa(/2345explorer/g)) {
    shell = '2345' // 2345浏览器
    shellVs = testVs(/2345explorer\/[\d._]+/g)
  } else if (testUa(/metasr/g)) {
    shell = 'sougou' // 搜狗浏览器(无版本)
    shellVs = 'unknow'
  } else if (testUa(/lbbrowser/g)) {
    shell = 'liebao' // 猎豹浏览器(无版本)
    shellVs = 'unknow'
  } else if (testUa(/maxthon/g)) {
    shell = 'maxthon' // 遨游浏览器
    shellVs = testVs(/maxthon\/[\d._]+/g)
  }
  const browInfo = {
    shellVs,
    shell,
    supporter,
    supporterVs
  }
  return browInfo
}

export default {
  getBrowserInfo,
  downloadMp4,
  getCharCount,
  createSrtFile,
  videoEditor,
  parseFileName,
  durationToSrtTime,
  replaceAllPunctuation,
  removeEndPunctuation,
  copy,
  dragmyDom,
  getByteLen,
  get50Byte,
  getStringByByteLength,
  timeFormatForTip,
  deepCopy,
  parseVideoDuration,
  parseAudioDuration,
  secondToDate,
  parseDuration
}
