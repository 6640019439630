/*
 * @Date: 2020-02-17 10:21:57
 * @Author: youxiu.yi
 * @LastEditors: yang.yang1809
 * @LastEditTime: 2020-05-12 16:21:46
 */
import getters from './getters'
import userInfo from './modules/user-info.js'
import status from './modules/status.js'
import staticInfo from './modules/static-info'
import globalInfo from './modules/global-info'
import editor from './modules/editor'
import speaker from './modules/speaker'
import system from './modules/system'
import request from './modules/request'
import talkingAvatar from '@/packages/talking-avatar/store/talking-photo-store'
import speakerAudio from '@/packages/speaker-audio/store/speaker-audio-store'
//import personLiable from '@/pages/personLiable/store/personLiable';
import Vue from 'vue'
import axios from '@/utils/axios'
import Vuex, { createNamespacedHelpers } from 'vuex'

let mutations = {},
  actions = {},
  modules = {}
Vue.use(Vuex)

const store = new Vuex.Store({
  getters: getters,
  modules: {
    userInfo,
    status,
    staticInfo,
    globalInfo,
    editor,
    speaker,
    system,
    request,
    speakerAudio,
    talkingAvatar
  }
})

export function getStor(self) {
  if (JSON.stringify(modules) != '{}') {
    for (let key in modules) {
      if (typeof key === 'string') {
        for (let subkey in modules[key].mutations) {
          modules[key].mutations[subkey] = modules[key].mutations[subkey].bind(
            self
          )
        }
        modules[key].hasRegistry = true
        store.registerModule(key, modules[key])
      }
    }
  }
  return store
}

export default store

export function mutation(nameSpace, mutationName) {
  return function(target, key, descriptor) {
    mutations[nameSpace] = mutations[nameSpace] || {}
    var originalMethod = descriptor.value
    descriptor.value = function(...args) {
      //这里不要用箭头函数, 会在执行环境中丢失正确的this指向
      originalMethod.apply(this, args) //因为descriptor.value的运行环境是在Class App中，所以这里的this会指向App。如果写成箭头那this就是log了
    }
    //创建一个模块缓存
    modules[nameSpace] = modules[nameSpace] || {
      namespaced: true,
      self: null,
      actions: {},

      mutations: {},
      state: {}
    }
    mutations[nameSpace][mutationName] = originalMethod
    modules[nameSpace].mutations = mutations[nameSpace]
  }
}

export function actionFactory(nameSpace, actionTyps, self) {
  for (let actionName in actionTyps) {
    let ajaxType = 'post',
      url = actionTyps[actionName]
    actions[nameSpace] = actions[nameSpace] || {}
    if (typeof url === 'string') {
      if (actionTyps[actionName].indexOf(' ') > 0) {
        ajaxType = actionTyps[actionName].split(' ')[0]
        url = actionTyps[actionName].split(' ')[1]
      }

      actions[nameSpace][actionName] = function({ commit }, params) {
        axios[ajaxType](url, params)
          .then(response => {
            commit(actionName, response)
          })
          .catch(err => {
            console.error(err || '服务出错，code132')
          })
      }
    } else if (url instanceof Array) {
      //这是个他妈的数组
      let promises = []
      url.map(_url => {
        if (_url.indexOf(' ') > 0) {
          let ajaxType = _url.split(' ')[0]
          _url = _url.split(' ')[1]
          promises.push(axios[ajaxType](_url, {}))
        }
      })
      actions[nameSpace][actionName] = function({ commit }) {
        axios
          .all(promises)
          .then(() => {
            commit(actionName, ...arguments)
          })
          .catch(err => {
            console.error(err || '服务出错，code131')
          })
      }
    }

    //创建一个模块缓存
    if (!modules[nameSpace]) {
      console.log('创建了新的module')
      modules[nameSpace] = {
        namespaced: true,
        actions: {},
        self: self,
        mutations: {},
        state: {}
      }
    }
    modules[nameSpace].actions = actions[nameSpace]
  }
}

function isEmptyObject(jsonObj) {
  let empty = true
  if (typeof jsonObj === 'string') {
    return empty
  }
  if( Object.keys(jsonObj).length>0){
    jsonObj = false
  }
  return empty
}
export function getModule({ state, actions, mutations, name, APIS }) {
  mutations = getMutations({ state, mutations })
  actions = getActions({ APIS, actions }, mutations)
  store.registerModule(name, {
    namespaced: true,
    state,
    mutations,
    actions
  })
  let { mapMutations, mapActions, mapState } = createNamespacedHelpers(name),
    mutationNames = [],
    actionNames = []
  for (let key in state) {
    mutationNames.push(getMutationName(key))
  }
  for (let key in actions) {
    actionNames.push(key)
  }
  let initVuex2 = initVuex({ mapMutations, mapActions, state })
  return {
    state,
    mapMutations,
    mapActions,
    mapState,
    mutationNames,
    actionNames,
    initVuex: initVuex2
  }
}
function getMutationName(stateName) {
  return `set${stateName[0].toUpperCase()}${stateName.substr(1)}`
}
function ajax({
  url,
  method,
  params,
  mutationName,
  commit,
  ajaxConfig,
  dispatch,
  after,
  afterParams,
  mutations
}) {
  ajaxConfig = ajaxConfig || {}
  dispatch = dispatch || function() {}
  after = after || ''
  afterParams = afterParams || {}
  params = params || ''

  if (method === 'get' || method === 'delete') {
    let stringParams = []
    for (let key in params) {
      stringParams.push(`${key}=${params[key]}`)
    }
    if (stringParams.length > 0) {
      url += '?' + stringParams.join('&')
    }
  }
  let actionPromise = new Promise((resolve, reject) => {
    axios[method](url, params, ajaxConfig)
      .then(response => {
        if (response.code === 0) {
          if (Object.keys(mutations).indexOf(`${mutationName}Success`) != -1) {
            commit(`${mutationName}Success`, response)
            after != '' &&
              console.log(
                '发现串联执行，参数为：' + JSON.stringify(afterParams)
              )
            after != '' && dispatch(after, afterParams)
          }
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
  return actionPromise
}
export function getMutations({ state, mutations }) {
  let res = mutations || {}
  if (res.$newFashion === true) {
    for (let key in res) {
      let mut = res[key]
      //let goingToRemove = false
      if (key.endsWith('Success') || key.endsWith('Fail')) {
        continue
      }
      res[[key, 'Fail'].join('')] = !mut.fail
        ? function() {}
        : mut.fail
      res[[key, 'Success'].join('')] = !mut.success
        ? function() {}
        : mut.success
      delete res[key]
    }
  }
  for (let key in state) {
    let mutationName = getMutationName(key)
    res[mutationName] = function(state, data) {
      state[key] = data
    }
  }
  return res
}
export function getActions({ APIS, actions }, mutations) {
  let res = actions || {};
    //url = '',
    //method = 'get' //默认是get方法
  APIS = APIS || {}
  for (let apiName in APIS) {
    if (typeof APIS[apiName] === 'string') {
      //只有一个url，就是最普通的ajax调用
      let urlResult = getAjaxUrlConf(APIS[apiName])
      res[apiName] = function({ commit }, params) {
        return ajax({
          url: urlResult.url,
          method: urlResult.method,
          params,
          mutationName: apiName,
          commit,
          mutations
        })
      }
    } else if (typeof APIS[apiName] === 'object' && APIS[apiName].ajaxConfig) {
      //带有post header头配置的ajax请求，适用于上传
      let ajaxConfig = APIS[apiName].ajaxConfig || {}
      let urlResult = getAjaxUrlConf(APIS[apiName].url)
      res[apiName] = function({ commit }, params) {
        return ajax({
          url: urlResult.url,
          method: urlResult.method,
          params,
          mutationName: apiName,
          commit,
          ajaxConfig,
          mutations
        })
      }
    } else if (typeof APIS[apiName] === 'object' && APIS[apiName].after) {
      //ajax串联执行
      let urlResult = getAjaxUrlConf(APIS[apiName].url)
      res[apiName] = function(store, params) {
        return ajax({
          url: urlResult.url,
          method: urlResult.method,
          params: params[0],
          mutationName: apiName,
          commit: store.commit,
          ajaxConfig: {},
          dispatch: store.dispatch,
          after: APIS[apiName].after,
          afterParams: params[1],
          mutations
        })
      }
    } else {
      console.warn(`unknow ajax type:${apiName}`)
    }
  }
  return res
}
export function initVuex({ mapMutations, mapActions, state }) {
  return function({ mutationNames, actionNames, extendsObject } = {}) {
    if (this) {
      actionNames = actionNames || this.actionNames
      mutationNames = mutationNames || this.mutationNames
    }
    return function(target) {
      let extendTarget = target.prototype
      //let moduleName = extendTarget.constructor.name
      let mutations = mapMutations(mutationNames)
      //第三方扩展,无双向绑定，纯粹就是扩展使用
      extendsObject = extendsObject || {}
      mutationNames.forEach(mutationName => {
        extendTarget[mutationName] = mutations[mutationName]
      })
      let actions = mapActions(actionNames)
      actionNames.forEach(actionName => {
        extendTarget[actionName] = actions[actionName]
      })
      if (!isEmptyObject(extendsObject)) {
        if (Array.isArray(extendsObject)) {
          //数组
          //extendsObject.forEach(extend => {
            for (let key in extendsObject) {
              extendTarget[key] = extendsObject[key]
            }
          //})
        } else {
          for (let key in extendsObject) {
            extendTarget[key] = extendsObject[key]
          }
        }
      }
      for (let propName in state) {
        Object.defineProperty(extendTarget, propName, {
          get() {
            return state[propName]
          },
          set(data) {
            this[getMutationName(propName)](data)
          }
        })
      }
    }
  }
}
function getAjaxUrlConf(confUrl) {
  let method = 'get',
    url = ''
  if (confUrl.indexOf(' ') > 0) {
    url = confUrl.split(' ')[1]
    method = confUrl.split(' ')[0]
  } else {
    url = confUrl
  }
  return {
    url,
    method
  }
}
