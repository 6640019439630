import {cookieLocalStorage} from './cookie-storage'
export const oldGetCookie = name => {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    return arr[2]
  } else {
    return null
  }
}

// export const oldSetCookie = (c_name, value, expiredays) => {
//   var exdate = new Date()
//   exdate.setDate(exdate.getDate() + expiredays)
//   document.cookie =
//   c_name +
//       '=' +
//       '' +
//       ';path=/;expires=' +
//       new Date(1970).toGMTString()
//   document.cookie =
//     c_name +
//     '=' +
//     escape(value) +
//     (expiredays == null
//       ? ''
//       : ';domain=.dupdub.com;path=/;expires=' + exdate.toGMTString())
// }

// 删除cookies
// export const oldDelCookie = name => {
//   var exp = new Date()
//   exp.setTime(exp.getTime() - 1)
//   var cval = getCookie(name)
//   if (cval != null) {
//     document.cookie =
//       name +
//       '=' +
//       '' +
//       ';domain=.dupdub.com;path=/;expires=' +
//       exp.toGMTString()
//     document.cookie =
//       name +
//       '=' +
//       '' +
//       ';path=/;expires=' +
//       exp.toGMTString()
//   }
// }

const { getCookie,setCookie,delCookie,delUrlParams} =cookieLocalStorage()
export {getCookie,setCookie,delCookie,delUrlParams}
// function tokenInit(){
//   //兼容线上已经使用cookie登录的用户
//   const ww_token = oldGetCookie('ww_token')
//   if(ww_token){
//     setCookie('ww_token',ww_token)
//     oldDelCookie('ww_token')
//   }
// }
// tokenInit()