<template>
  <div
    id="app"
    ref="vueApp"
    @click="calcus()"
    :class="[ routerName, { 'is-mobile': ua.mobile, manageHome: $route.path.indexOf('/tools/') > -1, 'no-min-width': routerName === 'ai-writing-editor' }]"
  >
  <div class="mobile-tips" v-if="ua.mobile && 
      ($route.path.indexOf('/tools/') > -1)">
    <iconpark-icon name="monitor" size="18"></iconpark-icon>
    <span>For a smoother experience, it is recommended to switch to a desktop device if your mobile device is not compatible.</span>
  </div>
    <!-- 非chrome浏览器打开网站提醒 -->
    <!-- <div
      v-if="!ua.mobile && supporter != 'chrome' && openTip"
      class="tips"
    >
      Please use Google Chrome if the pages of DupDub do not display well.
      <span>
        <a href="https://www.google.cn/intl/en_us/chrome/" target=_blank>
          Click here to download
          <img
            src="./assets/img/home/chrome.png"
            alt=""
          />Google Chrome
        </a>
      </span>
      . Ignore this hint if you are using Google Chrome.
      <span @click="close()" class="el-icon-close close"></span>
    </div> -->
    <keep-alive :max="2" v-if="!$route.meta.noCache">
      <router-view
        v-if="isRouterAlive"
        class="is-container"
      >
      </router-view>
    </keep-alive>
    <router-view
        v-if="isRouterAlive && $route.meta.noCache"
        class="is-container"
      >
      </router-view>
    <notification></notification>
    <!-- dupdub 问卷 start -->
    <questionnaire ref="questionnaire"></questionnaire>
    <!-- dupdub 问卷 end -->
    <!-- checklist和折扣弹框 -->
    <!-- <CouponOff /> -->
    <!-- 会员弹窗 start -->
    <pricingDialogueComponent></pricingDialogueComponent>
    <!-- 会员弹窗 end -->
    <!-- NPS弹框 -->
    <nps></nps>
    <!-- 发音人选择弹窗 -->
    <RenderSpeakerDialog />
    <!-- 取消订阅后引导续订折扣弹窗 -->
    <UnsubscribeDiscount />
    <!-- credits 明细弹框 -->
    <creditsDetalis></creditsDetalis>
    <!-- cookies 弹框 -->
    <cookiesDialog></cookiesDialog>
    <!-- lexicon -->
    <Lexicon />
    <Desktop/>
    <!-- AiSoundEffect -->
    <AiSoundEffect ref="aiSoundEffectBox" @getAudio="aiSoundEffectGetAduio" @aiSoundEffectStatus="aiSoundEffectStatus" />
  </div>
</template>

<script>
const _body = document.querySelector('body');
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import notification from '@/component/notification'
import questionnaire from '@/component/questionnaire/indexV2.vue'
import pricingDialogueComponent from '@/component/price-common-dialogue.vue'
import nps from '@/layout/components/nps.vue'
import UnsubscribeDiscount from '@/component/unsubscribeDiscount/index.vue'
import { getClientId } from '@/utils/utils'
import CouponOff from '@/component/get5-off/index.vue'
import RenderSpeakerDialog from '@/packages/speaker-audio/render-speaker-dialog'
import AiSoundEffect from '@/packages/ai-sound-effect/ai-sound-effect.vue'

import creditsDetalis from '@/component/credits-detalis.vue'
import cookiesDialog from '@/component/cookies.vue';
import Lexicon from '@/pages/lexicon/index.vue'
import http from '@/utils/http-request.js'
import Desktop from '@/component/desktop.vue'
import stat from '@/utils/stat'
export default {
  components: {
    notification,
    questionnaire,
    pricingDialogueComponent,
    nps,
    RenderSpeakerDialog,
    UnsubscribeDiscount,
    creditsDetalis,
    cookiesDialog,
    CouponOff,
    Lexicon,
    Desktop,
    AiSoundEffect
  },
  data() {
    return {
      deferredPrompt:null,//桌面端应用
      isRouterAlive: true,
      openTip: true,
      supporter: '',
      supporterVs: '',
      shell: '',
      shellVs: '',
      is5off_boolean_feature: false, //AB test
    }
  },
  name: 'app',
  provide() {
    return {
      $pwaApp:this,
      reload: this.reload
    }
  },
  computed: {
    ...mapState('request', ['isGetActivityVisibleState']),
    ...mapGetters('userInfo', ['user', 'isLogin']),
    routerName() {
      return this.$route.name
    }
  },
  created() {
    this.getDiscountInfo()
    // if(!this.isGetUserInterviewState) {
    //   this.getUserInterview()
    //   .then(()=>{
    //     this.setGetUserInterviewState(true)
    //   })
    // }
  },
  beforeDestroy(){
    window.removeEventListener("beforeinstallprompt", this.handleBeforeinstallprompt);
    window.removeEventListener("appinstalled", this.handleAppinstalled);
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        document.dispatchEvent(new Event('renderEvent'))
      })
    }, 10000)
    if (this.ua.mobile) document.getElementsByTagName('body')[0].style.height = 'auto'
    const client_id = getClientId();
    _body.setAttribute('client_id', client_id);
    // 监听更新状态的消息
    this.$bus.$on('couponStatus', this.updateCounpon)
    this.$bus.$on('aiSoundEffect', this.aiSoundEffect)
    window.addEventListener("beforeinstallprompt", this.handleBeforeinstallprompt);
    window.addEventListener("appinstalled", this.handleAppinstalled);
  },
  methods: {
    ...mapActions(['getDiscountInfo']),
    ...mapActions('system', ['getActivityVisibleState', 'getUserInterview']),
    ...mapMutations('request', ['setGetActivityVisibleState', 'setGetUserInterviewState']),
    handleBeforeinstallprompt(e){
      this.deferredPrompt=e
      console.log(e,'添加桌面')
      this.$bus.$emit('desktop-prompt')
    },
    handleAppinstalled(){
      this.$message.success('Successfully added DupDub app to desktop.')
      stat.event('addtodesktop_toast_exposure')
      this.deferredPrompt = null;
      this.$bus.$emit('pwa-desktop-dialog',false) //关闭弹框
    },
    // checklist完成之后更新接口
    updateCounpon(data) {
      // 1-avatar、2-voiceover、3-voiceClone、4-videoTranslation、5-transcription
      http.UpdateABCounpon({
        params: data
      }).then((res) => {
        if (res.data.data?.result) {
          if (res.data.data?.message) this.$message.success(res.data.data.message)
          this.$bus.$emit('couponStatusComplete')
        }
      })
    },
    // aiSoundEffect
    aiSoundEffect (config) {
      this.$refs.aiSoundEffectBox.open(config)
    },
    aiSoundEffectGetAduio (audioData) {
      this.$bus.$emit('ai-sound-effect-getAudio', audioData)
    },
    aiSoundEffectStatus (status) {
      this.$bus.$emit('ai-sound-effect-status', status)
    },
    calcus() {
      this.$root.$data.calcusStatus = true
      this.$root.$data.popoverShow = !this.$root.$data.popoverShow
      this.$root.$data.compoundStatus = false
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    close() {
      this.openTip = false
      localStorage.setItem('closeTime', Date.parse(new Date()) / 1000)
    }
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(value) {
        if(!value) return
        const wwid = JSON.parse(localStorage.getItem('userInfo'))?.wwid
        _body.setAttribute('wwid', wwid);
        // 用户都可以看到优惠券
        this.$store.commit('setCouponDiscount', true)
      }
    },
  }
}
</script>
<style>
@import url('@/assets/iconfont/font_3016299_opjwu11bxih/iconfont.css');
</style>
<style lang="less">
@import "@/assets/css/responsive.less";
html,
body {
  height: 100%;
  width: 100%;
  background: #F5F6FB!important;
}
#app {
  height: 100%;
  width: 100%;
  /* 设置最小宽度，如果更低 tool-bar 会换行 */
  min-width: 1300px;
  &.manageHome {
    min-width: 1400px !important;
  }
  &.no-min-width {
    min-width: 0;
  }
}
.screen-md({
  #app {
    min-width: auto;
  }
});
.mobile-tips{
  width: 100%;
  background: #FEE5D9;
  display: flex;
  padding: 8px 0;
  display: flex;
  align-items: center;
  z-index: 999;
  iconpark-icon{
    margin-right: 12px;
    margin-left: 10px;
  }
  span{
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #DF4747;
    line-height: 17px;
  }
}
.tips {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 30px;
  box-sizing: border-box;
  background: #ffdadf;
  color: #333;
  font-size: 14px;
  z-index: 999;
  text-align: center;
}
.tips span a {
  color: #629ae1;
  cursor: pointer;
}
.tips span a img {
  margin: 2px;
}
.close {
  float: right;
  margin-top: 8px;
  cursor: pointer;
}
.dialog-new-persion {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.2);
}
.footer-center .el-dialog__footer {
  text-align: center;
}
.footer-center .el-dialog {
  width: 480px !important;
  box-sizing: border-box;
  border-radius: 6px;
}
.footer-center .el-dialog__header {
  padding: 16px 24px 20px;
}
.footer-center .el-dialog__body {
  padding: 16px 24px 80px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}
.footer-center .el-dialog__footer {
  padding: 14px 0;
}
.footer-center .el-button {
  width: 96px;
  height: 36px;
  line-height: 0;
}
.mes-content {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.mes-content a {
  margin-top: 26px;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  color: #5a92f4;
  line-height: 20px;
  cursor: pointer;
}
.mobile-dialog .el-dialog__title,
.mobile-dialog .el-button--medium,
.mobile-dialog .el-dialog__headerbtn {
  font-size: 28px;
}
.mobile-dialog .el-button + .el-button {
  margin-left: 100px;
}
.mobile-dialog .el-dialog--center .el-dialog__body {
  padding: 35px 30px 40px;
}
.mobile-dialog .el-dialog__header,
.mobile-dialog .el-dialog__footer {
  padding: 30px 20px 35px;
}
.mobile-dialog .el-button--medium {
  padding: 20px 30px;
}
</style>
