<template>
  <el-dialog
    :visible.sync="$store.state.userInfo.showPriceModal"
    :lock-scroll="true"
    id="priceDialog"
    class="price-common-dialog_wrapper"
    custom-class="price-common-dialog"
  >
    <div class="dialogue-content" :class="{'dialogue-content-christmas': !priceListType}">
      <iconpark-icon name="iconcancle" size="18" class="close-btn" @click="closeEvt"></iconpark-icon>
      <h2 class="title-line">DupDub AI pricing plans 
        <!-- <img src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_back_five_1700547342/dupdub_back_five_1700547342/black-five-title.png" alt="" class="black-five-title"> -->
        <img v-if="!priceListType" src="https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/chrismas1702362065/chrismas1702362065/christmas.png" alt="" width="158">
      </h2>
      <pricingComponent :from="'pricepopup'" :priceListType="priceListType"></pricingComponent>
    </div>
  </el-dialog>
</template>
<script>
import pricingComponent from '@/component/pricing-component.vue'
export default {
  name: 'pricingcommonDialogue',
  data() {
    return{
      priceListType: true
    }
  },
  methods: {
    closeEvt() {
      this.$store.commit('userInfo/setShowPriceModal', [false])
    }
  },
  components: {
    pricingComponent
  }
}
</script>
<style lang="less">
.price-common-dialog_wrapper {
  z-index: 6000 !important;
}
.price-common-dialog{
  margin-top: 40px!important;
  margin-bottom: 40px!important;
  width: 1216px!important;
  background: linear-gradient(180deg, #F8F0FF 0%, #FFFFFF 100%)!important;
  border-radius: 20px!important;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
  .dialogue-content{
    background: linear-gradient(180deg, #F8F0FF 0%, #FFFFFF 100%);
    border-radius: 20px;
    padding: 30px 40px 40px;
    box-sizing: border-box;
    position: relative;
    .close-btn{
      position: fixed;
      color: #fff;
      top: 40px;
      right: calc((100vw - 1216px) / 2 - 38px);
      cursor: pointer;
    }
    .title-line{
      font-size: 26px;
      font-family: Poppins-Medium;
      font-weight: 500;
      color: #001126;
      line-height: 39px;
      margin-bottom: 24px;
    }
  }
  .dialogue-content-christmas{
    background:url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/chrismas1702362065/chrismas1702362065/christmas-bj.png') #fff no-repeat top center;
    background-size: contain;
    .title-line{
      color: #fff;
    }
  }
}
</style>