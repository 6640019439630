import axiosRequest from '@/utils/axios'

// 创建视频本地化工程
export const create = (data)=> 
  axiosRequest.post(`/gateway-apis/tts/v1/videoTrans/create`, data)

// 工程列表查询
export const videoTransList = (data)=> 
  axiosRequest.get(`/gateway-apis/tts/v1/videoTrans/list`, data)

// 创建视频本地化工程
export const queryUserCoupon = (data)=> 
  axiosRequest.post(`/gateway-apis/tts/v1/videoTrans/batchQryTask`, data)

// 解析视频链接获取视频信息
export const achieveVideoByLink = (data,configData)=> 
 axiosRequest.post(`/gateway-apis/moyin-tool//v1/videoToAudio/achieveVideoByLink`, data, configData)

// 删除项目
export const deleteProject = (data)=> 
axiosRequest.delNew(`/gateway-apis/tts/v1/videoTrans/${data.taskId}`)

// 修改项目名称
export const renameVideoTrans = (data)=> 
  axiosRequest.put(`/gateway-apis/tts/v1/videoTrans`, data)

// 获取项目原始发音人列表
export const getSpeakerList = (data) =>
  axiosRequest.get('/gateway-apis/tts/v1/videoTrans/speakerList' + axiosRequest.urlParamFormat(data))
 
// 更新项目打开时间
export const updateOpenTime = (data) =>
  axiosRequest.post('/gateway-apis/tts/v1/videoTrans/updateOpenTime' + axiosRequest.urlParamFormat(data))
  
// 项目retry
export const retry = (data) =>
  axiosRequest.get('/gateway-apis/tts/v1/videoTrans/retry', data)
 
// 是否第一次免费
export const usageCount = (data) =>
  axiosRequest.get('/gateway-apis/account/v1/user/newbies/usageCount', data)

// 使用demo地址使用准备好的数据
export const caseCreate = (data) =>
  axiosRequest.post('/gateway-apis/tts/v1/videoTrans/caseCreate', data)

// 视频本地化获取语言列表
export const getLanguage = (data) =>
  axiosRequest.get('/gateway-apis/tts/v1/videoTrans/language', data)

export default {
  create,
  videoTransList,
  queryUserCoupon,
  achieveVideoByLink,
  deleteProject,
  renameVideoTrans,
  getSpeakerList,
  updateOpenTime,
  retry,
  usageCount,
  caseCreate,
  getLanguage
}