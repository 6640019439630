import { render, staticRenderFns } from "./pricing-rights.vue?vue&type=template&id=39de7a4d&scoped=true"
import script from "./pricing-rights.vue?vue&type=script&lang=js"
export * from "./pricing-rights.vue?vue&type=script&lang=js"
import style0 from "./pricing-rights.vue?vue&type=style&index=0&id=39de7a4d&prod&lang=less"
import style1 from "./pricing-rights.vue?vue&type=style&index=1&id=39de7a4d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39de7a4d",
  null
  
)

export default component.exports