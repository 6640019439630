import {$t} from '@/assets/language/util.js'
import 'moment/locale/zh-cn'
import { i18n } from '@/assets/language/util.js'
const moment = require('moment')

export const timeFormat = (time) => {
  if (!time) return ''
  // 兼容safari时间格式 就是麻烦
  time = time.slice(0, 10).replace(/-/g, '/') + ' ' + time.slice(11, 19)
  // 如果展示年月日时分时，直接使用时间进行截取（因9月需要展示4位缩写）
  let _time = time
  time = new Date(time)
  let timeString = ''
  let timeStamp = time.getTime()
  let today0 = new Date(new Date().toLocaleDateString()).getTime()
  let yestoday0 = today0 - 60 * 60 * 24 * 1000 // 昨天0点
  let weekDay = new Date().getDay() // 今天周几
  let weekMon0 = today0 - (weekDay - 1) * 60 * 60 * 24 * 1000 // 周一0点
  // moment 转为英文
  moment.locale(['en-AU'])
  if (timeStamp > yestoday0) {
    timeString = moment(time).calendar(null, {
      sameDay: '[Today] HH:mm',
      lastDay: '[Yesterday] HH:mm'
    })
  } else if (timeStamp > weekMon0) {
    timeString = moment(time).format('dddd HH:mm')
  } else {
    timeString= i18n.t('englishTimeFormat', {
      month: i18n.t('mouthList.' + _time.slice(5, 7)),
      day: _time.slice(8, 10),
      year: _time.slice(0, 4),
      time: _time.slice(11, 16)
    })
  }
  return timeString
}

// 转换时间为年月日
export const timeFormatYMD = (time) => {
  // 兼容safari时间格式 就是麻烦
  time = time.slice(0, 10).replace(/-/g, '/') + ' ' + time.slice(11, 19)
  time = new Date(time)
 return moment(time).format('YYYY-MM-DD- HH:mm')
}

// 转化时间格式
export const formatDateTime = timestamp => {
  return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
}
// 转化时间格式
export const formatDate = (timestamp, format = 'YYYY.MM.DD') => {
  return moment(timestamp).format(format)
}
// 转化时间格式
export const formatDateTimeStr = timestamp => {
  return moment(timestamp).format('YYYYMMDDHHmmss')
}
// 转化两个时间之间的天数
export const formatDays = (timestamp1, timestamp2) => {
  return moment(timestamp2).diff(moment(timestamp1), 'days')
}
// 比较两个时间的大小
export const formatDateTimeIsBefore = (timestamp1, timestamp2) => {
  return moment(timestamp1).isBefore(timestamp2)
}
// 获取audio的时长
export const dealSoundTime = item => {
  const time = Math.ceil(item)
  const minute = time / 60
  let minutes = parseInt(minute)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  const second = time % 60
  let seconds = Math.round(second)
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${minutes}:${seconds}`
}
// 获取TN的名字
export const getLevel = val => {
  let el = ''
  switch (val) {
    case 'value':
      el = 'Number'
      break
    case 'digits':
      el = 'Digits'
      break
    case 'digits_o':
      el = 'Digits'
      break
    case 'en_ratio':
      el = 'Ratio'
      break
    case 'ratio':
      el = 'Ratio'
      break
    case 'date':
      el = 'Date'
      break
    case 'en_scale':
      el = 'Scale'
      break
    case 'scale':
      el = 'Scale'
      break
    case 'en_math':
      el = 'Math'
      break
    case 'math':
      el = 'Math'
      break
    case 'telephone':
      el = 'Telephone'
      break
    case 'en_time':
      el = 'Time'
      break
    case 'time':
      el = 'Time'
      break
    case 'en_fraction':
      el = 'Fraction'
      break
    case 'fraction':
      el = 'Fraction'
      break
    case 'alphabet':
      el = 'Alphabet'
      break
    case 'word':
      el = 'Word'
      break
    case 'ordinal':
      el = 'Ordinal'
      break
    case 'building':
      el = 'Building'
      break
    case 'contact':
      el = 'Contact'
      break
    case 'book-name':
      el = 'Book'
      break
    case 'festival-name':
      el = 'Festival'
      break
    case 'precent_numer':
      el = 'Precent'
      break
    case 'website':
      el = 'Website'
      break
    case 'currency':
      el = 'Currency'
      break
    case 'coordinate':
      el = 'Coordinate'
      break
    case 'temperature':
        el = 'Temperature'
        break
    case 'stock':
        el = 'Stock'
      break
    case 'string':
        el = 'string'
      break
    case 'unit':
        el = 'unit'
      break
    // 数字符号读法 key 没有的映射默认为 Others
    default:
      el = 'Others'
  }
  return el
}

export const rhythmList = [
  {
    key: $t('rhytm.tab.noBreak'),
    value: 0
  },
  {
    key: $t('rhytm.tab.short'),
    value: 1
  },
  {
    key: $t('rhytm.tab.medium'),
    value: 2
  },
  {
    key: $t('rhytm.tab.long'),
    value: 3
  },
  {
    key: $t('button.remove'),
    value: ''
  }
]

export const muteTimeList = [
  {
    index: '100ms',
    value: '100'
  },
  {
    index: '150ms',
    value: '150'
  },
  {
    index: '200ms',
    value: '200'
  },
  {
    index: '300ms',
    value: '300'
  },
  {
    index: '400ms',
    value: '400'
  },
  {
    index: '600ms',
    value: '600'
  }
]

// 金额分割
export const fmoney = (s, n) => {
  n = n >= 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
  let l = s.split(".")[0].split("").reverse(), r = s.split('.')[1] || '',t= "";
  for(let i = 0;i<l.length;i++){
    t += l[i] + ((i+1) % 3 == 0 && (i+1) != l.length ? ',' : '')
  }
  return t.split("").reverse().join("") + (n > 0 ? '.' : '') + r;
}

