/** 添加路由参照其他写法，添加 webpackChunkName 用于路由按需加载 */
export const routes = [
  {
    path: '/campaign-socialmedia',
    name: 'campaignSocialmedia',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/campaign-socialmedia/index.vue'),
    meta: {
      canonical: 'https://app.dupdub.com/campaign-socialmedia',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/promote',
    name: 'promotePage',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/promote/index.vue'),
    meta: {
      title: 'Unlock Your Video\'s Potential with DupDub AI Voice Generator - Create Compelling, Human-Sounding Voiceovers in Minutes!',
      content: {
        keywords: 'DupDub, AI voice generator, human-sounding voiceovers, YouTube videos, video conversions, voice cloning, subtitle alignment, video production, multilingual, diversified sound library, voiceover professionals, 30+ languages, 300+ voices, showtime ready, video codecs, pro subtitles formats, free trial',
        description: 'Boost your YouTube video conversions and captivate your audience with DupDub AI voice generator. Easily create engaging voiceovers in minutes for your videos in over 30 languages and 300 voices. Say goodbye to tedious dubbing and subtitle alignment - try DupDub now for free!'
      },
      canonical: 'https://app.dupdub.com/promote',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/promote-marketing',
    name: 'promoteMarketing',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/promote-marketing/index.vue'),
    meta: {
      title: 'Boost Conversions with Authentic Voiceovers: Try DupDub AI Voice Studio Now!',
      content: {
        keywords: 'Trustworthy voice, video conversions, DupDub AI voice studio, video production, text-to-speech, automatic subtitle alignment, voice cloning, human-like voices, video ads, voiceover professionals, 30+ languages, 300+ voices, subtitle alignment, efficient video production, voice cloning, showtime ready, voiceover software, user testimonials',
        description: 'Enhance video ad conversions with DupDub\'s trustworthy voiceovers. Create high-quality, human-like voiceovers in multiple languages with ease. No more tedious dubbing worries. Try DupDub for free!'
      },
      canonical: 'https://app.dupdub.com/promote-marketing',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/tob-landingpage',
    name: 'toBLandingPage',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/tob-landingpage/index.vue'),
    meta: {
      title: 'Unlock the Power of AI Voice Cloning: Transform Your Content Creation & Communication',
      content: {
        keywords: 'Voice Cloning, Text-to-Speech API, AI Voiceover Solutions, Lifelike Voices, Clone Voices, Realistic Sounding Voice Clones, Exclusive Voices, Voice Cloning Technology, Audiobooks, Conversational Systems, Entertainment, Healthcare, Publishers, Education, E-learning, Marketing, Advertisement, IVR System, Virtual Assistant, Content Creation, AI Voice Solutions',
        description: 'Discover the Future of Content Creation with DupDub\'s Voice Cloning & Text-to-Speech API. Clone lifelike voices in multiple languages and accents. Save time, money, and effort in audiobook production, gaming, e-learning, IVR systems, and more. Get started with a demo now!'
      },
      canonical: 'https://app.dupdub.com/tob-landingpage',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/cookie-policy',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/agreement/cookie-policy.vue'),
    meta: {
      title: 'DupDub Cookie Policy | DupDub',
      content: {
        keywords: 'Cookie, collect, tracking technologies',
        description: 'The cookie policy of DupDub'
      },
      canonical: 'https://app.dupdub.com/cookie-policy',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/affiliate-notice',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/agreement/corporate-affiliates-notice.vue'),
    meta: {
      title:'Corporate Affiliates Notice | DupDub'   ,
      content: {
        keywords: 'Corporate, Registered, Affiliates Notice',
        description: 'DupDub Corporate Affiliates Notice'
      },
      canonical: 'https://app.dupdub.com/affiliate-notice',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: true,
      // 是/否有 footer
      isOwnFooter: true
    }
  },
  {
    path: '/pricing-success',
    name: 'pricing-success',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/pricing/pricing-success'),
    meta: {
      title: 'Pricing Success Page | DupDub, with All Plans 30% OFF',
      content: {
        keywords: 'Payment, Upgraded, Plan, On sale',
        description: '14-Day Refund Policy. Upgrade Your Plan with NO RISK'
      },
      canonical: 'https://app.dupdub.com/pricing-success',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/pricing-clone-success',
    name: 'pricing-clone-success',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/pricing/clone-price/pricing-success/index'),
    meta: {
      title: 'Pricing Success Page | DupDub, with All Plans 30% OFF',
      content: {
        keywords: 'Payment, Upgraded, Plan, On sale',
        description: '14-Day Refund Policy. Upgrade Your Plan with NO RISK'
      },
      canonical: 'https://app.dupdub.com/pricing-clone-success',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  // 文章详情...
  {
    path: '/maintain',
    name: 'maintain',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/maintain'),
    meta: {
      canonical: 'https://app.dupdub.com/maintain',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/app-download',
    name: 'app-download',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/app-h5/download.vue'),
    meta: {
      title: 'Tell the stories behind every photo with DupDub AI talking photo',
      content: {
        description: 'Tell the stories behind every photo with DupDub AI talking photo'
      },
      canonical: 'https://app.dupdub.com/app-download',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/app-share',
    name: 'app-share',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/app-h5/share.vue'),
    meta: {
      title: 'AI Talking Photo - Tell the stories behind every photo',
      content: {
        keywords: 'AI Talking Photo, Lip sync photos, AI talking avatars, Animate pictures, photo storytelling, AI technology, image analysis, memorable moments, photo narration',
        description: 'DupDub AI talking photos uses a face photo or avatar image and combines your audio to create masterpieces in minutes! All you need to do is come up with creative ideas to make those talking photos stand out. Explore the power of AI talking photo to preserve memories, engage audiences, and add an unparalleled depth to visual storytelling.'
      },
      canonical: 'https://app.dupdub.com/app-share',
      // 是/否需要校验登录
      auth: true,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/email-activity-page',
    name: 'emailActivityPage',
    component: () => import(/* webpackChunkName: "others" */ '@/pages/activity/ticwatch-dupdub/ticwatch-dupdub.vue'),
    meta: {
      title:'Email Activity | Multilingual Text to Speech Online',
      content: {
        keywords: 'activity, email, free try, sales',
        description: 'The first-generation TicWatch x DupDub collaboration voice watchface.voice-store'
      },
      canonical: 'https://app.dupdub.com/email-activity-page',
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import(/* webpackChunkName: "others" */ '@/component/refresh.vue'),
    meta: {
      // 是/否需要校验登录
      auth: false,
      // 是/否有 header
      isOwnHeader: false,
      // 是/否有 footer
      isOwnFooter: false
    }
  },
]