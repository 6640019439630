<template>
  <div :class="['pricing-buy',{'pricing-buy-christmas':!priceListType}]">
    <div :class="['switch-wrapper', `switch-wrapper-${from}`,
     {'switch-top': switchClass}
    ]" id="switchWrapper">
      <div class="switch" :class="[selectedInterval]">
        <div class="btn btn-year" @click="switchEvt('year')">
          {{$t('pricing.Annually')}}<div
            v-if="initSales"
            class="tip"
            :class="[selectedInterval]"
            v-t="{
              path: 'pricing.initSales',
              args: { initSales }
            }"
          ></div>
        </div>
        <div class="btn btn-month" @click="switchEvt('month')">
          {{ $t('pricing.monthly') }}
        </div>
        <div class="btn btn-credits" @click="switchEvt('credits')">
          {{ $t('pricing.credits') }}
        </div>
      </div>
    </div>
    <div class="web-show">
      <div class="plan-list">
        <template v-if="selectedInterval !== 'credits'">
          <div
            v-for="plan in planList"
            :key="plan.vipType"
            :class="['plan-item', `plan-item-${from}`,
            planNameMap[plan.vipType], 
            {'popular-plan-item': 
              vipType !== VIP_TYPE.PROFESSIONAL 
              && vipType !== VIP_TYPE.ULTIMATE
              && planNameMap[plan.vipType] === 'professional'
              && !(plan.vipType === vipType &&
                getPriceByInterval(plan).interval === vipInterval)
            },
            {'has-current-tag': 
              plan.vipType === vipType && getPriceByInterval(plan).interval === vipInterval}
            ]"
          >
            <div class="plan-item-wrapper">
              <div class="plan-item-wrapper-top">
                <div
                  class="current-tag"
                  v-if="
                    plan.vipType === vipType &&
                    getPriceByInterval(plan).interval === vipInterval
                  "
                >
                  {{ $t('pricing.currentTip') }}
                </div>
                <div class="name">
                  <iconpark-icon :name="plan.icon"></iconpark-icon>
                  <p>{{ $t(`pricing.${planNameMap[plan.vipType]}`) }}</p>
                </div>
                <div class="name-tip">{{plan.showTip}} </div>
                <div class="price-wrapper">
                  <div class="price">
                    <!-- <div class="pro-discount-txt" v-if="planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'">
                      <img src="@/assets/img/header/label.png" alt="DupDub">
                    </div>  -->
                    <div class="price-text" :class="{'price-font-color': planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'}">
                     <del class="original" v-if="getPriceStr(plan, 'monthPriceOriginal')!==0 && !priceListType">{{ $t('pricing.$') }}{{ getPriceStr(plan, 'monthPriceOriginal') }}</del> <span class="professional-price">{{ $t('pricing.$') }}{{ getPriceStr(plan, 'monthPrice') }}</span>
                    </div>
                    <div class="market-price-text">
                      {{ $t('pricing.permonth') }}
                    </div>
                  </div>
                  <div class="year" v-if="selectedInterval === VIP_INTERVAL.YEAR">
                    <span :class="{'price-font-color': planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'}">
                      ${{ getPriceStr(plan, 'yearPrice') }}
                    </span>
                    <span class="unit">{{ $t('pricing.yearAnnually') }}</span>
                  </div>
                </div>
                <!-- 按钮-重新订阅 -->
                <div
                  class="btn primary" 
                  v-if="
                    unsubscribed &&
                    plan.vipType === vipType &&
                    getPriceByInterval(plan).interval === vipInterval
                  "
                  @click="onSubscribe(plan)"
                  :gtm-event="getPriceEvent(plan, 'renew')"
                >
                  {{ $t('pricing.renewBtn') }}
                </div>
                <!-- 按钮-打开编辑器 -->
                <div
                  class="btn"
                  :class="{
                    'open-tts-btn': 
                    (plan.vipType === vipType &&
                      getPriceByInterval(plan).interval === vipInterval)
                  }"
                  v-else-if="
                    plan.vipType === VIP_TYPE.FREE ||
                    (plan.vipType === vipType &&
                      getPriceByInterval(plan).interval === vipInterval)
                  "
                  @click="goTotts(plan)"
                  :gtm-event="getPriceEvent(plan, 'openstudio')"
                >
                  <!-- 进入到编辑器 -->
                  {{$t('pricing.openBtn')}}
                </div>
                <!-- 按钮-首次/升级订阅 -->
                <div
                  class="btn primary" 
                  v-else
                  @click="onSubscribe(plan)"
                  :gtm-event="
                    getPriceEvent(plan, isLogin && isBuyStatus ? 'upgrade' : 'new')
                  "
                >
                {{$t('pricing.upgradeBtn')}} 
                </div>
              </div>
              <div class="plan-item-wrapper-bot">
                <PriceRights :pricingType="priceListType" :vipType="plan.vipType" :selectedInterval="selectedInterval" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(plan, index) in creditsList" :class="`plan-item plan-item-credits plan-item-${from}`" :key="index">
            <div class="plan-item-wrapper">
              <div class="plan-item-wrapper-top">
                <div class="name">
                  <iconpark-icon :name="plan.icon"></iconpark-icon>
                  <p>{{getCreditDisplayName(plan.name)}}
                    <el-popover
                      v-if="plan.type !== 'enterprise' && plan.tips"
                      placement="top"
                      trigger="hover"
                      popper-class="price-tooltip-item"
                      effect="dark">
                      <div v-html="plan.tips"></div>
                      <iconpark-icon name="tip-price" size="20" slot="reference"></iconpark-icon>
                    </el-popover>
                  </p>
                </div>
                <div class="name-tip-credits"></div> 
                <div class="price-wrapper">
                  <div class="price">
                    <template v-if="plan.type === 'enterprise'">
                      <div class="price-text">Let’s talk</div>
                      <div class="market-price-text"></div>
                    </template>
                    <template v-else>
                      <div class="price-text">
                        <del class="original" v-if="!priceListType">{{ $t('pricing.$') }}{{ plan.unitAmountOriginal}}</del>  ${{plan.unitAmount}}
                      </div>
                      <div class="market-price-text">
                        {{$t('pricing.lifetime')}}
                      </div>
                    </template>
                  </div>
                </div>
                <div class="btn primary" v-if="plan.type === 'enterprise'" @click="gotoContact(plan)">
                  {{$t('pricing.enterprise')}}
                </div>
                <div class="btn primary" @click="onCreditSubscribe(plan)" v-else>
                  {{$t('pricing.upgradeBtn')}}
                </div>
              </div>
              <div class="plan-item-wrapper-bot credits-plan-item-wrapper-bot">
                <PriceRights :pricingType="priceListType" :vipType="plan.type === 'enterprise' ? 998: 999" :selectedInterval="selectedInterval+plan.name?.replace(' credits', '')"/>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="mobile-show plan-list-mobile" id="mobileList" v-if="ua.mobile">
      <template v-if="selectedInterval !== 'credits'">
        <swiper
          id="swiper1"
          ref="swiper"
          @slide-change="onSlideMobileChange"
          class="swiper-container swiper-container-price"
          :options="swiperOption">
          <swiper-slide v-for="plan in planList" :key="plan.vipType" :class="['plan-item', `plan-item-${from}`,
              planNameMap[plan.vipType], 
              {'popular-plan-item': 
                vipType !== VIP_TYPE.PROFESSIONAL 
                && vipType !== VIP_TYPE.ULTIMATE
                && planNameMap[plan.vipType] === 'professional'
                && !(plan.vipType === vipType &&
                  getPriceByInterval(plan).interval === vipInterval)
              },
              {'has-current-tag': 
                plan.vipType === vipType && getPriceByInterval(plan).interval === vipInterval}
              ]"
            >
              <div class="plan-item-wrapper">
                <div class="plan-item-wrapper-top">
                  <div
                    class="current-tag"
                    v-if="
                      plan.vipType === vipType &&
                      getPriceByInterval(plan).interval === vipInterval
                    "
                  >
                    {{ $t('pricing.currentTip') }}
                  </div>
                  <div class="name">
                    <!-- <div class="pro-discount-title" v-if="planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'">
                      Extra Surprise Drop! <img src="@/assets/img/header/star.png" alt="DupDub">
                    </div> -->
                    <iconpark-icon :name="plan.icon"></iconpark-icon>
                    <p>{{ $t(`pricing.${planNameMap[plan.vipType]}`) }}</p>
                  </div>
                  <div class="name-tip">{{plan.showTip}}</div> 
                  <div class="price-wrapper">
                    <div class="price">
                      <!-- <div class="pro-discount-txt" v-if="planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'">
                        <img src="@/assets/img/header/label.png" alt="DupDub">
                      </div>  -->
                      <div class="price-text" :class="{'price-font-color': planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'}">
                        <del class="original" v-if="getPriceStr(plan, 'monthPriceOriginal')!==0 && !priceListType">{{ $t('pricing.$') }}{{ getPriceStr(plan, 'monthPriceOriginal') }}</del> <span class="professional-price">{{ $t('pricing.$') }}{{ getPriceStr(plan, 'monthPrice') }}</span>
                      </div>
                      <div class="market-price-text">
                        {{ $t('pricing.permonth') }}
                      </div>
                    </div>
                    <div class="year" v-if="selectedInterval === VIP_INTERVAL.YEAR">
                      <span :class="{'price-font-color': planNameMap[plan.vipType] === 'professional' && getPriceByInterval(plan).interval === 'year'}">
                        ${{ getPriceStr(plan, 'yearPrice') }}
                      </span>
                      <span class="unit">{{ $t('pricing.yearAnnually') }}</span>
                    </div>
                  </div>
                  <!-- 按钮-重新订阅 -->
                  <div
                    class="btn primary" 
                    v-if="
                      unsubscribed &&
                      plan.vipType === vipType &&
                      getPriceByInterval(plan).interval === vipInterval
                    "
                    @click="onSubscribe(plan)"
                    :gtm-event="getPriceEvent(plan, 'renew')"
                  >
                    {{ $t('pricing.renewBtn') }}
                  </div>
                  <!-- 按钮-打开编辑器 -->
                  <div
                    class="btn" 
                    :class="{
                      'open-tts-btn': 
                      (plan.vipType === vipType &&
                        getPriceByInterval(plan).interval === vipInterval)
                    }"
                    v-else-if="
                      plan.vipType === VIP_TYPE.FREE ||
                      (plan.vipType === vipType &&
                        getPriceByInterval(plan).interval === vipInterval)
                    "
                    @click="goTotts(plan)"
                    :gtm-event="getPriceEvent(plan, 'openstudio')"
                  >
                    <!-- 进入到编辑器 -->
                    {{$t('pricing.openBtn')}}
                  </div>
                  <!-- 按钮-首次/升级订阅 -->
                  <div
                    class="btn primary"
                    v-else
                    @click="onSubscribe(plan)"
                    :gtm-event="
                      getPriceEvent(plan, isLogin && isBuyStatus ? 'upgrade' : 'new')
                    "
                  >
                  {{$t('pricing.upgradeBtn')}}
                  </div>
                </div>
                <div class="plan-item-wrapper-bot">
                  <PriceRights :pricingType="priceListType" :vipType="plan.vipType" :selectedInterval="selectedInterval"/>
                </div>
              </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </template>
      <template v-else>
        <swiper
          id="swiper2"
          ref="swiper2"
          @slide-change="onSlideMobileChange"
          class="swiper-container swiper-container-credits"
          :options="swiperMobileOption">
          <swiper-slide
            v-for="(plan, index) in creditsList" :class="`plan-item plan-item-${from}`" :key="index">
            <div class="plan-item-wrapper">
              <div class="plan-item-wrapper-top">
                <div class="name">
                  <iconpark-icon :name="plan.icon"></iconpark-icon>
                  <p>{{getCreditDisplayName(plan.name)}}
                    <el-popover
                      v-if="plan.type !== 'enterprise' && plan.tips"
                      placement="top"
                      ref="popoverTips"
                      trigger="hover"
                      popper-class="price-tooltip-item"
                      effect="dark">
                      <div v-html="plan.tips"></div>
                      <iconpark-icon name="tip-price" size="20" slot="reference"></iconpark-icon>
                    </el-popover>
                  </p>
                </div>
                <div class="name-tip-credits"></div> 
                <div class="price-wrapper">
                  <template v-if="plan.type === 'enterprise'">
                    <div class="price-text">Let’s talk</div>
                    <div class="market-price-text"></div>
                  </template>
                  <template v-else>
                    <div class="price-text" ><del class="original" v-if="!priceListType">{{ $t('pricing.$') }}{{ plan.unitAmountOriginal}}</del> ${{plan.unitAmount}}</div>
                    <div class="market-price-text">
                      {{$t('pricing.lifetime')}}
                    </div>
                  </template>
                </div>
                <div class="btn primary" v-if="plan.type === 'enterprise'" @click="gotoContact">
                  {{$t('pricing.enterprise')}}
                </div>
                <div class="btn primary" @click="onCreditSubscribe(plan)" v-else>
                  {{$t('pricing.upgradeBtn')}}
                </div>
              </div>
              <div class="plan-item-wrapper-bot credits-plan-item-wrapper-bot">
                <PriceRights :pricingType="priceListType" :vipType="plan.type === 'enterprise' ? 998: 999" :selectedInterval="selectedInterval+plan.name?.replace(' credits', '')"/>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </template>
    </div>
    <div class="plan-list-bottom-desc">
      <div class="title">
        <i></i>
        <span>{{$t('pricing.planBotDesc.title')}}</span>
        <i></i>
      </div>
      <div class="list">
        <template v-if="ua.mobile">
          <span v-for="(item, index) in $t('pricing.planBotDesc.listH5')" :key="index" v-html="item">
          </span>
        </template>
        <template v-else>
          <span v-for="(item, index) in $t('pricing.planBotDesc.list')" :key="index" v-html="item">
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PriceRights from './pricing-rights.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import apis from '@/utils/http-request'
import { VIP_TYPE, VIP_INTERVAL, PRODUCT_TYPE } from '@/constants/vip.js'
import { ua } from '@/utils/env'
import { throttle } from '@/assets/js/util'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import stat from '@/utils/stat'
let initPricingDiscount=1
// 获取价格信息
export function getPriceObj(vipType, interval, prices = []) {
  const nameMap = {
    [VIP_TYPE.PERSONAL]: 'Personal Plan',
    [VIP_TYPE.PROFESSIONAL]: 'Professional Plan',
    [VIP_TYPE.ULTIMATE]: 'Ultimate Plan'
  }
  const priceObj = prices.find(
    item => item.interval === interval && item.name === nameMap[vipType]
  ) || {
    interval,
    priceId: '',
    unitAmount: 0,
    monthPrice: 0, // 按年费用
    yearPrice: 0 // 按年费用
  }
  priceObj.priceId = priceObj.price_id || ''
  priceObj.unitAmount = (priceObj.unit_amount || 0) / 100
  let initMonthPrice = priceObj.interval === VIP_INTERVAL.MONTH ? priceObj.unitAmount: (priceObj.interval === VIP_INTERVAL.YEAR ? priceObj.unitAmount / 12: 0)
  let initYearPrice = priceObj.interval === VIP_INTERVAL.MONTH? priceObj.unitAmount * 12 : (priceObj.interval === VIP_INTERVAL.YEAR ? priceObj.unitAmount : 0)
  priceObj.monthPrice = Math.ceil(initMonthPrice * initPricingDiscount)
  priceObj.yearPrice =  Math.ceil(initYearPrice * initPricingDiscount)
  priceObj.monthPriceOriginal =  initMonthPrice
  return priceObj
}
// 创建会员计划
function createVipPlan(vipType, prices, icon, showTip) {
  return {
    vipType, // 会员类型
    icon: icon,
    showTip: showTip,
    prices: [
      getPriceObj(vipType, VIP_INTERVAL.MONTH, prices),
      getPriceObj(vipType, VIP_INTERVAL.YEAR, prices)
    ]
  }
}
export default {
  name: 'pricingPage',
  props: {
    //价格类型：是否是原价样式
    priceListType:{
      type: Boolean
    },
    // pricepopup 弹框样式 默认是组件样式
    'from': {
      type: String,
      default: ''
    }
  },
  components: {
    PriceRights,
    Swiper,
    SwiperSlide
  },
  mounted() {
    // 获取初始化的折扣
    this.getInitDiscount()
    this.getVipInfo().catch(() => {
      // 不关心失败结果
      console.log('未获取到vip会员信息')
    })
    this.checkIsNewFreeCus()
    if(ua.mobile) {
      window.addEventListener('scroll', throttle(this.scrollToTop, 300))
    }  
  },
  computed: {
    ...mapGetters('userInfo', ['isLogin']),
    ...mapState('userInfo', ['vipInfo', 'userInfoGot']),
    ...mapGetters(['discountInfo']),
    // 当前生效的会员订阅周期
    vipInterval() {
      return this.vipInfo.interval
    },
    // 当前生效的会员类型
    vipType() {
      return this.vipInfo.type
    },
    // 是否取消订阅了
    unsubscribed() {
      return !this.vipInfo.subscribe
    },
    // 购买状态加载中
    loadingBuyStatus() {
      if (this.userInfoGot && !this.isLogin) {
        // 确定未登录
        return false
      }
      if (this.userInfoGot && this.isBuyStatus !== null) {
        return false
      }
      return true
    },
    // 来自邮件的14天免费试用
    is14dayFreeTrial(){
      return this.$route.path === '/pricing/14dayfreetrial'
    }
  },
  data() {
    return {
      VIP_TYPE,
      VIP_INTERVAL,
      selectedInterval: VIP_INTERVAL.YEAR,
      planList: [
        createVipPlan(VIP_TYPE.FREE),
        createVipPlan(VIP_TYPE.PERSONAL),
        createVipPlan(VIP_TYPE.PROFESSIONAL),
        createVipPlan(VIP_TYPE.ULTIMATE)
      ],
      creditsList: [],
      allProductList: [],
      discount: 0, // 包年相对于包月的折扣
      planNameMap: {
        [VIP_TYPE.FREE]: 'free',
        [VIP_TYPE.PERSONAL]: 'personal',
        [VIP_TYPE.PROFESSIONAL]: 'professional',
        [VIP_TYPE.ULTIMATE]: 'ultimate'
      },
      isBuyStatus: null, //是否有购买记录
      currentPage: 0,
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      swiperMobileOption: {
        initialSlide: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      switchClass: false, //置顶的样式
      initSales: '--',
      initCouponCode: '',
    }
  },
  methods: {
    ...mapActions('userInfo', [
      'getVipInfo',
      'hasSubscribed',
      'subscribeVipWithLogin'
    ]),
    scrollToTop() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const switchWrapper = document.querySelector('#switchWrapper')
      const switchWrapperH = switchWrapper?.clientHeight || 0
      const priceList = document.querySelector('#mobileList')
      const priceTop = 200 
      const priceH = priceList ? priceList.clientHeight : 0
      const switchToTop = priceTop - switchWrapperH
      if(scrollTop <= switchToTop){
        this.switchClass = false
      }else if (switchToTop < scrollTop && scrollTop < priceH) {
        this.switchClass = true
      } else if(scrollTop >= priceH) {
        this.switchClass = false
      }
    },
    switchEvt(type) {
      switch(type) {
        case 'credits':
          this.selectedInterval = VIP_INTERVAL.CREDITS;
          break;
        case 'month':
          this.selectedInterval = VIP_INTERVAL.MONTH;
          break;
        default:  
          this.selectedInterval = VIP_INTERVAL.YEAR
      }
    },
    onCreditSubscribe(plan) {
      this.subscribeVipWithLogin({
        productType: PRODUCT_TYPE.CREDITS,
        promotionCode: this.initCouponCode,
        currentCreditsType: plan.name,
        from: '/tools/talkingphoto',
        initPricingDiscount: initPricingDiscount
      })
      // 弹框需要关闭
      this.$store.commit('userInfo/setShowPriceModal', [false])
      //GA埋点
      stat.event(this.from=='pricepopup'?'general_vip_popup_click':'pricing_upgrade_click', {
        plan: this.selectedInterval,
        version: plan.name,
        price: plan.unitAmount,
        source: this.$store.state.userInfo.priceSource
      })
      return
    },
    // 名称中有数字显示按千分位加,
    getCreditDisplayName(name) {
      const num = parseInt(name);
      if(!isNaN(num)) {
        return name.replace(num + '', num.toLocaleString());
      }
      return name;
    },
    // 是否有购买记录
    async checkIsNewFreeCus() {
      this.isBuyStatus = await this.hasSubscribed()
    },
    // 获取选中的订阅周期的计划价格
    getPriceByInterval(plan) {
      return plan.prices.find(
        priceObj => priceObj.interval === this.selectedInterval
      )
    },
    getPriceEvent(plan, type) {
      return `pricing-${
        {
          [VIP_TYPE.ULTIMATE]: 'ultimate',
          [VIP_TYPE.PERSONAL]: 'personal',
          [VIP_TYPE.PROFESSIONAL]: 'professional',
          [VIP_TYPE.FREE]: 'free'
        }[plan.vipType]
      }-${
        this.selectedInterval === VIP_INTERVAL.YEAR ? 'yearly' : 'monthly'
      }-${type}`
    },
    goTotts(plan) {
      this.$store.commit('userInfo/setShowPriceModal', [false])
      this.$router.push('/tools/home')
      this.gaPriceEvent(plan)
    },
    gaPriceEvent(plan){
      stat.event(this.from=='pricepopup'?'general_vip_popup_click':'pricing_upgrade_click', {
        plan: this.selectedInterval,
        version: this.planNameMap[plan.vipType],
        price:this.priceGA(plan,this.selectedInterval),
        source: this.$store.state.userInfo.priceSource
      })
    },
    priceGA(plan,interval){
      let price=null
      switch(interval){
        case 'month':
          price='monthPrice'
          break;
        case 'year':
          price='yearPrice'
      }
      return this.getPriceStr(plan,price)
    },
    getPriceStr(plan, type) {
      if (plan.vipType === VIP_TYPE.FREE) return 0
      const priceObj = this.getPriceByInterval(plan)
      return priceObj[type] || '-'
    },
    getMarketPriceStr(plan, type) {
      if (plan.vipType === VIP_TYPE.FREE) return 0
      const priceObj = this.getPriceByInterval(plan)
      return priceObj[type] || ''
    },
    // 点击订阅
    async onSubscribe(plan) {
      // (当前“选择的会员类型” < 当前“自己的会员类型”) && 排除非会员 && 已登陆状态 = 不允许降级
      if((plan.vipType < this.vipType) && (this.vipType !== 2) && this.isLogin){
        this.$message.error(this.$t('pricing.demoteErr'))
        return
      }

      this.subscribeVipWithLogin({
        vipType: plan.vipType,
        interval: this.selectedInterval,
        promotionCode: this.initCouponCode,
        from: '/pricing',
        // 价格页面不新窗口打开 只有弹框(from === 'pricepopup')新打开
        currentPageOpen: this.from ? false : true,
        initPricingDiscount: initPricingDiscount
      })
      this.$store.commit('userInfo/setShowPriceModal', [false])
      // GA埋点
      this.gaPriceEvent(plan)
      return
    },
    // 获取折扣
    async getInitDiscount() {
      try {
        const result = await apis.getDiscountPriceInfo()
        const { subDiscount, proCode, pricingDiscount } = result.data
        //如果是原价先写死25%
        this.initSales = this.priceListType?'25%': pricingDiscount
        //判断等于原价的时候
        if(!this.priceListType){
          this.initCouponCode = proCode
          initPricingDiscount = parseFloat(subDiscount) / 100
        }
        this.getPriceInfo()
      } catch (e) {
        console.log('获取折扣信息失败', e)
      }
    },
    // 获取会员价格信息
    async getPriceInfo() {
      try {
        const result = await apis.getPriceInfo()
        const { code, data } = result
        if (code === 200) {
          this.allProductList = data
          this.planList = [
            createVipPlan(VIP_TYPE.FREE, data, 'free-price', '3-day free trial'),
            createVipPlan(VIP_TYPE.PERSONAL, data, 'personal-price', 'Essential for individuals'),
            createVipPlan(VIP_TYPE.PROFESSIONAL, data, 'Professional1', 'Adequate for pro'),
            createVipPlan(VIP_TYPE.ULTIMATE, data, 'company-price', 'Perfect for business'),
          ]
          this.getCreditsList()
          return
        }
        console.log('获取价格信息失败', result)
      } catch (e) {
        console.log('获取价格信息失败', e)
      }
      this.$message.error('get pricing plans failed!')
    },
    getCreditsList() {
      let list = this.allProductList.filter(item => {
            return item.description.startsWith("credits")
          })
      this.creditsList = [
        this.createCredits('500 credits', 'free-price', list, ''),
        this.createCredits('1000 credits', 'personal-price', list, ''),
        this.createCredits('6000 credits', 'Professional1', list, ''),
        this.createCredits('Enterprise', 'company-price', list, '', 'enterprise'),
      ]
    },
    createCredits(name, icon, list, tips, type) {
      if(type === 'enterprise') {
        return {
          name,
          icon,
          tips,
          type
        }
      }
      const priceObj = list.findLast(
        item => item.name === name
      ) || {
        icon: '',
        priceId: '',
        tips: ''
      }
      priceObj.icon = icon
      priceObj.priceId = priceObj.price_id || ''
      priceObj.unitAmount = Math.ceil((priceObj.unit_amount || 0) / 100 * initPricingDiscount)
      priceObj.unitAmountOriginal = Math.ceil((priceObj.unit_amount || 0) / 100 )
      priceObj.tips = tips
      return priceObj
    },
    gotoContact(plan) {
      this.$router.push({path: '/contact'})
      stat.event(this.from=='pricepopup'?'general_vip_popup_click':'pricing_upgrade_click', {
        plan: this.selectedInterval,
        version: plan.type,
        price:'',
        source: this.$store.state.userInfo.priceSource 
      })
    },
    onSlideMobileChange() {
      const popovers = this.$refs.popoverTips
      if(popovers && popovers.length) {
        popovers.forEach((popover) => {
          popover.doClose()
        })
      }
    }
  }
}
</script>
<style lang="less">
.pricing-buy{
  a {
  color: #3583fb;
  &:hover {
    text-decoration: underline;
  }
  }
  .switch-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    &.switch-wrapper-pricepopup{
      margin-bottom: 54px;
    }
    .switch {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      font-size: 16px;
      background: #fff;
      border-radius: 14px;
      padding: 0 4px;
      .btn {
        user-select: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        width: 171px;
        height: 40px;
        transition: none;
        color: #001126;
        font-size: 16px;
        font-family: Poppins-SemiBold;
        font-weight: 600;
        &:hover{
          border-radius: 14px;
        }
      }

      &::before {
        position: absolute;
        left: 4px;
        top: 4px;
        content: '';
        width: 171px;
        height: 40px;
        background: #040D1A;
        border-radius: 12px;
        transition: all 0.15s linear;
      }

      &.year {
        .btn:nth-child(1) {
          color:#fff;
        }
        &::before {
          left: 4px;
        }
        .btn-month,
        .btn-credits{
          &:hover{
            background: #F7F7F9;
          }
        }
      }
      &.month{
        .btn:nth-child(2) {
          color: #fff;
        }
        &::before {
          left: 175px;
        }
        .btn-year,
        .btn-credits{
          &:hover{
            background: #F7F7F9;
          }
        }
      }
      &.credits{
        .btn:nth-child(3) {
          color: #fff;
        }
        &::before {
          left: 346px;
        }
        .btn-year,
        .btn-month{
          &:hover{
            background: #F7F7F9;
          }
        }
      }
    }
    .tip {
      padding-left: 6px;
    }
  }
  .plan-list {
    display: flex;
    justify-content: space-between;
  }
  .plan-item {
    position: relative;
    width: 266px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid #F0F1F3;
    background: #F9FAFF;
    font-size: 20px;
    color: #333;
    line-height: 28px;
    transition: transform 0.3s ease-in-out;
    // pro会员折扣促销
    .pro-discount-title{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      margin-bottom: 8px;
      font-size: 16px;
      font-family: Poppins-SemiBold, Poppins;
      font-weight: 600;
      color: #5C616A;
      line-height: 20px;
      background: linear-gradient(316deg, #445CFD 0%, #07BEFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      img{
        width: 15px;
        margin-left: 2px;
      }
    }
    // .price-font-color{
    //   font-family: Poppins-SemiBold, Poppins;
    //   font-weight: 600;
    //   color: #040D1A;
    //   background: linear-gradient(151deg, #3364FF 0%, #7426FF 100%);
    //   -webkit-background-clip: text;
    //   background-clip: text;
    //   -webkit-text-fill-color: transparent;
    // }
    // pro会员折扣促销
    &:hover{
      transform: translateY(-10px);
    }
    // 弹框样式修改开始
    &.plan-item-pricepopup{
      width: 362px;
      .name{
        height: auto;
      }
      &.free{
        display: none;
      }
      &.plan-item-credits{
        width: 266px;
      }
      .plan-item-wrapper{
        .plan-item-wrapper-top{
          padding: 30px 24px;
          position: sticky;
          top: 0;
          .name{
            & > iconpark-icon{
              display: none;
            }
          }
          &::before{
            width: 360px!important;
          }
          .pro-discount-title{
            position: absolute;
            left: 0;
            right: 0;
            top: 11px;
            font-size: 14px;
            line-height: 18px;
            height: 20px;
            img{
              width: 14px;
              margin-top: -2px;
            }
          }
          .price .pro-discount-txt {
            right: 24px;
          }
        }
        .plan-item-wrapper-bot{
          height: auto;
          padding: 20px 24px;
        }
      }
    }
    // 弹框样式修改结束
    // 高亮样式
    &.popular-plan-item{
      background: linear-gradient(180deg, #D8DCFF 0%, #F3F2FF 100%);
      border-radius: 0 0 12px 12px;
      .name{
        margin-bottom: 2px;
      }
      .plan-item-wrapper{
        .plan-item-wrapper-top{
          border-radius: 0 0 12px 12px;
          background: #F1EFFF;
          box-shadow: 0px 4px 8px 0px rgba(211,208,247,0.8);
          // top: 30px;
          &::before{
            content: 'Most popular';
            display: block;
            width: 264px;
            height: 30px;
            background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/price-popular.gif) no-repeat;
            background-size: cover;
            border-radius: 12px 12px 0px 0px;
            font-size: 18px;
            font-family: Poppins-Medium;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 30px;
            position: absolute;
            text-align: center;
            top: -30px;
            left: 0;
            right: 0;
          }
        }
      }
    }
    a{
      color: #040D1A;
      text-decoration: none;
    }
    .btn{
      transition: all 0.3s ease-in-out;
      a{
        display: block;
      }
      &:hover{
        background: #272F3A;
        transform: translateY(-10px);
      }
    }
    .plan-item-wrapper {
      position: relative;
      .plan-item-wrapper-top{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(238,240,246,0.8);
        border-radius: 12px;
        padding: 24px 24px 40px;
        box-sizing: border-box;
        text-align: center;
      }
      .plan-item-wrapper-bot{
        height: auto;
        padding: 22px 10px 22px 16px;
        box-sizing: border-box;
        &.credits-plan-item-wrapper-bot{
          height: auto;
        }
      }
    }
    .current-tag {
      position: absolute;
      top: 0;
      right: 0;
      width: 129px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 500;
      color: #FFFFFF;
      background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/current-tag1694586321.png) no-repeat;
    }
    .name {
      margin-bottom: 4px;
      height: 81px;
      & > iconpark-icon{
        font-size: 36px;
        margin-bottom: 11px;
      }
      p{
        font-size: 26px;
        font-family: Poppins-SemiBold;
        font-weight: 600;
        color: #040D1A;
        line-height: 34px;
      }
    }
    .name-tip{ 
      font-size: 14px;
      font-family: Poppins-Medium;
      font-weight: 500;
      color: #5C616A;
      line-height: 24px;
      margin-bottom: 20px;
      height: 24px;
    }
    .name-tip-credits{
      height: 16px;
    }
    .price-wrapper {
      margin-bottom: 24px;
    }
    .price {
      margin-bottom: 2px;
      text-align: center;
      position: relative;
      .pro-discount-txt{
        position: absolute;
        right: -24px;
        top: -10px;
        width: 90px;
        height: 26px;
        img{
          width: 100%;
          vertical-align: inherit;
        }
      }
    }
    .price-text {
      margin-bottom: 4px;
      font-size: 46px;
      font-family: Poppins-SemiBold;
      font-weight: 600;
      color: #040D1A;
      line-height: 54px;
    }
    .market-price-text {
      font-size: 14px;
      font-family: Poppins-SemiBold, Poppins;
      font-weight: 600;
      color: #040D1A;
      height: 22px;
      line-height: 22px;
    }
    .year {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Poppins-SemiBold;
      font-weight: 600;
      color: #040D1A;
      line-height: normal;
      .unit {
        margin-left: 4px;
      }
    }

    .btn {
      cursor: pointer;
      width: 100%;
      height: 40px;
      margin: 0 auto;
      border-radius: 12px;
      font-size: 14px;
      font-family: Poppins-SemiBold;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border: 2px solid rgba(4,13,26,0);
      &.primary {
        background: #040D1A;
        border-color: #040D1A;
        color: #fff;
      }
      &.open-tts-btn{
        border-color: rgba(4,13,26,0.1);
        &:hover{
          background: #F4F6FA;
        }
      }
    }
    &.free{
      .btn{
        border-color:rgba(4,13,26,0.1);
        a{
          color: #040D1A;
        }
        &:hover{
          background: #F4F6FA;
        }
      }
    }
    .tip {
      position: absolute;
      font-size: 14px;
      color: #666666;
      line-height: 30px;
      left: 0;
      right: 0;
      text-align: center;
      bottom: -40px;
    }
  }
  .plan-list-bottom-desc{
    margin-top: 16px;
    .title{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;
      i{
        display: inline-block;
        width: 102px;
        height: 3px;
        background: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/price-bottom-left.png') no-repeat;
        background-size: cover;
        &:last-child{
          background: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/price-bottom-right.png') no-repeat;
        }
      }
      span{
        font-size: 16px;
        font-family: Poppins-Medium;
        font-weight: 500;
        color: #5C616A;
        line-height: 23px;
        margin: 0 12px;
      }
    }
    .list{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      background: #F4F5FA;
      border-radius: 8px;
      &>span{
        padding: 20px 0;
        font-size: 12px;
        font-family: Poppins-Medium;
        font-weight: 500;
        color: #5C616A;
        line-height: 22px;
        border-right: 1px solid #E3E5EB;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        word-break: break-word;
        &:last-child{
          border-right: none;
        }
        span{ 
          font-family: Poppins-Medium;
          font-weight: 500;
          line-height: 22px;
          border-right: 1px solid #E3E5EB;
          text-align: center;
          color: rgba(4,13,26,0.45);
          word-break: keep-all;
        }
      }
    }
  }
}
.web-show{
  display: block!important;
}
.mobile-show{
  display: none!important;
}
// Christmas start
.pricing-buy-christmas{
  .plan-item{
    border-color: #F0F1F3;
    background-color: #FFF0F0;
    &.plan-item-pricepopup{
      .plan-item-wrapper{
        .plan-item-wrapper-top{
          &::before{
            width: 362px!important;
          }
        }
      }
    }
    .plan-item-wrapper{
      .plan-item-wrapper-top{
        box-shadow: none;
      }
    }
    &.free{
      // .price-text{
      //   color: #00D4EB;
      // }
      // .btn{
      //   border: 2px solid #00D4EB;
      //   &:hover{
      //     background: #00D4EB;
      //   }
      // }
    }
    &.personal{
      // .price-text{
      //   color: #2AF0C1;
      // }
      // .btn{
      //   border: 2px solid #2AF0C1;
      // }
      // .btn:hover{
      //   background-color: #2AF0C1;
      // }
    }
    &.ultimate{
      // .price-text{
      //   color: #FF93CC;
      // }
      // .btn{
      //   border: 2px solid #FF93CC;
      // }
      // .btn:hover{
      //   background-color: #FF93CC;
      // }
    }
    &.popular-plan-item,
    &.professional{
      background: linear-gradient(180deg, #FFC6C6 0%, #FFEBEB 100%);
      .year,
      .market-price-text{
        .price-font-color{
          font-weight: 600;
          color: rgba(4,13,26,0.94);
          line-height: 22px;
          background: linear-gradient(214deg, #FF6643 0%, #FC02B6 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .price-text{
        .professional-price{
          font-weight: 600;
          line-height: 54px;
          background: linear-gradient(214deg, #FF6643 0%, #FC02B6 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .plan-item-wrapper{
        .plan-item-wrapper-top{
          background: #FFEFEF;
          box-shadow: 0px 4px 8px 0px rgba(247,208,208,0.8);
          &::before{
            background-image: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/dupdub_back_five_1700547342/dupdub_back_five_1700547342/price-popular-black.gif');
          }
        }
      }
      .btn{
        &.open-tts-btn:hover{
          background: linear-gradient(142deg, #FC30FF 0%, #FF4242 100%);
        }
        border: none;
        color: #fff;
        background: linear-gradient(142deg, #FC30FF 0%, #FF4242 100%);
      }
    }
    .current-tag{
      top: -21px;
      background: url('https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/current-tag-bg-1700552497.png') no-repeat;
      background-size: 100% 100%;
    }
    .price-text{
      display: flex;
      align-items: center;
      justify-content: center;
      .original{
        font-size: 20px;
        font-weight: 500;
        color: rgba(150,154,163,0.8);
        line-height: 22px;
        margin-right: 5px;
        text-decoration:line-through;
      }
    }
  }
  .plan-list-bottom-desc{
    .title{
      span{
        font-weight: 500;
        color: #B0B5BF;
      }
    }
    .list{
      background: #FFF0F0;
      span{
        border-right: 1px solid #FFFFFF;
        color: #5C616A;
      }
    }
  }
  .plan-item-credits{
    .price-text{
      color: #040D1A;
    }
    .btn{
      &.primary {
        border: 2px solid #FFFFFF;
      }
    }
  }
  
  .switch-wrapper {
    .switch{
      .btn{
        font-weight: bold;
      }
    } 
    .tip {
      padding-left: 6px;
      font-size: 12px;
      font-weight: bold;
      color: #5C616A;
      line-height: 20px;
      background: linear-gradient(142deg, #FC30FF 0%, #FF4242 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
// Christmas end

// 小屏
@import '@/assets/css/responsive.less';
@rules: {
  .web-show{
    display: none!important;
  }
  .mobile-show{
    display: block!important;
  }
  .pricing-buy{
    .plan-list-bottom-desc{
      margin: 0 16px 18px;
      .title{
        span{
          text-align: center;
        }
        i{
          display: none;
        }
      }
      .list{
        grid-template-columns: repeat(2, 1fr);
        span{
          border-bottom: 1px solid #E3E5EB;
          &:nth-child(2){
            border-right: none;
          }
          &:nth-child(3){
            grid-column-start: 1;
            grid-column-end: 3;
            border-right: none;
          }
          &:nth-child(5){
            border-right: none;
          }
          &:nth-child(6), 
          &:nth-child(7){
            border-bottom: none;
          }
        }
      }
    }
    .switch-wrapper{
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      .tip{
        width: 70px;
        height: 18px;
        text-align: center;
        background: linear-gradient(120deg, #FF75E8 0%, #8F6CFF 100%);
        border-radius: 11px 11px 0px 11px;
        position: absolute;
        color: #FFFFFF;
        -webkit-text-fill-color: #fff;
        top: -8px;
        right: 0;
      }
      .switch{
        background: none;
        width: 307px;
        height: 40px;
        padding: 14px 0 14px;
        margin: 0 auto;
        &::before{
          display: none;
        }
        .btn{
          background: #FFFFFF;
          border-radius: 12px;
          font-size: 14px;
          &.btn-year{
            width: 89px;
            flex: none;
          }
          &.btn-month{
            width: 79px;
            flex: none;
            margin: 0 8px;
          }
          &.btn-credits{
            width: 123px;
            flex: none;
          }
        }
        &.year{
          .btn-year{
            background: #040D1A;
            font-size: 14px;
          }
        }
        &.month{
          .btn-month{
            background: #040D1A;
            font-size: 14px;
          }
        }
        &.credits{
          .btn-credits{
            background: #040D1A;
            font-size: 14px;
          }
        }
      }
      &.switch-top{
        position: fixed;
        top: 50px;
        z-index: 10;
        background: #F6F4F4;
        padding: 0;
        width: 100%;
      }
    }
  }
  .plan-list-mobile{
    .swiper-pagination-bullet {
      width: 9px;
      height: 4px;
      background: #C4C4C4;
      border-radius: 3px;
      margin: 0 4px;
      opacity: 1;
      &.swiper-pagination-bullet-active{
        background: #040D1A;
        width: 32px;
      }
    }
    .swiper-pagination {
      bottom: 40px;
      left: 0;
      right: 0;
    }
    .plan-item{
      margin-left: 24px;
    }
    .swiper-container-price{
      height: 1820px;
      padding-top: 45px;
      // .swiper-wrapper{
      //   height: 966px;
      // }
    }
    .swiper-container-credits{
      // height: 614px;
      padding-top: 45px;
      .swiper-wrapper{
        // height: 544px;
        .plan-item{
          margin-left: 24px;
          .price-text{
            letter-spacing: -1px;
          }
        }
      }
    }
  }
  // 黑五移动端 start
  .pricing-buy-black{
    .plan-item-wrapper{
        height: 966px;
      }
    .switch-wrapper{
      &.switch-top{
        background: #FFE0E0;
      }
      .switch{
        .tip{
          background: linear-gradient(214deg, #FF6643 0%, #FC02B6 100%);
          color: #fff;
          line-height: 18px;
        }
        .btn{
          background: #1D1D1D;
          font-weight: 400;
          color: #fff;
        }
        &.year{
          .btn-year{
            background: #FFFFFF;
            color: #0E0F0F;
          }
        }
        &.month{
          .btn-month{
            background: #FFFFFF;
            color: #0E0F0F;
          }
        }
        &.credits{
          .btn-credits{
            background: #FFFFFF;
            color: #0E0F0F;
          }
        }
      }
    }
    .plan-list-bottom-desc{
      .list{
        span{
          border-bottom: 1px solid rgba(227, 229, 235, 0.2);
        }
      }
      .title{
        span{
          color: #5C616A;
        }
      }
    }
  }
  .plan-list-mobile{
    border-radius: 12px;
    .swiper-pagination-bullet {
      background-color: rgba(255, 255, 255, 0.5);
      &.swiper-pagination-bullet-active{
        background: #FFFFFF;
      }
    }
    .swiper-container-credits{
      .btn{
        border: 2px solid #FFFFFF;
      }
    }
  }
  // 黑五移动端 end
}
.screen-md(@rules);
</style>