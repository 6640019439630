import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import '@/assets/css/component.css'
import '@/assets/css/reset.css'
import '@/assets/css/button.less'
import '@/assets/css/animate.less'
import '@/assets/css/common.less'

import 'animate.css'

/**
 * 引入静态资源
 */
export default function setupAssets() {
  // ...
}
