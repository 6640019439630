<template>
  <div class="result-container">
    <div class="ai-list-top">
      <div
        class="ai-list-top-left"
      >
        <div @click="back">
          <Icon name="icon-back-dp4752hn" :isShow="true" :size="20" />
        </div>
      </div>
      <div class="title">Generation results</div>
      <div class="ai-list-top-right">
      </div>
    </div>
    <div class="ai-box-wrap">
      <AiDetail
        :detail="resultData"
        :index="0"
        :type="1"
      />
    </div>
    <div class="ai-sound-btn-list">
      <div
        class="btn cancel"
        @click="cancel"
      >Cancel</div>
      <div
        class="btn generate"
        @click="back"
      >Regenerate
        <el-tooltip placement="top" popper-class="ai-sound-effect-tooltip middle-width" content="It will cost 2 credits to generate, and you will receive 4 sound effects.">
            <iconpark-icon name="common-i" size="14"></iconpark-icon>
          </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import AiDetail from './ai-detail.vue'
import Icon from './icon.vue'

export default {
  data () {
    return {
    }
  },
  props: ['resultData'],
  components: { AiDetail, Icon },
  methods: {
    back() {
      this.$emit('changeType', 1)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.result-container {
  .ai-list-top {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    box-shadow: inset 0px -1px 0px 0px #f0f0f0;
    .title {
      font-family: 'Poppins-SemiBold';
      color: #101010;
      font-size: 16px;
    }
    .ai-list-top-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #101010;
      width: 100px;
    }
    .ai-list-top-right {
      display: flex;
      align-items: center;
      width: 32px;
      width: 100px;
    }
  }
  .ai-box-wrap {
    height: 380px;
    overflow: auto;
    padding: 0 24px;
    margin-top: 16px;
  }
}
</style>