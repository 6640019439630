<template>
	<span class="listen-audio" @click="playAlias">
		<img
		v-if="!playStatus"
		src="@/pages/article-voice-v2/component/tool-bar/image/icon_audition_nor.svg"
		alt=""
		/>
		<img v-else src="@/pages/article-voice-v2/component/tool-bar/image/icon_audition_on.svg" alt="" />
		<audio :src="aliasAudioUrl" ref="audio" preload="auto"></audio>
	</span>
</template>
<script>
import api from '@/utils/http-request'
export default {
	props: {
		addLexiconParam: [Object]
	},
	data() {
		return {
			aliasAudioUrl: '',
			playStatus: false,
		}
	},
	methods: {
		playAlias() {
			this.$emit('playAlias')
		},
		listenAudio(url) {
			this.__getAudioBlob(url).then(data => {
				this.aliasAudioUrl = data
				this.playAudio()
			}).catch(e =>{
				console.log(e, 'getAudioBlobUrl')
			})
		},
		playAudio() {
			this.$nextTick(() => {
				this.playStatus = true
				const $audio = this.$refs.audio
				$audio.play()
				$audio.onended = () => {
					this.pronUrl = ''
					this.playStatus = false
				}
			})
		},
		// 获取音频的blob
  	__getAudioBlob(url) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				try {
					const body = { url }
					let res = await this.$http.post(api.audition, body, {
						responseType: 'blob'
					})
					const blob = window.URL.createObjectURL(res.data)
					resolve(blob)
				} catch (err) {
					reject(err)
				}
			})
  	}
	}
}
</script>
