// 获取audio的时长
export const dealSoundTime = item => {
  const time = Math.ceil(item)
  const minute = time / 60
  let minutes = parseInt(minute)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  const second = time % 60
  let seconds = Math.round(second)
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${minutes}:${seconds}`
}