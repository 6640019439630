<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="closeDialog"
    class="preview-out-of-words"
    :modal-append-to-body="true"
    :append-to-body="true"
  >
    <h3>{{ title }}</h3>
    <p>
      {{ desc }}
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeDialog">{{
        getI18n('previewOutOfWordsFree.cancel')
      }}</el-button>
      <el-button class="btn" @click="confirmClick" type="primary">
        {{ getI18n('previewOutOfWordsFree.upgrade') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
// import stat from '@/utils/stat'
export default {
  name: 'preview-out-of-words',
  inject: [
    'getI18n',
  ],
  data() {
    return {
      dialogVisible: false,
      title: '',
      desc: '',
      // 埋点来源标识
      // priceSource: '',
      // downloadType: ''
    }
  },
  // inject: ['openPricingDialogue'],
  methods: {
    openDialog({ title, desc, /* source, downloadType, */ onConfirm }) {
      this.dialogVisible = true
      this.title = title
      this.desc = desc
      // this.priceSource = source
      // this.downloadType = downloadType
      this.onConfirm = onConfirm
    },
    closeDialog() {
      this.dialogVisible = false
    },
    confirmClick() {
      // this.openPricingDialogue(this.priceSource)
      if(typeof this.onConfirm === 'function'){
        this.onConfirm()
        this.dialogVisible = false
      }
      // if (this.downloadType) {
      //   this.dialogVisible = false
      //   stat.event('tts-pricingpopup-srtandvideo-upgrade', {
      //     export_type: this.downloadType
      //   })
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.preview-out-of-words {
  :deep(.el-dialog) {
    width: 492px !important;
    border-radius: 20px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 32px 24px 32px;
    }
    .el-dialog__footer {
      padding: 0 24px 16px;
    }
    .el-dialog__headerbtn {
      right: 16px;
      top: 16px;
      .el-icon-close {
        font-size: 22px;
        font-weight: bold;
        color: rgba(193, 193, 193, 1);
      }
    }
    h3 {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
      word-break: break-word;
    }

    p {
      color: #666666;
      font-size: 14px;
      line-height: 20px;
      word-break: normal;
      overflow-wrap: break-word;
    }
    .btn {
      width: 72px;
      height: 32px;
      border-radius: 12px;
      line-height: 32px;
      border-radius: 12px;
      border: 1px solid #cccccc;
      padding: 0;
      margin-left: 16px;
      &.el-button--default {
        border: solid 1px #cccccc;
        color: #999;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      &.el-button--primary {
        background: #3583fb;
        border: solid 1px #3583fb;
        a {
          display: block;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
