/*
 * @Date: 2020-05-12 15:25:19
 * @Author: yang.yang1809
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-07-22 17:31:57
 */
import * as types from './mutation-types'
const app = {
  state: {
    speed: 1.0,
    speaker: 'billy_lpcnet',
    speakerLanguage: 'MANDARIN_CHINESE',
    type: '',
    aliasContent: '',
    // 英文发音内容
    pronContent: '',
    originSpeaker: 'billy_lpcnet',
    originSpeed: 1.0
  },
  getters: {
    speed: state => state.speed,
    speaker: state => state.speaker,
    speakerLanguage: state => state.speakerLanguage,
    type: state => state.type,
    aliasContent: state => state.aliasContent,
    pronContent: state => state.pronContent,
    originSpeaker: state => state.originSpeaker,
    originSpeed: state => state.originSpeed
  },
  mutations: {
    [types.PLAY_AUDIO_SPEED](state, count) {
      state.speed = count
    },
    [types.PLAY_AUDIO_SPEAKER](state, speaker) {
      state.speaker = speaker
    },
    [types.PLAY_AUDIO_SPEAKER_LANGUAGE](state, speakerLanguage) {
      state.speakerLanguage = speakerLanguage
    },
    [types.ARTICLE_TYPE](state, type) {
      state.type = type
    },
    [types.ALIAS_CONTENT](state, aliasContent) {
      state.aliasContent = aliasContent
    },
    [types.PRON_CONTENT](state, pronContent) {
      state.pronContent = pronContent
    },
    [types.AUDIO_ORIGIN_SPEAKER](state, speaker) {
      state.originSpeaker = speaker
    },
    [types.AUDIO_ORIGIN_SPEED](state, speed) {
      state.originSpeed = speed
    }
  },
  actions: {
    changePlaySpeed({ commit }, count) {
      commit(types.PLAY_AUDIO_SPEED, count)
    },
    changePlaySpeaker({ commit }, speaker) {
      commit(types.PLAY_AUDIO_SPEAKER, speaker)
    },
    changePlaySpeakerLanguage({ commit }, speakerLanguage) {
      commit(types.PLAY_AUDIO_SPEAKER_LANGUAGE, speakerLanguage)
    },
    /* 文章添加类型： type = 'IMPORT'(导入和粘贴) | type = ''(输入) */
    changeArticleType({ commit }, type) {
      commit(types.ARTICLE_TYPE, type)
    },
    changeAliasContent({ commit }, aliasContent) {
      commit(types.ALIAS_CONTENT, aliasContent)
    },
    changePronContent({ commit }, pronContent) {
      commit(types.PRON_CONTENT, pronContent)
    },
    changeOriginSpeaker({ commit }, speaker) {
      commit(types.AUDIO_ORIGIN_SPEAKER, speaker)
    },
    changeOriginSpeed({ commit }, speed) {
      commit(types.AUDIO_ORIGIN_SPEED, speed)
    }
  }
}

export default app
