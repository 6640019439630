<template>
  <div class="qiankun-micro-app">
    <!-- 子应用挂载点 -->
    <div :id="containerId" />
  </div>
</template>
<script>
import { checkActivityFunctions } from 'single-spa'
import { start } from 'qiankun'
import { apps } from './config'
import stat from '@/utils/stat'

export default {
  props: {
    appName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      containerId: ''
    }
  },
  created() {
    if (!this.appName) {
      console.warn('请确认传入的子应用名称！！！')
      stat.event('init_tts_pc_editor_error', {
        reason: 'noAppName'
      })
      return
    }
    this.containerId =
      apps.find(app => app.name === this.appName)?.containerId || ''
    if (!this.containerId) {
      console.warn('请确认传入的子应用名称！！！')
      stat.event('init_tts_pc_editor_error', {
        reason: 'invalidAppName'
      })
      return
    }
  },
  mounted() {
    if (!this.containerId) return
    if (!document.querySelector(`#${this.containerId}`)) {
      console.warn('请确认子应用挂载节点是否存在！！！')
      stat.event('init_tts_pc_editor_error', {
        reason: 'noContainer'
      })
      return
    }
    this.checkMicroApp()
    start()
  },
  methods: {
    checkMicroApp() {
      const matchedNames = checkActivityFunctions()
      console.log(matchedNames, 'matchedNames', this.appName)
      if (matchedNames.length === 0) {
        stat.event('init_tts_pc_editor_error', {
          reason: 'noActivity'
        })
        return
      }
      if (!matchedNames.includes(this.appName)) {
        console.warn('子应用名称匹配失败，请确认传入的子应用名称是否正确！！！')
        stat.event('init_tts_pc_editor_error', {
          reason: 'noActivityMatch'
        })
        return
      }
    }
  }
}
</script>

<style lang="less" scoped>
.micro-app {
  width: 100%;
}
</style>
