<template>
  <div class="count-timer">
    <p v-html="displayTime" class="count"></p>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TimeDistanceBlock',
  props: {
    prevDate: {
      default() {
        return new Date().getTime()
      }
    },
    autoPlay: {
      type: Boolean,
      default() {
        return true
      }
    },
    displayType: {
      type: String,
      default: 'second'
    }
  },
  data() {
    return {
      DateTime: 0,
      timer: null,
      afterDate: null
    }
  },
  computed: {
    ...mapGetters('userInfo', ['user', 'isLogin']),
    displayTime() {
      const res = this.computedTimeWithoutDay(this.DateTime)
      return (!res)
          ? '<span>0</span><em>:</em><span>0</span><em>:</em><span>0</span>'
          : `<span>${res.hour.charAt(0)}</span><span>${res.hour.charAt(1)}</span>
              <em>:</em>
              <span>${res.minute.charAt(0)}</span><span>${res.minute.charAt(1)}</span><em>:</em>
              <span>${res.second.charAt(0)}</span><span>${res.second.charAt(1)}</span>`
    }
  },
  mounted() {
    this.autoPlay && this.startCount()
  },
  methods: {
    ...mapActions('userInfo', ['getUserInfo']),
    async startCount() {
      const prev = this.prevDate
      //计算3天后的结束时间
      // 如果依赖的数据没有返回，则手动调用再执行
      if(!(this.user?.createdAt)) await this.getUserInfo()
      let registerTime = new Date(this.user.createdAt)
      let endTime = registerTime.setDate(registerTime.getDate() + 3)
      this.afterDate = endTime
      const next = this.afterDate
      this.DateTime = Math.abs(prev - next)
      this.countDownDecalary()
    },
    countDownDecalary() {
      if (this.displayType === 'ms') {
        // 毫秒时
        // this.DateTime = this.DateTime - 100
        this.timer = setInterval(() => {
          if (this.DateTime < 100) {
            clearInterval(this.timer)
            this.DateTime = 0
            this.$emit('end')
            return
          }
          this.DateTime = this.DateTime - 100
        }, 100)
      } else {
        // this.DateTime = this.DateTime - 1000
        this.timer = setInterval(() => {
          if (this.DateTime < 1000) {
            clearInterval(this.timer)
            this.DateTime = 0
            this.$emit('end')
            return
          }
          this.DateTime = this.DateTime - 1000
        }, 1000)
      }
    },
    computedTimeWithoutDay(time) {
      if(time === 0) {
        return undefined
      }
      const hour = Math.floor(time / 1000 / 60 / 60)
      const hourTime = hour * 1000 * 3600
      const minute = Math.floor((time - hourTime) / 1000 / 60)
      const minuteTime = 1000 * 60 * minute
      const second = Math.floor((time - hourTime - minuteTime) / 1000)
      const secondTime = second * 1000
      const ms = Math.floor((time - hourTime - minuteTime - secondTime) / 100)
      let hourVal = hour < 10 ? '0' + hour : hour
      let minuteVal = minute < 10 ? '0' + minute : minute
      let secondVal = second < 10 ? '0' + second : second
      return {
        hour: hourVal.toString(),
        minute: minuteVal.toString(),
        second: secondVal.toString(),
        ms
      }
    }
  }
}
</script>
<style scoped lang="less">
.count-timer{
  &.show-mini{
    .count{
      :deep(em){
        margin-right: 2px;
      }
      :deep(span){
        width: 24px;
        height: 31px;
        font-size: 22px;
      }
    }
  }
  .count{
    display: flex;
    align-items: center;
    :deep(em){
      color: #F6B601;
      margin-right: 3px;
      font-family: Poppins-SemiBold;
    }
    :deep(span){
      display: flex;
      width: 30px;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-size: 28px;
      font-family: Poppins-SemiBold;
      text-align: center;
      background: #F6B601;
      border-radius: 3px;
      color: #fff;
      margin-right: 3px;
    }
  }

}
</style>