import Vue from 'vue'
import Router from 'vue-router'
import { routes } from '@/router/routes'
import { createRouterFilter } from '@/router/router-filter'
import { routes as MovedRoutes } from './move-routes'
const originalPush = Router.prototype.push
const movePath = MovedRoutes.map(v => v.path)
const moveName = MovedRoutes.map(v => v.name)

Router.prototype.push = function push(location) {
  let path = ''
  if (location.name && moveName.includes(location.name)) {
    path = MovedRoutes.filter(v => v.name == location.name)[0]?.path || ''
  }
  if (location.path && movePath.includes(location.path)) {
    path = location.path
  }
  if (path) {
    path = this.resolve(location).href
    let suffix = (process.env.VUE_APP_DUPDUB_URL + path).indexOf('?') === -1 ? '?' : '&'
    window.location.href = process.env.VUE_APP_DUPDUB_URL + path + suffix + Vue.prototype.$ssrUrlSearch()
    return
  }
  return originalPush.call(this, location).catch(err => err)
}

export const registerRouter = () => {
  Vue.use(Router)
  // 创建路由守卫
  createRouterFilter(router)
}

/**
 * 如果想添加路由，请到 router/modules 下面的自文件夹寻找对应的路由文件添加
 * （***禁止在此处添加路由***）
 */
const router = new Router({
  mode: 'history',
  routes: [
    ...routes
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.onError((error) => {
  console.error('-------routerError', error)
})

export default router