import { TypeToExt } from './mime-types'

/**
 * 是否是可接受的文件类型
 * @param {File} file 
 * @param {string} accept 'video/mp4,video/x-flv'
 * @returns {boolean}
 */
export function isAcceptFile(file, accept, more = false) {
    const match = /\.(\w+)$/.exec(file.name)
    const extension = match ? match[1] : ''
    const extensionMore = match ? match[0].toLowerCase() : ''; // 转换为小写  
    if (more) {
        return (
            accept.indexOf(extensionMore) !== -1
        )
    } else {
        const mimeTypes = accept.split(',')
        const extensions = mimeTypes.map(mimeType => {
            if (TypeToExt[mimeType]) return TypeToExt[mimeType]
            let ext = mimeType.split('/')[1]
            ext = ext.replace(/^x-/, '')
            return ext
        }).flat()
        return (
            mimeTypes.indexOf(file.type) !== -1 ||
            extensions.indexOf(extension) !== -1
        )
    }
}

function customLocateFile(url, scriptDirectory) {
    // 假设您已经知道WASM文件的完整URL  
    // 注意：这里应该是一个完整的URL，而不是一个目录或前缀  
    const wasmUrl = 'https://cdn-static.dupdub.com/frontend/autoupload/dupdub/other/MediaInfoModule.wasm';
    // 如果库要求的是相对于某个目录的URL，您可能需要结合scriptDirectory来构建URL  
    // 但在这个例子中，我们假设我们已经有了完整的URL  
    return wasmUrl;
}
//解析文件时长
let mediaInfoPromise = null; // 用于缓存初始化后的Promise   
function initMediaInfo() {
    // 如果Promise已经存在，直接返回  
    if (mediaInfoPromise) {
        return mediaInfoPromise;
    }
    // 创建一个新的Promise  
    mediaInfoPromise = new Promise((resolve, reject) => {
        // format：结果值的格式（选项：object、、或）JSON XML HTML text 
        // locateFile  传递指定wasm地址路径的函数
        MediaInfo({ format: 'JSON', locateFile: customLocateFile }, (mediainfo) => {
            // 初始化成功后，解析Promise  
            resolve(mediainfo);
        }, (err) => {
            console.log(err, '初始化报错')
            // 初始化失败时，拒绝Promise  
            reject(err);
        });
    });
    // 返回Promise  
    return mediaInfoPromise;
}
export async function parseDuration(file) {
    let output = null;
    try {
        // 等待mediainfo初始化完成  
        const mediainfo = await initMediaInfo();
        // 封装获取文件大小和读取文件块的方法  
        const getSize = () => file.size;
        const readChunk = (chunkSize, offset) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event.target.error) {
                        reject(event.target.error);
                    }
                    resolve(new Uint8Array(event.target.result));
                };
                reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            });
        // 使用mediainfo实例分析文件  
        const result = await mediainfo.analyzeData(getSize, readChunk);
        output = JSON.parse(result).media;
    } catch (error) {
        console.log(`An error occurred:\n${error.stack || error}`)
        output = 0
    }
    // 如果需要，可以返回output  
    return output;
}