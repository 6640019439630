<template lang="">
    <div>
      <el-dialog
        :visible.sync="dialogVisible"
        :lock-scroll="false"
        custom-class="unsubscribe-discount-container"
        width="600px">
					<div class="unsubscribe-discount-box">
						<div class="show-discount">
							<div>{{detailData.discount}}% <br>&nbsp;Off</div>
						</div>
            <!-- 取消订阅但是还在有效期内 -->
						<template v-if="detailData.subId" >
							<div class="title">
								Exclusive<span> {{detailData.discount}}% Off </span><br>Discount to Win You Back!
							</div>
							<p>We noticed you canceled your subscription and we understand things change, and your needs might have evolved. </p>
							<p>To win you back, we are offering an exclusive <span>{{detailData.discount}}% Off discount</span> for your next subscription renewal. This offer is only valid for a limited time! Redeem your discount before it's gone.</p>
							<div class="discount" @click="recoverSubscription">Claim My Discount</div>
						</template>
            <!-- 会员已到期 -->
						<template v-else >
              <div class="title">
                We Miss You!<br>Come Back for<span>{{detailData.discount}}% Off </span>
              </div>
              <p>We value your support and would hate to see you leave. As a token of our appreciation, we'd like to offer you an exclusive <span>{{detailData.discount}}% Off discount</span> on your subscription.</p>
              <p>Don't miss out on this amazing offer! Take a moment to reconsider your decision and join thousands of satisfied users who are already enjoying the fullest potential of our platform.</p>
              <div class="discount" @click="subscribe">Get {{detailData.discount}}% Off</div>
            </template>
						<div class="nothanks" @click="cancel">No thanks</div>
					</div>
      </el-dialog>
      <!-- 用户已重新订阅的告知弹框 -->
			<Subscribe ref="subscribeBox" :subscribeData="subscribeData" />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Subscribe from './subscribe.vue'
import { unsubscribeDiscountApi } from '@/api/index.js'
import stat from '@/utils/stat'
import { VIP_TYPE } from '@/constants/vip.js'

export default {
  data() {
    return {
      dialogVisible: false,
      detailData: {},
      subscribeData: {}
    }
  },
  components: { Subscribe },
  watch: {
    isLogin (n) {
      if (n) this.getDiscountInfo()
    }
  },
  computed: {
    ...mapState({
      vipInfo: state => state.userInfo.vipInfo,
    }),
    ...mapGetters('userInfo', ['isLogin']),
  },
  mounted() {
    if (this.isLogin) this.getDiscountInfo()
  },
  methods: {
    ...mapActions('userInfo', [
      'subscribeVipWithLogin'
    ]),
    cancel() {
      this.dialogVisible = false
    },
    // 查看用户弹窗：取消订阅折扣
    getDiscountInfo() {
      unsubscribeDiscountApi.recoverSubCoupon()
        .then(res => {
          // 后端返回数据就展示弹框，不返回数据不弹弹框
          if (res.data.data && res.data.data.userId) {
            this.detailData = res.data.data
            this.dialogVisible = true
            if (res.data.data.subId) {
              stat.event('popup_unsubscribe_stillvalid_exposure')
            } else {
              stat.event('popup_unsubscribe_expired_exposure')
            }
          }
        })
    },
    // 取消订阅后有效期内恢复订阅，并使用优惠券
    recoverSubscription() {
      unsubscribeDiscountApi.recoverSubscription({
        params: {
          subId: this.detailData.subId,
          couponCode: this.detailData.couponCode
        }
      })
        .then(res => {
          if (res.data.code === 200) {
            this.cancel()
            this.subscribeData = res.data.data
            this.$refs.subscribeBox.open()
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    // 订阅
    subscribe () {
			try {
				if (!this.detailData.priceName) {
					throw 'priceName不能为空-----'
				} else {
					this.subscribeVipWithLogin({
            vipType: VIP_TYPE[this.detailData.priceName.toUpperCase()],
            interval: this.detailData.priceInterval,
            promotionCode: this.detailData.promotionCode,
            from: '/pricing',
            // 价格页面不新窗口打开 只有弹框(from === 'pricepopup')新打开
            currentPageOpen: false,
					})
				}
			} catch (err) {
				this.$message.error(err)
				console.error('err', err)
			}
    }
  }
}
</script>
<style lang="less" >
.unsubscribe-discount-container {
  background: linear-gradient(180deg, #f0e1ff 0%, #ffffff 45%) !important;
  border-radius: 16px !important;
  border: 1px solid #ffffff;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 40px 40px 0;
  }
  .unsubscribe-discount-box {
    .show-discount {
      width: 164px;
      height: 164px;
      position: absolute;
      right: -24px;
      top: -36px;
      background: url(https://cdn-static.dupdub.com/frontend/autoupload/dupdub/image/unsubscribeDiscount_133256900111/unsubscribeDiscount_133256900111/discount@1x.png)
        no-repeat center;
      background-size: cover;
      div {
        font-size: 30px;
        font-family: 'Poppins-Bold';
        font-weight: normal;
        color: #fff;
        line-height: 32px;
        position: relative;
        transform: rotate(-40deg);
        top: 19px;
        padding-left: 22px;
        padding-top: 25px;
        font-style: oblique;
      }
    }
    .title {
      font-size: 28px;
      font-family: Poppins-Medium, Poppins;
      font-weight: 600;
      color: #000f1a;
      line-height: 38px;
      margin-bottom: 20px;

      span {
        font-size: 36px;
        font-family: 'Poppins-Bold';
        font-weight: normal;
        color: #040a16;
        line-height: 55px;
        background: linear-gradient(270deg, #8747ff 0%, #3c46ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 0 15px;
        position: relative;
        top: 3px;
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #666f76;
      line-height: 24px;
      padding-bottom: 12px;
      word-break: keep-all;
      span {
        color: #222222;
        font-family: 'Poppins-Bold';
      }
    }
    .discount {
      width: 300px;
      height: 44px;
      margin: 0 auto;
      line-height: 44px;
      background: #001126;
      border-radius: 8px;
      color: #ffffff;
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
      &:hover{
        background: #263446;
      }
    }
    .nothanks {
      text-align: center;
      color: #8c949d;
      font-size: 14px;
      margin: 20px 0 28px;
      cursor: pointer;
      
      &:hover{
        color: #001126;
      }
    }
  }
}
</style>