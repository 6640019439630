<template>
  <el-dialog
    title="Credits details" @close="closeCredits" @open="openCredits" :visible="$store.state.userInfo.creditsDetalisModal" class="credits-detalis">
    <div class="header">
      <ul class="left">
        <li class="active">Remaining: {{creditInfo.allCredits}}</li>
        <li v-if="creditInfo.trial!=null">Trial: {{creditInfo.trial}}
          <div class="tip-box">
            <iconpark-icon class="iconpark" font-size="18" name="subtitle-tips2"></iconpark-icon>
            <div class="triangle-tip">The credits will be reset to zero at the end of trial period.</div>
          </div>
        </li>
        <li v-if="creditInfo.vipType!=2">Limited-time: {{creditInfo.limited}}
          <div class="tip-box">
            <iconpark-icon class="iconpark" font-size="18" name="subtitle-tips2"></iconpark-icon>
            <div class="triangle-tip">The limited-time credits will be temporarily set to 0, if you decide to cancel your subscription. However, when you choose to subscribe to any plan again, the unused credits will be added back to your account.</div>
          </div>
        </li>
        <li>Lifetime: {{creditInfo.lifetime}}
          <div class="tip-box">
            <iconpark-icon class="iconpark" font-size="18" name="subtitle-tips2"></iconpark-icon>
            <div class="triangle-tip">The lifetime credits are not time-limited and can be used whenever necessary until fully depleted.</div>
          </div>
        </li>
      </ul>
      <ul class="right">
        <li>Tips
          <div class="tip-box">
            <iconpark-icon class="iconpark" font-size="18" name="subtitle-tips2"></iconpark-icon>
            <div class="triangle-tip">Please note that the credits details prior to January 12, 2024 have not been saved.</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="subscription" v-if="creditInfo.vipType===2 && creditInfo.limited>0">Subscription expired, <span class="credits">{{creditInfo.limited}} credits</span> temporarily frozen. Please renew to unlock them.</div>
    <div class="content">
      <ul class="table-title">
        <li class="type">Type</li>
        <li class="credits">
          <span>Credits</span> 
          <div class="slesct-box">
            <div class="slesct">
              <span :class="{'activeSelect':selectValue!='All'}">{{selectValue}} </span>
              <iconpark-icon name="iconarrowgrey" class="iconarrowgrey" :class="{'iconpark-open':selectOpen}"></iconpark-icon>
              <div class="credits-screen">
                <div class="item" :class="{'active':selectValue==item.value}" v-for="item in selectList" :key="item.name" @click="selectBtn(item.value)">{{item.value}}</div>
              </div>
            </div>
          </div>
        </li>
        <li class="video-duration">Duration</li>
        <li class="video-duration">Date</li>
      </ul>
      <template v-if="creditesList.length>0">
        <ul class="table-body">
          <li v-for="item in creditesList" :key="item.id">
            <div class="type">{{item.type}}</div>
            <div class="credits">
              <span>{{creditsConsume(item.credits)}}</span>
            </div>
            <div class="video-duration">{{item.fileDuration?converTime(item.fileDuration):'-'}}</div>
            <div class="video-duration">{{timeTrans(item.createAt)}}</div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="table-body no-record">
          <span>No record</span>
        </div>
      </template>
      
    </div>
    <el-pagination v-if="totalPages>8" :page-size="paging.size" layout="prev, pager, next" :total="totalPages" @current-change="pageChange" ></el-pagination>
  </el-dialog>
</template>
<script>
import http from '@/utils/http-request.js'
import { timeFormat } from "@/assets/js/format.js";
import {mapActions } from 'vuex'
import to from 'await-to-js';

export default {
  data() {
    return {
      creditesList:[],//明细列表
      creditInfo:{},//title明细
      selectOpen:false,
      selectList:[
        {
          name: 'all',
          value: 'All'
        },
        {
          name: 'added',
          value: 'Added'
        },
        {
          name: 'deducted',
          value: 'Deducted'}
      ],
      selectValue: 'All',//当前高亮
      paging:{
        pages:0,
        size:8,
      },
      totalPages:0,//列表总数
    }
  },
  methods: {
    ...mapActions('userInfo', ['getUserRights']),
    converTime(item){
      if(!/^\d+$/.test(item)){
        return item
      }else{
        let hours = Math.floor(item / (60 * 60)); // 计算小时部分
        item %= (60 * 60); // 取余得到不足1小时的秒数
        let minutes = Math.floor(item / 60); // 计算分钟部分
        item %= 60; // 取余得到不足1分钟的秒数
        // console.log(item,hours,minutes,item)
        return `${minutes}'${item}''`;
      }
    },
    //处理+号展示
    creditsConsume(item){
      if(String(item).substring(0,1)==='-'){
        return item
      }else{
        return "+"+item
      }
    },
    timeTrans(time) {
      return timeFormat(time);
    },
    creditBalanceInit() {
      http.creditBalance().then(res => {
        if(res.data.code == 200){
          this.creditInfo=res.data.data
        }
      })
    },
    //credites明细列表
    creditesDetailsList(){
      let params={
        page:this.paging.pages,
        size:this.paging.size,
        status:this.selectValue
      }
      http.getCreditDetailsList(params).then(res => {
        if(res.data.code == 200){
          this.creditesList=res.data.data.content
          this.totalPages=res.data.data.totalElements
        }
      })
    },
    selectBtn(val){
      this.selectValue=val
      this.paging.pages=0
      this.creditesDetailsList()
    },
    pageChange(ev){
      this.paging.pages=ev-1
      this.creditesDetailsList()
    },
    //打开之后重新调用接口
    openCredits(){
      this.creditesDetailsList()
      this.creditBalanceInit()
    },
    //关闭弹框
    async closeCredits(){
      //更新导航栏数据
      if(await this.awaitGetUserRight() == 'getUserErr') return
      this.$store.commit('userInfo/seCreditsDetalisSatate', false)
    },
    async awaitGetUserRight(){
      const [userErr, userMes] = await to(this.getUserRights()) 
      if (!userMes) return 'getUserErr'
      if (userErr) {
          this.$message.error('getUserErr')
          return 'getUserErr'
      }
    },
  }
}
</script>
<style lang="less" scoped>
.credits-detalis {
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(.el-dialog) {
    padding: 19px 32px ;
    width: 984px;
    min-height: 656px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: 0 !important;
    box-sizing: border-box;
    margin: 0 !important;
    .el-dialog__header {
      padding: 0;
      .el-dialog__title {
        font-size: 18px;
        font-weight: 600;
        color: #040d1a;
        line-height: 26px;
      }
      .el-icon-close:before{
        font-size: 24px;
        color: #040D1A;
      }
      .el-dialog__headerbtn{
        width: 32px;
        height: 32px;
        right: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        &:hover{
          background-color: #F7F7F8;
        }
      }
    }
    .el-dialog__body{
      padding: 0;
      margin-top: 21px;
      
      .header{
        display: flex;
        justify-content: space-between;
        .left,.right{
          display: flex;
          li{
            margin-right: 11px;
            padding: 8px 20px;
            padding-right: 12px;
            background-color: #F9FAFF;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(4,13,26,0.65);
            display: flex;
            align-items: center;
            &.active{
              color: #040D1A;
            }
            .iconpark{
              margin-left: 12px;
              cursor: pointer;
            }
          }
        }
        .right{
          li{
            margin-right: 0;
          }
        }
      }
      .tip-box{
        position: relative;
        display: flex;
        .iconpark{
          color: #B2B9BF;
        }
        .triangle-tip{
          display: none;
          position: absolute;
          top: 24px;
          right: -19px;
          width: 170px;
          z-index: 100;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05);
          border: 0.5px solid rgba(4,13,26,0.1);
          font-size: 12px;
          color: #040D1A;
          line-height: 18px;
          padding: 10px 12px;
          border-radius: 7px;
          background-color: #FFFFFF;
          word-break: break-word;
          &:before, &:after {
            content: "";
            display: block;
            border-width: 7px;
            position: absolute;
            top: -14px;
            right: 18px;
            border-style: solid dashed dashed solid;
            border-color: transparent transparent rgba(4,13,26,0.1) transparent;
            font-size: 0;
            line-height: 0;
          }
            &:after {
            top: -13px;
            border-color: transparent transparent #FFF transparent;
          }
        }
        &:hover{
          .triangle-tip{
            display: block;
          }
        }
      }
      .subscription{
        font-size: 13px;
        font-weight: 400;
        color: #8B8F95;
        line-height: 20px;
        width: 100%;
        height: 30px;
        border-radius: 8px;
        background: linear-gradient(90deg, #F9F9F9 0%, #FFFFFF 100%);
        padding-left: 20px;
        line-height: 30px;
        box-sizing: border-box;
        margin-top: 8px;
        .credits{
          color: #5A5F68;
        }
      }
      .content{
        .table-title{
          background: #F9FAFF;
          display: flex;
          margin-top: 24px;
          border-radius: 8px 8px 0px 0px;
          border: 0.5px solid rgba(4, 13, 26, 0.07);
          height: 49px;
          li{
            line-height: 50px;
            padding-left: 24px;
            box-sizing: border-box;
            font-weight: 500;
            border-left: 0.5px solid rgba(4, 13, 26, 0.07);
            &:first-child{
              border-left: none;
            }
            .slesct{
              height: 24px;
              padding: 0 6px 0px 10px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 0.5px solid rgba(4,13,26,0.07);
              font-size: 12px;
              color: #040D1A;
              cursor: pointer;
              display: flex;
              align-items: center;
              position: relative;
              &:hover{
                background-color: #F9FAFC;
              }
              .activeSelect{
                color: #3583FB;
              }
              .iconarrowgrey{
                font-size: 14px;
                color: #040D1A;
                margin-left: 3px;
                transform: rotate(90deg);
              }
              .credits-screen{
                display: none;
                padding: 4px;
                top: 29px;
                right: -1px;
                box-sizing: border-box;
                position: absolute;
                width: 108px;
                height: 112px;
                background: #FFFFFF;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05);
                border-radius: 8px;
                border: 1px solid rgba(4,13,26,0.1);
                .item{
                  width: 100px;
                  height: 32px;
                  border-radius: 6px;
                  line-height: 32px;
                  padding-left: 8px;
                  width: 100%;
                  box-sizing: border-box;
                  margin-bottom: 4px;
                  &:hover{
                    background-color: rgba(245, 247, 250, 1);
                  }
                  &.active{
                    color: #3583FB;
                    background: rgba(53,131,251,0.1);
                    position: relative;
                    &::before{
                      position: absolute;
                      right: 8px;
                      top: 50%;
                      transform: translateY(-50%);
                      content: '';
                      width: 16px;
                      height: 16px;
                      background-image: url('@/assets/img/icon/select-icon.svg');
                      background-size: 100% 100%;
                    }
                  }
                }
              }
              
            }
            .slesct-box{
              height: 50px;
              display: flex;
              align-items: center;
              &:hover{
                .iconarrowgrey{
                  transform: rotate(270deg);
                }
                .credits-screen{
                  display: block;
                }
              }
            }
          }
        }
        .table-title,.table-body{
          box-shadow: inset 0px 0px 0px 0px rgba(4,13,26,0.07);
          li{
            font-size: 14px;
            color: #040D1A;
          }
          .type{
            width: 320px;
          }
          .credits{
            width: 200px;
            padding-right: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .video-duration{
            width: 200px;
          }
        }
        .table-body{
          margin-top: -1px;
          height: 403px;
          border-radius: 0px 0px 8px 8px;
          border: 0.5px solid rgba(4, 13, 26, 0.07);
          border-top: none;
          overflow: hidden;
          li{
            display: flex;
            border-bottom: 0.5px solid rgba(4, 13, 26, 0.07);
            border-top: none;
            &>div{
              line-height: 50px;
              padding-left: 24px;
              box-sizing: border-box;
              border-left: 0.5px solid rgba(4, 13, 26, 0.07);
              &:first-child{
                border-left: none;
              }
            }
          }
        }
        .no-record{
          display: flex;
          align-items: center;
          justify-content: center;
          .no-record{
            font-size: 14px;
            font-weight: 400;
            color: rgba(4,13,26,0.65);
          }
        }
      }
      .el-pagination{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        button, span:not([class*=suffix]){
          width: 32px;
          height: 32px;
          padding: 0;
          line-height: initial;
          min-width: initial;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        button{
          .el-icon{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .btn-next .el-icon, .btn-prev .el-icon{
          font-size: 14px;
          font-weight: 800;
          width: 32px;
          height: 32px;
          padding: 0;
          min-width: initial;

        }
        .btn-next{
          padding: 0;
          margin-left: 16px;
        }
        button:hover{
          border-radius: 50%;
          background-color: rgba(247, 247, 248, 1);
          color: #040D1A;
          font-weight: bold;
          font-size: 15px;
        }
        .el-pager{
          display: flex;
          .number{
            font-size: 14px;
            font-weight: 400;
            color: #040D1A;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            min-width: initial;
            width: 32px;
            height: 32px;
            margin-left: 16px;
            border-radius: 50%;
            &:hover{
              background-color: rgba(4, 13, 26, 0.03);
            }
            &.active{
              background: #040D1A;
              color: #FFFFFF;
            }
          }
          .more{
            color: #040D1A;
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }
  }
}

</style>