import Vue from 'vue'

import { axios } from '@/utils/axios'
import { ua } from '@/utils/env'
import DonMessage from '@/utils/el-message'
import { get } from "lodash"
import { stringifyObj } from '@/utils/url.js';
export const $bus = new Vue()
export const registerVuePrototype = () => {

  Vue.prototype.$http = axios

  Vue.prototype.ua = ua
  
  Vue.prototype.$message = DonMessage

  Vue.prototype.$JavaUploadUrl = process.env.VUE_APP_JAVA_UPLOAD_URL
  Vue.prototype.$FileUploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL // 文案提取上传文件
  Vue.prototype.$DownloadServer = process.env.VUE_APP_CPP_AUDIO_EXTRACT_HOST //视频下载
  Vue.prototype.$CloneUrl = process.env.VUE_APP_CLONE_URL
  Vue.prototype.$TTSASR = process.env.VUE_APP_TTS_UPLOAD_URL
  Vue.prototype.$VUE_APP_DUPDUB_URL = process.env.VUE_APP_DUPDUB_URL
  Vue.prototype.$VUE_APP_DUBBING_URL = process.env.VUE_APP_DUBBING_URL
  Vue.prototype.$VUE_APP_AI_WRITING_URL = process.env.VUE_APP_AI_WRITING_URL

  Vue.prototype.$bus = $bus;
  Vue.prototype.$ssrUrlSearch=()=>{
    let  token = localStorage.getItem('ww_token') || ''
    let  cookie = localStorage.getItem('cookiesPolicy') || ''
    let  wwid = JSON.parse(localStorage.getItem('userInfo')|| '{}').wwid || ''
    let params={
      ww_token:token,
      cookiesPolicy:cookie,
      wwid:wwid
    }
    return stringifyObj(params)
  }
  Vue.prototype._get = (obj, path, defaultValue) => {
    if (typeof obj === 'string') {
      return get(this, obj, path)
    }
    return get(obj, path, defaultValue)
  }
}
