//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(Vue) {
    Vue.prototype.$posthog = posthog.init(
      "phc_4BKX2rqOehLzRpGJnM8uL2h3igQmgpAqo4dVzTucVng",
      {
        api_host: "https://us.i.posthog.com",
        person_profiles: 'identified_only',
      }
    );
  }
};