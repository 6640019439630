import { axios as Axios } from '@/utils/axios'
const urlParamFormat = data => {
  let res = ''
  let keyArr = Object.keys(data)
  if (keyArr.length === 0) return ''
  keyArr.forEach((val, index) => {
    res = `${res}${index == 0 ? '?' : '&'}${val}=${data[val]}`
  })
  return res
}
export default {
  getSpeakerInfo: () =>
    Axios({
      url: '/gateway-apis/tts/v1/speakerClone/getSearchCriteria',
      method: 'GET'
    }),
  nameExist: (data) =>
    Axios({
      url: `/gateway-apis/tts/v1/speakerClone/nameExist${urlParamFormat(data)}`,
      method: 'GET'
    }),
  speakerClone: (data) =>
    Axios({
      url: '/gateway-apis/tts/v1/speakerClone',
      method: 'POST',
      data
    }),
  transformToWav: (url) => {
    return Axios({
      url: '/gateway-apis/moyin-tool/v1/videoToAudio/transformToWav',
      method: 'POST',
      data: {
        url
      }
    })
  },
  deleteVoice: (id) => {
    return Axios({
      url: '/gateway-apis/tts/v1/speakerClone/' + id,
      method: 'DELETE',
    })
  },
  extractAudioToWav:(url) =>{
    return Axios({
      url:'/gateway-apis/moyin-tool/v1/videoToAudio/extractAudioToWav',
      method:"POST",
      data:{
        url
      }
    })
  }
}