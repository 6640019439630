import Vue from 'vue'
// utils
import { formatDate } from '@/assets/js/format'


export const registerVueFilter = () => {

  Vue.filter('formatDate', (value) => {
    if (!value) return ''
    value = formatDate(value, 'YYYY.MM.DD HH:mm:ss')
    return value
  })

  Vue.filter('textFormat', (value) => {
    if (!value) return ''
    if (value.indexOf('|') !== -1) {
      let valueList = value.split('|')
      value = valueList[0] + ' | ' + valueList[1]
    }
    return value
  })
}
