import { getCookie } from '@/utils/cookie'
import { videoEditorUrl } from '@/utils/env'

/**
 * 将对象类型的参数转成拼到 url 上的参数
 */
export function changeOptionsToParams(options, isFirst = true) {
  const result = []
  for (const key in options) {
    result.push(`${ key }=${options[key]}`)
  }
  return !isEmptyObj(options) ? `${ isFirst ? '?' : '&' }${ result.join('&') }` : ''
}

/** 跳转到编辑器页面 */
export function toVideoAudioEditor(projectId) {
  // 需要拼到 url 上传递的参数
  const params = {}
  try {
    params['wwid'] = JSON.parse(localStorage.getItem('userInfo')).wwid
    
  } catch (error) {
    console.log(error)
  }
  
  params['ww_token'] = getCookie('ww_token')
  params['cookiesPolicy'] = getCookie('cookiesPolicy')
  if (projectId) {
    params['projectId'] = projectId
  }
  // 音视频剪辑项目 url
  const url = new URL(`${
    videoEditorUrl
  }${
    'App'
  }${
    changeOptionsToParams(params)
  }`)
  window.open(url.href, '_self')
}

/**
 * 打开新窗口
 * @param {*} url 
 */
export function windOpen(url) {
  const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  if (isSafari) {
    location.href = url
  } else {
    window.open(url)
  }
}
/**
 * 判断对象是不是空的
 * @param {*} obj 
 * @returns 
 */
export function isEmptyObj(obj = {}) {
  return Object.keys(obj).length === 0
}

/**
 * 添加客服弹窗
 */
export function setCustomerService() {
  
  (
    function(d, src, c) {
      var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');
      s.id='la_x2s6df8d';
      s.defer=true;
      s.src=src;
      s.onload = s.onreadystatechange = function() {
        var rs=this.readyState;
        if(rs&&(rs!='complete')&&(rs!='loaded')){return;}
        c(this);
      };
      t.parentElement.insertBefore(s,t.nextSibling);
    }
  )(
      document,
      'https://dupdub.ladesk.com/scripts/track.js',
      function(e){
        if (window.location.pathname.indexOf('/tools/home') > -1 ) {
          // eslint-disable-next-line no-undef
          LiveAgent.createButton('a59k97wx', e);
        }
      }
  );
}

/**
 * 时间刻度上显示的格式化
 * @param duration 时长 单位: ms
 * @returns "hh:mm:ss"
 */
export const formatTime = (duration, format = '', totalTime) => {
  // 播放时间和总时长有误差 做一个修正
  if(totalTime && duration && Math.abs(totalTime - duration) < 100) {
    duration = totalTime
  }
  // 计算秒数
  let second = Math.floor(duration / 1000)
  let hour = Math.floor(second / 3600)
  let min = Math.floor((second - hour * 3600) / 60)
  let sec = second % 60
  if (format) {
    format = format.replace('hh', hour < 10 ? `0${hour}` : `${hour}`)
    format = format.replace('mm', min < 10 ? `0${min}` : `${min}`)
    format = format.replace('ss', sec < 10 ? `0${sec}` : `${sec}`)
    return format
  }
  return `${hour > 0 ? hour + 'h' : ''}${hour > 0 ? min + 'm' : min > 0 ? min + 'm' : ''
    }${sec}s`
}
// copy文本
export function copyText(text,cb){
  var textarea = document.createElement('textarea')
  document.body.appendChild(textarea)
  // 隐藏此输入框
  textarea.style.position = 'fixed'
  textarea.style.clip = 'rect(0 0 0 0)'
  textarea.style.top = '-9999px'
  // 赋值
  textarea.value = text
  // 选中
  textarea.select()
  // 复制
  document.execCommand('copy', true)
  // 移除输入框
  document.body.removeChild(textarea)
  cb()
}

// 跳转到编辑器
export function dubbing(text, href) {
  // 内容为空
  if (text == '') return
  localStorage.setItem('_article_voice_txt',text)
  localStorage.setItem(
    '_article_voice_txt_info',
    JSON.stringify({
      source: 'translate'
    })
  )
  setTimeout(() => {
    //当前窗口打开
    window.open(href, '_self');
  }, 1)
}

export function secondToDate(time) {
  var m = Math.floor(time / 60)
  var s = Math.floor(time % 60)
  return (m > 0 ? m + ':' : '0:') + (s < 10 ? '0' + s : s)
}

// 去除对象中值为null的key
export function delEmptyQueryNodes (obj){
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  }
  console.log(obj)
  return obj;
}

export function getClientId() {
  const client_id = document.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join("_") ||'no_client_id';
  return client_id;
}
