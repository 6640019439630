<template >
  <div v-if="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :lock-scroll="false"
      custom-class="ai-sound-effect-container"
      width="768px"
      @close="close"
    >
      <div class="ai-sound-effect-close" @click="close" >
        <iconpark-icon
          name="iconclose"
          :size="18"
        ></iconpark-icon>
      </div>
      <CreateAiSound
        v-if="type === 1"
        @changeType="changeType"
        @cancel="close"
        @result="showResult"
      />
      <AiList
        v-if="type === 2"
        @changeType="changeType"
      />
      <Result
        v-if="type === 3"
        :resultData="resultData"
        @changeType="changeType"
        @cancel="close"
      />
    </el-dialog>
  </div>
</template>
<script>
import CreateAiSound from './pages/create-ai-sound.vue'
import AiList from './pages/ai-list.vue'
import Result from './pages/ai-result.vue'
import stat from '@/utils/stat'

export default {
  data() {
    return {
      dialogVisible: false,
      type: 1, // 1：创建 2：列表 3: 结果展示
      resultData: {},
      config: {
        source: '' // home/ttseditor/videoeditor
      },
      pagePlaying: true
    }
  },
  provide() {
    return {
      $aiSoundEffectPage: this
    }
  },
  components: { CreateAiSound, AiList, Result },
  methods: {
    open(config) {
      this.dialogVisible = true
      this.type = 1
      this.config = config
      this.$emit('aiSoundEffectStatus', this.dialogVisible)
      stat.event('aisoundeffect-popup-exposure', {
        source: config.source || ''
      })
    },
    close() {
      this.dialogVisible = false
      this.$emit('close')
      this.$emit('aiSoundEffectStatus', this.dialogVisible)
    },
    // 切换类型
    changeType(type) {
      this.type = type
    },
    showResult(data) {
      this.changeType(3)
      this.resultData = data
    },
    getAudio(data) {
      this.close()
      this.$emit('getAudio', data)
    },
    changePlaying (type) {
      this.pagePlaying = type
    }
  }
}
</script>

<style lang="less">
@import './css/common.less';
.ai-sound-effect-container {
  border-radius: 16px !important;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff !important;
  height: 562px;
  min-height: auto !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .ai-sound-effect-close {
    width: 32px;
    height: 32px;
    background: rgba(16, 16, 16, 0.6);
    border-radius: 16px;
    backdrop-filter: blur(6px);
    position: absolute;
    right: 8px;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      background: #101010;
    }
    iconpark-icon{
      color: #fff;
    }
  }
}
</style>