/*
 * @Author: pxbai
 * @Date:   2019-07-29 15:31:55
 * @Last Modified by: yaolin
 * @Last Modified time: 2022-09-02 11:05:38
 */
import axiosRequest from './axios'
import axios from 'axios'
import { getLocalPromoteInfo } from '@/utils/product-promotion.js'
import { getCookie } from '@/utils/cookie.js'
import { ua } from '@/utils/env.js'
import * as jose from 'jose'
import { parseUrl } from "@/utils/url"
// 获取avatar模版信息
const getGestureAvatarTmepId = (params) =>
    axiosRequest.get(`/gateway-apis/tts/v1/avatar/case/${params.tempId}`)

// 删除avatar克隆模版
const caseUsable = (data) =>
    axiosRequest.put(`/gateway-apis/tts/v1/avatar/case/changeUseStatus`,data)
// 删除avatar克隆模版
const deleteAvatarCase = (id) =>
    axiosRequest.del(`/gateway-apis/tts/v1/avatar/case/${id}`)
//重新克隆模版
const retryAvatarCase = (id) =>
    axiosRequest.post(`/gateway-apis/tts/v1/avatar/case/retry/${id}`)
//重新生成模版视频
const retryAvatarDemo = (id) =>
    axiosRequest.post(`/gateway-apis/tts/v1/avatar/case/demo/retry/${id}`)
// 获取apikey
const getAccountApiKey = (params) =>
    axiosRequest.get(`/gateway-apis/account/v1/user/apiKey?${__fParams(params)}`)
// a/b数据存储后端
const featureAdd = (data) =>
    axiosRequest.post(`/gateway-apis/account/v1/user/feature/add`, data)
// 获取a/b数据是否存储
const featureGetClientId = (data) =>
    axiosRequest.post(`/gateway-apis/account/v1/user/feature/getClientId`, data)
// 解析音频链接
const getAchieveInfoByLink = (data) => 
axiosRequest.post(`/gateway-apis/moyin-tool/v1/videoToAudio/achieveInfoByLink`,data)
//查询发音人参数是否收藏
const getIsCollect = (params) => 
  axiosRequest.get(`/gateway-apis/tts/v1/favourite/isFavouriteSpeakerParams?${__fParams(params)}`)

//收藏取消发音人参数
const collectSpeakerParams = (data) => 
  axiosRequest.post(`/gateway-apis/tts/v1/favourite/setFavouriteSpeakerParams`,data)

//credit 明细列表
const getCreditDetailsList= (params)=> 
  axiosRequest.get(`/gateway-apis/account/v1/user/creditDetails?${__fParams(params)}`)
//credit 余额明细
const creditBalance= ()=> 
  axiosRequest.get(`/gateway-apis/account/v1/user/creditBalance`)
//talking 模版
const photoProjectCaseTag= ()=> 
  axiosRequest.get(`/gateway-apis/tts/v1/photoProject/caseTag?source=web`)
//talking 模版
const photoProjectTemplate= (params)=> 
  axiosRequest.get(`/gateway-apis/tts/v1/photoProject/cases?${__fParams(params)}`)

const avatarCheckList= ()=> 
  axiosRequest.get(`/gateway-apis/tts/v1/avatar/case/checkList`)
//talking 模版
const photoProjectGesture= (params)=> 
  axiosRequest.get(`/gateway-apis/tts/v1/avatar/cases?${__fParams(params)}`)
//生成邀请码
const getInviteCode= ()=> 
  axiosRequest.get(`/gateway-apis/account/invite/manage/getInviteCode`)
//查询当前邀请人数
const getInviteUserNums= ()=> 
  axiosRequest.get(`/gateway-apis/account/invite/manage/queryInviteUserNums`)
  
// 折扣ab test接口
const abCounponShow = () => 
  axiosRequest.get(`/gateway-apis/account/v1/user/newbies/ABCounponShow`)

// 更新折扣ab test接口
const UpdateABCounpon = (data) => 
  axiosRequest.get(`/gateway-apis/account/v1/user/newbies/upABTestShow`, data) 

//是否展示问卷NPS
const getHasTriggerNps= (params)=> 
  axiosRequest.get(`/gateway-apis/account/v1/question/hasTriggerNps?${__fParams(params)}`)

// 左下角用户访谈显/隐状态
const getUserInterviewApi = () => {
  return axiosRequest.get(`/gateway-apis/account/v1/background/studioBanner`)
}

// changelog 文案
const changelog = () => {
  return axiosRequest.get('/gateway-apis/account/v1/background/changelog')
}

// 获取活动标签显/隐状态
const getActivityVisibleStateApi = () => {
  return axiosRequest.get(`/gateway-apis/account/v1/background/appBanner`)
}

// 老会员检测
const compensationCredit = () => 
  axiosRequest.get(`/gateway-apis/account/v1/user/newbies/compensationCredit`)

// talking photo
const getPhotoList = (params) => 
  axiosRequest.get(`/gateway-apis/tts/v1/photoProject/listByPage?${__fParams(params)}`)

const getPhotoDetail = (id) => 
  axiosRequest.get(`/gateway-apis/tts/v1/photoProject/${id}`)

const deletePhotoList = (id) =>
  axiosRequest.delNew(`/gateway-apis/tts/v1/photoProject/${id}`)

const deleteAvatar = (id) =>
  axiosRequest.delNew(`/gateway-apis/tts/v1/avatar/${id}`)
const avatarCase = (id) =>
  axiosRequest.get(`/gateway-apis/tts/v1/avatar/${id}`)

const updatePhoto = (id, data) => 
  axiosRequest.put(`/gateway-apis/tts/v1/photoProject/${id}`, data)
const avatarUpdatePhoto = (id, data) => 
  axiosRequest.put(`/gateway-apis/tts/v1/avatar/${id}`, data)
// 查询talking photo的状态
const getProjectsStatus = (data) => 
  axiosRequest.get(`/gateway-apis/tts/v1/photoProject/getProjects`, data)

//blog接口
const getBlog = (params) => 
  axiosRequest.get(`/gateway-apis/account/v1/blog/list?${__fParams(params)}`)

const getBlogDetail = (title) => 
  axiosRequest.get(`/gateway-apis/account/v1/blog/${title}`)

// 用户账户信息获取
const productGetSkuList = () =>
  axiosRequest.get(`/dolly-apis/api/p1/product/getSkuList`)

//clone 支付
async function orderSubmit(ajaxData) {
  return (await axiosRequest.post(`/dolly-apis/api/order/submit`, ajaxData)).data
}

// todo 
// 文案提取-列表
const transcriptionList = (data) =>
  axiosRequest.get('/gateway-apis/moyin-tool/v1/transcription/list?' + __fParams(data))
//文案提取-详情
const transcriptionDetail = (id)=>
  axiosRequest.get(`/gateway-apis/moyin-tool/v1/transcription/${id}`)
//文案提取-批量查询详情状态
const transcriptionDetailStatus = (data)=>
axiosRequest.get(`/gateway-apis/moyin-tool/v1/transcription/listByIds?ids=${data.ids}`)
//文案提取-详情
const transcriptionFormat = (data) =>  
  axiosRequest.put('/gateway-apis/moyin-tool/v1/transcription/updateFormat', data)

// 文案提取-删除记录
const transcriptionDetailDelete = (id)=>
  axiosRequest.delNew(`/gateway-apis/moyin-tool/v1/transcription/${id}`)

// 文案提取-专辑专辑列表
const transcriptionAlbumList = () => axiosRequest.get('/gateway-apis/moyin-tool/v1/transcription/album/list')

// 文案提取-删除某个专辑
const transcriptionAlbumDelete = (id)=>
  axiosRequest.delNew(`/gateway-apis/moyin-tool/v1/transcription/album/${id}`)

//文案提取-创建专辑
const transcriptionAlbumCreate = data =>
  axiosRequest.post('/gateway-apis/moyin-tool/v1/transcription/album/create', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'ww_token':getCookie('ww_token')
    }
  })

// 文案提取-专辑重命名
const transcriptionAlbumRename = (albumId, data) =>  
  axiosRequest.put(
    '/gateway-apis/moyin-tool/v1/transcription/album/' + albumId, data
  )

// 文案提取-移动端其他专辑里
const transcriptionUpdateAlbum = (data) =>
  axiosRequest.put(
    '/gateway-apis/moyin-tool/v1/transcription/updateAlbum', data
  )

// 文案提取-文案提取重命名
const transcriptionUpdateTitle = (data) =>  
  axiosRequest.put(
    '/gateway-apis/moyin-tool/v1/transcription/updateTitle', data
  )

//保存历史记录
const transcription = data => 
  axiosRequest.post(`/gateway-apis/moyin-tool/v1/transcription`, data)
  
// 文案提取asr接口
const asrBatch = data =>  
  axiosRequest.post(`/gateway-apis/moyin-tool/v1/asr/batch`, data)

// 文案提取结果
const asrBatchQuery = data =>  
  axiosRequest.post(`/gateway-apis/moyin-tool/v1/asr/batchQuery`, data)

// 文案提取新接口
const transcriptionsBatch = data =>
  axiosRequest.post(`/gateway-apis/moyin-tool/v1/asr/transcriptionsBatch`, data)

// transcription 保存接口
const transcriptionSave = data =>
  axiosRequest.post(`/gateway-apis/moyin-tool/v1/transcription/link`, data)

// 获取asr语言
const asrLanguagesQuery = () =>
  axiosRequest.get(`/gateway-apis/moyin-tool/v1/asr/languages?type=new`)

const ttsSsml = '/tts/ssml'

// 视频/封面下载
const streamingDownloadFile = '/streaming_download_file'

// 上传 视频/音频 字幕内容
const alignSrt = '/moyin-tool/v1/subtitles/alignSrt'

// 根据（视频/音频，字幕内容）解析出srt文件
const getFileIsExist = '/tts/getFileIsExist'

// 自动打轴（srt文件下载）
const downloadMedia = '/tts/article/downloadMedia'

// 声音商店 根据视频地址 搜索发音人
const voiceSearchVideoUrl = '/tts/v1/voiceSearch/videoUrl'

// 声音商店 根据音频/视频文件 搜索发音人
const voiceSearchAudio = '/tts/v1/voiceSearch/audio'

// 第三方登录接口 授权之后调用获取token
const thirdpartyAuth = '/account/thirdparty/auth'

const auditionV2 = '/tts/v2/audition'

const audition = '/tts/audition'

const postQueryConfig = data => 
  axiosRequest.post("/gateway-apis/account/v1/question", data)

const queryConfig = params =>  
  axiosRequest.get(`/gateway-apis/account/v1/question/queryConfig?${__fParams(params)}`)

const hasTriggerQuestion = params =>  
  axiosRequest.get(`/gateway-apis/account/v1/question/hasTriggerQuestion?${__fParams(params)}`, {hideLoginModal: true})

const hasCompleteQuestion = params =>
  axiosRequest.get(`/gateway-apis/account/v1/question/hasCompleteQuestion?${__fParams(params)}`, {hideLoginModal: true})
// 首页单独获取语言
const getAllAccents = () => 
  axiosRequest.get(`/gateway-apis/tts/v2/language/getLanguageAndAccent`)

// 活动给发音人点赞
const likeActivitySpeaker = (params) =>  
  axiosRequest.put(`/gateway-apis/account/v1/activity/likeActivitySpeaker?${__fParams(params)}`)

// 查询活动发音人
const querySpeakerLike = () =>  
  axiosRequest.get(`/gateway-apis/account/v1/activity/querySpeakerLike`)

//词典
const searchPageOptions = params =>  
  axiosRequest.get(`/gateway-apis/tts/v1/lexicon/searchPage?${__fParams(params)}`)

const postLexicon = data =>
  axiosRequest.post(`/gateway-apis/tts/v1/lexicon`, data)

const delLexicon = id =>
  axiosRequest.delNew(`/gateway-apis/tts/v1/lexicon/${id}`)

const editLexicon = data => 
  axiosRequest.put(`/gateway-apis/tts/v1/lexicon/${data.id}`, data)

const getAllLexicons = data => 
  axiosRequest.get(`/gateway-apis/tts/v1/lexicon/page?${__fParams(data)}`)

const getAudioKey = data =>
  axiosRequest.get(`/gateway-apis/tts/v1/playDemo/playLexicon?type=${data.type}&alternative=${data.alternative}&original=${data.original}`)

const articlesDownload = (articleId,type) => 
  axiosRequest.post(`/gateway-apis/tts/v1/articles/download?articleId=${articleId}&type=${type}`)

//暂时不可直接调用
const downloadMp3 = (audioUrl) => 
  axiosRequest.post(`/overseas-apis/filetool/downloadMp3`, audioUrl)

const companyArticle = (companyId,articleId) => 
  axiosRequest.delNew(`/gateway-apis/tts/v1/company/article?companyId=${companyId}&articleId=${articleId}`)

const companyArticlePost = (id,articleId) => 
  axiosRequest.post(`/gateway-apis/tts/v1/company/article?companyId=${id}&articleId=${articleId}`)

const articlesDelete = (articleId) =>
  axiosRequest.delNew(`/gateway-apis/tts/v1/articles/${articleId}`)

const ablumDelete = (companyId) =>
  axiosRequest.delNew(`/gateway-apis/tts/v1/company/articleV2?companyId=${companyId}`)

  // 字幕切分
const srtSplit = (data) => 
  axiosRequest.post('/tts/srt_split', data)

// 查询是否有过购买记录 
const isNewFreeCus = () => 
  axiosRequest.get('/gateway-apis/account/v1/stripePay/isNewFreeCus', {hideLoginModal: true})

// 获取header折扣信息
const getDiscountInfo = (data) => 
  axiosRequest.get('/gateway-apis/account/v1/background/banner', data)

// 用户中心修改昵称
const editNickName = (name) => 
  axiosRequest.post(`/gateway-apis/account/v1/user/editUserName?userName=` + name)

// 获取文案提取 自动打轴 视频下载 可用时长 获取可用编辑器剩余字数
const getUserRights = () => 
  axiosRequest.get(`/gateway-apis/account/v1/user/getUserRights`, {mergeRequest:true})

//效率工具
const videoTranscribe = (data) => 
  axiosRequest.post('/gateway-apis/moyin-tool/v1/videoToAudio/achieveVideoInfoV2', data)

// 播放器部分
const historySpeaker = formData =>
  axiosRequest.post(`/gateway-apis/account/v1/history/speaker`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'ww_token':getCookie('ww_token')
    }
  })

const useHistory2 = () =>
  axiosRequest.get('/gateway-apis/tts/v1/speaker/useHistory2')

const getStoreSpeaker2 = () =>
  axiosRequest.get('/gateway-apis/tts/v1/storeSpeaker/getStoreSpeaker2')

// 文案提取
const getExtractData = (postData) => 
axiosRequest.post('/gateway-apis/moyin-tool/v1/videoToAudio/achieveVideoInfo', postData, {
  timeout: 1000 * 60 * 5
})
// 注册，重置密码获取验证码
const getEmailCaptcha = (email, usage) =>
  axiosRequest.get(`/account/captcha/email?email=${email}&usage=${usage}&language=dupdub`)

// 登录
const overseasLogin = (body) =>
  axiosRequest.post('/account/login', body)

// 注册 重置密码
const registerOrResetPassword = (url, body) =>
  axiosRequest.post(`/account/customer/${url}`, body)

async function overseasLoginOut() {
  return (await axiosRequest.post('/gateway-apis/account/v1/logout', null, {hideLoginModal: true})).data
}

//获取用户信息
const getUserInfo = (params) => {
  /*
    canva token解析后的aud数据
    talkingphoto: "AAF0-VoDYDU" 
    tts: "AAF1-ydC4fI"
  */
  let feature='none'
  //处理地址栏参数
  const appUrl = location.href
  const appProps = parseUrl(appUrl).query // 页面初始参数
  if(appProps.canva_user_token){
    const res = jose.decodeJwt(appProps.canva_user_token)
    if(res.aud==='AAF0-VoDYDU'){
      feature='talkingphoto'
    }else if(res.aud==='AAF1-ydC4fI'){
      feature= 'tts'
    }
  }
  // 邀请码 && 邀请链接
  const { invite_code, invite_code_link,canvaUserId,canvaBrandId } = params
  // ua.system() 获取操作系统
  let source=`dupdub_${ua.system()}_${appProps.canva_user_token?'canva':'official'}_${feature}`
  let baseUrl = `/gateway-apis/account/v1/user?source=${source}`
  let promoteInfo = getLocalPromoteInfo()
  return axiosRequest.get(`${baseUrl}${
    promoteInfo ? '&' + promoteInfo : ''
  }${
    invite_code ? `&invite_code=${ invite_code }` : ''
  }${
    invite_code_link ? `&invite_code_link=${ invite_code_link }` : ''
  }${
    canvaUserId ? `&canvaUserId=${ canvaUserId }&canvaBrandId=${canvaBrandId}` : ''
  }`, { hideLoginModal: true })
}
// canva登录校验nonce过期
const validateNonce = async (data) => axiosRequest.get(`/canva/validate/nonce?${__fParams(data)}`)

const companyUpdate = (companyId, data) =>
  axiosRequest.put(
    '/gateway-apis/tts/v1/company/' + companyId, data
  )

const companyCreate = data =>
  axiosRequest.post('/gateway-apis/tts/v1/company', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'ww_token':getCookie('ww_token')
    }
  })

const getALLArticles = (data) =>
  axiosRequest.get('/gateway-apis/tts/v1/articles/getALLArticles?' + __fParams(data))

const getSpeakerByType = () =>
  axiosRequest.get('/gateway-apis/tts/v1/mediaPlayer/getSpeakerByType')

const getSearchCriteria = () =>
  axiosRequest.get('/gateway-apis/tts/v1/mediaPlayer/getSearchCriteria')

const getUserSpeakerByType = () =>
  axiosRequest.get('/gateway-apis/tts/v1/mediaPlayer/getUserSpeakerByType')

/*********************************新发音人接口 start***********************************/
// 声音商店查询列表
const searchSpeakerList = data =>
  axiosRequest.get(`/gateway-apis/tts/v1/storeSpeakerV2/searchSpeakerList` + data)
// 获取不同类型发音人（收藏、最近使用、DupdubLib）  
const speakerListByType = data => 
  axiosRequest.get(`/gateway-apis/tts/v1/storeSpeakerV2/speakerListByType` + data)
// 获取用户历史设置信息
const getUserHistory = () => axiosRequest.get('/gateway-apis/account/v1/history')
/*********************************新发音人接口 end*************************************/
const setFavouriteSpeaker = data =>
  axiosRequest.post(
    '/gateway-apis/tts/v1/favourite/setFavouriteSpeaker' + data
  )

const getStoreSearchCriteria = () =>
  axiosRequest.get('/gateway-apis/tts/v1/storeSpeakerV2/getStoreSearchCriteria')

  //声音商店打包用
const searchSpeaker = data =>
  axiosRequest.get('/overseas-apis/tts/v1/storeSpeakerV2/searchSpeaker' + data)

const searchHotVideo = data =>
  axiosRequest.get('/overseas-apis/tts/v1/audios/searchHotVideo' + data)

const getVideoTagsBySpeaker = data =>
  axiosRequest.get('/overseas-apis/tts/v1/audios/getVideoTagsBySpeaker' + data)

// 获取专辑列表
const getCompanyList = () => axiosRequest.get('/gateway-apis/tts/v1/company')

// 音视频工具
const getSubtitleContent = formData =>
  axiosRequest.postFile('/gateway-apis/moyin-tool/v1/subtitles/showContext', formData)

// 提取记录
const getExtractList = params =>
  axiosRequest.get(
    `/gateway-apis/moyin-tool/v1/videoToAudio/getExtractDurationList?${__fParams(params)}`
  )
// 删除记录
const delExtractItem = params =>
  axiosRequest.get(
    `/gateway-apis/moyin-tool/v1/videoToAudio/deleteExtractDuration?${__fParams(params)}`
  )
// 翻译语种的配置
const supportedLanguage = () =>
  axiosRequest.get(
    `/overseas-apis/moyin-tool/translation/supportedLanguage?newServiceFlag=true`
  )

// 翻译
const translationText = formData =>
  axiosRequest.postFile(`/overseas-apis/moyin-tool/translation`,formData)
  
const getTTSVideoUrl = params =>
  axiosRequest.get(`/tts/v2/video?contentType=application/octet-stream&${params}`, {
    timeout: 1000 * 60 * 5
  })

const saveAudio = formData =>
  axiosRequest.postFile('/overseas-apis/tts/v1/audios', formData)

// 获取情绪图片
const getSpeakerEmotionImgList = () =>
  axiosRequest.get(`/overseas-apis/tts/v1/speakerEmotion/list`)

// 声音商店打包用-获取情绪图片
const getSpeakerTypeList = () =>
  axiosRequest.get(`/overseas-apis/tts/v1/speakerType`)

//声音商店关键字suggest查询
const getKeyWordSearch = params =>
  axiosRequest.post(`/overseas-apis/getKeyWordSearch`, params)

// 使用帮助tab的公钥
const getPublicKey = (data) => axiosRequest.post(`/overseas-apis/account/v1/userUsingHelp/getPublicKey?${__fParams(data)}`)

// 新手引导弹窗
const isNewbies = () => axiosRequest.get(`/gateway-apis/account/v1/user/newbies/isNewbies`)

// 更新引导状态标记
const upNewbies  = (data) => axiosRequest.post(`/overseas-apis/account/v1/user/newbies/upNewbies?${__fParams(data)}`)

//播放统计
const addPlayCount = (data) => axiosRequest.post(`/overseas-apis/account/v1/userUsingHelp/addPlayCount?${__fParams(data)}`)

// 创建副本
const copyArticle = data =>
  axiosRequest.post(`/gateway-apis/tts/v1/articles/copy?${__fParams(data)}`)

// 重命名
const renameArticle = data =>
  axiosRequest.put(`/gateway-apis/tts/v1/articles/title/${data.articleId}?${__fParams(data)}`)

//删除账号
const delAccountInfo = () => axiosRequest.put(
  `/gateway-apis/account/v1/user/logout/cancelUser`
)

// 获取初始化折扣信息 
async function getDiscountPriceInfo(){
  return  (await axiosRequest.get(`/gateway-apis/account/v1/background/homeCouponMange`)).data
}
// 获取订阅价目
async function getPriceInfo(){
  return  (await axiosRequest.get(`/gateway-apis/account/v1/product/all`)).data
}

// 联系我们
async function contactUs(data){
  return (await axiosRequest.post(`/gateway-apis/account/v1/contact`, data)).data
}

// 会员信息
async function getVipInfo() {
  return (await axiosRequest.get(`/gateway-apis/account/v1/vip/status`, {hideLoginModal: true})).data
}

// 订阅会员
async function subscribeVip({productType, priceId, freeTrialType, successUrl, cancelUrl, promotionCode, referral }) {
  let promoteInfo = getLocalPromoteInfo()
  return (await axiosRequest.post(`/overseas-apis/account/v1/stripePay/createCheckOut${promoteInfo ? '?' + promoteInfo : ''}`, {
    productType,
    priceId,
    freeTrialType,
    promotionCode,
    sucessUrl: successUrl,
    cancelUrl,
    referral
  })).data
}

// 更新订阅会员
async function updateSubscribeVip({ priceId, returnUrl }) {
  let promoteInfo = getLocalPromoteInfo()
  return (await axiosRequest.get(`/overseas-apis/account/v1/stripePay/upSubscription?${promoteInfo ? promoteInfo + '&' : ''}${__fParams({
    priceId,
    returnUrl
  })}`)).data
}

// 取消会员订阅
async function unsubscribeVip() {
  return (await axiosRequest.get(`/gateway-apis/account/v1/stripePay/deleteSubscription`)).data
}

// 使用兑换码
async function redeemAppsumo({ code }) {
  return (await axiosRequest.post(`/gateway-apis/account/v1/vip/exchange-code?code=${code}`)).data
}

// 保存linkmink追踪数据
async function saveLinkminkData({ lmData }) {
  return (await axiosRequest.post(`/overseas-apis/account/v1/user/save/lmdata`, { lmdata: lmData || '' })).data
}

/****************************** 音视频剪辑 ******************************/

/** 获取项目列表 */
async function getProjectListApi({ page, size, keyword, sort }) {
  return (await axiosRequest.get(`/gateway-apis/tts/v1/project/list?${__fParams({
    page, size, keyword, sort
  })}`)).data
}

/** 更新项目 */
async function updateProjectApi({ id, title, coverUrl, url, type }) {
  return (await axiosRequest.put(`/gateway-apis/tts/v1/project/${ id }`, {
    title, coverUrl, url, type
  })).data
}

/** 删除项目 */
async function deleteProjectApi({ id }) {
  return (await axiosRequest.delNew(`/gateway-apis/tts/v1/project/${ id }`)).data
}


/** 拼接参数 */
function __fParams(params) {
  if (!params || !Object.keys(params).length) return ''
  let paramsStr = ''
  for (let i in params) {
    paramsStr += `${i}=${params[i]}&`
  }
  return paramsStr.substr(0, paramsStr.length - 1)
}

// 多发音人试听
const playDemoChooseForSpeaker = params =>
  axiosRequest.get(
    `/overseas-apis/tts/v1/playDemo/playDemoChooseForSpeaker?${__fParams(
      params
    )}`
  )

let requests = {
  getGestureAvatarTmepId,
  caseUsable,
  avatarCheckList,
  retryAvatarDemo,
  retryAvatarCase,
  deleteAvatarCase,
  avatarCase,
  deleteAvatar,
  avatarUpdatePhoto,
  photoProjectGesture,
  getAccountApiKey,
  featureAdd,
  featureGetClientId,
  transcriptionFormat,
  getAchieveInfoByLink,
  getCreditDetailsList,
  creditBalance,
  playDemoChooseForSpeaker,
  collectSpeakerParams,
  getIsCollect,
  photoProjectCaseTag,
  photoProjectTemplate,
  getInviteUserNums,
  getInviteCode,
  changelog,
  getHasTriggerNps,
  getUserInterviewApi,
  getActivityVisibleStateApi,
  transcriptionList,
  transcriptionDetailDelete,
  transcriptionAlbumList,
  transcriptionAlbumDelete,
  transcriptionAlbumCreate,
  transcriptionAlbumRename,
  transcriptionUpdateAlbum,
  transcriptionUpdateTitle,
  transcriptionDetail,
  transcriptionDetailStatus,
  transcription,
  compensationCredit,
  updatePhoto,
  getProjectsStatus,
  getPhotoDetail,
  deletePhotoList,
  getPhotoList,
  getBlog,
  getBlogDetail,
  abCounponShow,
  UpdateABCounpon,
  orderSubmit,
  productGetSkuList,
  ttsSsml,
  asrBatchQuery,
  asrBatch,
  streamingDownloadFile,
  alignSrt,
  getFileIsExist,
  downloadMedia,
  voiceSearchVideoUrl,
  voiceSearchAudio, 
  thirdpartyAuth,
  auditionV2,
  audition,
  hasTriggerQuestion,
  hasCompleteQuestion,
  postQueryConfig,
  queryConfig,
  getAllAccents,
  likeActivitySpeaker,
  querySpeakerLike,
  searchPageOptions,
  postLexicon,
  delLexicon,
  editLexicon,
  getAllLexicons,
  getAudioKey,
  ablumDelete,
  articlesDelete,
  companyArticlePost,
  companyArticle,
  downloadMp3,
  articlesDownload,
  srtSplit,
  getDiscountInfo,
  isNewFreeCus,
  editNickName,
  delAccountInfo,
  getUserRights,
  videoTranscribe,
  historySpeaker,
  useHistory2,
  getStoreSpeaker2,
  getExtractData,
  getEmailCaptcha,
  overseasLogin,
  overseasLoginOut,
  registerOrResetPassword,
  getUserInfo,
  companyUpdate,
  companyCreate,
  getALLArticles,
  delExtractItem,
  supportedLanguage,
  translationText,
  getExtractList,
  getSpeakerByType,
  getSearchCriteria,
  getUserSpeakerByType,
  searchSpeaker,
  getStoreSearchCriteria,
  setFavouriteSpeaker,
  searchHotVideo,
  getVideoTagsBySpeaker,
  getCompanyList,
  getSubtitleContent,
  getTTSVideoUrl,
  saveAudio,
  getSpeakerEmotionImgList,
  getSpeakerTypeList,
  getKeyWordSearch,
  getPublicKey,
  addPlayCount,
  copyArticle,
  renameArticle,
  getDiscountPriceInfo,
  getPriceInfo,
  contactUs,
  // 会员相关
  getVipInfo,
  subscribeVip,
  updateSubscribeVip,
  unsubscribeVip,
  redeemAppsumo,
  isNewbies,
  upNewbies,
  // 三方推广相关
  saveLinkminkData,
  // 音视频剪辑
  getProjectListApi,
  updateProjectApi,
  deleteProjectApi,
  asrLanguagesQuery,
  transcriptionsBatch,
  transcriptionSave,
  //canva登录相关
  validateNonce,
  searchSpeakerList,
  speakerListByType,
  getUserHistory
}

export default requests
