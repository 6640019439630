import { delCookie } from '@/utils/cookie'
import api from '@/utils/http-request'
import { appEnv } from '@/utils/env'

// 文章字体添加索引
export const addIndex = val => {
  if (!val.length) return
  val.forEach((item, index) => {
    if (item.length === 0) val.splice(index, 1)
  })
  let activeIndex = 0
  val.map(flag => {
    flag.map((item, itemIndex) => {
      item.index = activeIndex
      activeIndex++
      item.originWord.map((data, dataIndex) => {
        if (data.startSpeaker) {
          let flag = data.index - data.start
          data.start = data.index
          data.end = data.end + flag
        }
        if (spliceStatus(data, item, dataIndex, itemIndex)) {
          item.originWord.splice(dataIndex, 1)
        }
      })
    })
  })
  return val
}

const spliceStatus = (data, item, dataIndex, itemIndex) => {
  return (
    !data.word &&
    dataIndex === 0 &&
    itemIndex === 0 &&
    item.originWord.length > 1 &&
    !data.continuousChange &&
    !data.specialStatus
  )
}

// 添加停顿
export const addMute = val => {
  val.forEach(item => {
    let originArr = item[item.length - 1].originWord
    // 判断是否为空段落
    let temp = false
    if (item.length === 1) {
      item[0].originWord.forEach(data => {
        if (data.word) temp = true
      })
    }
    if (item.length > 1) temp = true
    if (
      originArr.length > 0 &&
      temp &&
      !originArr[originArr.length - 1].isMute
    ) {
      item[item.length - 1].originWord.push({
        digital: 600,
        isMute: true,
        visible: false,
        isPlaceHolder: 1,
        word: ''
      })
    }
  })
  return val
}

// 处理文章内容
export const detailArticleContent = val => {
  let articleArr = []
  val.forEach(item => {
    let itemStr = item
      .replace(/。/g, '。\n')
      .replace(/？/g, '？\n')
      .replace(/！/g, '！\n')
      .replace(/；/g, '；\n')
    itemStr = itemStr.replace(/\r/g, '\n')
    let itemArr = []
    let articleSentence = itemStr.split('\n')
    articleSentence.forEach(sentence => {
      if (sentence.length > 0) itemArr.push({ sentence: sentence })
    })
    if (itemArr.length > 0) articleArr.push(itemArr)
  })
  return articleArr
}
// 数据闭环多音字
export const polyPhoneDataLoop = val => {
  let polyPhoneDataArr = []
  val.map(item => {
    item.map(flag => {
      let polyPhoneObj = {
        fromSource: 'edit_platform',
        type: 1
      }
      if (JSON.stringify(flag.originWord).indexOf('isChange') > -1) {
        polyPhoneObj.originText = flag.original
        polyPhoneObj.tnText = flag.tnOriginal
        polyPhoneObj.polyPhoneDataList = []
        flag.originWord.map((data, index) => {
          if (data.isChange) {
            let form = data.originPinyin
            let to = data.pinyin
            polyPhoneObj.polyPhoneDataList.push({
              offset: index,
              from: form,
              to: to
            })
          }
        })
        polyPhoneDataArr.push(polyPhoneObj)
      }
    })
  })
  return polyPhoneDataArr
}

// 数据闭环TN
export const tnDataLoop = val => {
  let polyPhoneDataArr = []
  val.map(item => {
    item.map(flag => {
      let polyPhoneObj = {
        fromSource: 'edit_platform',
        type: 2
      }
      if (JSON.stringify(flag.originWord).indexOf('tnChange') > -1) {
        polyPhoneObj.originText = flag.original
        polyPhoneObj.tnText = flag.tnOriginal
        polyPhoneObj.polyPhoneDataList = []
        flag.originWord.map((data, index) => {
          if (data.tnChange) {
            let origin = data.word
            let originObj = data.originObj
            let tnObj = {}
            tnObj[data.pron] = data.tnObj[data.pron]
            polyPhoneObj.polyPhoneDataList.push(
              Object.assign(
                {
                  offset: index,
                  origin: origin
                },
                originObj,
                tnObj
              )
            )
          }
        })
        polyPhoneDataArr.push(polyPhoneObj)
      }
    })
  })
  return polyPhoneDataArr
}
// 获取更改的数量
export const getChangeNum = val => {
  let changeNum = ''
  val.forEach(item => {
    if (item.isChange || item.tnChange || item.muteChange || item.rhythmData)
      changeNum++
  })
  return changeNum
}
// 获取多发音人/变速arr
export const getMultiVoiceOrVariableSpeed = val => {
  let sentenceArr = []
  let list = []
  val.forEach((item, index) => {
    try {
      if (item.speed && !item.speaker) {
        list.push(item)
        if (
          item.endSpeed ||
          (item.speed && val[index + 1].speaker) ||
          item.speed !== val[index + 1].speed
        ) {
          sentenceArr.push(list)
          list = []
        }
      } else if (item.speaker && !item.speed) {
        list.push(item)
        //判断 index+1 是否越界
        //(val[index +1].speed && item.speaker) 可以用 val[index +1].speed 代替
        if (
          item.endSpeaker ||
          (val[index + 1].speed && item.speaker) ||
          item.speaker !== val[index + 1].speaker
        ) {
          sentenceArr.push(list)
          list = []
        }
      } else if (item.speed && item.speaker) {
        list.push(item)
        if (
          val[index + 1].endSpeaker ||
          !val[index + 1].speed ||
          !val[index + 1].speaker ||
          item.speed !== val[index + 1].speed ||
          item.speaker !== val[index + 1].speaker
        ) {
          sentenceArr.push(list)
          list = []
        }
      } else {
        sentenceArr.push(item)
      }
    } catch {
      sentenceArr.push(list)
    }
  })
  return sentenceArr
}
// 防抖
let timeout = null
export const eventDebounce = (fn, wait) => {
  if (timeout !== null) clearTimeout(timeout)
  timeout = setTimeout(fn, wait)
}

// 节流
export const throttle = (fn, t) => {
  let last
  let timer
  let interval = t || 500
  return function() {
    let args = arguments
    let now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}

// 检查是否为个性化音色
export const isPersonalTTS = speakerSource => {
  if (speakerSource === 'mini_program') {
    return true
  } else {
    return false
  }
}

// 退出登录
export const logout = async () => {
  try {
    await api.overseasLoginOut()
  } catch (e) {
    console.warn('logout error', e);
  }
  delCookie('ww_token')
  sessionStorage.clear()
  localStorage.removeItem('userInfo')
  if(window.location.href.includes('/tools/home')) {
    window.history.go(0)
    return
  }
  if (appEnv === 'development') {
    window.location.href = window.location.origin
  } else if(appEnv === 'prd') {
    window.location.href = 'https://www.dupdub.com'
  } else {
    window.location.href = `https://${appEnv}.dupdub.com`
  }
}
